import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { ListSuggestionWrapper } from '../Suggestion.style';
import { fetchSuggestions, selectSuggestions, selectSuggestionsStatus } from '../../../../../store/SuggestionSlice';
import Heading from '../../../../UI/Title/Heading';
import SuggestionItem from './SuggestionItem';
import Card from '../../../../UI/Wrapper/Card';

function ListSuggestion() {
  const dispatch = useDispatch();
  const suggestions = useSelector(selectSuggestions);
  const status = useSelector(selectSuggestionsStatus);

  const organizedSuggestions = useMemo(() => {
    if (suggestions.length === 0) {
      return [];
    }

    const suggestionsByScope = [];

    suggestions.forEach((suggestion) => {
      suggestion.scope.forEach((scope) => {
        const existingScopeIndex = suggestionsByScope.findIndex((item) => item.scope === scope);

        if (existingScopeIndex === -1) {
          suggestionsByScope.push({
            scope,
            suggestions: [suggestion],
          });
        } else {
          suggestionsByScope[existingScopeIndex].suggestions.push(suggestion);
        }
      });
    });

    return suggestionsByScope;
  }, [suggestions]);

  useEffect(() => {
    if (status !== 'idle') {
      return;
    }

    dispatch(fetchSuggestions({ pagination: false }));
  }, [dispatch, status]);

  return (
    <ListSuggestionWrapper>
      {suggestions.length > 0 &&
        organizedSuggestions.map((sortedS) => (
          <Card key={sortedS.scope} sx={{ maxHeight: '35vh' }}>
            <Heading variant="h3">{sortedS.scope}</Heading>
            {sortedS.suggestions.map((suggestion) => (
              <SuggestionItem key={suggestion.id} suggestionId={suggestion.id} />
            ))}
          </Card>
        ))}
    </ListSuggestionWrapper>
  );
}

export default ListSuggestion;
