import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllPrebidVersion() {
  const url = `${API_BASE_URL}/prebid_versions/fetch`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function modifyPrebidsVersions(data) {
  const url = `${API_BASE_URL}/prebid_versions/update`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}

export async function patchPrebidVersion(id, data) {
  const url = `${API_BASE_URL}/prebid_versions/${id}`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}

export async function getPrebidVersions() {
  const url = `${API_BASE_URL}/prebid_versions?isActive=true`;
  const response = await defaultFetch(url);

  return response.json();
}
