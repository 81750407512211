import styled from '@emotion/styled/macro';
import { AiFillCheckCircle } from 'react-icons/ai';

const CheckIcon = styled(AiFillCheckCircle)`
  align-self: center;
`;

const PositionCardWrapper = styled.div``;

export { CheckIcon, PositionCardWrapper };
