import PropTypes from 'prop-types';
import { ImCheckboxChecked, ImCancelCircle } from 'react-icons/im';

function BooleanIcon(props) {
  const { bool } = props;

  return bool ? <ImCheckboxChecked size={20} color="green" /> : <ImCancelCircle size={20} color="red" />;
}

BooleanIcon.propTypes = {
  bool: PropTypes.bool.isRequired,
};

export default BooleanIcon;
