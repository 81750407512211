/* eslint-disable */
import classes from './PartnerItem.module.css';
import Label from '../../../../UI/Basic/Labels/Label';

function PartnerItem(props) {
  const selectPartnerHandler = () => {
    props.onSelectPartner(props.partnerKeyName);
  };
  const label = props.partner.hasAmpStatus === '1' ? <Label>AMP</Label> : <Label>NON AMP</Label>;

  return (
    <div className={classes.partnerItem} onClick={selectPartnerHandler}>
      <h3 className={classes.name}>
        {props.partnerKeyName} ({props.partner.trigram})
      </h3>
      {label}
    </div>
  );
}

export default PartnerItem;
