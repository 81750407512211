import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { lightGreen, red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { TbDeviceGamepad } from 'react-icons/tb';
import { TiTick } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { lastPositionModificationSelector } from '../../../../../../../store/ModificationsSlice';
import { changeSelectedTabBiddersNode } from '../TabBiddersModificationsSlice';
import { StyledListItemButton } from './SelectionItem.style';
import useSelectedTabBidderNode from './useSelectedTabBidderNode';

function SelectionItemMediatype(props) {
  const { item, metadata, added } = props;
  const dispatch = useDispatch();

  const newMetadata = useMemo(() => ({ ...metadata, mediatype: item.id }), [item.id, metadata]);
  const selected = useSelectedTabBidderNode(newMetadata);
  const selectorArgs = useMemo(
    () => ({ pagetypeId: -1, deviceId: newMetadata.device, positionId: newMetadata.position }),
    [newMetadata.device, newMetadata.position]
  );

  const selectorLastModification = useMemo(lastPositionModificationSelector, []);
  const lastModificationPosition = useSelector((state) => selectorLastModification(state, selectorArgs));

  const handleClick = () => {
    dispatch(changeSelectedTabBiddersNode({ id: item.node, metadata: newMetadata }));
  };

  const render = useMemo(() => {
    if (lastModificationPosition?.operation === 'removePosition' || (!lastModificationPosition && !item.active)) {
      return {
        backgroundColor: red[100],
        icon: <BsExclamationCircleFill color="red" />,
      };
    }

    if (added) {
      return {
        backgroundColor: lightGreen[100],
        icon: <TiTick style={{ backgroundColor: 'green', borderRadius: '50%', color: 'white' }} />,
      };
    }

    return {
      icon: <TbDeviceGamepad />,
    };
  }, [added, lastModificationPosition, item.active]);

  return (
    <StyledListItemButton onClick={handleClick} backgroundColor={render?.backgroundColor} selected={selected}>
      <ListItemIcon sx={{ minWidth: '30px' }}>{render?.icon}</ListItemIcon>
      <ListItemText primary={item.name} />
    </StyledListItemButton>
  );
}

SelectionItemMediatype.propTypes = {
  metadata: PropTypes.shape({
    device: PropTypes.number,
    position: PropTypes.number,
  }).isRequired,
  item: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    node: PropTypes.number,
    active: PropTypes.bool,
  }).isRequired,
  added: PropTypes.bool,
};

SelectionItemMediatype.defaultProps = {
  added: false,
};

export default SelectionItemMediatype;
