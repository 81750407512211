/* eslint-disable */
import PartnerItem from './PartnerItem';
import classes from './PartnersList.module.css';
import RoundedNumber from '../../../../UI/Basic/RoundedNumber';

function PartnersList(props) {
  let partnersList = <p>En cours de chargement...</p>;

  const partnerSelectedHandler = (partnerKeyName) => {
    props.onPartnerSelected(partnerKeyName);
  };

  if (props.partners) {
    partnersList = [];
    for (const partnerKeyName in props.partners) {
      partnersList.push(
        <PartnerItem
          partnerKeyName={partnerKeyName}
          onSelectPartner={partnerSelectedHandler}
          key={partnerKeyName}
          partner={props.partners[partnerKeyName]}
        />
      );
    }
  }

  return (
    <div>
      <div className={classes.flex}>
        <RoundedNumber number="1" />
        <h2>Sélectionnez un site partenaire</h2>
      </div>
      <div className={classes.partnersList}>{partnersList}</div>
    </div>
  );
}

export default PartnersList;
