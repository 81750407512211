import { useMemo } from 'react';
import { BsShop } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import GoogleTagVersion from '../../../../../types/GoogleTagVersionType';
import Heading from '../../../../UI/Title/Heading';
import { CartWrapper, SelectionWrapper } from './Cart.style';
import DraftConfirmation from './DraftConfirmation';
import SelectionDraft from './SelectionDraft/SelectionDraft';

function Cart(props) {
  const { gtag } = props;

  const draft = useSelector((state) => state.draft.draft);

  const idDraft = useMemo(() => (draft ? draft.id ?? 'new' : 'new'), [draft]);

  return (
    <CartWrapper>
      <div>
        <Heading variant="h2">
          <BsShop /> Votre panier de modifications (GTAG : {gtag ? gtag.releaseId : ''})
        </Heading>
        <p>{draft ? 'Vous avez un draft en cours. Pensez à le valider !' : 'Aucun draft sauvegardé'}</p>
        <DraftConfirmation />
      </div>
      <SelectionWrapper>
        <SelectionDraft draftId={idDraft} />
      </SelectionWrapper>
    </CartWrapper>
  );
}

Cart.propTypes = {
  gtag: GoogleTagVersion.isRequired,
};

export default Cart;
