import styled from '@emotion/styled/macro';
import { Button, css } from '@mui/material';

const MassButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => !['colorButton', 'width', 'sx'].includes(prop),
})(
  ({ width, colorButton }) => css`
    width: ${width};
    text-align: center;
    background-color: ${colorButton};
    border-radius: 10px;
    color: white;
    border: 1px solid transparent;

    &:hover {
      background-color: white;
      border-radius: 10px;
      border: 1px solid ${colorButton};
      color: ${colorButton};
    }

    &:disabled {
      background-color: lightgrey;
    }
  `
);

export { MassButtonStyled };
