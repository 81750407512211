import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import DraftConfirmation from '../../GoogleTag/Edit/MultiUnitModification/Cart/DraftConfirmation';
import History from '../../GoogleTag/Edit/MultiUnitModification/Sidebar/History/History';
import { CustomTabs } from '../../UI/Tabs/Tabs.style';
import SidebarAction from './Action/SidebarAction';
import SidebarFilter from './Filter/SidebarFilter';
import { SidebarContent, SidebarTab, SidebarTabs, SidebarWrapper } from './Sidebar.style';
import MepFilterSidebar from './MepFilter/MepFilterSidebar';
import SelectionDraft from '../../GoogleTag/Edit/MultiUnitModification/Cart/SelectionDraft/SelectionDraft';

function Sidebar(props) {
  const { headerTab, tab, handleChange, handleFilterChange } = props;

  const draft = useSelector((state) => state.draft.draft);

  const idDraft = useMemo(() => draft?.id ?? 'new', [draft]);

  return (
    <SidebarWrapper>
      <SidebarTabs
        value={tab}
        onChange={handleChange}
        sx={CustomTabs}
        TabIndicatorProps={{ style: { background: 'transparent', height: 2 } }}
      >
        <SidebarTab value={1} label="Filtre" sx={{ backgroundColor: tab === 1 ? 'white' : 'transparent' }} />
        <SidebarTab value={2} label="Action" sx={{ backgroundColor: tab === 2 ? 'white' : 'transparent' }} />
      </SidebarTabs>
      <SidebarContent tab={tab}>
        {tab === 1 && headerTab !== 5 && <SidebarFilter handleFilterChange={handleFilterChange} />}
        {tab === 1 && headerTab === 5 && <MepFilterSidebar />}
        {tab === 2 && <SidebarAction headerTab={headerTab} />}
      </SidebarContent>
      {draft && (
        <>
          <hr />
          <SidebarContent>
            <SelectionDraft type={1} draftId={idDraft} />
          </SidebarContent>
        </>
      )}
      <hr />
      <SidebarContent>
        <History reload mass={1} />
      </SidebarContent>
      <hr />
      <SidebarContent>
        <DraftConfirmation direction="column" />
      </SidebarContent>
    </SidebarWrapper>
  );
}

Sidebar.propTypes = {
  headerTab: PropTypes.number.isRequired,
  tab: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default Sidebar;
