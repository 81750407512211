import { TablePagination } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Loader from '../Animation/Loader';
import HeadTable from './Head/HeadTable';
import { CustomTR, LoaderWrapper, ScrollWrapper, TableWrapper } from './Table.style';

function Table(props) {
  const { width, columns, getData, itemsPerPage, Line, data, total, pageExt, setPageExt, pagination, heightScroll } =
    props;

  const [heightTable, setHeightTable] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef(null);

  const updateTableHeight = useCallback(() => {
    const height = heightScroll ?? `${window.innerHeight - 350}px`;
    let currentMaxHeight = 0;
    if (height.slice(-2) === 'vh') {
      currentMaxHeight = Math.round(window.innerHeight * (parseInt(height.substring(-2), 10) / 100));
    } else {
      currentMaxHeight = parseInt(height.substring(-2), 10);
    }

    setHeightTable(currentMaxHeight);
  }, [heightScroll]);

  /**
   * Permet d'écouter lorsque la fenêtre est resize
   */
  useLayoutEffect(() => {
    window.addEventListener('resize', updateTableHeight);
    updateTableHeight();

    return () => window.removeEventListener('resize', updateTableHeight);
  }, [updateTableHeight]);

  useEffect(() => {
    // Vérification de si la première requête de data a été faite.
    if (data.length > 0 && tableRef.current.clientHeight > 0) {
      // Si la taille maximale du tableau n'est pas atteinte avec la/les première(s) requête(s)
      if (heightTable > tableRef.current.clientHeight) {
        // Rajout d'une requête de data pour que le scroll existe
        if (pageExt !== null) {
          setPageExt(pageExt + 1);
        } else {
          setPage(page + 1);
        }
      }
    }
    // Ajout de la non exhaustive deps pour que l'useEffect ne se réexecute pas à chaque changement de page.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, heightTable]);

  const handleScroll = useCallback(() => {
    const pageItem = pageExt ?? page;
    // Calcul du scroll de la div
    const tableElement = tableRef.current;
    if (!tableElement) return;

    const { scrollTop } = tableElement;
    const { scrollHeight } = tableElement;
    const { clientHeight } = tableElement;

    if (scrollTop + clientHeight < scrollHeight || isLoading || (pageItem + 1) * itemsPerPage >= total || pagination) {
      return;
    }

    setIsLoading(true);
    setPage(page + 1);
    setPageExt(pageExt + 1);
  }, [pageExt, page, isLoading, itemsPerPage, total, pagination, setPageExt]);

  useEffect(() => {
    const currentRef = tableRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!pageExt) {
      getData(page, itemsPerPage);
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageExt, itemsPerPage]);

  const handleChangePage = (event, newPage) => {
    if (pageExt !== null) {
      setPageExt(newPage);
    } else {
      setPage(newPage);
    }
  };

  return (
    <>
      <TableWrapper width={width} stickyHeader>
        <HeadTable columns={columns} />
        <ScrollWrapper ref={tableRef} height={`${heightTable}px`}>
          {data.map((item) => (
            <CustomTR key={item.id}>
              <Line item={item} page={page} itemsPerPage={itemsPerPage} />
            </CustomTR>
          ))}
        </ScrollWrapper>
      </TableWrapper>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[itemsPerPage]}
          component="div"
          count={total}
          rowsPerPage={itemsPerPage}
          page={pageExt || page}
          onPageChange={handleChangePage}
        />
      )}
      {isLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </>
  );
}

Table.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ).isRequired,
  getData: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  Line: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageExt: PropTypes.number,
  setPageExt: PropTypes.func,
  pagination: PropTypes.bool,
  heightScroll: PropTypes.string,
};

Table.defaultProps = {
  width: '100%',
  itemsPerPage: 30,
  pageExt: null,
  pagination: false,
  setPageExt: () => {},
  heightScroll: null,
};

export default Table;
