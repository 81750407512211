import { MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { BiCodeBlock, BiVideoRecording } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { TbTestPipe2 } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser, selectCurrentUser, selectUser, updateUser } from '../../../../store/UsersSlice';
import AlertDialog from '../../../UI/Dialog/Dialog';
import LinkWrapper from '../../../UI/LinkWrapper/LinkWrapper';
import { CheckIcon, ColumnStyle, EmptyDiv, IconWrapper, ItemWrapper } from './UserItem.style';

const RIGHTS = [
  {
    value: 'ROLE_QUAL',
    title: 'Changer le droit de mise en qual',
    icon: TbTestPipe2,
  },
  {
    value: 'ROLE_PROD',
    title: 'Changer le droit de mise en prod',
    icon: BiVideoRecording,
  },
  {
    value: 'ROLE_BLOC',
    title: 'Changer le droit de gestion des blocs',
    icon: BiCodeBlock,
  },
];

function UserItem(props) {
  const { userId } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const userSelector = useMemo(selectUser, []);
  const user = useSelector((state) => userSelector(state, userId));
  const currentUser = useSelector(selectCurrentUser);

  const [role, setRole] = useState(user.roles[0]);
  const [open, setOpen] = useState(false);
  const [modifyOpen, setModifyOpen] = useState(false);

  const sameProfile = useMemo(() => currentUser?.id === userId, [currentUser?.id, userId]);

  const onChange = (event) => {
    setRole(event.target.value);
  };

  const onChangeRole = (roleToChange) => {
    const roleFunc = roleToChange.slice(5, roleToChange.length).toLowerCase();
    let roles;

    if (user.roles.includes(roleToChange)) {
      roles = user.roles.filter((roleFor) => roleFor !== roleToChange);
    } else {
      roles = [...user.roles, roleToChange];
    }

    dispatch(updateUser({ id: userId, body: { roles } }))
      .unwrap()
      .then(() =>
        enqueueSnackbar(`Role de ${roleFunc} a été modifié pour ${user.firstname} ${user.lastname}`, {
          variant: 'success',
        })
      );
  };

  useEffect(() => {
    if (!sameProfile) {
      setModifyOpen(role !== user.roles[0]);
    }
  }, [role, sameProfile, user.roles]);

  const deleteItem = () => {
    dispatch(removeUser(user.id))
      .unwrap()
      .then(() =>
        enqueueSnackbar(`L'utilisateur ${user.firstname} ${user.lastname} a été supprimé`, { variant: 'success' })
      )
      .finally(() => setOpen(false));
  };

  const confirmRoleChange = () => {
    const data = { roles: [role] };
    dispatch(updateUser({ id: userId, body: data }))
      .unwrap()
      .then(() =>
        enqueueSnackbar(`L'utilisateur ${user.firstname} ${user.lastname} a changé de role`, { variant: 'success' })
      );
  };

  return (
    <ItemWrapper>
      <AlertDialog
        open={open}
        content={`Voulez-vous supprimer l'utilisateur ${user.firstname} ${user.lastname}`}
        title="Suppression d'utilisateur"
        onCancel={() => setOpen(false)}
        onValidate={deleteItem}
        cancelLabel="NON"
        validateLabel="OUI"
      />
      <ColumnStyle left>{user.id}</ColumnStyle>
      <ColumnStyle>{user.lastname}</ColumnStyle>
      <ColumnStyle>{user.firstname}</ColumnStyle>
      <ColumnStyle>{user.email}</ColumnStyle>
      <ColumnStyle>
        <Select value={role} onChange={onChange} disabled={sameProfile}>
          <MenuItem value="ROLE_USER">Utilisateur</MenuItem>
          <MenuItem value="ROLE_ADMIN">Administrateur</MenuItem>
        </Select>
        {modifyOpen && (
          <LinkWrapper onClick={confirmRoleChange}>
            <CheckIcon size={30} color="green" title="Changer le role de l'utilisateur" />
          </LinkWrapper>
        )}
      </ColumnStyle>
      <ColumnStyle right>
        <IconWrapper>
          {!sameProfile ? (
            <LinkWrapper onClick={() => setOpen(true)}>
              <RiDeleteBin6Line size={30} />
            </LinkWrapper>
          ) : (
            <EmptyDiv style={{ width: '30px' }} />
          )}
          {RIGHTS.map((right) => (
            <LinkWrapper key={right.value} onClick={() => onChangeRole(right.value)}>
              <right.icon size={30} color={user.roles.includes(right.value) ? 'green' : 'red'} title={right.title} />
            </LinkWrapper>
          ))}
        </IconWrapper>
      </ColumnStyle>
    </ItemWrapper>
  );
}

UserItem.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default UserItem;
