import PropTypes from 'prop-types';

function SettingDetail(props) {
  const { modification } = props;
  const { metadata } = modification;

  return (
    <div>
      La variable <strong>({metadata.variableName})</strong> a été modifiée
    </div>
  );
}

SettingDetail.propTypes = {
  modification: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      variableName: PropTypes.string,
    }).isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default SettingDetail;
