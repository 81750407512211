import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import { LoaderWrapper, PartnerContainer } from './Partner.style';
import GoogleTagInfos from './GoogleTagInfos/GoogleTagInfos';
import UpdatePartner from './UpdatePartner/UpdatePartner';
import Loader from '../UI/Animation/Loader';
import BidderGraph from './BidderGraph/BidderGraph';
import Errors from './Errors/Errors';
import PartnerModifications from './Modifications/PartnerModifications';
import GoogleTagList from './GoogleTagVersionsList/GoogleTagList';
import { selectCurrentUser } from '../../store/UsersSlice';
import { fetchPartner, fetchPartnerInfos, selectCurrentPartner, selectPartnerInfos } from './PartnerPageSlice';

function Partner() {
  const { partnerId } = useParams();
  const currentUser = useSelector(selectCurrentUser);

  const dispatch = useDispatch();
  const infos = useSelector(selectPartnerInfos);
  const partner = useSelector(selectCurrentPartner);

  const fetchInfos = useCallback(() => {
    dispatch(fetchPartnerInfos(partnerId));
  }, [dispatch, partnerId]);

  useEffect(() => {
    dispatch(fetchPartner(partnerId));
  }, [dispatch, infos, partnerId]);

  const adminRole = useMemo(() => currentUser?.roles.includes('ROLE_ADMIN'), [currentUser]);

  useEffect(() => {
    fetchInfos();
  }, [fetchInfos]);

  if (!partner || !infos || partner.id !== parseInt(partnerId, 10))
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  return (
    <PartnerContainer>
      <div>
        <GoogleTagInfos />
        <BidderGraph />
        <hr />
        <Errors />
        <hr />
        <GoogleTagList />
      </div>
      <Card sx={{ padding: '20px' }}>
        {adminRole && (
          <>
            <UpdatePartner />
            <hr />
          </>
        )}
        <PartnerModifications />
      </Card>
    </PartnerContainer>
  );
}

export default Partner;
