import BodyRightSidebar from './Body/BodyRightSidebar';
import FooterRightSidebar from './Footer/FooterRightSidebar';
import HeaderRightSidebar from './Header/HeaderRightSidebar';
import { BlockCodeEditorContainer } from './RightSidebarCodeEditor.style';

export default function RightSidebarCodeEditor() {
  return (
    <BlockCodeEditorContainer>
      <HeaderRightSidebar />
      <BodyRightSidebar />
      <FooterRightSidebar />
    </BlockCodeEditorContainer>
  );
}
