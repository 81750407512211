import styled from '@emotion/styled/macro';

const NoNotificationsContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 22px;
  color: grey;
  font-style: italic;
`;

export { NoNotificationsContainer };
