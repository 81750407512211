/* eslint-disable */
import classes from './GoogleTagsList.module.css';
import GtagItem from './GtagItem';
import RoundedNumber from '../../../UI/Basic/RoundedNumber';

function GoogleTagsList(props) {
  let list = <p>Choisissez un site d'abord</p>;

  if (props.gtags) {
    list = [];
    props.gtags.map((gtag, index) => {
      list.push(
        <GtagItem gtagIndex={index} keyName={props.keyName} onAddGtag={props.onGtagSelected} key={index} gtag={gtag} />
      );
    });
  }

  return (
    <div>
      <div className={classes.flex}>
        <RoundedNumber number="2" />
        <h2>Sélectionnez un Google Tag</h2>
      </div>
      <div className={classes.googleTagsList}>{list}</div>
    </div>
  );
}

export default GoogleTagsList;
