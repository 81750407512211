/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllSizes } from '../helpers/api/sizes';

const domain = 'sizes';

// Async actions
export const fetchSizes = createAsyncThunk(`${domain}/fetchSizes`, async (thunkAPI) => {
  try {
    return getAllSizes();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  sizes: [],
  status: 'idle',
  error: null,
};

export const sizesSlice = createSlice({
  name: domain,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSizes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSizes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sizes = action.payload['hydra:member'];
      })
      .addCase(fetchSizes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions

// Selectors
export const selectSizes = (state) => state[domain].sizes;
export const selectSizesStatus = (state) => state[domain].status;
export const selectSize = () =>
  createSelector(
    (state) => state[domain],
    (_, ids) => ids,
    (state, ids) => state.sizes.filter((size) => ids.includes(size.id))
  );

export default sizesSlice.reducer;
