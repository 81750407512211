import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { clearCurrentDraft, fetchDraft, fetchSelectedDraft } from '../../store/DraftSlice';
import { fetchAllGoogleTagVersion } from '../../store/GoogleTagVersionSlice';
import { resetModifications } from '../../store/ModificationsSlice';
import Content from './Content/Content';
import HeaderTabs from './HeaderTabs/HeaderTabs';
import { MassModificationWrapper } from './Massmodification.style';
import Sidebar from './Sidebar/Sidebar';
import Heading from '../UI/Title/Heading';

function MassModification() {
  const [headerTab, setHeaderTab] = useState(1);
  const [sidebarTab, setSidebarTab] = useState(1);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const googleTagDraftID = useMemo(() => searchParams.get('draft'), [searchParams]);

  const [selectedPrebidVersion, setSelectedPrebidVersion] = useState(null);
  const [selectedBidder, setSelectedBidder] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPartners, setSelectedPartners] = useState(null);

  const [page, setPage] = useState(0);

  const handleChangeHeader = (event, newValue) => {
    setHeaderTab(newValue);
  };

  const handleChangeSidebar = (event, newValue) => {
    setSidebarTab(newValue);
  };

  const fetchGoogleTag = useCallback(() => {
    const data = {
      'exists[googleTagPositionConfigs]': true,
      page: page + 1,
      itemsPerPage: 20,
    };

    if (selectedStatus) data.status = selectedStatus.value;
    if (selectedBidder?.length) data.bidder = selectedBidder.map((e) => e.id);
    if (selectedPrebidVersion?.length) data.prebidVersion = selectedPrebidVersion.map((e) => e.id);
    if (selectedSize?.length) data.size = selectedSize.map((e) => e.id);
    if (selectedPosition?.length) data.position = selectedPosition.map((e) => e.id);
    if (selectedPartners?.length) data.partnerWebsites = selectedPartners.map((e) => e.id);

    dispatch(fetchAllGoogleTagVersion(data));
  }, [
    dispatch,
    page,
    selectedBidder,
    selectedPartners,
    selectedPosition,
    selectedPrebidVersion,
    selectedSize,
    selectedStatus,
  ]);

  useEffect(() => {
    dispatch(resetModifications());
    if (googleTagDraftID) {
      if (googleTagDraftID !== 'new') {
        dispatch(fetchSelectedDraft(googleTagDraftID));
      } else {
        dispatch(clearCurrentDraft());
      }
    } else {
      dispatch(fetchDraft());
    }
  }, [dispatch, googleTagDraftID]);

  useEffect(() => {
    fetchGoogleTag();
  }, [fetchGoogleTag]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleFilterChange = (data) => {
    const { prebidVersion, bidder, size, position, status, partners } = data;

    setPage(0);

    setSelectedPrebidVersion(prebidVersion);
    setSelectedBidder(bidder);
    setSelectedSize(size);
    setSelectedPosition(position);
    setSelectedStatus(status);
    setSelectedPartners(partners);
  };

  return (
    <div>
      <Heading variant="h2">Modifications de masse</Heading>
      <HeaderTabs value={headerTab} handleChange={handleChangeHeader} />
      <MassModificationWrapper>
        <Content tab={headerTab} fetchGoogleTag={fetchGoogleTag} page={page} setPage={handlePageChange} />
        <Sidebar
          headerTab={headerTab}
          tab={sidebarTab}
          handleChange={handleChangeSidebar}
          handleFilterChange={handleFilterChange}
        />
      </MassModificationWrapper>
    </div>
  );
}

export default MassModification;
