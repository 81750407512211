/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllPositions, postPositions } from '../helpers/api/positions';

const domain = 'positions';

// Async actions
export const fetchPositions = createAsyncThunk(`${domain}/fetchPositions`, async (thunkAPI) => {
  try {
    return getAllPositions();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createPosition = createAsyncThunk(`${domain}/createPosition`, async (body, thunkAPI) => {
  try {
    return postPositions(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  positions: [],
  status: 'idle',
  error: null,
};

export const positionsSlice = createSlice({
  name: domain,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPositions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPositions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.positions = action.payload['hydra:member'];
      })
      .addCase(fetchPositions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(createPosition.fulfilled, (state, action) => {
        state.positions = state.positions.concat(action.payload).sort((a, b) => {
          const aLower = a.genericName.toLowerCase();
          const bLower = b.genericName.toLowerCase();
          if (aLower < bLower) {
            return -1;
          }

          if (aLower > bLower) {
            return 1;
          }

          return 0;
        });
        state.status = 'succeeded';
      });
  },
});

// Actions

// Selectors
export const selectAllPositions = (state) => state[domain].positions;
export const selectAllPositionsStatus = (state) => state[domain].status;
export const selectPositions = () =>
  createSelector(
    (state) => state[domain],
    (_, ids) => ids,
    (state, ids) => state.positions.filter((position) => ids.includes(position.id))
  );
export const selectPosition = () =>
  createSelector(
    (state) => state[domain],
    (_, id) => id,
    (state, id) => state.positions.find((position) => id === position.id)
  );

export default positionsSlice.reducer;
