import PropTypes from 'prop-types';

const GoogleTagVersion = PropTypes.shape({
  '@context': PropTypes.string,
  '@id': PropTypes.string,
  '@type': PropTypes.string,
  id: PropTypes.number,
  author: PropTypes.shape({
    trigram: PropTypes.string,
  }),
  createdAt: PropTypes.string,
  description: PropTypes.string,
  googleTagSnapshot: PropTypes.string,
  googleTagStatus: PropTypes.shape({
    name: PropTypes.string,
  }),
  hasDigitekaPlayer: PropTypes.bool,
  hasVerticalLoading: PropTypes.bool,
  partnerGroup: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  prebidVersion: PropTypes.oneOfType([
    PropTypes.shape({
      version: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  prodMepStatus: PropTypes.number,
  qualMepStatus: PropTypes.number,
  releaseIndex: PropTypes.number,
  releaseId: PropTypes.string,
  updatedAt: PropTypes.string,
  uuid: PropTypes.string,
  validatedAt: PropTypes.string,
  googleTagDraft: PropTypes.arrayOf(PropTypes.string),
  googleTagPositionConfigs: PropTypes.arrayOf(PropTypes.string),
  gtagBlockVersions: PropTypes.arrayOf(PropTypes.string),
  gtagComments: PropTypes.arrayOf(PropTypes.string),
  unitUpdateHistories: PropTypes.arrayOf(PropTypes.string),
  inQualAt: PropTypes.string,
  inProdAt: PropTypes.string,
});

export default GoogleTagVersion;
