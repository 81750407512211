import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createModificationForSizes } from '../../../../../../helpers/modificationsTools';
import SizeType from '../../../../../../types/Size/SizeType';
import RemovableLabel from '../../../../../UI/Basic/Labels/RemovableLabel';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { addSizeModification } from '../../../../../../store/ModificationsSlice';
import { selectNodesHeaders, selectNodesHeadersToggled } from './NodesSlice';

function SizeObject(props) {
  const { size, pagetype, device, position, source } = props;

  const dispatch = useDispatch();
  const headers = useSelector(selectNodesHeaders);
  const headersToggled = useSelector(selectNodesHeadersToggled);
  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const label = `${size.concatenatedSize} ${size.stringValue ? size.stringValue : ''}`;

  const handleClick = useCallback(() => {
    if (pagetype === null || device == null || position == null || source == null) {
      return;
    }

    const newModification = createModificationForSizes(
      'removeSize',
      {
        sizeId: size.id,
        pagetype,
        device,
        position,
        headers,
        headersToggled,
        source,
      },
      [googleTagVersion['@id']]
    );

    dispatch(addSizeModification(newModification));
  }, [device, dispatch, googleTagVersion, headers, headersToggled, pagetype, position, size.id, source]);

  return (
    <div>
      <RemovableLabel
        onClick={() => handleClick()}
        height="auto"
        width="auto"
        backgroundColor={`#${size.hexColorCode.code}`}
      >
        {label}
      </RemovableLabel>
    </div>
  );
}

SizeObject.propTypes = {
  size: SizeType.isRequired,
  pagetype: PropTypes.number,
  device: PropTypes.number,
  position: PropTypes.number,
  source: PropTypes.string,
};

SizeObject.defaultProps = {
  source: null,
  pagetype: null,
  device: null,
  position: null,
};

export default SizeObject;
