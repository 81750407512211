import styled from '@emotion/styled/macro';

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a9d8f;
  height: 70px;
  text-align: center;
  color: #ffffff;
  flex-direction: column;
  flex: 0 0 auto;
`;

const Copyright = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`;

const SubCopyright = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  font-size: 0.8em;
`;

export { Wrapper, Copyright, SubCopyright };
