import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Heading from '../Title/Heading';
import { IconWrapper } from './MuiModal.style';

const style = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.1px solid black',
  borderRadius: 2,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function MuiModal(props) {
  const { children, open, handleClose, width, title } = props;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width }}>
        {title && (
          <IconWrapper>
            <Heading variant="h2">{title}</Heading>
          </IconWrapper>
        )}
        {children}
      </Box>
    </Modal>
  );
}

MuiModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
};

MuiModal.defaultProps = {
  width: 400,
  title: '',
};

export default MuiModal;
