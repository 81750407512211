import React from 'react';
import PropTypes from 'prop-types';
import PopoverTemplate from './PopoverTemplate';
import History from '../../Edit/MultiUnitModification/Sidebar/History/History';

function ViewModificationPopover(props) {
  const { anchorEl, setAnchorEl } = props;

  return (
    <PopoverTemplate
      setAnchorEl={setAnchorEl}
      anchorEl={anchorEl}
      horizontal="left"
      vertical="bottom"
      title="Modifications"
      header={false}
    >
      <History />
    </PopoverTemplate>
  );
}

ViewModificationPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setAnchorEl: PropTypes.func.isRequired,
};

ViewModificationPopover.defaultProps = {
  anchorEl: 'button',
};

export default ViewModificationPopover;
