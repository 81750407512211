import { Button, FormHelperText, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useWatch } from 'react-hook-form';
import { MdOutlineAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import Heading from '../../UI/Title/Heading';
import {
  fetchPrebidFloorFieldsTarget,
  selectPrebidFloorFields,
  selectPrebidFloorFieldsDetails,
  updateDetail,
} from '../PrebidFloorFieldsSlice';
import Delimiter from './Delimiter';
import { DroppableList } from './FloorComponents.style';
import RuleItem from './RuleItem';
import SchemaFields from './SchemaFields';

function FloorCore(props) {
  const { control, hidden, registerTarget, errors, setValue } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const prebidFloorFields = useSelector(selectPrebidFloorFields);
  const prebidFloorFieldsDetails = useSelector(selectPrebidFloorFieldsDetails);

  const registerTargetValues = useMemo(() => `${registerTarget}values`, [registerTarget]);
  const registerTargetDelimiter = useMemo(() => `${registerTarget}schema.delimiter`, [registerTarget]);
  const registerTargetFields = useMemo(() => `${registerTarget}schema.fields`, [registerTarget]);

  const selectedFields = useWatch({ control, name: registerTargetFields });
  const { fields, prepend, insert, remove, move } = useFieldArray({
    control,
    name: registerTargetValues,
    rules: {
      validate: {
        required: (value) => {
          if (!hidden && !value?.length) {
            return 'Au moins une valeur est requise';
          }

          return true;
        },
      },
    },
  });

  // Permet de charger les tables depuis la BDD
  useEffect(() => {
    if (!selectedFields) {
      return;
    }

    selectedFields.forEach((field) => {
      if (!(field in prebidFloorFieldsDetails)) {
        const element = prebidFloorFields.find((e) => e.name === field);

        if (!element) {
          enqueueSnackbar(`Le champ '${field}' n'a pas été trouvé dans le référentiel des fields.`, {
            variant: 'warning',
          });
        }

        if (element?.target) {
          dispatch(fetchPrebidFloorFieldsTarget(element.id))
            .unwrap()
            .then((data) => dispatch(updateDetail({ key: field, value: data })));
        }

        // On dispatch une value vide pour éviter le chargement de la même ressource plusieurs fois
        dispatch(updateDetail({ key: field, value: null }));
      }
    });
  }, [selectedFields, prebidFloorFieldsDetails, prebidFloorFields, enqueueSnackbar, dispatch]);

  const rootError = useReactHookFormError(registerTargetValues, errors);

  const appendLine = () => {
    const line = { value: '' };
    if (selectedFields) {
      selectedFields.forEach((field) => {
        line[field] = '';
      });
    }

    prepend(line);
  };

  const handleDragEnd = (result) => {
    move(result.source.index, result.destination.index);
  };

  return (
    <>
      <Heading variant="h2">Schéma</Heading>
      <Delimiter control={control} registerTarget={registerTargetDelimiter} />
      <SchemaFields
        control={control}
        hidden={hidden}
        registerTarget={registerTargetFields}
        errors={errors}
        setValue={setValue}
      />
      <Heading variant="h2">
        Règles &nbsp;
        <Tooltip title="Ajouter une règle">
          <Button type="button" onClick={() => appendLine()} variant="contained">
            <MdOutlineAdd />
          </Button>
        </Tooltip>
      </Heading>
      {!!rootError?.root && <FormHelperText error>{rootError?.root?.message}</FormHelperText>}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`droppable-${registerTargetValues}`}>
          {(provided) => (
            <DroppableList ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((item, index) => (
                <RuleItem
                  key={item.id}
                  control={control}
                  remove={remove}
                  insert={insert}
                  registerTarget={registerTargetValues}
                  selectedFields={selectedFields ?? []}
                  index={index}
                  item={item}
                  errors={errors}
                  hidden={hidden}
                />
              ))}
              {provided.placeholder}
            </DroppableList>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

FloorCore.propTypes = {
  setValue: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  registerTarget: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default FloorCore;
