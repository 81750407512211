import Editor from '@monaco-editor/react';
import { useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

function FunctionVariable() {
  const editorRef = useRef(null);
  const { control } = useFormContext();
  const { field } = useController({ name: 'value', control });

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <Editor
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      height="100px"
      language="javascript"
      options={{ lineNumbers: 'off', folding: false, minimap: { enabled: false } }}
      onMount={handleEditorDidMount}
    />
  );
}

export default FunctionVariable;
