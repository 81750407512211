import Heading from '../../UI/Title/Heading';
import { BlockAdminWrapper } from './AdminBlock.style';
import BlockCreation from './BlockCreation/BlockCreation';
import BlockList from './BlockList/BlockList';

function AdminBlock() {
  return (
    <>
      <Heading>Administration des blocs pour la création d&#39;un GTAG</Heading>
      <BlockAdminWrapper>
        <BlockList />
        <BlockCreation />
      </BlockAdminWrapper>
    </>
  );
}

export default AdminBlock;
