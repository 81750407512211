import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 1rem 1.5rem;
  border-radius: 10px;
`;

const Scrollable = styled.div`
  height: 55vh;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
`;

const HeaderBlockList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { Wrapper, Scrollable, HeaderBlockList };
