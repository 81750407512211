import styled from '@emotion/styled/macro';

const PartnerButton = styled.div`
  background-color: #264653;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  overflow-x: hidden;
  border-bottom-left-radius: ${(props) => props.isSelected && '0'};
  border-bottom-right-radius: ${(props) => props.isSelected && '0'};
  color: ${(props) => props.isSelected && 'orange'};
`;

const PartnerItemWrapper = styled.div`
  margin-bottom: 3px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PrebidSection = styled.p`
  margin-right: 20px;
  padding: 10px;
  background-color: ${({ color }) => color};
  color: white;
`;

export { PartnerButton, PartnerItemWrapper, IconWrapper, PrebidSection };
