import styled from '@emotion/styled/macro';

const TableContainer = styled.div`
  max-height: 66vh;
  overflow-y: scroll;
  width: 100%;
`;

const TablePosition = styled.table`
  width: 100%;
`;

const TheadPosition = styled.tr`
  border: 1px solid black;
  padding: 10px;
`;

const PositionTD = styled.td`
  padding: 10px;
  text-align: center;
  font-weight: ${({ bold }) => bold && 'bold'};
`;

const PositionTH = styled.th`
  text-align: center;
  padding: 10px;
  background-color: #264653;
  color: white;
`;

export { TableContainer, TheadPosition, PositionTD, TablePosition, PositionTH };
