import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function GoogleTagLink(props) {
  const { id, label, releaseId } = props;

  return (
    <Link to={`/edit-google-tag/${id}`}>
      <span>
        {label ? `${label} (${id})` : `GoogleTag n°${id}`}
        {releaseId && ` (${releaseId})`}
      </span>
    </Link>
  );
}

GoogleTagLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  releaseId: PropTypes.number,
  label: PropTypes.string,
};

GoogleTagLink.defaultProps = {
  releaseId: null,
  label: null,
};

export default GoogleTagLink;
