import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import GoogleTagLink from '../../../UI/Link/GoogleTagLink';
import { selectNotification } from '../../NotificationSlice';
import NotificationTemplate from '../../NotificationTemplate/NotificationTemplate';

function BlockImportNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));
  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        Un import de <b>{metadata.type}</b> a été fait.
      </p>
      <p>
        L&apos;import concerne le(s) googleTag(s) :&nbsp;
        {metadata.summary?.googleTagVersion.map((idGtag, index) => (
          <Fragment key={idGtag}>
            {index ? ', ' : ''}
            <GoogleTagLink id={idGtag} />
          </Fragment>
        ))}
      </p>
    </NotificationTemplate>
  );
}

BlockImportNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default BlockImportNotification;
