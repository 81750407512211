import { useForm } from 'react-hook-form';
import { Checkbox, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import Heading from '../../../UI/Title/Heading';
import { SCOPE } from '../../../../constants/Suggestions';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import { ControlledAutocompleteSuggestion, TextFieldSuggestion } from './Suggestion.style';
import Card from '../../../UI/Wrapper/Card';
import { snackActions } from '../../../../helpers/SnackBarUtils';
import { createSuggestion } from '../../../../store/SuggestionSlice';

function AddSuggestion() {
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const postSuggestion = (data) => {
    const body = { ...data };
    body.defaultValue = data.defaultValue.length > 0 ? data.defaultValue : null;
    body.scope = data.scope.map((s) => s.value);
    body.updatable = true;

    dispatch(createSuggestion(body))
      .unwrap()
      .then(() => snackActions.success('Votre suggestion a bien été ajouté.'))
      .catch((e) => snackActions.error(e.message));
  };

  return (
    <Card height="45vh">
      <Heading variant="h2">Création d&apos;une suggestion</Heading>
      <form onSubmit={handleSubmit(postSuggestion)}>
        <TextFieldSuggestion
          {...register('label', { required: true })}
          id="input-with-sx"
          label="Label"
          placeholder="Exemple: device"
          variant="standard"
          error={errors.label}
          helperText={errors.label && 'Le label est requis'}
        />
        <TextFieldSuggestion
          {...register('defaultValue')}
          multiline
          minRows={2}
          maxRows={4}
          id="input-with-sx"
          label="Valeur par défaut"
          variant="standard"
        />
        <ControlledAutocompleteSuggestion
          multiple
          control={control}
          options={SCOPE}
          renderInput={(params) => <TextField {...params} label="Scope" placeholder="Scope" />}
          getOptionLabel={(option) => option.label}
          name="scope"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.label}
            </li>
          )}
          sx={{ margin: '20px' }}
        />
        <MassButton type="submit">Créer une suggestion</MassButton>
      </form>
    </Card>
  );
}

export default AddSuggestion;
