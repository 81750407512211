import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { SecondaryButton } from './Button.style';

function Button(props) {
  const { type, onClick, disabled, children, color, hoverColor, sx, title } = props;

  return (
    <Tooltip title={title}>
      <SecondaryButton color={color} type={type} onClick={onClick} disabled={disabled} hoverColor={hoverColor} sx={sx}>
        {children}
      </SecondaryButton>
    </Tooltip>
  );
}

Button.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  sx: PropTypes.shape({}),
};

Button.defaultProps = {
  title: '',
  type: 'button',
  disabled: false,
  children: React.createElement('div'),
  onClick: () => {
    return null;
  },
  color: '#e3e0e0',
  hoverColor: '#23c483',
  sx: {},
};

export default Button;
