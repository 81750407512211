import styled from '@emotion/styled/macro';
import { Button, css } from '@mui/material';

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  width: 100%;
  margin: 0;

  ${(props) =>
    props.toggled.map(
      (toggled) =>
        css`
          & .${toggled} {
            background-color: rgba(143, 88, 240, 0.15);
          }
        `
    )}
`;

const LineGridHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-column: 1 / ${(props) => props.columns + 1};
  width: 100%;
  margin: 0;
`;

const LineGrid = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'grid')};
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-column: 1 / ${(props) => props.columns + 1};
  width: 100%;
  margin-left: -5px;

  border-left: 5px solid ${(props) => (props.hover ? 'rgba(143, 88, 240, 0.10)' : 'transparent')};
  background-color: ${(props) => (props.hover ? 'rgba(143, 88, 240, 0.10)' : 'transparent')};

  ${(props) =>
    props.deleted
      ? css`
          pointer-events: none;
          border-left: 5px solid red;

          & > * {
            opacity: 0.5;
          }
        `
      : css`
          &:hover {
            background-color: rgba(143, 88, 240, 0.1);
            border-left: 5px solid rgba(143, 88, 240, 0.1);
          }
        `}
`;

const CellButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 ${(props) => 10 + props.index * 30}px;
  height: 100%;

  ${(props) => props.sx && { ...props.sx }}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70px;
  height: 100%;
  justify-content: center;
`;

const ButtonWrapper = styled.span`
  margin: 1px 0;
  min-height: 23px;
`;

const CustomButton = styled(Button)`
  cursor: pointer;
  height: 100%;
  max-height: 30px;
  min-width: 40px;
  padding: 0;
  border-radius: 5px;
  width: 100%;
`;

const Cell = styled.div`
  padding: 0;
  min-height: ${(props) => (props.expanded ? 20 : 50)}px;

  display: flex;
  flex-flow: wrap;
`;

const ListContainer = css`
  margin: 0;
  padding: 0;
  grid-column: 1 / 5;
`;

const ListItemButtonWrapper = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1 / 5;
  padding: 0;
`;

const MediaTypeColor = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70px;
`;

const ColorMediatype = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
  margin: 2px;
`;

export {
  LineGridHeader,
  LineGrid,
  CellButton,
  ButtonContainer,
  ButtonWrapper,
  CustomButton,
  Cell,
  Table,
  ListContainer,
  ListItemButtonWrapper,
  MediaTypeColor,
  ColorMediatype,
};
