import styled from '@emotion/styled/macro';

const BlockAdminWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr ${(props) => props.trashOpen && '0.3fr'};
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 0;
`;

export { BlockAdminWrapper };
