import { useSelector } from 'react-redux';
import { selectAllDrafts } from '../../../store/DraftSlice';
import DraftItem from './Item/DraftItem';
import { ContentWrapper } from './DraftContent.style';

function DraftContent() {
  const drafts = useSelector(selectAllDrafts);

  return (
    <ContentWrapper>{drafts && drafts.map((draft) => <DraftItem key={draft.id} draftId={draft.id} />)}</ContentWrapper>
  );
}

export default DraftContent;
