import PropTypes from 'prop-types';
import VariableType from './VariableType';

const BlockCustomType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  idGtagBv: PropTypes.number,
  idVersion: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  parentBloc: PropTypes.string.isRequired,
  versionBlock: PropTypes.string.isRequired,
  variable: PropTypes.arrayOf(VariableType),
});

export default BlockCustomType;
