/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { loginCheck } from '../helpers/api/authentication';
import { createUser, deleteUser, fetchCurrentUser, fetchUsers, modifyUser } from '../helpers/api/users';
import { jwtDecode } from '../helpers/jwtHelper';

const DOMAIN = 'users';

export const loginUser = createAsyncThunk(`${DOMAIN}/login`, async (data, thunkAPI) => {
  try {
    return loginCheck(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchLoggedUser = createAsyncThunk(`${DOMAIN}/fetchUser`, async (thunkAPI) => {
  try {
    return fetchCurrentUser();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchAllUsers = createAsyncThunk(`${DOMAIN}/fetchAllUser`, async (thunkAPI) => {
  try {
    return fetchUsers();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const postUser = createAsyncThunk(`${DOMAIN}/post`, async (data, thunkAPI) => {
  try {
    return createUser(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateUser = createAsyncThunk(`${DOMAIN}/update`, async (data, thunkAPI) => {
  const { id, body } = data;
  try {
    return modifyUser(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const removeUser = createAsyncThunk(`${DOMAIN}/remove`, async (id, thunkAPI) => {
  try {
    return deleteUser(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  users: [],
  currentUser: null,
  status: '',
  currentUserStatus: 'idle',
  error: null,
};

export const UserSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    logout: (state) => {
      state.currentUser = initialState.currentUser;
      state.currentUserStatus = 'idle';
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.status = 'resolved';
      // eslint-disable-next-line camelcase
      const { token, refresh_token } = action.payload;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh', refresh_token);
      localStorage.setItem('expiresAt', jwtDecode(token).exp);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchLoggedUser.pending, (state) => {
      state.currentUserStatus = 'loading';
    });
    builder.addCase(fetchLoggedUser.fulfilled, (state, action) => {
      state.currentUserStatus = 'resolved';
      state.currentUser = action.payload;
    });
    builder.addCase(fetchLoggedUser.rejected, (state, action) => {
      state.currentUserStatus = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchAllUsers.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.users = action.payload['hydra:member'];
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(postUser.fulfilled, (state, action) => {
      const { payload } = action;
      state.users.push(payload);
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const { payload } = action;
      state.users = state.users.map((user) => (user.id === payload.id ? payload : user));

      if (payload.id === state.currentUser.id) {
        state.currentUser = payload;
      }
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      const { meta } = action;
      state.users = state.users.filter((user) => user.id !== meta.arg);
    });
  },
});

export const { logout } = UserSlice.actions;

export const selectUsers = (state) => state[DOMAIN].users;
export const selectUsersStatus = (state) => state[DOMAIN].status;
export const selectUser = () =>
  createSelector(
    (state) => state[DOMAIN].users,
    (_, id) => id,
    (state, id) => state.find((user) => user.id === id)
  );
export const selectCurrentUser = (state) => state[DOMAIN].currentUser;
export const selectCurrentUserStatus = (state) => state[DOMAIN].currentUserStatus;

export default UserSlice.reducer;
