/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllRTBPosition, getExistingPositions } from '../helpers/api/positions';

const domain = 'positionRTB';

export const fetchRtb = createAsyncThunk(`${domain}/fetchRtb`, async (thunkAPI) => {
  try {
    return getAllRTBPosition();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchExistingAddPosition = createAsyncThunk(`${domain}/fetchExistingVersion`, async (id, thunkAPI) => {
  try {
    return getExistingPositions(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  existingAddPosition: {},
  rtbs: [],
  status: 'idle',
  addPositionStatus: 'idle',
  error: null,
};

export const positionRtbSlice = createSlice({
  name: 'positionRTB',
  initialState,
  extraReducers(builder) {
    builder
      // RTB
      .addCase(fetchRtb.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRtb.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.rtbs = action.payload['hydra:member'];
      })
      .addCase(fetchRtb.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // EXISTING ADDPOSITION
      .addCase(fetchExistingAddPosition.pending, (state) => {
        state.addPositionStatus = 'loading';
      })
      .addCase(fetchExistingAddPosition.fulfilled, (state, action) => {
        state.addPositionStatus = 'succeeded';
        state.existingAddPosition = action.payload;
      })
      .addCase(fetchExistingAddPosition.rejected, (state, action) => {
        state.addPositionStatus = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions

// Selectors
export const selectRtbPositions = (state) => state.positionRTB;

export const selectPositionRTBStatus = (state) => state.positionRTB.status;

export const selectExistingAddPositionsStatus = (state) => state.positionRTB.addPositionStatus;

export const selectPositionRTBById = () =>
  createSelector(
    (state) => state.positionRTB,
    (_, id) => id,
    (state, id) => state.rtbs.find((position) => position.id === id)
  );

export default positionRtbSlice.reducer;
