import { Checkbox, Chip, FormControl, InputLabel, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { fetchPrebidVersions, selectPrebidVersionsSelected } from '../../../../store/PrebidVersionSlice';
import { IconWarning, SelectedWrapper, SelectPrebid, Wrapper } from './SelectPrebidVersion.style';
import { fetchMandatoryModules, selectMandatoryModules } from '../../../../store/MandatoryModulesSlice';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import { patchCurrentGoogleTagVersion, selectGoogleTagVersion } from '../../../../store/GoogleTagVersionSlice';
import googleTagStatus from '../../../../constants/GoogleTagStatus';

function SelectPrebidVersion() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const prebids = useSelector(selectPrebidVersionsSelected);
  const mandatoryModules = useSelector(selectMandatoryModules);
  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const prebidVersionSelected = useMemo(() => googleTagVersion.prebidVersion, [googleTagVersion.prebidVersion]);

  const defaultValuePrebid = useMemo(() => {
    if (prebids && prebidVersionSelected) {
      return prebidVersionSelected.isActive ? prebidVersionSelected.id : 'nonactive';
    }

    return '';
  }, [prebidVersionSelected, prebids]);

  const {
    handleSubmit,
    watch,
    resetField,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      modules: [],
      prebidVersion: defaultValuePrebid,
    },
  });

  const prebidVersionWatched = watch('prebidVersion');

  const prebidVersionModules = useMemo(() => {
    const foundPrebid = prebids.find((prebid) => prebid.id === parseInt(prebidVersionWatched, 10));

    return foundPrebid?.prebidVersionMandatoryModules ?? [];
  }, [prebids, prebidVersionWatched]);

  const defaultMandatories = useMemo(() => {
    const mandatoriesModules = [];

    if (defaultValuePrebid === prebidVersionWatched) {
      if (googleTagVersion?.mandatoryModules) {
        googleTagVersion?.mandatoryModules.forEach((module) => {
          mandatoriesModules.push(module.name);
        });
      }
    }

    if (prebidVersionModules) {
      prebidVersionModules.forEach((module) => {
        if (module.required) {
          mandatoriesModules.push(module.mandatoryModule.name);
        }
      });
    }

    return [...new Set(mandatoriesModules)];
  }, [defaultValuePrebid, googleTagVersion?.mandatoryModules, prebidVersionModules, prebidVersionWatched]);

  useEffect(() => {
    dispatch(fetchMandatoryModules());
    dispatch(fetchPrebidVersions());
  }, [dispatch]);

  useEffect(() => {
    resetField('modules', { defaultValue: defaultMandatories });
  }, [defaultMandatories, resetField]);

  const onChangePrebidVersion = (data) => {
    const { prebidVersion, modules } = data;
    const arrayModules = [];

    modules.forEach((module) => {
      const findModule = mandatoryModules.find((mod) => mod.name === module);
      if (findModule) {
        arrayModules.push(`api/mandatory_modules/${findModule.id}`);
      }
    });

    const dataToRequest = {
      prebidVersion: `api/prebid_versions/${prebidVersion}`,
      mandatoryModules: arrayModules,
    };

    dispatch(patchCurrentGoogleTagVersion({ id: googleTagVersion.id, body: dataToRequest }));
    enqueueSnackbar(`La version et les modules du Google Tag a été modifié`, { variant: 'success' });
  };

  return (
    <Wrapper>
      <SelectedWrapper>
        <form onSubmit={handleSubmit(onChangePrebidVersion)}>
          <div style={{ display: 'flex' }}>
            <Controller
              name="prebidVersion"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <FormControl>
                    <InputLabel>Version de prebid</InputLabel>
                    <SelectPrebid {...field} label="Version de prebid" warning={!prebidVersionWatched}>
                      {prebidVersionWatched === 'nonactive' && (
                        <MenuItem value="nonactive" disabled>
                          Version non activée par l&#39;administrateur ({prebidVersionSelected.version})
                        </MenuItem>
                      )}
                      {prebids.map((version) => (
                        <MenuItem key={version.id} value={version.id}>
                          {version.version}
                        </MenuItem>
                      ))}
                    </SelectPrebid>
                  </FormControl>
                );
              }}
            />
            {!prebidVersionWatched && <IconWarning size={40} color="red" />}
          </div>
          {errors.prebidVersion && (
            <span style={{ color: 'red' }} role="alert">
              Une version de prebid est obligatoire
            </span>
          )}

          <Controller
            name="modules"
            control={control}
            defaultValue={[]}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <FormControl sx={{ marginTop: '10px' }}>
                  <InputLabel>Modules</InputLabel>
                  <SelectPrebid
                    {...field}
                    label="Modules"
                    multiple
                    name="modules"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {prebidVersionModules.map((prebidVersionModule) => {
                      let required = false;
                      const idPrebid = parseInt(prebidVersionWatched, 10);
                      if (prebidVersionModule?.prebidVersion) {
                        const splitedVersion = prebidVersionModule.prebidVersion.split('/');
                        if (
                          parseInt(splitedVersion[splitedVersion.length - 1], 10) === idPrebid &&
                          prebidVersionModule.required
                        ) {
                          required = true;
                        }
                      }

                      return (
                        <MenuItem
                          key={prebidVersionModule.mandatoryModule.name}
                          value={prebidVersionModule.mandatoryModule.name}
                          disabled={required}
                        >
                          <Checkbox checked={field.value.indexOf(prebidVersionModule.mandatoryModule.name) > -1} />
                          <p>{prebidVersionModule.mandatoryModule.name}</p>
                        </MenuItem>
                      );
                    })}
                  </SelectPrebid>
                </FormControl>
              );
            }}
          />
          {errors.modules && (
            <span style={{ color: 'red' }} role="alert">
              Au moins un module est requis
            </span>
          )}

          <MassButton
            type="submit"
            disabled={[googleTagStatus.JADIS, googleTagStatus.PROD, googleTagStatus.QUAL].includes(
              googleTagVersion.status
            )}
          >
            Modifier la version de prebid
          </MassButton>
        </form>
      </SelectedWrapper>
    </Wrapper>
  );
}

export default SelectPrebidVersion;
