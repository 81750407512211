/* eslint-disable */
import PropTypes from 'prop-types';
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js';

function DoughnutChart(props) {
  const { title, width, labels, data, backgrounds } = props;

  ChartJS.register(ArcElement, Tooltip, Legend);

  const configChart = {
    labels,
    datasets: [{
      label: title,
      data,
      backgroundColor: backgrounds,
      hoverOffset: 4
    }]
  };

  return (
    <div style={{width}}>
      <Doughnut data={configChart} width="300px" height="300px"/>
    </div>
);
}

DoughnutChart.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  backgrounds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

DoughnutChart.defaultProps = {
  width: "300px",
}

export default DoughnutChart;
