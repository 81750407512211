import { useMemo, useState } from 'react';
import { Collapse } from '@mui/material';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DraftWrapper, TitleWrapper } from './DraftItem.style';
import LinkWrapper from '../../../../UI/LinkWrapper/LinkWrapper';
import Modification from '../../../../Modifications/Modification';
import { selectDraft } from '../../../../../store/DraftSlice';

function DraftItem(props) {
  const { draftId } = props;

  const [open, setOpen] = useState(false);

  const draftSelector = useMemo(selectDraft, []);
  const draft = useSelector((state) => draftSelector(state, draftId))[0];

  const date = useMemo(() => new Date(draft.createdAt).toLocaleDateString(), [draft.createdAt]);
  const isActive = useMemo(() => draft.isActive, [draft.isActive]);
  const label = useMemo(() => draft.label, [draft.label]);

  const Icon = useMemo(() => {
    if (open) {
      return MdOutlineKeyboardArrowDown;
    }

    return MdOutlineKeyboardArrowLeft;
  }, [open]);

  const title = useMemo(() => {
    let string = `${draft.draftModifications.length} modification(s) commencée(s) le ${date}`;
    if (!isActive) {
      const validated = new Date(draft.validatedAt).toLocaleDateString();
      string += ` et validée(s) le ${validated}`;
    } else if (draft.draftModifications.length > 1) {
      string += ` mais ne sont pas encore validées`;
    } else {
      string += ` mais n'est pas encore validée`;
    }
    return string;
  }, [date, isActive, draft.draftModifications.length, draft.validatedAt]);

  const onChangeOpen = () => {
    setOpen(!open);
  };

  return (
    <DraftWrapper isActive={draft.isActive}>
      <TitleWrapper>
        <div>
          <p>
            {title} <br /> <b> Nom des modifications: {label}</b>
          </p>
        </div>
        <TitleWrapper>
          <LinkWrapper onClick={onChangeOpen}>
            <Icon size={30} />
          </LinkWrapper>
        </TitleWrapper>
      </TitleWrapper>
      <Collapse in={open}>
        {draft.draftModifications
          .map((modif) => (
            <Modification key={modif.createdAt} showDetails createdAt={modif.createdAt} modificationProp={modif} />
          ))
          .reverse()}
      </Collapse>
    </DraftWrapper>
  );
}

DraftItem.propTypes = {
  draftId: PropTypes.number.isRequired,
};

export default DraftItem;
