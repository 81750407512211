import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { StyledTextField } from '../Floor.style';

function FloorProvider(props) {
  const { control } = props;

  return (
    <Controller
      render={({ field }) => (
        <StyledTextField
          id="floorProvider"
          label="Floor Provider"
          type="text"
          variant="filled"
          {...field}
          value={field.value ?? ''}
        />
      )}
      control={control}
      name="floorProvider"
    />
  );
}

FloorProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
};

export default FloorProvider;
