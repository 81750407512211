import { statusGoogleTags } from './status';

export const returnStatusValue = (status) => {
  const UnknownObject = {
    key: 'Unknown',
    color: '#b40000',
  };

  return statusGoogleTags.find((s) => parseInt(s.value, 10) === status) ?? UnknownObject;
};
