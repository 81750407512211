import { useSelector } from 'react-redux';
import GeneralDescription from '../../../components/GoogleTag/Edit/Sections/GeneralDescription';
import Links from '../../../components/GoogleTag/Edit/Sections/Links';
import CommentsSection from '../../../components/Reusable/GtagComment/CommentsSection';
import UpdateHistorySection from '../../../components/Reusable/GtagUpdateHistory/UpdateHistorySection';
import GoogleTagStatus from '../../../constants/GoogleTagStatus';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import { MiscellaneousContainer, MiscellaneousWrapper } from './GoogleTagEdit.style';

function GoogleTagEdit() {
  const googleTagVersion = useSelector(selectGoogleTagVersion);

  return (
    <div>
      {googleTagVersion && <GeneralDescription gtag={googleTagVersion} />}
      {googleTagVersion.status !== GoogleTagStatus.JADIS && <Links />}
      <MiscellaneousWrapper>
        <MiscellaneousContainer>
          <CommentsSection />
          <UpdateHistorySection />
        </MiscellaneousContainer>
      </MiscellaneousWrapper>
    </div>
  );
}

export default GoogleTagEdit;
