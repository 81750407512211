import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addedTabBidderDefaultValueSelector,
  addedTabBidderValueSelector,
  updateAddedTabBidder,
} from '../../../../../../../store/ModificationsSlice';
import { selectSelectedPartner } from '../TabBiddersModificationsSlice';

function AddedKeywordText(props) {
  const { placement, createdAt, metadata } = props;
  const dispatch = useDispatch();

  const { placementKeywordLib } = placement;

  const selectedPartner = useSelector(selectSelectedPartner);

  const keyname = useMemo(() => placementKeywordLib.keyName, [placementKeywordLib.keyName]);
  const selectorArgs = useMemo(
    () => ({ partnerWebsite: selectedPartner, metadata, createdAt, keyname }),
    [selectedPartner, metadata, createdAt, keyname]
  );

  const selectTabBidderDefaultValue = useMemo(addedTabBidderDefaultValueSelector, []);
  const defaultValue = useSelector((state) => selectTabBidderDefaultValue(state, selectorArgs));
  const selectTabBidderValueModification = useMemo(addedTabBidderValueSelector, []);
  const modificationValue = useSelector((state) => selectTabBidderValueModification(state, selectorArgs));

  const handleChange = useCallback(
    (event) => {
      dispatch(
        updateAddedTabBidder({
          metadata,
          keyname,
          value: event.target.value,
          createdAt,
        })
      );
    },
    [createdAt, dispatch, keyname, metadata]
  );

  return (
    <TextField
      key={placementKeywordLib.id}
      label={keyname}
      value={modificationValue ?? ''}
      onChange={handleChange}
      style={{ margin: '15px 0' }}
      helperText={defaultValue && modificationValue === defaultValue && 'Valeur auto générée'}
    />
  );
}

AddedKeywordText.propTypes = {
  placement: PropTypes.shape({
    placementKeywordLib: PropTypes.shape({
      id: PropTypes.number.isRequired,
      keyName: PropTypes.string.isRequired,
    }).isRequired,
    bidderPlacementKeywordRules: PropTypes.arrayOf(
      PropTypes.shape({
        defaultValue: PropTypes.string,
        constraints: PropTypes.string,
      })
    ),
  }).isRequired,
  metadata: PropTypes.shape({
    mediatype: PropTypes.number,
  }).isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default AddedKeywordText;
