import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RxCross1 } from 'react-icons/rx';
import { AiFillLock, AiOutlineCheck } from 'react-icons/ai';
import { ItemWrapper, ModuleItem, ModuleName } from '../PrebidCard.style';
import {
  deleteMandatoryModulesWithPrebidVersion,
  patchMandatoryModulesWithPrebidVersion,
  postMandatoryModulesWithPrebidVersion,
  selectMandatoryModule,
} from '../../../../../../store/MandatoryModulesSlice';
import LinkWrapper from '../../../../../UI/LinkWrapper/LinkWrapper';

function ItemModule(props) {
  const { prebidMandatoryModules, moduleId, prebidId } = props;

  const dispatch = useDispatch();
  const moduleSelector = useMemo(selectMandatoryModule, []);
  const module = useSelector((state) => moduleSelector(state, moduleId));

  const exist = useMemo(
    () => prebidMandatoryModules.find((mod) => mod.mandatoryModule.id === module.id),
    [module.id, prebidMandatoryModules]
  );

  const moduleData = useMemo(() => {
    if (exist) {
      if (exist.required)
        return {
          state: 'mandatory',
          color: 'red',
          Icon: AiFillLock,
        };
      if (!exist.required)
        return {
          state: 'available',
          color: 'green',
          Icon: AiOutlineCheck,
        };
    }
    return {
      state: 'inactive',
      color: 'black',
      Icon: RxCross1,
    };
  }, [exist]);

  const changeModuleStatus = (status) => {
    let dataRequest;
    switch (status) {
      case 'inactive':
        // Passage d'un inactif a un available
        dataRequest = {
          required: false,
          mandatoryModule: `/api/mandatory_modules/${module.id}`,
          prebidVersion: `/api/prebid_versions/${prebidId}`,
        };
        dispatch(postMandatoryModulesWithPrebidVersion(dataRequest));
        break;
      case 'available':
        // Passage d'un available a un mandatory
        dataRequest = {
          id: exist.id,
          body: {
            required: true,
          },
        };
        dispatch(patchMandatoryModulesWithPrebidVersion(dataRequest));
        break;
      case 'mandatory':
        // Passage d'un mandatory a un inactif
        dataRequest = exist.id;
        dispatch(deleteMandatoryModulesWithPrebidVersion(dataRequest));
        break;
      default:
    }
  };

  return (
    <ModuleItem key={module.id} color={moduleData.color} border={moduleData.color === 'black'}>
      <LinkWrapper onClick={() => changeModuleStatus(moduleData.state)}>
        <ItemWrapper>
          <moduleData.Icon color={moduleData.color === 'black' ? 'black' : 'white'} size={20} />
          <ModuleName black={moduleData.color === 'black'}>{module.name}</ModuleName>
        </ItemWrapper>
      </LinkWrapper>
    </ModuleItem>
  );
}

ItemModule.propTypes = {
  prebidMandatoryModules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      prebidVersion: PropTypes.string,
      required: PropTypes.bool,
      MandatoryModule: PropTypes.arrayOf({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    })
  ),
  moduleId: PropTypes.number.isRequired,
  prebidId: PropTypes.number.isRequired,
};

ItemModule.defaultProps = {
  prebidMandatoryModules: [],
};

export default ItemModule;
