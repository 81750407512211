import styled from '@emotion/styled/macro';

const PrebidVersionListWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PrebidIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-width: 200px;
  background-color: ${(props) => props.color}55;
  padding: 0 10px;
`;

const IconLabel = styled.p`
  margin-left: 5px;
`;

export { PrebidVersionListWrapper, IconsWrapper, HeaderWrapper, PrebidIcon, IconLabel };
