import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import Loader from '../Animation/Loader';
import { FieldWrapper, FormWrapper } from './FormModal.style';

function FormModal(props) {
  const { onSubmit, loading, children } = props;

  return (
    <FormWrapper onSubmit={onSubmit}>
      <FieldWrapper>{children}</FieldWrapper>
      <Button type="submit" variant="contained" disabled={loading} endIcon={loading && <Loader size="small" />}>
        Valider
      </Button>
    </FormWrapper>
  );
}

FormModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

FormModal.defaultProps = {
  loading: false,
};

export default FormModal;
