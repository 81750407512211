/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getGtagVersionBlockByGoogleTagAndBlockName, getVersionBlocksByBlockName } from '../../../helpers/api/blocks';
import { getAllVariablesByBlock, patchGlobalVariable, postGlobalVariable } from '../../../helpers/api/variableGlobals';
import {
  getAllLocalVariablesByBlock,
  patchLocalVariable,
  postLocalVariable,
} from '../../../helpers/api/variableLocals';

const domain = 'variableSettings';

// Async actions
export const fetchVariableBlock = createAsyncThunk(`${domain}/fetchVariableBlock`, async (googleTagId, thunkAPI) => {
  try {
    return getGtagVersionBlockByGoogleTagAndBlockName(googleTagId, 'Variable');
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchBlockVariableVersion = createAsyncThunk(`${domain}/fetchBlockVariableVersion`, async (thunkAPI) => {
  try {
    return getVersionBlocksByBlockName('Variable');
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchBlockVariableVariables = createAsyncThunk(
  `${domain}/fetchBlockVariableVariables`,
  async (thunkAPI) => {
    try {
      return getAllVariablesByBlock('Variable');
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchBlockVariableLocalVariables = createAsyncThunk(
  `${domain}/fetchBlockVariableLocalVariables`,
  async (googleTagId, thunkAPI) => {
    try {
      return getAllLocalVariablesByBlock('Variable', googleTagId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const createGlobalVariable = createAsyncThunk(`${domain}/postGlobal`, async (body, thunkAPI) => {
  try {
    return postGlobalVariable(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createLocalVariable = createAsyncThunk(`${domain}/post`, async (body, thunkAPI) => {
  try {
    return postLocalVariable(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateGlobalVariable = createAsyncThunk(`${domain}/patchGlobal`, async (args, thunkAPI) => {
  const { variableId, data } = args;
  try {
    return patchGlobalVariable(variableId, data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateLocalVariable = createAsyncThunk(`${domain}/patch`, async (args, thunkAPI) => {
  const { variableId, body } = args;
  try {
    return patchLocalVariable(variableId, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  variableBlock: {},
  blockVariableVersion: {},
  defaultVariables: [],
  defaultLocalVariables: [],
  blockVariableVersionStatus: 'idle',
  defaultVariablesStatus: 'idle',
  defaultLocalVariablesStatus: 'idle',
};

export const settingVariablesSlice = createSlice({
  name: domain,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVariableBlock.pending, (state) => {
        state.defaultVariablesStatus = 'loading';
      })
      .addCase(fetchVariableBlock.fulfilled, (state, action) => {
        state.defaultVariablesStatus = 'succeeded';
        const [block] = action.payload['hydra:member'];

        if (block) {
          state.variableBlock = block;
        }
      })
      .addCase(fetchVariableBlock.rejected, (state) => {
        state.defaultVariablesStatus = 'failed';
      })

      .addCase(fetchBlockVariableVersion.pending, (state) => {
        state.blockVariableVersionStatus = 'loading';
      })
      .addCase(fetchBlockVariableVersion.fulfilled, (state, action) => {
        state.blockVariableVersionStatus = 'succeeded';
        const [version] = action.payload['hydra:member'];

        if (version) {
          state.blockVariableVersion = version;
        }
      })
      .addCase(fetchBlockVariableVersion.rejected, (state) => {
        state.blockVariableVersionStatus = 'failed';
      })

      .addCase(fetchBlockVariableVariables.pending, (state) => {
        state.defaultVariablesStatus = 'loading';
      })
      .addCase(fetchBlockVariableVariables.fulfilled, (state, action) => {
        state.defaultVariablesStatus = 'succeeded';
        state.defaultVariables = action.payload['hydra:member'];
      })
      .addCase(fetchBlockVariableVariables.rejected, (state) => {
        state.defaultVariablesStatus = 'failed';
      })

      .addCase(fetchBlockVariableLocalVariables.pending, (state) => {
        state.defaultLocalVariablesStatus = 'loading';
      })
      .addCase(fetchBlockVariableLocalVariables.fulfilled, (state, action) => {
        state.defaultLocalVariablesStatus = 'succeeded';
        state.defaultLocalVariables = action.payload['hydra:member'];
      })
      .addCase(fetchBlockVariableLocalVariables.rejected, (state) => {
        state.defaultLocalVariablesStatus = 'failed';
      })

      .addCase(createGlobalVariable.fulfilled, (state, action) => {
        state.defaultVariables.push(action.payload);
      })

      .addCase(updateGlobalVariable.fulfilled, (state, action) => {
        state.defaultVariables = state.defaultVariables.map((variable) =>
          variable.id === action.payload.id ? action.payload : variable
        );
      })

      .addCase(createLocalVariable.fulfilled, (state, action) => {
        state.defaultLocalVariables.push(action.payload);
      });
  },
});

// Actions
export const { reset } = settingVariablesSlice.actions;

// Selectors
export const selectVariableBlock = (state) => state[domain].variableBlock;

export const selectBlockVariableVersion = (state) => state[domain].blockVariableVersion;
export const selectBlockVariableVersionStatus = (state) => state[domain].blockVariableVersionStatus;

export const selectDefaultVariables = (state) => state[domain].defaultVariables;
export const selectDefaultVariablesStatus = (state) => state[domain].defaultVariablesStatus;
export const selectDefaultVariable = () =>
  createSelector(
    selectDefaultVariables,
    (_, id) => id,
    (defaultVariables, id) => defaultVariables.find((defaultVariable) => defaultVariable.id === id)
  );

export const selectDefaultLocalVariables = (state) => state[domain].defaultLocalVariables;
export const selectDefaultLocalVariablesStatus = (state) => state[domain].defaultLocalVariablesStatus;
export const selectDefaultLocalVariable = () =>
  createSelector(
    selectDefaultLocalVariables,
    (_, id) => id,
    (defaultLocalVariables, id) =>
      defaultLocalVariables.find((defaultLocalVariable) => defaultLocalVariable.variableGlobal === id)
  );

export default settingVariablesSlice.reducer;
