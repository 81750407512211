import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getMandatoryModules(data) {
  const url = new URL(`${API_BASE_URL}/mandatory_modules`);
  url.search = param(data);

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function postMandatoryModules(body) {
  const url = `${API_BASE_URL}/mandatory_modules`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function deleteMandatoryModules(id) {
  const url = `${API_BASE_URL}/mandatory_modules/${id}`;
  return defaultFetch(url, 'DELETE');
}

export async function modifyMandatoryModules(id, body) {
  const url = `${API_BASE_URL}/mandatory_modules/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}

export async function postMandatoryPrebid(body) {
  const url = `${API_BASE_URL}/prebid_version_mandatory_modules`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function deleteMandatoryPrebid(id) {
  const url = `${API_BASE_URL}/prebid_version_mandatory_modules/${id}`;
  return defaultFetch(url, 'DELETE');
}

export async function patchMandatoryPrebid(id, body) {
  const url = `${API_BASE_URL}/prebid_version_mandatory_modules/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}
