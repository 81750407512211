import styled from '@emotion/styled/macro';
import { Input } from '@mui/material';

const ModifySection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const GCPtextField = styled(Input)`
  width: 200px;
`;

export { ModifySection, GCPtextField };
