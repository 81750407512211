import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AiFillQuestionCircle, AiOutlineGlobal } from 'react-icons/ai';
import { MdMode, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { Collapse } from '@mui/material';
import { selectDraft } from '../../../../store/DraftSlice';
import { AccessDraft, DraftItemWrapper, GridItem, IdIcon, Wrapper } from './DraftItem.style';
import Modification from '../../../Modifications/Modification';
import LinkWrapper from '../../../UI/LinkWrapper/LinkWrapper';
import DraftConfirmation from '../../../GoogleTag/Edit/MultiUnitModification/Cart/DraftConfirmation';

function DraftItem(props) {
  const { draftId } = props;

  const [openModif, setOpenModif] = useState(false);

  const draftSelector = useMemo(selectDraft, []);
  const draft = useSelector((state) => draftSelector(state, draftId))[0];

  const dateString = useMemo(() => {
    let string = `Créé le ${new Date(draft.createdAt).toLocaleDateString()}`;
    if (draft.validatedAt) {
      string += ` et validé le ${new Date(draft.validatedAt).toLocaleDateString()}`;
    }

    return string;
  }, [draft]);

  const isActive = useMemo(() => draft.isActive, [draft]);
  const IconModif = useMemo(() => {
    if (openModif) {
      return MdOutlineKeyboardArrowDown;
    }

    return MdOutlineKeyboardArrowLeft;
  }, [openModif]);

  const modificationLength = useMemo(() => {
    let string = `${draft.draftModifications.length} modification`;
    if (draft.draftModifications.length > 1) {
      string += 's';
    }
    return string;
  }, [draft]);

  const Icon = useMemo(() => {
    switch (draft.type) {
      case 0:
        return MdMode;
      case 1:
        return AiOutlineGlobal;
      default:
        return AiFillQuestionCircle;
    }
  }, [draft]);

  const gotoDraft = useMemo(() => {
    // On est obligatoirement sur un draft unitaire, donc on prend la première modif, et le seul GoogleTag dans cette modification.
    if (draft.type === 0 && draft.draftModifications?.[0]?.googleTagVersions?.[0]) {
      const modifToSearchId = draft.draftModifications[0].googleTagVersions[0];

      return `/edit-google-tag/${modifToSearchId.id}/unit-modification?draft=${draft.id}`;
    }

    if (draft.type) {
      return `/marsse?draft=${draft.id}`;
    }

    return null;
  }, [draft]);

  return (
    <Wrapper>
      <DraftItemWrapper isActive={isActive}>
        <IdIcon>
          <Icon size={30} />
          <p> {draft.id} </p>
        </IdIcon>
        <GridItem>
          <p> {modificationLength}</p>
        </GridItem>
        <GridItem>
          <p>{dateString}</p>
        </GridItem>
        <GridItem>
          {isActive && (
            <>
              <AccessDraft to={gotoDraft}>
                <b> Accéder au draft.</b>
              </AccessDraft>
              <DraftConfirmation idDraft={draft.id} />
            </>
          )}
        </GridItem>
        <LinkWrapper onClick={() => setOpenModif(!openModif)}>
          <IconModif size={30} />
        </LinkWrapper>
      </DraftItemWrapper>
      <Collapse in={openModif}>
        {draft.draftModifications
          .map((modif) => (
            <Modification key={modif.createdAt} showDetails createdAt={modif.createdAt} modificationProp={modif} />
          ))
          .reverse()}
      </Collapse>
    </Wrapper>
  );
}

DraftItem.propTypes = {
  draftId: PropTypes.number.isRequired,
};

export default DraftItem;
