/* eslint-disable no-continue */
import { List, ListSubheader } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../../../../../store/GoogleTagVersionSlice';
import { selectMediatype } from '../../../../../../../store/MediatypesSlice';
import { addTabBidder, addedTabBiddersSelector } from '../../../../../../../store/ModificationsSlice';
import { selectPartner } from '../../../../../../../store/PartnersSlice';
import Card from '../../../../../../UI/Wrapper/Card';
import {
  selectSelectedPartner,
  selectSelectedTabBidders,
  selectSelectedTabBiddersNode,
  selectTabBidders,
} from '../TabBiddersModificationsSlice';
import AddedTabBiddersItem from './AddedTabBiddersItem';
import TabBiddersItem from './TabBiddersItem';
import { fetchDevices, selectAllDevicesStatus, selectDevice } from '../../../../../../../store/DevicesSlice';

function TabBiddersList() {
  const dispatch = useDispatch();
  const { metadata } = useSelector(selectSelectedTabBiddersNode);

  const selectedPartner = useSelector(selectSelectedPartner);
  const selectedTabBidders = useSelector(selectSelectedTabBidders);
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const deviceStatus = useSelector(selectAllDevicesStatus);

  const partnerSelector = useMemo(selectPartner, []);
  const partner = useSelector((state) => partnerSelector(state, selectedPartner));

  const selectAddedTabBidders = useMemo(addedTabBiddersSelector, []);
  const selectorArgs = useMemo(() => ({ partnerWebsite: selectedPartner, metadata }), [metadata, selectedPartner]);
  const addedTabBidders = useSelector((state) => selectAddedTabBidders(state, selectorArgs));

  const mediatypeSelector = useMemo(selectMediatype, []);
  const mediatype = useSelector((state) => mediatypeSelector(state, metadata?.mediatype));

  const deviceSelector = useMemo(selectDevice, []);
  const device = useSelector((state) => deviceSelector(state, metadata?.device));

  const bidders = useSelector(selectTabBidders);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const calculateRules = useCallback(
    (bidder) => {
      const values = {};
      const { bidderPlacementKeywords } = bidder;

      for (let i = 0; i < bidderPlacementKeywords.length; i += 1) {
        const placement = bidderPlacementKeywords[i];

        const { bidderPlacementKeywordRules } = placement;
        if (!Array.isArray(bidderPlacementKeywordRules) || bidderPlacementKeywordRules.length === 0) {
          continue;
        }

        const placementKeyName = placement?.placementKeywordLib?.keyName;
        if (!placementKeyName) {
          continue;
        }

        for (let index = 0; index < bidderPlacementKeywordRules.length; index += 1) {
          const rule = bidderPlacementKeywordRules[index];
          let test = true;

          if (values[placementKeyName]) {
            continue;
          }

          if (rule.constraints) {
            const constraints = rule.constraints.split(';');
            for (let y = 0; y < constraints.length; y += 1) {
              const constraint = constraints[y];

              let [target, value] = constraint.split('===');
              target = target.trim();
              value = value.trim();

              switch (target) {
                case '{{mediatype}}':
                  if (mediatype?.genericName !== value) {
                    test = false;
                  }
                  break;

                case '{{lssite}}':
                  if (partner?.lSSite !== value) {
                    test = false;
                  }
                  break;

                case '{{device}}':
                  if (device?.genericName !== value) {
                    test = false;
                  }
                  break;

                default:
                  test = false;
                  break;
              }

              if (!test) {
                break;
              }
            }
          }

          if (test) {
            values[placementKeyName] = rule?.defaultValue ?? '';
          }
        }
      }

      return values;
    },
    [device?.genericName, mediatype?.genericName, partner?.lSSite]
  );

  const handleDrop = useCallback(
    (event) => {
      if (!metadata) {
        return;
      }

      event.preventDefault();

      const bidderId = Number.parseInt(event.dataTransfer.getData('text'), 10);

      if (bidderId === null) {
        return;
      }

      const bidder = bidders.find((b) => b.id === bidderId);
      const values = calculateRules(bidder);

      dispatch(
        addTabBidder({
          selectedPartner,
          bidderKeyname: bidder.keyname,
          bidderId,
          metadata,
          googleTagVersions: [googleTagVersion['@id']],
          values,
          defaultValues: values,
        })
      );
    },
    [bidders, calculateRules, dispatch, googleTagVersion, metadata, selectedPartner]
  );

  const sortMethod = (a, b) => {
    const idA = a.bidder.id ? `/api/bidders/${a.bidder.id}` : a.bidder;
    const idB = b.bidder.id ? `/api/bidders/${b.bidder.id}` : b.bidder;

    if (idA < idB) {
      return -1;
    }

    if (idA > idB) {
      return 1;
    }

    return 0;
  };

  const [formattedAdded, setFormattedAdded] = useState([]);

  useEffect(() => {
    setFormattedAdded((oldValue) => {
      const newValue = addedTabBidders.map((e) => ({
        createdAt: e.createdAt,
        added: true,
        bidder: {
          id: e.metadata.bidder,
        },
      }));

      return JSON.stringify(oldValue) === JSON.stringify(newValue) ? oldValue : newValue;
    });
  }, [addedTabBidders]);

  useEffect(() => {
    if (deviceStatus !== 'idle') {
      return;
    }

    dispatch(fetchDevices());
  }, [deviceStatus, dispatch]);

  const allTabBidders = useMemo(
    () => selectedTabBidders.concat(formattedAdded).sort(sortMethod),
    [formattedAdded, selectedTabBidders]
  );

  return (
    <Card marginTop="0" height="auto" width="80%" onDragOver={handleDragOver} onDrop={handleDrop}>
      <List
        sx={{ width: '100%' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Liste des TabBidders
          </ListSubheader>
        }
      >
        {allTabBidders.map((element) =>
          element.added ? (
            <AddedTabBiddersItem key={element.createdAt} createdAt={element.createdAt} />
          ) : (
            <TabBiddersItem key={element.id} id={element.id} />
          )
        )}
      </List>
    </Card>
  );
}

export default TabBiddersList;
