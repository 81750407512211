export const createModificationForSizes = (operation, metadata, googleTagVersions) => {
  const { sizeId, pagetype, device, position, headers, headersToggled, source = null } = metadata;
  if (operation === null || sizeId === null || pagetype === null) {
    return null;
  }

  const modification = {
    operation,
    metadata: {
      sizeId,
      pagetype,
      device,
      position,
    },
    googleTagVersions,
  };

  if (headersToggled.length === 0) {
    if (operation === 'addSize') {
      modification.metadata.headersToggled = [...headers];
    } else {
      modification.metadata.headersToggled = [source];
    }
  } else {
    modification.metadata.headersToggled = [...headersToggled];
  }

  return modification;
};
