import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyTokenExist } from '../../helpers/storage';
import { logout } from '../../store/UsersSlice';
import Header from '../../components/Authentication/Header';
import FormResetPassword from '../../components/Authentication/FormResetPassword';

function ForgotPassword() {
  const { token } = useParams();
  const dispatch = useDispatch();

  if (verifyTokenExist()) {
    dispatch(logout());
  }

  return (
    <div>
      <Header />
      <FormResetPassword token={token} />
    </div>
  );
}

export default ForgotPassword;
