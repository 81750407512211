/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  deleteConfiguration,
  getConfigurations,
  patchConfiguration,
  postConfiguration,
} from '../helpers/api/configuration';

const DOMAIN = 'configurations';

export const fetchConfigurations = createAsyncThunk(`${DOMAIN}/fetch`, async (thunkAPI) => {
  try {
    return getConfigurations();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createConfiguration = createAsyncThunk(`${DOMAIN}/create`, async (body, thunkAPI) => {
  try {
    return postConfiguration(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateConfiguration = createAsyncThunk(`${DOMAIN}/update`, async (data, thunkAPI) => {
  const { id, body } = data;

  try {
    return patchConfiguration(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const removeConfiguration = createAsyncThunk(`${DOMAIN}/delete`, async (id, thunkAPI) => {
  try {
    return deleteConfiguration(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  configurations: [],
  status: 'idle',
};

export const ConfigurationsSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConfigurations.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchConfigurations.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.configurations = action.payload['hydra:member'];
    });
    builder.addCase(fetchConfigurations.rejected, (state) => {
      state.status = 'rejected';
    });
    builder.addCase(createConfiguration.fulfilled, (state, action) => {
      state.configurations.push(action.payload);
    });
    builder.addCase(updateConfiguration.fulfilled, (state, action) => {
      const { payload } = action;
      state.configurations = state.configurations.map((configuration) =>
        configuration.id === payload.id ? payload : configuration
      );
    });
    builder.addCase(removeConfiguration.fulfilled, (state, action) => {
      state.configurations = state.configurations.filter((configuration) => configuration.id !== action.meta.arg);
    });
  },
});

export const selectConfigurations = (state) => state[DOMAIN].configurations;
export const selectConfigurationsStatus = (state) => state[DOMAIN].status;
export const selectConfiguration = () =>
  createSelector([selectConfigurations, (_, id) => id], (configurations, id) => {
    return configurations.find((configuration) => configuration.id === id);
  });
export const selectConfigurationByKey = () =>
  createSelector([selectConfigurations, (_, key) => key], (configurations, key) => {
    return configurations.find((configuration) => configuration.aKey === key);
  });

export default ConfigurationsSlice.reducer;
