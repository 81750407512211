import styled from '@emotion/styled/macro';
import { Input } from '@mui/material';

const GroupNameWrapper = styled.div`
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const InputGroup = styled(Input)`
  width: 90%;
`;

export { GroupNameWrapper, InputGroup, FormWrapper };
