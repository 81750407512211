import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledTextField } from '../Floor.style';

function SkipRate(props) {
  const { control, registerTarget, errors = {} } = props;
  const error = useReactHookFormError(registerTarget, errors);

  return (
    <Controller
      render={({ field }) => (
        <Tooltip title="Valeur par défaut: 0">
          <StyledTextField
            id={registerTarget}
            label="Skip Rate"
            type="number"
            variant="filled"
            placeholder="0"
            error={!!error}
            helperText={error?.message}
            {...field}
            value={field.value ?? ''}
          />
        </Tooltip>
      )}
      control={control}
      name={registerTarget}
      rules={{
        validate: {
          validSkipRate: (value) => {
            const errorMessage = 'La champ doit être un entier positif compris entre 0 et 100.';
            const intValue = parseInt(value, 10);

            if (intValue < 0 || intValue > 100) {
              return errorMessage;
            }

            return null;
          },
        },
      }}
    />
  );
}

SkipRate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  registerTarget: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any.isRequired,
};

export default SkipRate;
