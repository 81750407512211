import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllBidders(params = {}) {
  const url = new URL(`${API_BASE_URL}/bidders`);
  if (params) {
    url.search = param(params);
  }

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function getCurrentBidders(id) {
  const url = `${API_BASE_URL}/google_tag_position_nodes/${id}/tab_bidders_bidders`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function patchBidder(id, data) {
  const url = `${API_BASE_URL}/bidders/${id}`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}

export async function putBidder(id, data) {
  const url = `${API_BASE_URL}/bidders/${id}`;
  const response = await defaultFetch(url, 'PUT', data);

  return response.json();
}

export async function importCSV(id, body) {
  const url = `${API_BASE_URL}/bidders/${id}/import`;
  return defaultFetch(url, 'POST', body);
}

export async function getMonetizationType() {
  const url = `${API_BASE_URL}/monetization_types`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getAdapters() {
  const url = `${API_BASE_URL}/adapters`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postBidder(data) {
  const url = `${API_BASE_URL}/bidders`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function getPlacementKeywords() {
  const url = `${API_BASE_URL}/placement_keyword_libs`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postPlacementKeywords(data) {
  const url = `${API_BASE_URL}/placement_keyword_libs`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}
