import { Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import MassButton from '../../UI/Basic/Buttons/MassButton/MassButton';
import Heading from '../../UI/Title/Heading';
import { patchPartner, selectCurrentPartner } from '../PartnerPageSlice';
import { PartnerTextField } from './UpdatePartner.style';
import { snackActions } from '../../../helpers/SnackBarUtils';

function UpdatePartner() {
  const partner = useSelector(selectCurrentPartner);
  const dispatch = useDispatch();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      displayName: partner.displayName,
      keyname: partner.keyname,
      trigram: partner.trigram,
      uuid: partner.uuid,
      lssite: partner.lSSite,
      gitFileName: partner.gitFileName,
      publisherId: partner.publisherId,
      isAuthorized: partner.isAuthorized,
      gcpRepository: partner.partnerGroup?.gcpRepository,
    },
  });

  const onUpdatePartner = (data) => {
    let partnerData = data;
    if (partnerData.publisherId === '') {
      partnerData.publisherId = null;
    }

    if (partnerData.gcpRepository) {
      partnerData = {
        ...partnerData,
        partnerGroup: {
          gcpRepository: partnerData.gcpRepository,
        },
      };
    }

    dispatch(patchPartner({ id: partner.id, body: partnerData }))
      .unwrap()
      .then(() => snackActions.success('Le partenaire a bien été modifié.'));
  };

  return (
    <>
      <Heading variant="h2">Modification du partenaire</Heading>
      <form onSubmit={handleSubmit(onUpdatePartner)}>
        <PartnerTextField {...register('displayName', { required: true })} label="Display name *" />
        <PartnerTextField {...register('keyname', { required: true })} label="Keyname *" />
        <PartnerTextField {...register('trigram', { required: true })} label="Trigram *" />
        <PartnerTextField {...register('uuid', { required: true })} label="UUID *" />
        <PartnerTextField {...register('lssite', { required: true })} label="lSSite *" />
        <PartnerTextField {...register('gitFileName', { required: true })} label="Nom du fichier GIT *" />
        <PartnerTextField {...register('gcpRepository')} label="Repertoire GCP" />
        <PartnerTextField {...register('publisherId')} type="number" label="Publisher ID" />
        <FormControlLabel
          control={<Checkbox {...register('isAuthorized')} defaultChecked={partner.isAuthorized} />}
          label="Autorisé"
        />

        <MassButton type="submit">Modifier le partenaire</MassButton>
      </form>
    </>
  );
}

export default UpdatePartner;
