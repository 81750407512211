import styled from '@emotion/styled/macro';

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 15px 0;
  box-sizing: border-box;
`;

const CommentTitleWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (!props.currentUser ? 'flex-start' : 'flex-end')};
  align-items: center;
`;

const CommentTitle = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-right: 2rem;
`;

const CommentText = styled.div`
  background-color: ${(props) => (props.currentUser ? 'lightblue' : 'lightgrey')};
  width: 80%;
  min-height: 50px;
  padding: 13px;
  border-radius: 10px 10px 10px 10px;
  letter-spacing: -0.05em;
  line-height: normal;
  display: block;
  text-align: left;
  margin-top: 10px;
  white-space: pre;
  align-self: ${(props) => props.currentUser && 'flex-end'};
`;

export { CommentWrapper, CommentTitleWrapper, CommentTitle, CommentText };
