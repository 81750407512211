/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getDevices } from '../helpers/api/device';

const domain = 'devices';

// Async actions
export const fetchDevices = createAsyncThunk(`${domain}/fetchDevices`, async (thunkAPI) => {
  try {
    return getDevices();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  devices: [],
  status: 'idle',
  error: null,
};

export const devicesSlice = createSlice({
  name: domain,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDevices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.devices = action.payload['hydra:member'];
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions

// Selectors
export const selectAllDevices = (state) => state[domain].devices;
export const selectAllDevicesStatus = (state) => state[domain].status;
export const selectDevices = () =>
  createSelector(
    (state) => state[domain],
    (_, ids) => ids,
    (state, ids) => state.devices.filter((position) => ids.includes(position.id))
  );

export const selectDevice = () =>
  createSelector(
    (state) => state[domain],
    (_, id) => id,
    (state, id) => state.devices.find((item) => item.id === id)
  );

export default devicesSlice.reducer;
