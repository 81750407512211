import styled from '@emotion/styled/macro';

const NotificationWrapper = styled.div`
  position: relative;
  padding: 2px 10px 10px 10px;
  margin: 5px;
  background-color: #e7e7e7;

  &:hover {
    background-color: #dcdbdb;
  }
`;

const BulletNotification = styled.div`
  position: absolute;
  top: 7px;
  right: 3px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
`;

const DateNotification = styled.p`
  font-size: 10px;
  font-style: italic;
`;

export { NotificationWrapper, BulletNotification, DateNotification };
