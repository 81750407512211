import PropTypes from 'prop-types';
import BidderPlacementKeywordsType from './BidderPlacementKeywordsType';

const BidderType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  uuid: PropTypes.string,
  keyname: PropTypes.string,
  displayName: PropTypes.string,
  authorizedToBid: PropTypes.bool,
  hexColorCode: PropTypes.string,
  fontAwesomeIcon: PropTypes.string,
  isAmpCompliant: PropTypes.bool,
  bidderPlacementKeyswords: PropTypes.arrayOf(BidderPlacementKeywordsType),
  monetizationType: PropTypes.shape({
    genericName: PropTypes.string,
  }),
  adapter: PropTypes.shape({
    adapter: PropTypes.string,
  }),
  type: PropTypes.number,
});

export default BidderType;
