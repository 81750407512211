import PropTypes from 'prop-types';
import { useState } from 'react';
import FileInput from '../../../UI/FileInput/FileInput';
import Heading from '../../../UI/Title/Heading';
import { UploadWrapper } from './UploadFile.style';

function UploadFile(props) {
  const { fileChanged } = props;
  const [nameFile, setNameFile] = useState('');

  function handleChange(event) {
    setNameFile(event.target.files[0].name);
    fileChanged(event.target.files[0]);
  }

  return (
    <UploadWrapper>
      <Heading variant="h2">Importez votre Fichier ici :</Heading>
      <FileInput accept="text/csv" onChange={(event) => handleChange(event)} width="90%" />
      {nameFile !== '' && (
        <p>
          <b>Fichier uploadé</b> : {nameFile}
        </p>
      )}
    </UploadWrapper>
  );
}

UploadFile.propTypes = {
  fileChanged: PropTypes.func,
};

UploadFile.defaultProps = {
  fileChanged: () => {},
};

export default UploadFile;
