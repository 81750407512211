import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNotification } from '../NotificationSlice';
import NotificationTemplate from '../NotificationTemplate/NotificationTemplate';

function AppNexusNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  const type = useMemo(() => (metadata.type === 'publisher' ? 'publisher' : 'placement'), [metadata]);

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        Le {type} ayant l&lsquo;id {metadata.id} et le nom {metadata.name} a été créé.
      </p>
    </NotificationTemplate>
  );
}

AppNexusNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default AppNexusNotification;
