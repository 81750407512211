import styled from '@emotion/styled/macro';
import { ImCross } from 'react-icons/im';

const PopoverHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentPopover = styled.div`
  background-color: white;
  padding: 1.5rem;
`;

const TextAreaDescription = styled.textarea`
  width: 100%;
  margin-bottom: 5px;
`;

const ClosePopover = styled(ImCross)`
  &:hover {
    cursor: pointer;
  }
`;

export { PopoverHeader, TabWrapper, TextAreaDescription, ClosePopover, ContentPopover };
