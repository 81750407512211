/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  createPlacementKeywordLib,
  getAllPlacementKeywordLibs,
  getPlacementKeywordLibsTypes,
  modifyPlacementKeywordLib,
} from '../helpers/api/placementKeywordLib';

const DOMAIN = 'placementKeywordLib';

// Async actions
export const fetchPlacementKeywordLibs = createAsyncThunk(`${DOMAIN}/fetchAll`, async (thunkAPI) => {
  try {
    return getAllPlacementKeywordLibs();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const addPlacementKeywordLib = createAsyncThunk(`${DOMAIN}/create`, async (body, thunkAPI) => {
  try {
    return createPlacementKeywordLib(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchPlacementKeywordLib = createAsyncThunk(`${DOMAIN}/patch`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return modifyPlacementKeywordLib(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchPlacementKeywordLibsTypes = createAsyncThunk(`${DOMAIN}/fetchTypes`, async (body, thunkAPI) => {
  try {
    return getPlacementKeywordLibsTypes(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  data: [],
  status: 'idle',
  types: [],
  typesStatus: 'idle',
  error: null,
};

export const placementKeywordLibSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPlacementKeywordLibs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlacementKeywordLibs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload['hydra:member'];
      })
      .addCase(fetchPlacementKeywordLibs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPlacementKeywordLib.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(fetchPlacementKeywordLibsTypes.pending, (state) => {
        state.typesStatus = 'loading';
      })
      .addCase(fetchPlacementKeywordLibsTypes.fulfilled, (state, action) => {
        state.typesStatus = 'succeeded';
        state.types = action.payload['hydra:member'];
      })
      .addCase(patchPlacementKeywordLib.fulfilled, (state, action) => {
        const { payload } = action;
        state.data = state.data.map((d) => (d.id === payload.id ? payload : d));
      });
  },
});

// Actions

// Selectors
export const selectPlacementKeywordLibs = (state) => state[DOMAIN].data;
export const selectPlacementKeywordLibsStatus = (state) => state[DOMAIN].status;
export const selectPlacementKeywordLibsTypes = (state) => state[DOMAIN].types;
export const selectPlacementKeywordLibsTypesStatus = (state) => state[DOMAIN].typesStatus;

export const selectPlacementKeywordLib = () =>
  createSelector([selectPlacementKeywordLibs, (state, id) => id], (placements, id) => {
    return placements.find((p) => p.id === id);
  });

export default placementKeywordLibSlice.reducer;
