import { Box, Button, Tooltip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { RxUpdate } from 'react-icons/rx';
import { importGoogleTagsFromGithub } from '../../../../../helpers/api/googleTagVersion';
import { BASE_URL, MERCURE_BASE_URL } from '../../../../../references/base-urls';
import LinearProgressWithLabel from '../../../Animation/LinearProgressWithLabel';
import MuiModal from '../../../Modal/MuiModal';
import { ButtonContainer, IconWrapper, UpdateButton } from './HeaderActionButtons.style';

function UpdateGoogleTags() {
  const [updating, setUpdating] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  const onClickHandler = useCallback(() => {
    if (!updating) {
      setOpen((o) => !o);
    }
  }, [updating]);

  const reloadGoogleTagHandler = useCallback(() => {
    if (!updating) {
      importGoogleTagsFromGithub();
      setUpdating(true);
    }
    setOpen((o) => !o);
  }, [updating]);

  useEffect(() => {
    const url = new URL(MERCURE_BASE_URL);
    url.searchParams.append('topic', `${BASE_URL}/api/google_tag_snapshots?importing`);
    const sse = new EventSource(url);

    sse.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.importing) {
        setUpdating(true);
      } else {
        setUpdating(false);
      }

      if (data.current && data.total) {
        setProgress((data.current / data.total) * 100);
      }
    };

    return () => {
      sse.close();
    };
  }, []);

  return (
    <>
      <Tooltip
        title={
          updating ? (
            <Box sx={{ width: '250px', margin: '5px' }}>
              <LinearProgressWithLabel variant="determinate" value={progress} />
            </Box>
          ) : (
            <span style={{ fontSize: '14px' }}>Mettre à jour les GoogleTags</span>
          )
        }
      >
        <UpdateButton type="button" onClick={onClickHandler}>
          <IconWrapper loading={updating ? 'true' : 'false'}>
            <RxUpdate />
          </IconWrapper>
        </UpdateButton>
      </Tooltip>
      <MuiModal open={open} handleClose={onClickHandler} title="Recharger tous les GoogleTags de Prod ?">
        <p>Le rechargement des GoogleTag peut entraîner des pertes de données.</p>
        <p>Il est possible de recharger seulement un partenaire en allant sur la page de ce partenaire.</p>
        <p>Êtes-vous sûr de continuer ?</p>
        <ButtonContainer>
          <Button onClick={onClickHandler} variant="contained" color="primary" fullWidth>
            Retour
          </Button>
          <Button onClick={reloadGoogleTagHandler} variant="contained" color="warning" fullWidth>
            Valider
          </Button>
        </ButtonContainer>
      </MuiModal>
    </>
  );
}

export default UpdateGoogleTags;
