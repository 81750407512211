import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import positionType from '../../../../../../../types/PositionType';
import RtbType from '../../../../../../../types/RtbType';
import { selectGoogleTagVersion } from '../../../../../../../store/GoogleTagVersionSlice';
import {
  addRtbPositionsModification,
  selectRtbPositionModifications,
} from '../../../../../../../store/ModificationsSlice';
import { selectAllPositions } from '../../../../../../../store/PositionsSlice';
import { selectRtbPositions } from '../../../../../../../store/PositionRTBSlice';
import { PositionCardWrapper } from './PositionCard.style';

function PositionCard(props) {
  const { rtb, positionsSelect } = props;
  const dispatch = useDispatch();

  const { existingAddPosition } = useSelector(selectRtbPositions);
  const positions = useSelector(selectAllPositions);
  const rtbPositionModifications = useSelector(selectRtbPositionModifications);
  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const [selectedPositions, setSelectedPositions] = useState([]);

  const findExisting = useCallback(() => {
    if ('adPositions' in existingAddPosition) {
      const rtbExistingAddP = existingAddPosition.adPositions.filter((pos) => pos.rtbPosition.value === rtb.value);
      const arraySelected = [];

      rtbPositionModifications.forEach((modif) => {
        if (modif.metadata.rtbPosition === rtb.id) {
          arraySelected.push(modif.metadata.name);
        }
      });

      rtbExistingAddP.forEach((exi) => {
        arraySelected.push(exi.position.genericName);
      });

      setSelectedPositions(arraySelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAddPosition, rtb]);

  useEffect(() => {
    findExisting();
  }, [existingAddPosition, findExisting]);

  const onChange = (event) => {
    const { value } = event.target;

    setSelectedPositions(value);
    const goodPosition = positionsSelect.filter((pos) => pos.genericName === value[value.length - 1]);

    const data = {
      position: goodPosition[0].id,
      name: goodPosition[0].genericName,
    };
    let modification;

    const alreadyExist = existingAddPosition.adPositions.filter((pos) => pos.position.id === goodPosition[0].id);
    if (alreadyExist.length === 0) {
      modification = {
        operation: 'add adPosition',
        metadata: {
          ...data,
          partnerGroup: googleTagVersion.partnerGroup.id,
          rtbPosition: rtb.id,
        },
        googleTagVersions: [googleTagVersion['@id']],
      };
    } else {
      modification = {
        operation: 'modify adPosition',
        metadata: {
          ...data,
          idAddPosition: alreadyExist[0].id,
          rtbPosition: rtb.id,
        },
        googleTagVersions: [googleTagVersion['@id']],
      };
    }

    dispatch(addRtbPositionsModification(modification));
  };

  const onDelete = (valueSelect) => {
    const goodPosition = positions.filter((pos) => pos.genericName === valueSelect);
    const alreadyExist = existingAddPosition.adPositions.filter((pos) => pos.position.id === goodPosition[0].id);

    if (goodPosition.length > 0) {
      const modification = {
        operation: 'delete adPosition',
        metadata: {
          position: goodPosition[0].id,
          name: goodPosition[0].genericName,
        },
        googleTagVersions: [googleTagVersion['@id']],
      };

      if (alreadyExist.length > 0) {
        modification.metadata.idAddPosition = alreadyExist[0].id;
      }

      dispatch(addRtbPositionsModification(modification));
      setSelectedPositions(selectedPositions.filter((rtbTriage) => rtbTriage !== valueSelect));
    }
  };

  const onMouseDown = (event) => {
    event.stopPropagation();
  };

  return (
    <PositionCardWrapper>
      <FormControl fullWidth sx={{ m: 1, minWidth: 300, maxWidth: 300 }}>
        <InputLabel id="dselectRtb-label" size="normal">
          {rtb.value} - {rtb.description}
        </InputLabel>
        <Select
          labelId="selectRtb-label"
          id="selectRtb"
          multiple
          value={selectedPositions}
          label={`${rtb.value} - ${rtb.description}`}
          onChange={onChange}
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                maxHeight: 270,
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} onDelete={() => onDelete(value)} onMouseDown={onMouseDown} />
              ))}
            </Box>
          )}
        >
          {positionsSelect.map((pos) => (
            <MenuItem value={pos.genericName} key={pos.id}>
              {pos.genericName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </PositionCardWrapper>
  );
}

PositionCard.propTypes = {
  rtb: RtbType.isRequired,
  positionsSelect: positionType.isRequired,
};

export default PositionCard;
