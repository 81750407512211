import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

const ManageKeywordWrapper = styled.div`
  margin: 2em;
`;

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const ButtonPlacement = styled(Button)`
  background-color: #264653;
  color: white;
  padding: 15px;
  height: 100%;
  border: 1px solid transparent;

  &:hover {
    background-color: white;
    color: #264653;
    height: 100%;
    border: 1px solid #264653;
  }
`;

export { ManageKeywordWrapper, ListWrapper, ButtonPlacement };
