import styled from '@emotion/styled/macro';

const DraftWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  grid-column-gap: 20px;
  height: 50vh;
`;

export { DraftWrapper };
