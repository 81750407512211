import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDevices } from '../../../store/DevicesSlice';
import { selectMediatypes } from '../../../store/MediatypesSlice';
import { selectPlacementKeywordLibs } from '../../../store/PlacementKeyworLibSlice';
import { selectPositions } from '../../../store/PositionsSlice';
import { selectTabBidderByIds } from '../../../store/TabBiddersSlice';

const returnValues = (values, placementKeywordLibs) => {
  const entries = Object.entries(values);
  if (!entries.length) {
    return "Aucune valeur n'a été settée.";
  }

  return Object.entries(values).map(([key, value]) => {
    const keyword = placementKeywordLibs.find(
      (placementKeywordLib) => placementKeywordLib.id === Number.parseInt(key, 10)
    );
    return [
      <strong key="keyword">&quot;{keyword?.keyName ?? key}&quot;</strong>,
      ' => ',
      <strong key="value">&quot;{value}&quot;</strong>,
    ];
  });
};

function TabBidderDetail(props) {
  const { modification } = props;
  const { operation, metadata } = modification;

  const devicesSelector = useMemo(selectDevices, []);
  const deviceIds = Array.isArray(metadata.device) ? metadata.device : [metadata.device];
  const devices = useSelector((state) => devicesSelector(state, deviceIds));
  const positionsSelector = useMemo(selectPositions, []);
  const positionIds = Array.isArray(metadata.position) ? metadata.position : [metadata.position];
  const positions = useSelector((state) => positionsSelector(state, positionIds));
  const mediatypesSelector = useMemo(selectMediatypes, []);
  const mediatypeIds = Array.isArray(metadata.mediatype) ? metadata.mediatype : [metadata.mediatype];
  const mediatypes = useSelector((state) => mediatypesSelector(state, mediatypeIds));

  const bidderSelector = useMemo(selectTabBidderByIds, []);
  const bidderIds = Array.isArray(metadata.bidder) ? metadata.bidder : [metadata.bidder];
  const bidders = useSelector((state) => bidderSelector(state, bidderIds));

  const placementKeywordLibs = useSelector(selectPlacementKeywordLibs);

  const plural = metadata.occurrence && metadata.occurrence === -1;

  let globalMessage;
  let placementMessage = [];

  const bidderLabel = bidderIds.map((id) => {
    const found = bidders.find((bidder) => bidder.id === id);
    return found ? found.displayName : id;
  });

  switch (operation) {
    case 'updateTabBidders':
      globalMessage = [
        plural ? 'Toutes les occurrences des bidders ' : `L'occurrence ${metadata.occurrence} des bidders `,
        <strong key="bidders">({bidderLabel.join(', ')})</strong>,
        plural ? ' ont été modifiées' : ' a été modifiée',
      ];
      placementMessage = returnValues(metadata.values, placementKeywordLibs);
      break;
    case 'addTabBidders':
      globalMessage = [`Les bidders `, <strong key="bidders">({bidderLabel.join(', ')})</strong>, ' ont été ajoutés'];
      placementMessage = returnValues(metadata.values, placementKeywordLibs);
      break;
    case 'disableTabBidders':
      globalMessage = [
        plural ? 'Toutes les occurrences des bidders ' : `L'occurrence ${metadata.occurrence} des bidders `,
        <strong key="bidders">({bidderLabel.join(', ')})</strong>,
        plural ? ' ont été désactivées' : ' a été désactivée',
      ];
      break;
    case 'enableTabBidders':
      globalMessage = [
        plural ? 'Toutes les occurrences des bidders ' : `L'occurrence ${metadata.occurrence} des bidders `,
        <strong key="bidders">({bidderLabel.join(', ')})</strong>,
        plural ? ' ont été activées' : ' a été activée',
      ];
      break;
    default:
      break;
  }

  const deviceLabel = deviceIds.map((id) => {
    const found = devices.find((p) => p.id === id);
    return found ? found.genericName : id;
  });

  const positionLabel = positionIds.map((id) => {
    const found = positions.find((p) => p.id === id);
    return found ? found.genericName : id;
  });

  const mediatypeLabel = mediatypeIds.map((id) => {
    const found = mediatypes.find((p) => p.id === id);
    return found ? found.genericName : id;
  });

  return (
    <>
      <div>
        {globalMessage} sur
        {metadata.device && [' les devices ', <strong key="devices">({deviceLabel.join(', ')})</strong>]}
        {metadata.position && [' les positions ', <strong key="positions">({positionLabel.join(', ')})</strong>]}
        {metadata.mediatype && [' les mediatypes ', <strong key="mediatypes">({mediatypeLabel.join(', ')})</strong>]}
      </div>
      {Array.isArray(placementMessage) && placementMessage.length > 0 ? (
        <div>
          Les valeurs suivantes ont été settées:
          {placementMessage.map((e, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>{e}</div>
          ))}
        </div>
      ) : (
        placementMessage
      )}
    </>
  );
}

TabBidderDetail.propTypes = {
  modification: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      occurrence: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      bidder: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
      device: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      position: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      mediatype: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      values: PropTypes.shape({}),
    }).isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default TabBidderDetail;
