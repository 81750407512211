import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNotification } from '../NotificationSlice';
import NotificationTemplate from '../NotificationTemplate/NotificationTemplate';

function DraftModification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  const type = useMemo(() => (metadata.type === 0 ? 'unitaire' : 'de masse'), [metadata]);

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        Le <b>draft {type}</b> ayant le nom <b>{metadata.name}</b> a été validé le&nbsp;
        <b>{new Date(metadata.validatedAt).toLocaleString()}</b>
      </p>
    </NotificationTemplate>
  );
}

DraftModification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default DraftModification;
