import styled from '@emotion/styled/macro';
import { VscChromeClose } from 'react-icons/vsc';

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled(VscChromeClose)`
  cursor: pointer;
`;

export { IconWrapper, Icon };
