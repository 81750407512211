/* eslint-disable */
import { useEffect, useState, useCallback, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../references/base-urls';
import Intructions from '../../components/Reusable/NewGtag/Instructions';
import PendingGtagReminderForPartner from '../../components/Reusable/Warning/PendingGtagReminderForPartner';
import SelectGtagToClone from '../../components/GoogleTag/GenerateNew/SelectGtagToClone/SelectGtagToClone';
import Button from '../../components/UI/Basic/Buttons/Button';
import { getOnePartner } from '../../helpers/api/partner';

function GenerateNewGtag() {
  const { partnerId } = useParams();
  const [showGtagsToCopy, setShowGtagsToCopy] = useState(false);
  const [otherDrafts, setOtherDrafts] = useState({ closestGtag: null, nbOfDrafts: 0 });
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    fetcherPartnerByUuid();
  }, []);

  const fetcherPartnerByUuid = useCallback(async () => {
    try {
      getOnePartner(partnerId).then((res) => {
        setPartner(res);
      })
      const response2 = await fetch(`${API_BASE_URL}/get-latest-draftgtag-by-partner-uuid/${partnerUuid}`);
      const data2 = await response2.json();

      const obj = {
        pendingGtag: data2.content.closestGtag,
        nbOfDrafts: data2.content.nbOfDrafts,
      };
      setOtherDrafts(obj);
    } catch (error) {
      // setError(error.message);
    }
  }, []);

  const showSelection = () => {
    setShowGtagsToCopy(true);
  };

  let warning = '';
  if (otherDrafts.closestGtag && otherDrafts.nbOfDrafts) {
    warning = (
      <PendingGtagReminderForPartner nbOfDrafts={otherDrafts.nbOfDrafts} pendingGtag={otherDrafts.closestGtag} />
    );
  }

  return (
    <>
      <h1>Générez un nouveau Google Tag pour le Site Partenaire: {partner && partner.displayName }</h1>
      <Intructions />
      {warning}
      {!showGtagsToCopy && <Button onClick={showSelection}>Poursuivre ?</Button>}
      {showGtagsToCopy && partner && <SelectGtagToClone partnerId={partnerId} />}
    </>
  );
}

export default GenerateNewGtag;
