import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const KeywordTextContainer = styled.div`
  display: flex;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export { KeywordTextContainer };
