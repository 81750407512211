import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CollapseNotification from './CollapseNotification/CollapseNotification';
import NoNotifications from './NoNotifications/NoNotifications';
import {
  fetchNotificationTypes,
  selectNotificationTypes,
  selectTypeStatus,
  selectUserNotifications,
} from './NotificationSlice';
import { NotificationsContainer } from './SwitchNotifications.style';

function SwitchNotifications() {
  const dispatch = useDispatch();

  const typeStatus = useSelector(selectTypeStatus);
  const notificationTypes = useSelector(selectNotificationTypes);
  const notifications = useSelector(selectUserNotifications);

  useEffect(() => {
    if (typeStatus === 'idle') {
      dispatch(fetchNotificationTypes());
    }
  }, [dispatch, typeStatus]);

  const initialState = useMemo(() => {
    const state = {};
    notificationTypes.forEach((type) => {
      state[type.id] = false;
    });
    return state;
  }, [notificationTypes]);

  const [open, setOpen] = useState(initialState);

  const handleChangeOpen = (id) => {
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  return (
    <NotificationsContainer>
      {notifications.length === 0 ? (
        <NoNotifications />
      ) : (
        notificationTypes &&
        notificationTypes.map((type) => (
          <CollapseNotification
            key={type.id}
            typeId={type.id}
            open={open[type.id] ?? false}
            setOpen={() => handleChangeOpen(type.id)}
            typeValue={type.value}
          />
        ))
      )}
    </NotificationsContainer>
  );
}

export default SwitchNotifications;
