import { Box, Button, Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getPrebidFloorFile } from '../../helpers/api/prebidFloor';
import TabPanel from '../UI/TabPanel/TabPanel';
import Currency from './components/Currency';
import FloorProvider from './components/FloorProvider';
import ModelTimestamp from './components/ModelTimestamp';
import SkipRate from './components/SkipRate';
import Version from './components/Version';
import Fields from './Fields';
import FloorSelection from './FloorSelection';
import FloorVersion1 from './FloorVersion1';
import FloorVersion2 from './FloorVersion2';
import { fetchPrebidFloorFields, selectPrebidFloorFields } from './PrebidFloorFieldsSlice';
import { postPrebidFloors, selectPrebidFloor } from './PrebidFloorSlice';

const defaultValues = {
  version: '',
  floorProvider: '',
  currency: '',
  skipRate: '',
  modelTimestamp: '',
  floorsSchemaVersion: 1,
  modelVersion: '',
  schema: {
    delimitor: '',
    fields: [],
  },
  values: [],
  default: '',
  modelGroups: [],
};

function Floor() {
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);
  const [selectedVersion, setSelectedVersion] = useState('');
  const [floorJson, setFloorJson] = useState({});
  const [formattedFloorJson, setformattedFloorJson] = useState({});
  const floorFiles = useSelector(selectPrebidFloor);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
  });

  const activeTab = useWatch({ control, name: 'floorsSchemaVersion' });
  const prebidFloorFields = useSelector(selectPrebidFloorFields);

  useEffect(() => {
    dispatch(fetchPrebidFloorFields({ paginate: false }));
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue('floorsSchemaVersion', newValue);
  };

  function formatFloorCoreInput(data) {
    const newData = { ...data };

    const { delimiter, fields } = newData.schema;
    const values = Object.entries(newData.values);

    newData.values = values.map(([key, value]) => {
      const keys = key.split(delimiter ?? '|');
      const object = {
        value,
      };

      keys.forEach((aKey, index) => {
        const floorField = prebidFloorFields.find((element) => element.name === fields[index]);
        let newValue = aKey.toString();

        if (floorField?.prefix) {
          newValue = aKey.slice(floorField.prefix.length);
        }

        object[fields[index]] = newValue;
      });

      return object;
    });

    newData.default = newData.default.toString();

    return newData;
  }

  function clearEmptyValues(data) {
    const cleanedData = { ...data };

    Object.keys(cleanedData).forEach((key) => {
      if (cleanedData[key] === '' || cleanedData[key] == null) {
        delete cleanedData[key];
      } else if (!Number.isNaN(cleanedData[key]) && !Number.isNaN(parseFloat(cleanedData[key]))) {
        cleanedData[key] = parseFloat(cleanedData[key]);
      } else if (typeof cleanedData[key] === 'object') {
        cleanedData[key] = clearEmptyValues(cleanedData[key]);
      }
    });

    return cleanedData;
  }

  function formatFloorCore(data) {
    const newData = { ...data };

    const { delimiter, fields } = newData.schema;
    let values = Object.values(newData.values);

    newData.schema.fields = Object.values(fields);

    values = values.map((value) => {
      const keys = newData.schema.fields.map((field) => {
        const floorField = prebidFloorFields.find((element) => element.name === field);

        if (value[field] === '*') {
          return value[field];
        }

        return floorField?.prefix && !value[field].startsWith(floorField.prefix)
          ? floorField.prefix + value[field]
          : value[field];
      });

      const newKey = keys.join(delimiter ?? '|');

      return [newKey, value.value];
    });

    newData.values = {};

    values.forEach(([key, value]) => {
      newData.values[key] = value;
    });

    return newData;
  }

  const handleReset = useCallback(
    (json = formattedFloorJson) => {
      reset((formValues) => ({
        ...defaultValues,
        ...json,
        version: formValues.version,
      }));
    },
    [formattedFloorJson, reset]
  );

  const handleSelectedFloorChange = async (event) => {
    setSelectedVersion(event.target.value);
    const floor = floorFiles.find((floorFile) => floorFile.version === event.target.value);

    let json = {};
    if (floor && floor?.contentUrl) {
      json = await getPrebidFloorFile(floor.contentUrl);
      setFloorJson({ ...json });

      if (json.floorsSchemaVersion === 1) {
        json = formatFloorCoreInput(json);
      } else {
        json.modelGroups = json.modelGroups.map((modelGroup) => formatFloorCoreInput(modelGroup));
      }
    } else {
      json = {};
    }

    setformattedFloorJson(json);
    handleReset(json);
  };

  const onSubmit = (dataForm) => {
    let cleanedData = { ...dataForm };

    const { version } = cleanedData;
    delete cleanedData.version;

    if (cleanedData.floorsSchemaVersion === 1) {
      delete cleanedData.modelGroups;
    } else {
      delete cleanedData.schema;
      delete cleanedData.values;
      delete cleanedData.default;
    }

    cleanedData = clearEmptyValues(cleanedData);

    if (cleanedData.floorsSchemaVersion === 1) {
      cleanedData = formatFloorCore(cleanedData);
    } else {
      cleanedData.modelGroups = Object.values(cleanedData.modelGroups).map((modelGroup) => {
        return formatFloorCore(modelGroup);
      });
    }

    const floorFile = new File([JSON.stringify(cleanedData)], 'floor.json', { type: 'application/json' });

    const formData = new FormData();
    formData.append('file', floorFile);
    formData.append('version', version);

    dispatch(postPrebidFloors(formData))
      .unwrap()
      .then(() => {
        setSelectedVersion(version);
        setFloorJson(cleanedData);
      });
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab value={0} label="Gestion des Floors" />
          <Tab value={1} label="Gestion des Fields" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <FloorSelection
          floorJson={floorJson}
          selectedVersion={selectedVersion}
          handleChange={handleSelectedFloorChange}
        />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Version control={control} errors={errors} />
          <FloorProvider control={control} />
          <Currency control={control} registerTarget="currency" />
          <SkipRate control={control} registerTarget="skipRate" errors={errors} />
          <ModelTimestamp control={control} errors={errors} />
          <Tabs value={activeTab} onChange={handleChange}>
            <Tab value={1} label="Floor Version 1" />
            <Tab value={2} label="Floor Version 2" />
          </Tabs>
          <div hidden={activeTab !== 1}>
            <FloorVersion1 control={control} errors={errors} hidden={activeTab !== 1} setValue={setValue} />
          </div>
          <div hidden={activeTab !== 2}>
            <FloorVersion2 control={control} errors={errors} hidden={activeTab !== 2} setValue={setValue} />
          </div>
          <Button type="button" onClick={() => handleReset()} variant="contained" color="warning">
            Reset formulaire
          </Button>
          &nbsp;
          <Button type="submit" variant="contained">
            Sauvegarder
          </Button>
        </form>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Fields />
      </TabPanel>
    </>
  );
}

export default Floor;
