import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import { store } from './app/store';
import ConnectionUtilsConfigurator from './helpers/ConnectionUtils';
import SnackbarUtilsConfigurator from './helpers/SnackBarUtils';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} preventDuplicate>
        <SnackbarUtilsConfigurator />
        <ConnectionUtilsConfigurator />
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
);
