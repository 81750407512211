import { createListenerMiddleware, isRejected } from '@reduxjs/toolkit';
import { snackActions } from '../helpers/SnackBarUtils';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isRejected,
  effect: async (action) => {
    snackActions.error(action.error.message);
  },
});

export default listenerMiddleware.middleware;
