import styled from '@emotion/styled/macro';

const DateModification = styled.p`
  color: grey;
  font-size: 12px;
  font-style: italic;
`;

const TextWrapper = styled.div`
  padding: 10px;
  background-color: lightgrey;
  margin-top: -12px;
  font-size: 14px;
`;

const AffectedGoogleTags = styled.div`
  white-space: nowrap;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export { DateModification, TextWrapper, AffectedGoogleTags };
