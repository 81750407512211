import styled from '@emotion/styled/macro';
import { Tab, Tabs } from '@mui/material';

const HeaderTabsStyle = styled(Tab)`
  color: #264653;
  font-weight: bolder;
  &.Mui-selected {
    color: #264653;
  }
`;

const TabWrapper = styled(Tabs)`
  padding: 20px;
  margin: 0;
  border-radius: 15px 15px 0 15px;
`;

const HeaderWrapper = styled.div`
  background-color: lightgrey;
  display: flex;
  justify-content: space-between;
  border-radius: 15px 15px 0 15px;
`;

export { HeaderTabsStyle, TabWrapper, HeaderWrapper };
