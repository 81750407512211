/* eslint-disable react/jsx-props-no-spreading */
import { Button, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { postPartner } from '../../../../../helpers/api/partner';
import { fetchPartnerGroups, selectGroups } from '../../../../../store/GroupSlice';
import Loader from '../../../../UI/Animation/Loader';
import Heading from '../../../../UI/Title/Heading';
import { AddIcon, DeleteIcon } from '../../../ManageBidders/List/ModalModificationBidder/ModalModificationBidder.style';
import { CheckBoxWrapper, CreatePartnerButton, FieldError, FieldsWrapper, SubmitWrapper } from '../CreatePartner.style';

function FormPartner() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { partnerGroup: 'null', gamAdUnits: [] } });

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'gamAdUnits',
    rules: {
      validate: {
        required: (value) => {
          if (!value?.length) {
            return 'Au moins une valeur est requise';
          }

          return true;
        },
      },
    },
  });

  const createPublisherId = useWatch({ control, name: 'createPublisherId' });
  const partnerGroups = useSelector(selectGroups);

  useEffect(() => {
    dispatch(fetchPartnerGroups());
  }, [dispatch]);

  const createPartner = (data) => {
    setLoading(true);
    const dataToRequest = { ...data };

    if (dataToRequest.partnerGroup !== 'null') {
      dataToRequest.partnerGroup = `api/partner_groups/${data.partnerGroup}`;
    } else {
      dataToRequest.partnerGroup = null;
    }

    postPartner(dataToRequest)
      .then((response) => {
        enqueueSnackbar(`Le partenaire ${response.displayName} a bien été créé.`, { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar(`Une erreur est survenue : ${e.message}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit((data) => createPartner(data))} method="POST">
        <FieldsWrapper>
          <TextField
            {...register('displayName', { required: true })}
            required
            id="input-with-sx"
            label="DisplayName"
            variant="standard"
            error={errors.displayname}
            helperText={errors.displayname && 'Le displayName est requis'}
          />
          <TextField
            {...register('keyname', { required: true })}
            required
            id="input-with-sx"
            label="Keyname"
            variant="standard"
            error={errors.keyname}
            helperText={errors.keyname && 'Le keyname est requis'}
          />
          <TextField
            {...register('fontAwesomeIcon', { required: true })}
            required
            id="input-with-sx"
            label="Icone font Awesome"
            variant="standard"
            error={errors.fontAwesomeIcon}
            helperText={errors.fontAwesomeIcon && "L'icone est requise"}
          />
          <CheckBoxWrapper
            {...register('isPartner')}
            control={<Checkbox />}
            label="Est-ce un partenaire ?"
            labelPlacement="start"
          />
          <CheckBoxWrapper
            {...register('isAuthorized')}
            control={<Checkbox />}
            label="Est-ce qu'il est autorisé ?"
            labelPlacement="start"
          />
          <TextField
            {...register('lSSite', { required: true })}
            required
            id="input-with-sx"
            label="LSSite"
            variant="standard"
            error={errors.lSSite}
            helperText={errors.lSSite && 'Le LSSite est requis'}
          />
          <CheckBoxWrapper
            {...register('partnerKeyNameNotInGtag')}
            control={<Checkbox />}
            label="Keyname du partenaire non présent dans le GTAG"
            labelPlacement="start"
          />
          <CheckBoxWrapper
            {...register('hasAmpStatus')}
            control={<Checkbox />}
            label="Status AMP"
            labelPlacement="start"
          />
          <TextField {...register('ampKeyName')} id="input-with-sx" label="AMP Keyname" variant="standard" />
          <TextField
            {...register('hexColorCode', {
              required: true,
              maxLength: 6,
              minLength: 6,
              pattern: /^([0-9A-Fa-f]{3}){1,2}$/i,
            })}
            required
            id="input-with-sx"
            label="Couleur héxadécimal (sans le dièze)"
            variant="standard"
            error={errors.hexColorCode}
            helperText={
              errors.hexColorCode &&
              (errors.hexColorCode?.type === 'required'
                ? 'La couleur est requise et doit faire 7 caractères'
                : 'Héxadécimal non compatible')
            }
          />
          <TextField
            {...register('gitFileName', { required: true })}
            required
            id="input-with-sx"
            label="Nom du fichier GIT (pour import)"
            variant="standard"
            error={errors.gitFileName}
            helperText={errors.gitFileName && 'Le nom du dossier GIT est requis'}
          />
          <TextField
            {...register('trigram', { required: true, maxLength: 3, minLength: 3 })}
            required
            id="input-with-sx"
            label="Trigram du partenaire"
            variant="standard"
            error={errors.trigram}
            helperText={errors.trigram && 'Le trigram est requis et doit faire 3 caractères'}
          />
          <FormControl>
            <InputLabel id="group-label">Groupe</InputLabel>
            <Select
              labelId="group-label"
              label="Groupe"
              {...register('partnerGroup', { required: true })}
              defaultValue="null"
            >
              <MenuItem value="null">Tout seul</MenuItem>
              {partnerGroups &&
                partnerGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
            </Select>
            {errors.group?.type === 'required' && <FieldError>Le groupe est requis</FieldError>}
          </FormControl>
        </FieldsWrapper>

        <Heading variant="h2">Xandr</Heading>
        <FieldsWrapper>
          <CheckBoxWrapper
            {...register('createPublisherId')}
            control={<Checkbox />}
            label="Création automatique du publisher Xandr"
            labelPlacement="start"
          />
          <TextField
            {...register('publisherId', { disabled: createPublisherId, valueAsNumber: true })}
            type="number"
            id="input-with-sx"
            label="PublisherId"
            variant="standard"
          />
        </FieldsWrapper>

        <Heading variant="h2">
          GAM
          <Button
            onClick={() => prepend({ name: '' })}
            variant="outlined"
            sx={{ margin: '0 5px' }}
            endIcon={<AddIcon />}
          >
            Ajouter une AdUnit
          </Button>
          {errors?.gamAdUnits?.root && <FieldError>{errors.gamAdUnits.root.message}</FieldError>}
        </Heading>
        <FieldsWrapper>
          {fields.map((field, index) => (
            <div key={field.id}>
              <TextField
                {...register(`gamAdUnits.${index}.name`, {
                  required: 'Champ requis',
                  pattern: { value: /.*\.site$/, message: "L'adUnit doit terminer par '.site'" },
                })}
                type="text"
                id={`ad-unit-${index}-input`}
                label="AdUnit"
                variant="standard"
                error={errors?.gamAdUnits?.[index]?.name}
                helperText={errors?.gamAdUnits?.[index]?.name?.message}
              />
              <Button
                onClick={() => remove(index)}
                color="error"
                variant="outlined"
                sx={{ margin: '0 5px' }}
                endIcon={<DeleteIcon />}
              >
                Supprimer l&apos;AdUnit
              </Button>
            </div>
          ))}
        </FieldsWrapper>
        <SubmitWrapper>
          <CreatePartnerButton type="submit" disabled={loading}>
            Créer le partenaire
          </CreatePartnerButton>
          {loading && <Loader />}
        </SubmitWrapper>
      </form>
    </div>
  );
}

export default FormPartner;
