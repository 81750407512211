import { keyframes } from '@emotion/react';
import styled from '@emotion/styled/macro';

const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  border: ${({ size }) => (size === 'small' ? '3px' : '6px')} solid #f3f3f3;
  border-top: ${({ size }) => (size === 'small' ? '3px' : '6px')} solid #264653;
  border-radius: 50%;
  width: ${({ size }) => (size === 'small' ? '20px' : '32px')};
  height: ${({ size }) => (size === 'small' ? '20px' : '32px')};
  animation: ${loaderAnimation} 2s linear infinite;
`;

export default Loader;
