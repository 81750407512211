import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledTextField } from '../Floor.style';

function ModelTimestamp(props) {
  const { control, errors } = props;

  const registerTarget = useMemo(() => 'modelTimestamp', []);
  const error = useReactHookFormError(registerTarget, errors);

  return (
    <Controller
      render={({ field }) => (
        <Tooltip title="Doit être rensigné si le Model Version est renseigné">
          <StyledTextField
            id={registerTarget}
            label="Model Timestamp"
            type="number"
            variant="filled"
            error={!!error}
            helperText={error?.message}
            {...field}
            value={field.value ?? ''}
          />
        </Tooltip>
      )}
      control={control}
      name={registerTarget}
      rules={{
        validate: {
          isInteger: (value) => {
            if (parseInt(value, 10) < 0) {
              return 'La champ doit être un entier positif.';
            }

            return true;
          },
        },
      }}
    />
  );
}

ModelTimestamp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default ModelTimestamp;
