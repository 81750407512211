/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  deleteMandatoryModules,
  deleteMandatoryPrebid,
  getMandatoryModules,
  modifyMandatoryModules,
  patchMandatoryPrebid,
  postMandatoryModules,
  postMandatoryPrebid,
} from '../helpers/api/mandatoryModules';

const DOMAIN = 'mandatoryModules';

export const fetchMandatoryModules = createAsyncThunk(`${DOMAIN}/fetch`, async (data, thunkAPI) => {
  try {
    return getMandatoryModules(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createMandatoryModules = createAsyncThunk(`${DOMAIN}/create`, async (body, thunkAPI) => {
  try {
    return postMandatoryModules(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const removeMandatoryModules = createAsyncThunk(`${DOMAIN}/delete`, async (id, thunkAPI) => {
  try {
    return deleteMandatoryModules(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateMandatoryModules = createAsyncThunk(`${DOMAIN}/update`, async (data, thunkAPI) => {
  const { id, body } = data;

  try {
    return modifyMandatoryModules(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const postMandatoryModulesWithPrebidVersion = createAsyncThunk(
  `${DOMAIN}/postMandatoryPrebid`,
  async (body, thunkAPI) => {
    try {
      return postMandatoryPrebid(body);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const patchMandatoryModulesWithPrebidVersion = createAsyncThunk(
  `${DOMAIN}/patchMandatoryPrebid`,
  async (data, thunkAPI) => {
    const { id, body } = data;
    try {
      return patchMandatoryPrebid(id, body);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteMandatoryModulesWithPrebidVersion = createAsyncThunk(
  `${DOMAIN}/deleteMandatoryPrebid`,
  async (id, thunkAPI) => {
    try {
      return deleteMandatoryPrebid(id);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const initialState = {
  mandatoryModules: [],
  total: 0,
  status: 'idle',
  error: null,
};

export const MandatoryModulesSlice = createSlice({
  name: DOMAIN,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMandatoryModules.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchMandatoryModules.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.mandatoryModules = action.payload['hydra:member'];
      state.total = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchMandatoryModules.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
    builder.addCase(createMandatoryModules.fulfilled, (state, action) => {
      state.mandatoryModules.unshift(action.payload);
    });

    builder.addCase(removeMandatoryModules.fulfilled, (state, action) => {
      state.mandatoryModules = state.mandatoryModules.filter((module) => module.id !== action.meta.arg);
    });

    builder.addCase(updateMandatoryModules.fulfilled, (state, action) => {
      const { payload } = action;
      state.mandatoryModules = state.mandatoryModules.map((module) => (module.id === payload.id ? payload : module));
    });
  },
});

export const selectMandatoryModules = (state) => state[DOMAIN].mandatoryModules;
export const selectMandatoryModulesTotal = (state) => state[DOMAIN].total;

export const selectMandatoryModulesStatus = (state) => state[DOMAIN].status;

export const selectMandatoryModule = () =>
  createSelector([selectMandatoryModules, (state, id) => id], (modules, id) => {
    return modules.find((module) => module.id === id);
  });

export default MandatoryModulesSlice.reducer;
