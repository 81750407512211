import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSuggestionByScope from '../../../../../../../hooks/useSuggestionByScope';
import { selectGoogleTagVersion } from '../../../../../../../store/GoogleTagVersionSlice';
import {
  tabBiddersModificationValueSelector,
  updateTabBidderKeywordValue,
} from '../../../../../../../store/ModificationsSlice';
import InputWithSuggestions from '../../../../../../UI/Basic/Input/InputWithSuggestions';
import { selectSelectedPartner } from '../TabBiddersModificationsSlice';

function KeywordText(props) {
  const { bidderId, placement, selectedTabBidder, metadata, occurrence } = props;

  const { placementKeywordLib } = placement;

  const suggestionSSP = useSuggestionByScope('SSP');

  const dispatch = useDispatch();

  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const selectedPartner = useSelector(selectSelectedPartner);

  const selectorArgs = useMemo(
    () => ({ partnerWebsite: selectedPartner, metadata, bidderId, occurrence, keywordId: placementKeywordLib.id }),
    [selectedPartner, metadata, bidderId, occurrence, placementKeywordLib.id]
  );
  const selectTabBidderModification = useMemo(tabBiddersModificationValueSelector, []);
  const modificationValue = useSelector((state) => selectTabBidderModification(state, selectorArgs));

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = selectedTabBidder?.placementIdRefs.find((t) => t.placementKeyWordLib.id === placementKeywordLib.id);
    setDefaultValue(value?.placementId ?? '');
  }, [placementKeywordLib.id, selectedTabBidder?.placementIdRefs]);

  const handleChange = useCallback(
    (event) => {
      dispatch(
        updateTabBidderKeywordValue({
          selectedPartner,
          operation: 'updateTabBidders',
          metadata,
          bidderId,
          occurrence,
          keywordId: placementKeywordLib.id,
          value: event.target.value,
          googleTagVersions: [googleTagVersion['@id']],
        })
      );
    },
    [bidderId, dispatch, googleTagVersion, metadata, occurrence, placementKeywordLib.id, selectedPartner]
  );

  return (
    <InputWithSuggestions
      formProps={{
        label: placementKeywordLib.keyName,
        value: modificationValue ?? defaultValue,
        onChange: (event) => handleChange(event),
        style: { margin: '15px 0' },
      }}
      suggestions={suggestionSSP}
      infos={`Les valeurs suggérées sont automatiquement transformées à l'application du draft. Elles peuvent être multiples. (Ex: "{{pagetype}}_{{device}}")`}
    />
  );
}

KeywordText.propTypes = {
  bidderId: PropTypes.number.isRequired,
  placement: PropTypes.shape({
    placementKeywordLib: PropTypes.shape({
      id: PropTypes.number.isRequired,
      keyName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  selectedTabBidder: PropTypes.shape({
    placementIdRefs: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  occurrence: PropTypes.number.isRequired,
};

export default KeywordText;
