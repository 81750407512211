import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from 'react-icons/bs';
import { CiRead } from 'react-icons/ci';
import { useDispatch } from 'react-redux';
import AllNotifications from '../AllNotifications/AllNotifications';
import { readAllNotificationsByUser } from '../NotificationSlice';
import SwitchNotifications from '../SwitchNotifications';
import {
  HeaderNotificationsContainer,
  IconContainer,
  IconWrapper,
  NotifTitle,
  SwitchContainer,
  SwitchLabel,
} from './NotificationsModal.style';

const style = {
  position: 'absolute',
  height: '100vh',
  top: 0,
  bottom: 0,
  right: 0,
  width: '30vw',
  bgcolor: 'whitesmoke',
};

function NotificationsModal(props) {
  const { open, handleClose } = props;
  const [type, setType] = useState(false);
  const dispatch = useDispatch();
  const [audio, setAudio] = useState(localStorage.getItem('audio'));

  const handleChangeSoundAuthorization = () => {
    const targetVolume = audio === '0' ? '1' : '0';
    localStorage.setItem('audio', targetVolume);
    setAudio(targetVolume);
  };

  const handleReadAll = () => {
    dispatch(readAllNotificationsByUser());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <HeaderNotificationsContainer>
          <NotifTitle>Notifications</NotifTitle>
          <IconContainer>
            <IconWrapper>
              <Tooltip title="Tout marquer comme lu">
                <IconButton onClick={handleReadAll}>
                  <CiRead color="black" />
                </IconButton>
              </Tooltip>
            </IconWrapper>
            <IconWrapper>
              <Tooltip title={`${audio === '0' ? 'Désactiver' : 'Activer'} le son des notifications`}>
                <IconButton onClick={handleChangeSoundAuthorization}>
                  {audio === '0' ? <BsFillVolumeMuteFill color="black" /> : <BsFillVolumeUpFill color="black" />}
                </IconButton>
              </Tooltip>
            </IconWrapper>
            <SwitchContainer>
              <Switch value={type} onChange={() => setType(!type)} />
              <SwitchLabel>Notifications triées par type</SwitchLabel>
            </SwitchContainer>
          </IconContainer>
        </HeaderNotificationsContainer>
        <hr />
        {type ? <SwitchNotifications /> : <AllNotifications />}
      </Box>
    </Modal>
  );
}

NotificationsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NotificationsModal;
