import styled from '@emotion/styled/macro';
import { ListItem } from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';

const BlockListItem = styled(ListItem)`
  border: 1px solid black;
  border-radius: 3px;
  padding: 0.1rem 2rem;
  background: ${(props) => props.currentlydragging === 'true' && 'lightgrey'};
`;

const BlockListItemText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PrimaryTextForListItem = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const DeleteIcon = styled(AiFillDelete)`
  cursor: pointer;
`;

export { BlockListItem, BlockListItemText, PrimaryTextForListItem, DeleteIcon };
