/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { deleteComment, getComments, postComment } from '../helpers/api/gtagComments';

const DOMAIN = 'comments';

export const fetchComments = createAsyncThunk(`${DOMAIN}/fetch`, async (data, thunkAPI) => {
  try {
    return getComments(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createComment = createAsyncThunk(`${DOMAIN}/create`, async (body, thunkAPI) => {
  try {
    return postComment(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const removeComment = createAsyncThunk(`${DOMAIN}/delete`, async (id, thunkAPI) => {
  try {
    return deleteComment(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  comments: [],
  status: 'idle',
  error: null,
};

export const CommentsSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    resetComments: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchComments.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.comments = action.payload['hydra:member'];
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
    builder.addCase(createComment.fulfilled, (state, action) => {
      state.comments.unshift(action.payload);
    });
    builder.addCase(removeComment.fulfilled, (state, action) => {
      state.comments = state.comments.filter((comment) => comment.id !== action.meta.arg);
    });
  },
});

export const { resetComments } = CommentsSlice.actions;

export const selectComments = (state) => state[DOMAIN].comments;
export const selectComment = () =>
  createSelector([selectComments, (state, id) => id], (comments, id) => {
    return comments.find((comment) => comment.id === id);
  });

export default CommentsSlice.reducer;
