import PropTypes from 'prop-types';
import classes from './Card.module.css';
import compilePropClasses from '../../../helpers/classBuilder';
import { CardDiv } from './Wrapper.style';

function Card(props) {
  const { extraClasses, children, width, height, marginTop, hidden, onDragOver, onDrop, sx } = props;
  const additionnalClasses = compilePropClasses(classes, extraClasses);

  return (
    <CardDiv
      className={`${classes.card} ${additionnalClasses}`}
      width={width}
      height={height}
      marginTop={marginTop}
      hidden={hidden}
      onDragOver={onDragOver}
      onDrop={onDrop}
      sx={sx}
    >
      {children}
    </CardDiv>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  extraClasses: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.string,
  height: PropTypes.string,
  marginTop: PropTypes.string,
  hidden: PropTypes.bool,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  sx: PropTypes.shape({}),
};

Card.defaultProps = {
  children: null,
  extraClasses: [],
  width: null,
  height: null,
  marginTop: null,
  hidden: false,
  onDragOver: null,
  onDrop: null,
  sx: null,
};

export default Card;
