import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import MuiModal from '../../../../../../UI/Modal/MuiModal';
import { selectMandatoryModule, updateMandatoryModules } from '../../../../../../../store/MandatoryModulesSlice';
import MassButton from '../../../../../../UI/Basic/Buttons/MassButton/MassButton';
import { ModifyModuleTextField } from './ModalModify.style';

function ModalModify(props) {
  const { open, handleClose, moduleId } = props;
  const dispatch = useDispatch();
  const moduleSelector = useMemo(selectMandatoryModule, []);
  const module = useSelector((state) => moduleSelector(state, moduleId));
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: module.name,
      keyname: module.keyname,
      description: module.description,
    },
  });

  const updateModule = (data) => {
    dispatch(updateMandatoryModules({ id: moduleId, body: data }));
    reset();
    handleClose();
  };

  return (
    <MuiModal open={open} handleClose={handleClose}>
      <form onSubmit={handleSubmit(updateModule)}>
        <ModifyModuleTextField
          {...register('name', { required: true })}
          required
          id="input-with-sx"
          label="Nom du module"
          variant="outlined"
          error={errors.name}
        />
        <ModifyModuleTextField
          {...register('keyname', { required: true })}
          required
          id="input-with-sx"
          label="Keyname du module"
          variant="outlined"
          error={errors.keyname}
        />
        <ModifyModuleTextField
          {...register('description', { required: true })}
          required
          id="input-with-sx"
          multiline
          minRows={2}
          label="Description du module"
          variant="outlined"
          error={errors.description}
        />
        <MassButton type="submit" width="70%">
          Modifier un module
        </MassButton>
      </form>
    </MuiModal>
  );
}

ModalModify.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  moduleId: PropTypes.number.isRequired,
};

export default ModalModify;
