/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createPrebidFloorFields,
  getPrebidFloorFields,
  getPrebidFloorFieldsTarget,
  removePrebidFloorFields,
} from '../../helpers/api/prebidFields';
import { snackActions } from '../../helpers/SnackBarUtils';

const domain = 'prebidFloorFields';

export const fetchPrebidFloorFields = createAsyncThunk(`${domain}/fetch`, async (options = {}, thunkAPI = null) => {
  try {
    return getPrebidFloorFields(options);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchPrebidFloorFieldsTarget = createAsyncThunk(`${domain}/fetchTarget`, async (id, thunkAPI = null) => {
  try {
    return getPrebidFloorFieldsTarget(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const postPrebidFloorFields = createAsyncThunk(`${domain}/post`, async (datas, thunkAPI) => {
  try {
    return createPrebidFloorFields(datas);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deletePrebidFloorFields = createAsyncThunk(`${domain}/delete`, async (id, thunkAPI) => {
  try {
    return removePrebidFloorFields(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  datas: [],
  details: {},
  total: 0,
  status: '',
  error: null,
};

export const PrebidFloorFieldsSlice = createSlice({
  name: 'prebidFloorFields',
  initialState,
  reducers: {
    updateDetail: (state, action) => {
      state.details[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrebidFloorFields.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPrebidFloorFields.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.datas = action.payload['hydra:member'];
      state.total = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchPrebidFloorFields.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
    builder.addCase(deletePrebidFloorFields.fulfilled, () => {
      snackActions.success('Le field a été supprimé.');
    });
  },
});

export const { updateDetail } = PrebidFloorFieldsSlice.actions;

export const selectPrebidFloorFields = (state) => state.prebidFloorFields.datas;
export const selectPrebidFloorFieldsDetails = (state) => state.prebidFloorFields.details;

export default PrebidFloorFieldsSlice.reducer;
