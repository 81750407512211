import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { BulletNotification, DateNotification, NotificationWrapper } from './NotificationTemplate.style';
import { patchUserNotification } from '../NotificationSlice';

function NotificationTemplate(props) {
  const { children, date, userNotification } = props;
  const dispatch = useDispatch();

  const handleHover = () => {
    if (userNotification.state === 0) {
      const body = { state: 1 };
      dispatch(patchUserNotification({ id: userNotification.id, body }));
    }
  };

  return (
    <NotificationWrapper onMouseEnter={handleHover}>
      <DateNotification>{date}</DateNotification>
      {children}
      {userNotification.state === 0 && <BulletNotification />}
    </NotificationWrapper>
  );
}

NotificationTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  userNotification: PropTypes.shape({
    '@id': PropTypes.string,
    id: PropTypes.number.isRequired,
    state: PropTypes.number.isRequired,
    user: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotificationTemplate;
