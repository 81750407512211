import { Button, Checkbox, FormControlLabel } from '@mui/material';
import downloadjs from 'downloadjs';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { TbPackageExport } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import GoogleTagStatus from '../../../../../constants/GoogleTagStatus';
import { getLastDraftOfGoogleTag } from '../../../../../helpers/api/googleTagDrafts';
import { generate } from '../../../../../helpers/api/googleTagVersion';
import { fetchGoogleTagVersion, selectGoogleTagVersion } from '../../../../../store/GoogleTagVersionSlice';
import PopoverTemplate from '../../../../GoogleTag/CodeGeneration/Popover/PopoverTemplate';
import IconButtonTooltip from '../../../Buttons/IconButtonTooltip';
import AlertDialog from '../../../Dialog/Dialog';
import NameVersionModal from '../../../NameVersionModal/NameVersionModal';
import { IconWrapper, UpdateButton } from './HeaderActionButtons.style';

function ExportGoogleTag() {
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchor, setAnchor] = useState(null);

  const [loading, setLoading] = useState(false);

  const [prod, setProd] = useState(false);
  const [qual, setQual] = useState(false);
  const [download, setDownload] = useState(false);
  const [minify, setMinify] = useState(false);
  const [downloadProd, setDownloadProd] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRollback, setOpenRollback] = useState(false);
  const [open, setOpen] = useState(false);
  const [nameVersion, setNameVersion] = useState('');

  useEffect(() => {
    if (open) {
      getLastDraftOfGoogleTag(googleTagVersion.id).then((res) => {
        setNameVersion(res);
      });
    }
  }, [googleTagVersion.id, open]);

  const isOnQual = useMemo(
    () =>
      googleTagVersion.status === GoogleTagStatus.QUAL ||
      googleTagVersion.status === GoogleTagStatus.APPROVED_MODIFICATIONS,
    [googleTagVersion.status]
  );
  const isOnProd = useMemo(() => googleTagVersion.status === GoogleTagStatus.PROD, [googleTagVersion.status]);
  const isJadisOnProd = useMemo(() => googleTagVersion.status === GoogleTagStatus.JADIS, [googleTagVersion.status]);

  const handleOpenPopover = (event) => {
    setAnchor(event.currentTarget);
  };

  const exportGoogleTag = useCallback(async () => {
    const body = { prod, download, minify, qual, downloadProd };

    body.label = null;
    if (qual) {
      body.label = nameVersion;
    }

    setOpenDialog(false);
    setOpenRollback(false);
    setLoading(true);
    setAnchor(null);

    const response = await generate(googleTagVersion.id, body)
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });

    if (!response) return;

    if (download || minify || downloadProd) {
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = null;

      if (contentDisposition) {
        [filename] = contentDisposition.split('filename=')[1].split(';');
      }

      const blob = await response.blob();
      downloadjs(blob, filename);
    } else {
      const action = prod ? 'PROD' : 'QUAL';
      enqueueSnackbar(`Le Google Tag a bien été mis en ${action}`, { variant: 'success' });
      dispatch(fetchGoogleTagVersion(googleTagVersion.id));
    }

    setQual(false);
    setProd(false);
  }, [dispatch, download, downloadProd, enqueueSnackbar, googleTagVersion.id, minify, prod, qual, nameVersion]);

  const onSubmitPopup = () => {
    if (isJadisOnProd) {
      return setOpenRollback(true);
    }

    setOpen(false);

    if (prod) {
      // PAS DE MISE EN PROD LE VENDREDI

      const currentDate = new Date().getDay();

      if (currentDate === 5) {
        return setOpenDialog(true);
      }
    }

    return exportGoogleTag();
  };

  const onChangeDownload = () => {
    setDownload(!download);
    if (minify) {
      setMinify(false);
    }
  };

  const handleChangeVersionName = (event) => {
    setNameVersion(event.target.value);
  };

  const onExportGoogleTag = () => {
    if (qual) {
      setOpen(true);
    } else {
      onSubmitPopup();
    }
  };

  const handleCloseQualModal = () => {
    if (open) {
      setNameVersion('');
    }

    setOpen(!open);
  };

  return (
    <>
      <UpdateButton type="button" title="Exporter le GoogleTag" onClick={handleOpenPopover}>
        <IconWrapper>
          <TbPackageExport />
        </IconWrapper>
      </UpdateButton>

      <AlertDialog
        open={openDialog}
        title=""
        content="Mise en prod le vendredi, hummmmmm mauvaise idée, êtes vous bien sur de ce que vous faites ?"
        onCancel={() => setOpenDialog(false)}
        onValidate={exportGoogleTag}
      />

      <AlertDialog
        open={openRollback}
        title=""
        content="Etes-vous sur de faire un rollback sur cette version de GoogleTag ?"
        onCancel={() => setOpenRollback(false)}
        onValidate={exportGoogleTag}
      />

      <NameVersionModal
        open={open}
        handleClose={handleCloseQualModal}
        nameVersion={nameVersion}
        onChangeNameVersion={handleChangeVersionName}
        onValidate={onSubmitPopup}
        googleTagId={googleTagVersion.id}
      />

      <PopoverTemplate
        anchorEl={anchor}
        setAnchorEl={setAnchor}
        title="Export du GoogleTag"
        horizontal="center"
        vertical="bottom"
        transformHorizontal="center"
      >
        <div>
          <FormControlLabel
            control={<Checkbox checked={download} onChange={onChangeDownload} />}
            label="Télécharger le fichier"
          />
        </div>
        {download && (
          <div>
            <FormControlLabel
              control={<Checkbox checked={minify} onChange={() => setMinify(!minify)} />}
              label="Version minifiée"
            />
            <IconButtonTooltip
              Icon={FaInfoCircle}
              title="Si vous voulez tester la minification du fichier, et voir ce que ça donne"
            />
          </div>
        )}
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={qual}
                onChange={() => setQual(!qual)}
                disabled={isOnQual || isOnProd || isJadisOnProd}
              />
            }
            label="Mise en QUAL"
          />
          <IconButtonTooltip
            Icon={FaInfoCircle}
            title="Vous pouvez mettre en qual seulement si le GoogleTag n'est actuellement pas en qual"
          />
        </div>
        <div>
          <FormControlLabel
            control={<Checkbox checked={prod} onChange={() => setProd(!prod)} disabled={!isOnQual && !isJadisOnProd} />}
            label={`Mise en PROD ${isJadisOnProd ? '(Rollback)' : ''}`}
          />
          <IconButtonTooltip
            Icon={FaInfoCircle}
            title="Vous pouvez mettre en prod seulement si le GoogleTag est actuellement en qual ou pour faire un rollback"
          />
        </div>
        {isOnProd && (
          <div>
            <FormControlLabel
              control={<Checkbox checked={downloadProd} onChange={() => setDownloadProd(!downloadProd)} />}
              label="Télécharger le fichier de PROD"
            />
            <IconButtonTooltip
              Icon={FaInfoCircle}
              title="Télécharger la version de ce GoogleTag en prod actuellement"
            />
          </div>
        )}
        <Button onClick={onExportGoogleTag} disabled={loading}>
          Exporter le GoogleTag
        </Button>
      </PopoverTemplate>
    </>
  );
}

export default ExportGoogleTag;
