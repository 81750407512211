import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllLocalVariablesByBlock(blockName, googleTagId) {
  const url = `${API_BASE_URL}/variable_locals?pagination=false&gtagVersionBlock.versionBlock.block.name=${blockName}&gtagVersionBlock.googleTagVersion=${googleTagId}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postLocalVariable(body) {
  const url = `${API_BASE_URL}/variable_locals`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function patchLocalVariable(variableId, body) {
  const url = `${API_BASE_URL}/variable_locals/${variableId}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}
