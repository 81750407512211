import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import { selectNotification } from '../../NotificationSlice';
import NotificationTemplate from '../../NotificationTemplate/NotificationTemplate';
import ResumeModal from './ResumeModal';

function GoogleTagImportNotification(props) {
  const { notifId } = props;

  const [open, setOpen] = useState(false);

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <ResumeModal handleClose={() => setOpen(false)} open={open} notifId={notifId} />
      <p>
        Un import de <b>{metadata.type}</b> a été fait.
      </p>
      <MassButton type="button" onClick={() => setOpen(true)}>
        Résumé de l&apos;import
      </MassButton>
    </NotificationTemplate>
  );
}

GoogleTagImportNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default GoogleTagImportNotification;
