import { useEffect, useMemo, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../components/UI/Layout/Layout';
import { verifyTokenExist } from '../helpers/storage';
import { Subtitle, TextContent, Title, Window404, Wrapper } from './PageNotFound.style';

function PageNotFound() {
  const image = useMemo(() => {
    const index = Math.floor(Math.random() * 3 + 1);
    return `${process.env.PUBLIC_URL}/assets/NotFound${index}.jpg`;
  }, []);

  const jamesWebbRef1 = useRef();
  const jamesWebbRef2 = useRef();
  const token = verifyTokenExist();

  const handleMouseOver = (event) => {
    const width = window.innerWidth / 2;
    const height = window.innerHeight / 2;
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    const depth1 = `${50 + (mouseX - width) * 0.01}% ${50 + (mouseY - height) * 0.03}%`;

    jamesWebbRef1.current.style.backgroundPosition = depth1;
    jamesWebbRef2.current.style.backgroundPosition = depth1;
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseOver);

    return () => {
      window.removeEventListener('mousemove', handleMouseOver);
    };
  }, []);

  return token ? (
    <Layout>
      <Wrapper onMouseOver={handleMouseOver}>
        <Window404 ref={jamesWebbRef1} image={image} />
        <div>
          <Title>404</Title>
          <Subtitle>Page not found</Subtitle>
          <TextContent>The page you looking for has been lost in space</TextContent>
        </div>
        <Window404 ref={jamesWebbRef2} image={image} reverse />
      </Wrapper>
    </Layout>
  ) : (
    <Navigate to="/login" replace />
  );
}

export default PageNotFound;
