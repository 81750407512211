/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllMediaTypes, patchMediaType } from '../helpers/api/mediatype';

const domain = 'mediatypes';

// Async actions
export const fetchMediaTypes = createAsyncThunk(`${domain}/fetch`, async (thunkAPI) => {
  try {
    return getAllMediaTypes();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateMediaType = createAsyncThunk(`${domain}/update`, async (data, thunkAPI) => {
  const { id, body } = data;

  try {
    return patchMediaType(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  mediaTypes: [],
  status: 'idle',
  error: null,
};

export const mediatypesSlice = createSlice({
  name: domain,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMediaTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMediaTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mediaTypes = action.payload['hydra:member'];
      })
      .addCase(fetchMediaTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateMediaType.fulfilled, (state, action) => {
        const { payload } = action;

        state.mediaTypes = state.mediaTypes.map((mediatype) => (mediatype.id === payload.id ? payload : mediatype));
      });
  },
});

// Selectors
export const selectAllMediaTypes = (state) => state[domain].mediaTypes;
export const selectAllMediaTypesStatus = (state) => state[domain].status;
export const selectMediatype = () =>
  createSelector(
    (state) => state[domain],
    (_, id) => id,
    (state, id) => state.mediaTypes.find((item) => item.id === id)
  );
export const selectMediatypes = () =>
  createSelector(
    (state) => state[domain],
    (_, ids) => ids,
    (state, ids) => state.mediaTypes.filter((item) => ids.includes(item.id))
  );

export default mediatypesSlice.reducer;
