import {
  Card,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { TbTrash } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from '../UI/Dialog/Dialog';
import AddField from './AddField';
import { deletePrebidFloorFields, fetchPrebidFloorFields, selectPrebidFloorFields } from './PrebidFloorFieldsSlice';

const columns = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'name',
    label: 'Nom',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'function',
    label: 'Fonction',
  },
  {
    id: 'prefix',
    label: 'Prefix',
  },
  {
    id: 'target',
    label: 'Target',
  },
];

function Fields() {
  const datas = useSelector(selectPrebidFloorFields);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleDelete = (id) => {
    setOpen(false);
    dispatch(deletePrebidFloorFields(id))
      .unwrap()
      .then(() => dispatch(fetchPrebidFloorFields()));
  };

  return (
    <Card sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <AddField />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas.map((data) => (
              <TableRow key={data.id}>
                {columns.map((column) => (
                  <TableCell sx={{ borderBottom: 'unset' }} key={column.id}>
                    {column.format && data?.[column.id] ? column.format(data?.[column.id]) : data?.[column.id]}
                  </TableCell>
                ))}
                <TableCell sx={{ borderBottom: 'unset' }}>
                  <IconButton onClick={() => setOpen(true)}>
                    <TbTrash color="red" />
                  </IconButton>
                </TableCell>
                <AlertDialog
                  open={open}
                  content={
                    <span>
                      <DialogContentText>Etes-vous sûr de vouloir supprimer ce field ?</DialogContentText>
                      <DialogContentText>
                        S&apos;il est utilisé dans un floor, il risque de ne plus fonctionner
                      </DialogContentText>
                    </span>
                  }
                  title="Suppression d'un field"
                  onCancel={() => setOpen(false)}
                  onValidate={() => handleDelete(data.id, data.name)}
                  cancelLabel="Annuler"
                  validateLabel="Confirmer la suppression"
                  validateColor="error"
                  validateVariant="contained"
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default Fields;
