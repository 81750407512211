import styled from '@emotion/styled/macro';

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export { CardWrapper, ContentWrapper };
