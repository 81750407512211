import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPen } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { SCOPE } from '../../../../../constants/Suggestions';
import { snackActions } from '../../../../../helpers/SnackBarUtils';
import { patchSuggestion, selectSuggestion } from '../../../../../store/SuggestionSlice';
import FormModal from '../../../../UI/Modal/FormModal';
import MuiModal from '../../../../UI/Modal/MuiModal';
import { SuggestionItemContainer } from '../Suggestion.style';

function SuggestionItem(props) {
  const { suggestionId } = props;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const suggestionSelector = useMemo(selectSuggestion, []);
  const suggestion = useSelector((state) => suggestionSelector(state, suggestionId));

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      defaultValue: suggestion.defaultValue,
      scope: suggestion.scope ?? [],
    },
  });

  const modifySuggestion = (body) => {
    const data = { id: suggestionId, body };
    setLoading(true);
    dispatch(patchSuggestion(data))
      .unwrap()
      .then(() => {
        snackActions.success(`La suggestion '${suggestion.label}' a été modifiée`);
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  const helperTextLabel = useMemo(() => {
    if (!suggestion.updatable) {
      return "La valeur de cette suggestion n'est pas modifiable";
    }

    if (errors.defaultValue) {
      return 'Une defaultValue est requise';
    }

    return null;
  }, [suggestion.updatable, errors.defaultValue]);

  return (
    <>
      <SuggestionItemContainer>
        <Tooltip title={`valeur par défaut : ${suggestion.defaultValue ?? 'null'}`}>
          <p>{suggestion.label}</p>
        </Tooltip>
        <Tooltip title={`Modifier la valeur par défaut de : ${suggestion.label}`}>
          <IconButton onClick={() => setOpen(true)}>
            <FaPen />
          </IconButton>
        </Tooltip>
      </SuggestionItemContainer>
      <MuiModal open={open} handleClose={() => setOpen(false)} title={`Modification de: ${suggestion.label}`}>
        <FormModal onSubmit={handleSubmit(modifySuggestion)} loading={loading}>
          <TextField
            disabled={!suggestion.updatable}
            {...register('defaultValue')}
            multiline
            fullWidth
            label="Valeur par défaut"
            placeholder={`Valeur par défaut qui sera assigné à ${suggestion.label} sur tous ses scopes`}
            error={errors.defaultValue}
            helperText={helperTextLabel}
            minRows={2}
            maxRows={4}
            variant="filled"
          />
          <Controller
            render={({ field }) => (
              <FormControl fullWidth sx={{ margin: '10px 0' }}>
                <InputLabel id=".label">Scope</InputLabel>
                <Select {...field} label="Scope" labelId="type" multiple>
                  {SCOPE.map((s) => (
                    <MenuItem key={s.value} value={s.value}>
                      {s.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
            name="scope"
          />
        </FormModal>
      </MuiModal>
    </>
  );
}

SuggestionItem.propTypes = {
  suggestionId: PropTypes.number.isRequired,
};

export default SuggestionItem;
