import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTabBidders, selectTabBidders } from '../../../../store/TabBiddersSlice';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import { importCSV } from '../../../../helpers/api/bidders';
import Heading from '../../../UI/Title/Heading';
import classes from './SelectBidder.module.css';

function SelectBidder(props) {
  const { selectedBidderId, onSelected } = props;
  const dispatch = useDispatch();
  const bidders = useSelector(selectTabBidders);

  useEffect(() => {
    dispatch(fetchTabBidders({ pagination: false, isActive: true }));
  }, [dispatch]);

  const onSelect = (event) => {
    onSelected(event.target.value);
  };

  const downloadTemplate = () => {
    const formData = new FormData();
    formData.append('action', 'template');

    importCSV(selectedBidderId, formData).then((response) => {
      response.blob().then((blob) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition) {
          [filename] = contentDisposition.split('filename=')[1].split(';');
        } else {
          filename = 'bidder.csv';
        }

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    });
  };

  return (
    <div className={classes.selectBidder}>
      <Heading variant="h2">Choisissez votre Bidder</Heading>
      <FormControl fullWidth margin="normal">
        <InputLabel id="select-bidder-label">Choisissez un SSP</InputLabel>
        <Select
          labelId="select-bidder-label"
          id="select-bidder"
          value={selectedBidderId}
          label="Choisissez un SSP"
          onChange={(event) => onSelect(event)}
        >
          {bidders.map((bidder) => (
            <MenuItem key={bidder.id} value={bidder.id}>
              {bidder.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedBidderId && <MassButton onClick={downloadTemplate}>Télécharger le template du bidder</MassButton>}
    </div>
  );
}

SelectBidder.propTypes = {
  selectedBidderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default SelectBidder;
