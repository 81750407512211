import { Checkbox, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { reorder } from '../../../helpers/arrayHelper';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledFormControl } from '../Floor.style';
import { selectPrebidFloorFields } from '../PrebidFloorFieldsSlice';
import { DraggableField, DraggableFieldContainer } from './FloorComponents.style';

function SchemaFields(props) {
  const { control, hidden, registerTarget, errors, setValue } = props;

  const selected = useWatch({ control, name: registerTarget });
  const prebidFloorFields = useSelector(selectPrebidFloorFields);
  const error = useReactHookFormError(registerTarget, errors);

  const handleDragEnd = (result) => {
    const items = reorder(selected, result.source.index, result.destination.index);

    setValue(registerTarget, items);
  };

  return (
    <>
      <Controller
        render={({ field }) => (
          <StyledFormControl sx={{ width: '150px' }}>
            <InputLabel id={`${registerTarget}-label`}>Fields</InputLabel>
            <Select
              id={registerTarget}
              labelId={`${registerTarget}-label`}
              label="Fields"
              multiple
              renderValue={(select) => select.join(', ')}
              {...field}
              value={field.value ?? []}
            >
              {prebidFloorFields.map((prebidFloorField) => (
                <MenuItem key={prebidFloorField.id} value={prebidFloorField.name}>
                  <Checkbox checked={selected?.indexOf(prebidFloorField.name) > -1} />
                  <ListItemText primary={prebidFloorField.name} />
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </StyledFormControl>
        )}
        control={control}
        name={registerTarget}
        rules={{
          validate: {
            required: (value) => {
              if (!hidden && !value.length) {
                return 'Le champ est requis.';
              }

              return true;
            },
          },
        }}
      />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <DraggableFieldContainer
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
              {...provided.droppableProps}
            >
              {selected &&
                selected.map((option, index) => (
                  <Draggable key={option} draggableId={option} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <DraggableField
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        isDragging={draggableSnapshot.isDragging}
                      >
                        {option}
                      </DraggableField>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </DraggableFieldContainer>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

SchemaFields.propTypes = {
  setValue: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  registerTarget: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default SchemaFields;
