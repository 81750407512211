import PropTypes from 'prop-types';
import BlockDetail from './Details/BlockDetail';
import ChangePositionBlockDetail from './Details/ChangePositionBlockDetail';
import PositionDetail from './Details/PositionDetail';
import PrebidVersionDetail from './Details/PrebidVersionDetail';
import RtbDetail from './Details/RtbDetail';
import SettingDetail from './Details/SettingDetail';
import SizeDetail from './Details/SizeDetail';
import TabBidderDetail from './Details/TabBidderDetail';
import VariableDetail from './Details/VariableDetail';
import VersionBlockDetail from './Details/VersionBlockDetail';
import CsvImport from './Details/CsvImport';

function SwitchModification(props) {
  const { modification } = props;

  switch (modification.operation) {
    case 'removeSize':
    case 'addSize':
      return <SizeDetail modification={modification} />;
    case 'updateTabBidders':
    case 'addTabBidders':
    case 'disableTabBidders':
    case 'enableTabBidders':
      return <TabBidderDetail modification={modification} />;
    case 'modifyPosition':
    case 'removePosition':
    case 'addPosition':
      return <PositionDetail modification={modification} />;
    case 'add adPosition':
    case 'delete adPosition':
    case 'modify adPosition':
      return <RtbDetail modification={modification} />;
    case 'updatePrebidVersion':
      return <PrebidVersionDetail modification={modification} />;
    case 'addBlock':
    case 'removeBlock':
      return <BlockDetail modification={modification} />;
    case 'changeGlobalVariable':
    case 'changeLocalVariable':
      return <VariableDetail modification={modification} />;
    case 'changeVersion':
      return <VersionBlockDetail modification={modification} />;
    case 'changeBlockPosition':
      return <ChangePositionBlockDetail modification={modification} />;
    case 'updateVariable':
      return <SettingDetail modification={modification} />;
    case 'csvImport':
      return <CsvImport modification={modification} />;
    default:
      return null;
  }
}

SwitchModification.propTypes = {
  modification: PropTypes.shape({
    id: PropTypes.number,
    operation: PropTypes.string,
    metadata: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.shape({}),
      ])
    ),
    createdAt: PropTypes.string,
    googleTagDraft: PropTypes.string,
    googleTagVersion: PropTypes.arrayOf(PropTypes.string),
    targetGoogleTagVersion: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default SwitchModification;
