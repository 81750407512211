import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';

const HeaderPartner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FilterPartner = styled(TextField)`
  width: 250px;
`;

export { HeaderPartner, FilterPartner };
