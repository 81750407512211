import styled from '@emotion/styled/macro';
import { css } from '@emotion/react/macro';
import { ListItemButton } from '@mui/material';

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => !['backgroundColor', 'selected'].includes(prop),
})(
  ({ backgroundColor, selected }) => css`
    background-color: ${backgroundColor ?? (selected && '#00468b22')};
    border-left: 3px solid ${selected ? '#00468b' : 'transparent'};
  `
);

export { StyledListItemButton };
