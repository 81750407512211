import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getNotificationsByUser(params) {
  const url = new URL(`${API_BASE_URL}/user_notifications`);
  if (params) {
    url.search = param(params);
  }

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function patchUserNotif(id, body) {
  const url = `${API_BASE_URL}/user_notifications/${id}`;
  const response = await defaultFetch(url.toString(), 'PATCH', body);

  return response.json();
}

export async function readAllNotifications() {
  const url = `${API_BASE_URL}/user_notifications/read_all`;
  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function getAllNotificationTypes() {
  const url = `${API_BASE_URL}/notification_types`;
  const response = await defaultFetch(url.toString());

  return response.json();
}
