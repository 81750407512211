import styled from '@emotion/styled/macro';
import { Input } from '@mui/material';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 30px;
`;

const InputPartner = styled(Input)`
  width: 80%;
`;

export { FormWrapper, FieldWrapper, InputPartner };
