/* eslint-disable */
import { React } from 'react';
import { Link } from 'react-router-dom';
import classes from './TextWithLink.module.css';

function TextWithLink(props) {
  return (
    <div className={classes.block}>
      <Link to={props.url}>
        <h2>{props.title}</h2>
      </Link>
      <p>{props.description}</p>
    </div>
  );
}

export default TextWithLink;
