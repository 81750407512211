import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledFormControl, StyledTextField } from '../Floor.style';
import { selectPrebidFloorFieldsDetails } from '../PrebidFloorFieldsSlice';

function RuleItemField(props) {
  const { registerTarget, control, fieldTarget, index, hidden, errors } = props;

  const labelId = useMemo(() => `values.${index}.${fieldTarget}-label`, [index, fieldTarget]);
  const registerTargetField = useMemo(() => `${registerTarget}.${fieldTarget}`, [registerTarget, fieldTarget]);

  const prebidFloorFieldsDetails = useSelector(selectPrebidFloorFieldsDetails);
  const error = useReactHookFormError(registerTargetField, errors);

  if (!(fieldTarget in prebidFloorFieldsDetails) || prebidFloorFieldsDetails[fieldTarget] === null) {
    return (
      <Controller
        render={({ field }) => (
          <StyledTextField
            key={fieldTarget}
            label={fieldTarget}
            error={!!error}
            helperText={error?.message}
            placeholder="*"
            {...field}
            value={field.value ?? ''}
          />
        )}
        control={control}
        name={registerTargetField}
        rules={{
          validate: {
            required: (value) => {
              if (!hidden && !value) {
                return 'Le champ est requis.';
              }

              return true;
            },
          },
        }}
      />
    );
  }

  return (
    <Controller
      render={({ field }) => (
        <StyledFormControl key={fieldTarget} sx={{ width: '150px' }}>
          <InputLabel id={labelId}>{fieldTarget}</InputLabel>
          <Select
            id={registerTargetField}
            labelId={labelId}
            label={fieldTarget}
            defaultValue=""
            error={!!error}
            {...field}
            value={field.value ?? ''}
          >
            <MenuItem value="*">*</MenuItem>
            {prebidFloorFieldsDetails[fieldTarget].map((data) => (
              <MenuItem key={data.value} value={data.value}>
                {data.value}
              </MenuItem>
            ))}
          </Select>
          {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
        </StyledFormControl>
      )}
      control={control}
      name={registerTargetField}
      rules={{
        validate: {
          required: (value) => {
            if (!hidden && !value) {
              return 'Le champ est requis.';
            }

            return true;
          },
        },
      }}
    />
  );
}

RuleItemField.propTypes = {
  registerTarget: PropTypes.string.isRequired,
  fieldTarget: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  hidden: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default RuleItemField;
