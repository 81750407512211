import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getVariableGlobals(params) {
  const url = new URL(`${API_BASE_URL}/variable_globals`);
  url.search = param(params);

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function getAllVariablesByBlock(blockName) {
  const url = `${API_BASE_URL}/variable_globals?pagination=false&versionBlock.block.name=${blockName}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postGlobalVariable(body) {
  const url = `${API_BASE_URL}/variable_globals`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function patchGlobalVariable(id, body) {
  const url = `${API_BASE_URL}/variable_globals/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}
