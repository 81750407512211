import { Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Label from '../../UI/Basic/Labels/Label';
import DoughnutChart from '../../UI/Chart/DoughnutChart';
import Heading from '../../UI/Title/Heading';
import { selectPartnerInfos } from '../PartnerPageSlice';
import { BidderNameContent, BidderNameWrapper, BidderWrapper } from './BidderGraph.style';

function BidderGraph() {
  const partnerInfos = useSelector(selectPartnerInfos);
  const [tab, setTab] = useState(1);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const bidders = useMemo(() => {
    if (partnerInfos.gtagData) {
      return partnerInfos.gtagData.bidders;
    }
    return null;
  }, [partnerInfos]);

  const activeIndex = useMemo(() => {
    if (bidders) {
      return bidders?.activeBidders?.list.length;
    }

    return 0;
  }, [bidders]);

  const inactiveIndex = useMemo(() => {
    if (bidders) {
      return bidders?.inactiveBidders?.list.length;
    }

    return 0;
  }, [bidders]);

  const inactiveBiddersContent = useMemo(() => {
    if (bidders?.inactiveBidders) {
      return bidders.inactiveBidders.list.map((bidder) => (
        <Label key={bidder.id} color="#264653">
          <p>{bidder.displayName}</p>
        </Label>
      ));
    }
    return <p>Pas de données SSP actif</p>;
  }, [bidders]);

  const activeBiddersContent = useMemo(() => {
    if (bidders) {
      return bidders.activeBidders?.list.map((bidder) => (
        <Label key={bidder.id} color="#264653">
          <p>{bidder.displayName}</p>
        </Label>
      ));
    }
    return <p>Pas de données SSP actif</p>;
  }, [bidders]);

  const percentActive = useMemo(() => {
    if (bidders?.activeBidders) {
      return Math.round((100 * bidders.activeBidders.quantity) / (activeIndex + inactiveIndex), 10);
    }
    return null;
  }, [activeIndex, bidders, inactiveIndex]);

  const percentInactive = useMemo(() => {
    if (bidders?.inactiveBidders) {
      return Math.round((100 * bidders.inactiveBidders.quantity) / (activeIndex + inactiveIndex), 10);
    }
    return null;
  }, [activeIndex, bidders, inactiveIndex]);

  return (
    <BidderWrapper>
      {!percentActive && !percentInactive ? (
        <Heading variant="h3">Aucune données sur les bidders disponibles</Heading>
      ) : (
        <>
          <DoughnutChart
            title="Taux de participation des bidders"
            width="500px"
            labels={['Actifs', 'Inactif']}
            data={[percentActive, percentInactive]}
            backgrounds={['Green', 'red']}
          />
          <div style={{ width: '100%' }}>
            <Tabs value={tab} onChange={handleChange}>
              <Tab value={1} label={`Bidders actifs : (${percentActive}%)`} />
              <Tab value={2} label={`Bidders inactifs : (${percentInactive}%)`} />
            </Tabs>
            <BidderNameContent>
              {tab === 1 && <BidderNameWrapper>{activeBiddersContent}</BidderNameWrapper>}
              {tab === 2 && <BidderNameWrapper>{inactiveBiddersContent}</BidderNameWrapper>}
            </BidderNameContent>
          </div>
        </>
      )}
    </BidderWrapper>
  );
}

export default BidderGraph;
