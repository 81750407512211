import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectGoogleTag } from '../../../../store/GoogleTagVersionSlice';
import {
  GoogleTagItemContainer,
  GoogleTagReleaseId,
  GoogleTagDescription,
  InformationsContainer,
} from './GoogleTagItem.style';
import StatusLabel from '../../../Reusable/Status/StatusLabel';

function GoogleTagItem(props) {
  const { googleTagVersionId } = props;

  const googleTagVersionSelector = useMemo(selectGoogleTag, []);
  const googleTagVersion = useSelector((state) => googleTagVersionSelector(state, googleTagVersionId));

  const dateLastUpdate = useMemo(() => {
    const date = googleTagVersion?.inProdAt ?? googleTagVersion?.inQualAt ?? '';
    const prodPrefix = googleTagVersion?.inProdAt && 'Mis en PROD le ';
    const qualprefix = googleTagVersion?.inQualAt && 'Mis en QUAL le ';

    if (date) {
      return (prodPrefix ?? qualprefix) + new Date(date).toLocaleString();
    }

    return '';
  }, [googleTagVersion]);

  return (
    googleTagVersion && (
      <GoogleTagItemContainer to={`/edit-google-tag/${googleTagVersion.id}`}>
        <InformationsContainer>
          <GoogleTagReleaseId>{googleTagVersion.releaseId}</GoogleTagReleaseId>
          <StatusLabel status={googleTagVersion.status} />
          <GoogleTagDescription>{googleTagVersion.description}</GoogleTagDescription>
        </InformationsContainer>
        <p>{dateLastUpdate}</p>
      </GoogleTagItemContainer>
    )
  );
}

GoogleTagItem.propTypes = {
  googleTagVersionId: PropTypes.number.isRequired,
};

export default GoogleTagItem;
