/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllBidders, getCurrentBidders } from '../../../../../../helpers/api/bidders';
import { getTabBiddersNodesByGtag } from '../../../../../../helpers/api/nodeLibraries';
import { fetchGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';

const domain = 'tabBiddersModifications';

// Async actions
export const fetchTabBiddersNodes = createAsyncThunk(`${domain}/fetchTabBiddersNodes`, async (data, thunkAPI) => {
  const { id, selectedPartner } = data;

  try {
    return getTabBiddersNodesByGtag(id, selectedPartner);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchCurrentBidders = createAsyncThunk(`${domain}/fetchCurrentBidders`, async (data, thunkAPI) => {
  try {
    const { id } = data;

    return getCurrentBidders(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchTabBidders = createAsyncThunk(`${domain}/fetchTabBidders`, async (data, thunkAPI) => {
  try {
    return getAllBidders(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  selectedPartner: '',
  selectedTabBidders: [],
  selectedTabBiddersStatus: 'idle',
  selectedTabBiddersNode: { id: null, metadata: null },
  tabBiddersNodes: [],
  tabBiddersNodesStatus: 'idle',
  tabBidders: [],
  tabBiddersStatus: 'idle',
  error: null,
};

export const tabBiddersModificationsSlice = createSlice({
  name: 'tabBiddersModifications',
  initialState,
  reducers: {
    reset: () => initialState,
    partialReset: (state) => {
      state.selectedTabBidders = initialState.selectedTabBidders;
      state.selectedTabBiddersStatus = initialState.selectedTabBiddersStatus;
      state.selectedTabBiddersNode = initialState.selectedTabBiddersNode;
      state.error = initialState.error;
    },
    setSelectedTabBidders: (state) => {
      state.selectedTabBidders = [];
    },
    changePartnerWebsite: (state, action) => {
      const newState = { ...initialState };
      newState.selectedPartner = action.payload;

      return newState;
    },
    changeSelectedTabBiddersNode: (state, action) => {
      state.selectedTabBiddersNode = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTabBiddersNodes.pending, (state) => {
        state.tabBiddersNodesStatus = 'loading';
      })
      .addCase(fetchTabBiddersNodes.fulfilled, (state, action) => {
        state.tabBiddersNodesStatus = 'succeeded';
        state.tabBiddersNodes = action.payload['hydra:member'];
      })
      .addCase(fetchTabBiddersNodes.rejected, (state, action) => {
        state.tabBiddersNodesStatus = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchCurrentBidders.pending, (state) => {
        state.selectedTabBiddersStatus = 'loading';
      })
      .addCase(fetchCurrentBidders.fulfilled, (state, action) => {
        state.selectedTabBiddersStatus = 'succeeded';
        state.selectedTabBidders = action.payload['hydra:member'];
      })
      .addCase(fetchCurrentBidders.rejected, (state, action) => {
        state.selectedTabBiddersStatus = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchTabBidders.pending, (state) => {
        state.tabBiddersStatus = 'loading';
      })
      .addCase(fetchTabBidders.fulfilled, (state, action) => {
        state.tabBiddersStatus = 'succeeded';
        state.tabBidders = action.payload['hydra:member'];
      })
      .addCase(fetchTabBidders.rejected, (state, action) => {
        state.tabBiddersStatus = 'failed';
        state.error = action.error.message;
      })

      .addCase(fetchGoogleTagVersion.fulfilled, (state, action) => {
        state.selectedPartner = action.payload?.partnerGroup?.partnerWebsites?.[0]?.id ?? '';
      });
  },
});

// Actions
export const { reset, setSelectedTabBidders, partialReset, changePartnerWebsite, changeSelectedTabBiddersNode } =
  tabBiddersModificationsSlice.actions;

// Selectors
export const selectTabBiddersNodes = (state) => state[domain].tabBiddersNodes;
export const selectTabBiddersNodesStatus = (state) => state[domain].tabBiddersNodesStatus;
export const selectSelectedPartner = (state) => state[domain].selectedPartner;
export const selectSelectedTabBidders = (state) => state[domain].selectedTabBidders;
export const selectSelectedTabBiddersStatus = (state) => state[domain].selectedTabBiddersStatus;
export const selectedTabBiddersByIdSelector = () =>
  createSelector([selectSelectedTabBidders, (state, id) => id], (selectedTabBidders, id) => {
    return selectedTabBidders.find((node) => node.id === id);
  });
export const selectSelectedTabBiddersNode = (state) => state[domain].selectedTabBiddersNode;
export const selectTabBidders = (state) => state[domain].tabBidders;
export const selectTabBiddersStatus = (state) => state[domain].tabBiddersStatus;
export const selectTabBidderById = () =>
  createSelector([selectTabBidders, (_, id) => id], (tabBidders, id) =>
    tabBidders.find((tabBidder) => tabBidder.id === id || tabBidder['@id'] === id)
  );

export default tabBiddersModificationsSlice.reducer;
