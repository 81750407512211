import { Icon, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { draftApplied } from '../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import { resetModifications } from '../../../store/ModificationsSlice';
import { HistoryExpandIcon } from '../../GoogleTag/Edit/MultiUnitModification/Sidebar/History/History.style';
import ModalDetail from '../../Modifications/ModalDetail';
import LinearProgressWithLabel from '../../UI/Animation/LinearProgressWithLabel';
import { DraftErrorContainer, DraftEventContainer } from './DraftEvent.style';

function DraftEvent(props) {
  const { content, progress, handleEventRemove, id, error } = props;
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const googleTagVersion = useSelector(selectGoogleTagVersion);

  useEffect(() => {
    if (!googleTagVersion || progress !== 100) return;

    if (content?.type === 0) {
      // C'est un draft unitaire
      const [modification] = content.draftModifications;

      if (
        modification?.targetGoogleTagVersions?.length === 1 &&
        googleTagVersion.id === modification.targetGoogleTagVersions[0].id
      ) {
        dispatch(resetModifications());
      }
    }

    dispatch(draftApplied(content));
  }, [content, dispatch, googleTagVersion, progress]);

  const modifications = useMemo(() => content.draftModifications, [content]);

  const handleModal = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return (
    <>
      <DraftEventContainer>
        {error ? (
          <DraftErrorContainer>
            <Icon>
              <AiFillWarning color="red" />
            </Icon>
            &nbsp; Une erreur est survenue
          </DraftErrorContainer>
        ) : (
          <LinearProgressWithLabel value={progress} size="80%" />
        )}
        <IconButton onClick={handleModal}>
          <HistoryExpandIcon />
        </IconButton>
        <IconButton onClick={() => handleEventRemove(id)}>
          <FaTimes />
        </IconButton>
      </DraftEventContainer>
      <ModalDetail open={open} handleClose={handleModal} modifications={modifications} />
    </>
  );
}

DraftEvent.propTypes = {
  content: PropTypes.shape({
    draftModifications: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.number,
  }),
  progress: PropTypes.number.isRequired,
  handleEventRemove: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  error: PropTypes.bool,
};

DraftEvent.defaultProps = {
  content: {},
  error: false,
};

export default DraftEvent;
