import { Checkbox, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Controller, useForm } from 'react-hook-form';
import {
  fetchPrebidVersions,
  selectPrebidVersionsSelected,
  selectPrebidVersionsStatus,
} from '../../../../../store/PrebidVersionSlice';
import MassButton from '../../../../UI/Basic/Buttons/MassButton/MassButton';
import { selectSelectedGoogleTags, updatePrebidVersion } from '../../../../../store/ModificationsSlice';
import { fetchMandatoryModules, selectMandatoryModules } from '../../../../../store/MandatoryModulesSlice';

function PrebidVersionSidebar() {
  const dispatch = useDispatch();

  const prebidVersions = useSelector(selectPrebidVersionsSelected);
  const mandatoryModules = useSelector(selectMandatoryModules);
  const status = useSelector(selectPrebidVersionsStatus);
  const selectedGoogleTags = useSelector(selectSelectedGoogleTags);

  const { control, handleSubmit, watch, resetField } = useForm({
    defaultValues: {
      modules: [],
      prebidVersion: '',
    },
  });

  const prebidVersionWatched = watch('prebidVersion');

  const prebidVersionModule = useMemo(() => {
    const foundPrebid = prebidVersions.find((prebid) => prebid.id === parseInt(prebidVersionWatched, 10));

    return foundPrebid?.prebidVersionMandatoryModules ?? [];
  }, [prebidVersions, prebidVersionWatched]);

  const defaultMandatories = useMemo(() => {
    const mandatoriesModules = [];

    if (prebidVersionModule) {
      prebidVersionModule.forEach((module) => {
        if (module.required) {
          mandatoriesModules.push(module.mandatoryModule.name);
        }
      });
    }

    return [...new Set(mandatoriesModules)];
  }, [prebidVersionModule]);

  useEffect(() => {
    if (status !== 'resolved' && status !== 'pending') {
      dispatch(fetchPrebidVersions());
    }
    dispatch(fetchMandatoryModules());
  }, [dispatch, status]);

  const submitPrebidVersion = (data) => {
    const { prebidVersion, modules } = data;

    const arrayModules = [];
    modules.forEach((module) => {
      const findModule = mandatoryModules.find((mod) => mod.name === module);
      if (findModule) {
        arrayModules.push(findModule.id);
      }
    });

    const prebidModification = {
      operation: 'updatePrebidVersion',
      metadata: {
        prebidVersion,
        modules: arrayModules,
      },
      googleTagVersions: selectedGoogleTags.map((googleTag) => `api/google_tag_versions/${googleTag}`),
    };

    dispatch(updatePrebidVersion(prebidModification));
  };

  useEffect(() => {
    resetField('modules', { defaultValue: defaultMandatories });
  }, [defaultMandatories, resetField]);

  return (
    <form onSubmit={handleSubmit(submitPrebidVersion)}>
      <p>Sélection de la version de prebid</p>
      <Controller
        name="prebidVersion"
        control={control}
        render={({ field }) => {
          return (
            <FormControl fullWidth>
              <InputLabel id="select-prebid-label">Version</InputLabel>
              <Select {...field} labelId="select-prebid-label" id="select-prebid" label="Version">
                {prebidVersions.length ? (
                  prebidVersions.map((prebidVersion) => (
                    <MenuItem key={prebidVersion.id} value={prebidVersion.id}>
                      {prebidVersion.version}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    Aucune version disponible
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          );
        }}
      />
      <Controller
        name="modules"
        control={control}
        defaultValue={[]}
        render={({ field }) => {
          return (
            <FormControl sx={{ marginTop: '10px', width: '100%' }}>
              <InputLabel>Modules</InputLabel>
              <Select
                {...field}
                label="Modules"
                multiple
                name="modules"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {prebidVersionModule.map((p) => {
                  let required = false;
                  const idPrebid = parseInt(prebidVersionWatched, 10);
                  if (p?.prebidVersion) {
                    const splitedVersion = p.prebidVersion.split('/');
                    if (parseInt(splitedVersion[splitedVersion.length - 1], 10) === idPrebid && p.required) {
                      required = true;
                    }
                  }

                  return (
                    <MenuItem key={p.mandatoryModule.name} value={p.mandatoryModule.name} disabled={required}>
                      <Checkbox checked={field.value.indexOf(p.mandatoryModule.name) > -1} />
                      <p>{p.mandatoryModule.name}</p>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          );
        }}
      />
      <MassButton type="submit" disabled={selectedGoogleTags.length === 0}>
        Mettre a jour PrebidVersion
      </MassButton>
    </form>
  );
}

export default PrebidVersionSidebar;
