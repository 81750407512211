import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function fetchUsers() {
  const url = `${API_BASE_URL}/users?order%5Bid%5D=desc`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function createUser(user) {
  const url = `${API_BASE_URL}/users`;
  const response = await defaultFetch(url, 'POST', user);

  return response.json();
}

export async function fetchUser(id) {
  const url = `${API_BASE_URL}/users/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function fetchCurrentUser() {
  const url = `${API_BASE_URL}/users/current`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function deleteUser(id) {
  const url = `${API_BASE_URL}/users/${id}`;
  return defaultFetch(url, 'DELETE');
}

export async function modifyUser(id, data) {
  const url = `${API_BASE_URL}/users/${id}`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}
