import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectPartners } from '../../../store/PartnersSlice';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import { selectOneOfModification } from '../../../store/ModificationsSlice';
import GoogleTagLink from '../../UI/Link/GoogleTagLink';
import DraftModificationType from '../../../types/DraftModificationType';

function ListGoogleTagsAffected(props) {
  const { modificationId, modificationProp } = props;
  const partners = useSelector(selectPartners);
  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const modificationSelector = useMemo(selectOneOfModification, []);
  const modificationSlice = useSelector((state) => modificationSelector(state, modificationId));

  const modification = useMemo(() => modificationProp ?? modificationSlice, [modificationProp, modificationSlice]);

  const googleTagsAffected = useMemo(() => {
    let googleTags;
    let id;

    const { googleTagVersions, targetGoogleTagVersions } = modification;
    if (targetGoogleTagVersions && targetGoogleTagVersions.length > 0) {
      googleTags = targetGoogleTagVersions;
    } else {
      googleTags = googleTagVersions;
    }

    // Parti pour identifier le partenaire des googleTags affectés.
    const modifiedGoogleTags = [];
    googleTags.forEach((gtag) => {
      let goodPartner = {};

      // Modification unitaire/masse, popup de détail de modification.
      if (typeof gtag === 'string') {
        const splitGoogleTag = gtag.split('/');
        id = splitGoogleTag[splitGoogleTag.length - 1];
      }

      if (googleTagVersion) {
        // eslint-disable-next-line prefer-destructuring
        goodPartner = googleTagVersion.partnerGroup.partnerWebsites[0];
        id = googleTagVersion.id;
      } else if (partners.length !== 0) {
        goodPartner = partners.find((part) => gtag.partnerGroup === `/api/partner_groups/${part.idGroup}`);
      }

      // je garde le champ ID pour le Link vers le googleTag
      modifiedGoogleTags.push({ partner: goodPartner?.displayName, id: gtag.id ?? id });
    });

    return modifiedGoogleTags;
  }, [googleTagVersion, modification, partners]);

  return (
    <p>
      GoogleTags affectés:&nbsp;
      {googleTagsAffected.map((googleTag, index) => {
        return (
          <Fragment key={googleTag.id}>
            {index ? ', ' : ''}
            <GoogleTagLink key={googleTag.id} id={googleTag.id} label={googleTag.partner} />
          </Fragment>
        );
      })}
    </p>
  );
}

ListGoogleTagsAffected.propTypes = {
  modificationId: PropTypes.number,
  modificationProp: DraftModificationType,
};

ListGoogleTagsAffected.defaultProps = {
  modificationId: null,
  modificationProp: null,
};

export default ListGoogleTagsAffected;
