import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { backgroundColor, logoColor } from '../../helpers/color';

const LoginWrapper = styled.div`
  //background-color: #f4f7fc;
  background-color: ${backgroundColor};
`;

const ForgotPassword = styled(Link)`
  display: flex;
  justify-content: center;
  color: ${logoColor};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`;

export { LoginWrapper, ForgotPassword };
