import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledTextField } from '../Floor.style';
import { selectPrebidFloor } from '../PrebidFloorSlice';

function Version(props) {
  const { control, errors } = props;

  const floorFiles = useSelector(selectPrebidFloor);
  const registerTarget = useMemo(() => 'version', []);
  const error = useReactHookFormError(registerTarget, errors);

  return (
    <div>
      <Controller
        render={({ field }) => (
          <StyledTextField
            id={registerTarget}
            label="Version&nbsp;*"
            type="text"
            variant="filled"
            error={!!error}
            helperText={error?.message}
            {...field}
            value={field.value ?? ''}
          />
        )}
        control={control}
        name={registerTarget}
        rules={{
          required: 'Le champ est requis.',
          validate: {
            isUnique: (value) => {
              const found = floorFiles.find((floor) => floor.version === value);
              if (found) {
                return 'La version doit être unique.';
              }

              return true;
            },
          },
        }}
      />
    </div>
  );
}

Version.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default Version;
