/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteTopic, getTopic, getTopics, postTopic, putTopic } from '../../../helpers/api/topics';
import { getBlocks } from '../../../helpers/api/blocks';
import { getVariableGlobals } from '../../../helpers/api/variableGlobals';

export const domain = 'topicsAdministration';

// Async actions
export const retrieveTopics = createAsyncThunk(`${domain}/retrieveTopics`, async (thunkAPI) => {
  try {
    return getTopics();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const retrieveTopic = createAsyncThunk(`${domain}/retrieveOneTopic`, async (payload, thunkAPI) => {
  try {
    return getTopic(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createTopic = createAsyncThunk(`${domain}/create`, async (data, thunkAPI) => {
  try {
    return postTopic(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateTopic = createAsyncThunk(`${domain}/update`, async (data, thunkAPI) => {
  const { id, ...body } = data;
  try {
    return putTopic(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const removeTopic = createAsyncThunk(`${domain}/remove`, async (id, thunkAPI) => {
  try {
    return deleteTopic(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const retrieveBlocks = createAsyncThunk(`${domain}/fetchBlocks`, async (params, thunkAPI) => {
  try {
    return getBlocks(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const retrieveVariableGlobals = createAsyncThunk(`${domain}/fetchVariableGlobals`, async (params, thunkAPI) => {
  try {
    return getVariableGlobals(params);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  topics: [],
  blocks: [],
  variableGlobals: [],
  selectedTopic: null,
  topicsStatus: 'idle',
  blocksStatus: 'idle',
  variableGlobalsStatus: 'idle',
};

export const topicsSlice = createSlice({
  name: domain,
  initialState,
  reducers: {
    reset: () => initialState,
    changeSelected: (state, action) => {
      state.selectedTopic = state.topics.find((topic) => topic.id === action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(retrieveTopics.pending, (state) => {
        state.topicsStatus = 'loading';
      })
      .addCase(retrieveTopics.fulfilled, (state, action) => {
        state.topicsStatus = 'succeeded';
        state.topics = action.payload['hydra:member'];
      })
      .addCase(retrieveTopics.rejected, (state) => {
        state.topicsStatus = 'failed';
      })

      .addCase(createTopic.pending, (state) => {
        state.topicsStatus = 'loading';
      })
      .addCase(createTopic.fulfilled, (state, action) => {
        state.topicsStatus = 'succeeded';
        state.topics.push(action.payload);
        state.selectedTopic = action.payload;
      })
      .addCase(createTopic.rejected, (state) => {
        state.topicsStatus = 'failed';
      })

      .addCase(updateTopic.pending, (state) => {
        state.topicsStatus = 'loading';
      })
      .addCase(updateTopic.fulfilled, (state, action) => {
        state.topicsStatus = 'succeeded';
        state.topics = state.topics.map((topic) => (action.payload.id === topic.id ? action.payload : topic));
      })
      .addCase(updateTopic.rejected, (state) => {
        state.topicsStatus = 'failed';
      })

      .addCase(removeTopic.pending, (state) => {
        state.topicsStatus = 'loading';
      })
      .addCase(removeTopic.fulfilled, (state, action) => {
        state.topicsStatus = 'succeeded';
        state.topics = state.topics.filter((topic) => action.meta.arg !== topic.id);
        if (state.selectedTopic?.id === action.meta.arg) {
          state.selectedTopic = null;
        }
      })
      .addCase(removeTopic.rejected, (state) => {
        state.topicsStatus = 'failed';
      })

      .addCase(retrieveBlocks.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(retrieveBlocks.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.blocks = action.payload['hydra:member'];
      })
      .addCase(retrieveBlocks.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })

      .addCase(retrieveVariableGlobals.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(retrieveVariableGlobals.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.variableGlobals = action.payload['hydra:member'];
      })
      .addCase(retrieveVariableGlobals.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      });
  },
});

// Actions
export const { reset, changeSelected } = topicsSlice.actions;

// Selectors
export const selectBlocks = (state) => state[domain].blocks;
export const selectBlocksStatus = (state) => state[domain].blocksStatus;

export const selectVariableGlobals = (state) => state[domain].variableGlobals;
export const selectVariableGlobalsStatus = (state) => state[domain].variableGlobalsStatus;

export const selectTopics = (state) => state[domain].topics;
export const selectTopicsStatus = (state) => state[domain].topicsStatus;
export const selectSelectedTopic = (state) => state[domain].selectedTopic;

export default topicsSlice.reducer;
