import PropTypes from 'prop-types';
import ModelVersion from './components/ModelVersion';
import FloorCore from './components/FloorCore';
import DefaultFloor from './components/DefaultFloor';

function FloorVersion1(props) {
  const { hidden, control, errors, setValue } = props;

  return (
    <>
      <ModelVersion control={control} registerTarget="modelVersion" />
      <FloorCore hidden={hidden} control={control} registerTarget="" errors={errors} setValue={setValue} />
      <DefaultFloor hidden={hidden} control={control} registerTarget="default" errors={errors} />
    </>
  );
}

FloorVersion1.propTypes = {
  setValue: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any.isRequired,
};

export default FloorVersion1;
