import styled from '@emotion/styled/macro';

const TableHeadRowStyle = styled.th`
  text-align: center;
  padding: 10px;
  background-color: #264653;
  color: white;
  border-bottom-left-radius: ${(props) => props.left && '10px'};
  border-top-left-radius: ${(props) => props.left && '10px'};
  border-bottom-right-radius: ${(props) => props.right && '10px'};
  border-top-right-radius: ${(props) => props.right && '10px'};
`;

export { TableHeadRowStyle };
