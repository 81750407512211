import { Button, TextField, Tooltip } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { snackActions } from '../../../../helpers/SnackBarUtils';
import {
  createConfiguration,
  fetchConfigurations,
  selectConfigurationByKey,
  selectConfigurationsStatus,
  updateConfiguration,
} from '../../../../store/ConfigurationsSlice';
import Heading from '../../../UI/Title/Heading';
import { ButtonWrapper, FormStyled } from '../Placements/ManagePlacement.style';

function ManageTemplatePublisher() {
  const dispatch = useDispatch();

  const status = useSelector(selectConfigurationsStatus);
  const configurationSelector = useMemo(selectConfigurationByKey, []);
  const configuration = useSelector((state) => configurationSelector(state, 'templatePublisherXandr'));

  const { control, handleSubmit, resetField } = useForm({
    defaultValues: {
      templatePublisherXandr: configuration?.value ?? '',
    },
  });

  useEffect(() => {
    if (status !== 'idle') return;

    dispatch(fetchConfigurations());
  }, [status, dispatch]);

  useEffect(() => {
    resetField('templatePublisherXandr', {
      defaultValue: configuration?.value ?? '',
    });
  }, [configuration, resetField]);

  const onSubmit = async (data) => {
    const body = {
      aKey: 'templatePublisherXandr',
      value: data.templatePublisherXandr,
    };

    let result;
    if (configuration) {
      result = dispatch(updateConfiguration({ id: configuration.id, body }));
    } else {
      result = dispatch(createConfiguration(body));
    }

    result.then(snackActions.success(`Template publisher Xandr mis à jour`));
  };

  return (
    <>
      <Heading variant="h2">Gestion du publisher template</Heading>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="templatePublisherXandr"
          control={control}
          render={({ field }) => (
            <Tooltip title="Veuillez renseigner l'id du template Xandr">
              <TextField type="number" {...field} label="PublisherId" placeholder="Id du template du publisher" />
            </Tooltip>
          )}
        />
        <ButtonWrapper>
          <Button sx={{ margin: '0 auto' }} type="submit" variant="contained">
            Modifier
          </Button>
        </ButtonWrapper>
      </FormStyled>
    </>
  );
}

export default ManageTemplatePublisher;
