import styled from '@emotion/styled/macro';

const PartnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: 20px;
  grid-row-gap: 40px;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export { PartnerContainer, LoaderWrapper };
