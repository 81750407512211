import PropTypes from 'prop-types';
import GoogleTagTab from './GoogleTagTab/GoogleTagTab';
import History from './History/History';
import Mep from './Mep/Mep';
import { ContentContainer } from './Content.style';

function Content(props) {
  const { tab, fetchGoogleTag, page, setPage } = props;

  return (
    <ContentContainer>
      {tab > 0 && tab < 5 && <GoogleTagTab fetchGoogleTag={fetchGoogleTag} page={page} setPage={setPage} />}
      {tab === 5 && <Mep />}
      {tab === 6 && <History />}
    </ContentContainer>
  );
}

Content.propTypes = {
  tab: PropTypes.number.isRequired,
  fetchGoogleTag: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Content;
