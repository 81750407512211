function LogoAdditi() {
  return (
    <svg
      id="b9d7296c-af64-4e2b-887a-2aa80d21f8e0"
      data-name="LOGO"
      xmlns="http://www.w3.org/2000/svg"
      width="191.26mm"
      height="63.87mm"
      viewBox="0 0 542.16 181.04"
    >
      <path
        d="M160.4,124.75a5.47,5.47,0,0,0-1.82-1.27,6,6,0,0,0-2.45-.47,6.15,6.15,0,0,0-2.59.53,5.76,5.76,0,0,0-1.93,1.42,6.18,6.18,0,0,0-1.22,2.06,7.47,7.47,0,0,0,0,4.94,6.18,6.18,0,0,0,1.22,2.06,5.76,5.76,0,0,0,1.93,1.42,6.15,6.15,0,0,0,2.59.53,8.63,8.63,0,0,0,2.23-.29,8,8,0,0,0,1.91-.77v-5H156.7V128.5h5.1v7.34A10.82,10.82,0,0,1,159,137a11.64,11.64,0,0,1-2.89.39,8,8,0,0,1-3.11-.59,7.44,7.44,0,0,1-4.11-4.13,8.57,8.57,0,0,1,0-6.3,7.44,7.44,0,0,1,4.11-4.13,8,8,0,0,1,3.11-.59,8.77,8.77,0,0,1,3.1.49,6.5,6.5,0,0,1,2.31,1.52Z"
        fill="#585858"
      />
      <path
        d="M167.32,137H165.8V122h4.61a5.88,5.88,0,0,1,3.61,1,3.52,3.52,0,0,1,1.33,3,3.72,3.72,0,0,1-1,2.63,4.16,4.16,0,0,1-2.84,1.24l4.31,7.11H174l-4.11-7h-2.54Zm0-8.36H170a6.15,6.15,0,0,0,1.73-.21,3.22,3.22,0,0,0,1.13-.56,2.09,2.09,0,0,0,.62-.83,2.85,2.85,0,0,0,.19-1,2.87,2.87,0,0,0-.19-1,2.24,2.24,0,0,0-.62-.83,3.07,3.07,0,0,0-1.13-.57,6.58,6.58,0,0,0-1.73-.2h-2.71Z"
        fill="#585858"
      />
      <path
        d="M193,129.49a8.26,8.26,0,0,1-.59,3.15,7.76,7.76,0,0,1-1.64,2.49,7.51,7.51,0,0,1-2.48,1.64,8.44,8.44,0,0,1-6.2,0,7.42,7.42,0,0,1-4.12-4.13,8.7,8.7,0,0,1,0-6.3,7.42,7.42,0,0,1,4.12-4.13,8.44,8.44,0,0,1,6.2,0,7.51,7.51,0,0,1,2.48,1.64,7.76,7.76,0,0,1,1.64,2.49A8.29,8.29,0,0,1,193,129.49Zm-1.65,0a7.09,7.09,0,0,0-.43-2.47,6.16,6.16,0,0,0-1.21-2.06,5.91,5.91,0,0,0-1.94-1.42,6.1,6.1,0,0,0-2.58-.53,6.18,6.18,0,0,0-2.59.53,5.87,5.87,0,0,0-1.93,1.42,6.18,6.18,0,0,0-1.22,2.06,7.47,7.47,0,0,0,0,4.94,6.18,6.18,0,0,0,1.22,2.06,5.87,5.87,0,0,0,1.93,1.42,6.18,6.18,0,0,0,2.59.53,6.1,6.1,0,0,0,2.58-.53,5.91,5.91,0,0,0,1.94-1.42,6.16,6.16,0,0,0,1.21-2.06A7.09,7.09,0,0,0,191.31,129.49Z"
        fill="#585858"
      />
      <path
        d="M206.72,131.4a10,10,0,0,1-.22,2,5.33,5.33,0,0,1-.84,1.94,4.73,4.73,0,0,1-1.68,1.47,6.81,6.81,0,0,1-5.5,0,4.79,4.79,0,0,1-1.67-1.47,5.15,5.15,0,0,1-.83-1.94,9.32,9.32,0,0,1-.23-2V122h1.53v9.23a7.14,7.14,0,0,0,.21,1.85,4.59,4.59,0,0,0,.56,1.3,3.17,3.17,0,0,0,.78.84,4.13,4.13,0,0,0,.87.47,3.53,3.53,0,0,0,.84.23,4.79,4.79,0,0,0,1.38,0,3.48,3.48,0,0,0,.85-.23,4.13,4.13,0,0,0,.87-.47,3.17,3.17,0,0,0,.78-.84,4.59,4.59,0,0,0,.56-1.3,7.14,7.14,0,0,0,.21-1.85V122h1.53Z"
        fill="#585858"
      />
      <path
        d="M210.53,122h4.36a5.9,5.9,0,0,1,3.61,1,4.09,4.09,0,0,1,0,6,5.84,5.84,0,0,1-3.61,1h-2.84v7h-1.52Zm1.52,6.62h2.46a6.08,6.08,0,0,0,1.72-.21,3.28,3.28,0,0,0,1.14-.56A2.06,2.06,0,0,0,218,127a2.81,2.81,0,0,0,0-2,2.21,2.21,0,0,0-.61-.83,3.12,3.12,0,0,0-1.14-.57,6.5,6.5,0,0,0-1.72-.2h-2.46Z"
        fill="#585858"
      />
      <path d="M224.2,135.59h8V137h-9.55V122H232v1.39H224.2v5.11h7.26v1.39H224.2Z" fill="#585858" />
      <path
        d="M255.72,129.49a8.07,8.07,0,0,1-.59,3.15,7.42,7.42,0,0,1-4.12,4.13,8.44,8.44,0,0,1-6.2,0,7.51,7.51,0,0,1-2.48-1.64,7.76,7.76,0,0,1-1.64-2.49,8.7,8.7,0,0,1,0-6.3,7.76,7.76,0,0,1,1.64-2.49,7.51,7.51,0,0,1,2.48-1.64,8.44,8.44,0,0,1,6.2,0,7.42,7.42,0,0,1,4.12,4.13A8.11,8.11,0,0,1,255.72,129.49Zm-1.65,0a7.32,7.32,0,0,0-.42-2.47,6.18,6.18,0,0,0-1.22-2.06,5.87,5.87,0,0,0-1.93-1.42,6.18,6.18,0,0,0-2.59-.53,6.1,6.1,0,0,0-2.58.53,5.91,5.91,0,0,0-1.94,1.42,6.16,6.16,0,0,0-1.21,2.06,7.31,7.31,0,0,0,0,4.94,6.16,6.16,0,0,0,1.21,2.06,5.91,5.91,0,0,0,1.94,1.42,6.1,6.1,0,0,0,2.58.53,6.18,6.18,0,0,0,2.59-.53,5.87,5.87,0,0,0,1.93-1.42,6.18,6.18,0,0,0,1.22-2.06A7.32,7.32,0,0,0,254.07,129.49Z"
        fill="#585858"
      />
      <path
        d="M269.48,131.4a9.29,9.29,0,0,1-.22,2,5.33,5.33,0,0,1-.84,1.94,4.65,4.65,0,0,1-1.68,1.47,6.79,6.79,0,0,1-5.49,0,4.7,4.7,0,0,1-1.67-1.47,5.33,5.33,0,0,1-.84-1.94,9.29,9.29,0,0,1-.22-2V122H260v9.23a7.14,7.14,0,0,0,.21,1.85,4.33,4.33,0,0,0,.57,1.3,3.17,3.17,0,0,0,.78.84,4.13,4.13,0,0,0,.87.47,3.46,3.46,0,0,0,.83.23,6.55,6.55,0,0,0,.7.05c.18,0,.41,0,.69-.05a3.6,3.6,0,0,0,.85-.23,4.32,4.32,0,0,0,.86-.47,3.37,3.37,0,0,0,.79-.84,4.59,4.59,0,0,0,.56-1.3,7.14,7.14,0,0,0,.21-1.85V122h1.52Z"
        fill="#585858"
      />
      <path d="M274.82,135.59h8V137h-9.55V122h9.3v1.39h-7.77v5.11h7.26v1.39h-7.26Z" fill="#585858" />
      <path
        d="M292.94,124.47A3.3,3.3,0,0,0,290,123a4.59,4.59,0,0,0-1.17.16,3.21,3.21,0,0,0-1,.49,2.32,2.32,0,0,0-.73.85,2.46,2.46,0,0,0-.28,1.21,2.11,2.11,0,0,0,.57,1.58,4.64,4.64,0,0,0,1.42.92,16,16,0,0,0,1.85.65,8.54,8.54,0,0,1,1.86.76,4.48,4.48,0,0,1,1.41,1.26,3.49,3.49,0,0,1,.58,2.14,3.87,3.87,0,0,1-.44,1.83,4.19,4.19,0,0,1-1.14,1.36,5.29,5.29,0,0,1-1.59.85,5.38,5.38,0,0,1-1.77.29,6.78,6.78,0,0,1-2.61-.51,5,5,0,0,1-2.11-1.71l1.4-1a3.57,3.57,0,0,0,1.35,1.32,4.07,4.07,0,0,0,2.05.5,3.77,3.77,0,0,0,1.14-.18,3.3,3.3,0,0,0,1-.53,3,3,0,0,0,.75-.86,2.22,2.22,0,0,0,.29-1.16,2.37,2.37,0,0,0-.32-1.3,2.78,2.78,0,0,0-.87-.87,5,5,0,0,0-1.23-.58L289,130c-.48-.16-1-.33-1.42-.53a5.19,5.19,0,0,1-1.22-.75,3.43,3.43,0,0,1-.87-1.14,4,4,0,0,1-.33-1.73,4.1,4.1,0,0,1,.41-1.88,4,4,0,0,1,1.1-1.34,4.76,4.76,0,0,1,1.56-.78,6.69,6.69,0,0,1,4.09.15,5,5,0,0,1,1.92,1.42Z"
        fill="#585858"
      />
      <path d="M302.42,137H300.9V123.39h-5V122h11.56v1.39h-5Z" fill="#585858" />
      <path d="M311.25,132.6h-5.44v-1.39h5.44Z" fill="#585858" />
      <path d="M315.61,137h-1.52V122h9v1.39h-7.51v5.11h7v1.39h-7Z" fill="#585858" />
      <path
        d="M327.38,137h-1.53V122h4.62a5.9,5.9,0,0,1,3.61,1,3.54,3.54,0,0,1,1.32,3,3.76,3.76,0,0,1-1,2.63,4.2,4.2,0,0,1-2.85,1.24l4.32,7.11H334l-4.11-7h-2.54Zm0-8.36h2.71a6.08,6.08,0,0,0,1.72-.21,3.28,3.28,0,0,0,1.14-.56,2.06,2.06,0,0,0,.61-.83,2.81,2.81,0,0,0,0-2,2.21,2.21,0,0,0-.61-.83,3.12,3.12,0,0,0-1.14-.57,6.5,6.5,0,0,0-1.72-.2h-2.71Z"
        fill="#585858"
      />
      <path
        d="M338.15,137h-1.78l6.53-15h1.48l6.52,15h-1.78l-1.65-3.93H339.8Zm2.23-5.33h6.49l-3.23-7.87Z"
        fill="#585858"
      />
      <path d="M363.77,134.7h0V122h1.52v15h-1.9l-8.94-12.82h0V137h-1.52V122h1.9Z" fill="#585858" />
      <path
        d="M381.74,134.61a5.87,5.87,0,0,1-.94,1.08,6.17,6.17,0,0,1-1.28.88,7.07,7.07,0,0,1-1.56.58,7.27,7.27,0,0,1-1.77.21,7.93,7.93,0,0,1-3.1-.59,7.36,7.36,0,0,1-2.47-1.64,7.63,7.63,0,0,1-1.65-2.49,8.7,8.7,0,0,1,0-6.3,7.63,7.63,0,0,1,1.65-2.49,7.36,7.36,0,0,1,2.47-1.64,7.93,7.93,0,0,1,3.1-.59,7.54,7.54,0,0,1,2.8.55,5.54,5.54,0,0,1,2.39,1.82l-1.33,1a3.77,3.77,0,0,0-.64-.72,4.39,4.39,0,0,0-.92-.63,6,6,0,0,0-1.11-.45,4.44,4.44,0,0,0-1.19-.17,6.1,6.1,0,0,0-2.58.53,5.91,5.91,0,0,0-1.94,1.42,6.16,6.16,0,0,0-1.21,2.06,7.31,7.31,0,0,0,0,4.94,6.16,6.16,0,0,0,1.21,2.06,5.91,5.91,0,0,0,1.94,1.42,6.1,6.1,0,0,0,2.58.53,6.64,6.64,0,0,0,1.18-.11,4.72,4.72,0,0,0,1.14-.36,4.19,4.19,0,0,0,1.06-.69,5,5,0,0,0,.92-1.09Z"
        fill="#585858"
      />
      <path d="M385.82,135.59h8V137H384.3V122h9.29v1.39h-7.77v5.11h7.27v1.39h-7.27Z" fill="#585858" />
      <path d="M246.84,51.69a2.65,2.65,0,1,0-2.64,2.65A2.64,2.64,0,0,0,246.84,51.69Z" fill="#da3832" />
      <path d="M206.63,51.69A2.65,2.65,0,1,0,204,54.34,2.65,2.65,0,0,0,206.63,51.69Z" fill="#314956" />
      <path
        d="M90.06,97.72H84.83V91.43a19.68,19.68,0,0,1-16.12,7.21,19.92,19.92,0,0,1-14.22-6.43,21.26,21.26,0,0,1,.43-29.77,19.29,19.29,0,0,1,15.06-6,20.18,20.18,0,0,1,14.42,6.5A19.23,19.23,0,0,1,90.06,77.5V97.72Zm-36-20.57a15.62,15.62,0,0,0,4.17,11.17,14.79,14.79,0,0,0,10.54,5,14.09,14.09,0,0,0,11.31-4.6,15.87,15.87,0,0,0,4.59-11.66,15.63,15.63,0,0,0-4.52-10.89,14,14,0,0,0-10.54-4.53,14.85,14.85,0,0,0-11,4.53A15.16,15.16,0,0,0,54.07,77.15Z"
        fill="#314956"
      />
      <path
        d="M139.7,77.6a19.6,19.6,0,0,1-6.21,15,20,20,0,0,1-15.1,6,19.69,19.69,0,0,1-14.25-6.35,20.47,20.47,0,0,1-5.79-14.61,20.68,20.68,0,0,1,6.28-15.1,19.24,19.24,0,0,1,15-6.07,19.9,19.9,0,0,1,14.82,7.13V43.37h5.29ZM118.46,93.34a13.81,13.81,0,0,0,11.29-4.59,15.35,15.35,0,0,0,4.52-11.57,15.59,15.59,0,0,0-4.52-10.87,13.86,13.86,0,0,0-10.51-4.52,14.64,14.64,0,0,0-10.94,4.52,15.15,15.15,0,0,0-4.59,10.94A15.65,15.65,0,0,0,108,88.4,14.31,14.31,0,0,0,118.46,93.34Z"
        fill="#314956"
      />
      <path
        d="M189.38,77.6a19.64,19.64,0,0,1-6.21,15,20,20,0,0,1-15.1,6,19.7,19.7,0,0,1-14.26-6.35,21.32,21.32,0,0,1,.5-29.71,19.23,19.23,0,0,1,15-6.07,19.92,19.92,0,0,1,14.82,7.13V43.37h5.29ZM168.14,93.34a13.84,13.84,0,0,0,11.29-4.59A15.39,15.39,0,0,0,184,77.18a15.67,15.67,0,0,0-4.52-10.87,13.87,13.87,0,0,0-10.52-4.52A14.62,14.62,0,0,0,158,66.31a15.11,15.11,0,0,0-4.59,10.94,15.69,15.69,0,0,0,4.23,11.15A14.33,14.33,0,0,0,168.14,93.34Z"
        fill="#314956"
      />
      <path d="M206.87,97.5h-5.3V61.61h5.3Z" fill="#314956" />
      <path d="M247,97.5h-5.29V61.61H247Z" fill="#314956" />
      <path
        d="M232.63,97.65c-9.81,0-14.68-4.16-14.68-12.49V43.35h5.44V61.94h9.24v5h-9.24V84.24c0,6,3.1,9,9.24,9Z"
        fill="#314956"
      />
      <path
        d="M375.85,84.41l4.34,2.31a22.74,22.74,0,0,1-4.93,6.81,20,20,0,0,1-6.36,3.91,22.28,22.28,0,0,1-8,1.35q-9.86,0-15.41-6.46A21.76,21.76,0,0,1,340,77.75a21.52,21.52,0,0,1,4.71-13.68,19.23,19.23,0,0,1,16-7.66,19.9,19.9,0,0,1,16.44,7.84A21.56,21.56,0,0,1,381.51,78H345.28a16.53,16.53,0,0,0,4.49,11.57,14.25,14.25,0,0,0,10.7,4.51,16.9,16.9,0,0,0,6-1.09,16,16,0,0,0,4.94-2.85A24.07,24.07,0,0,0,375.85,84.41Zm0-10.86a17,17,0,0,0-3-6.63,14.22,14.22,0,0,0-5.26-4,16,16,0,0,0-6.88-1.53,14.76,14.76,0,0,0-10.19,3.83,17.48,17.48,0,0,0-4.67,8.34Z"
        fill="#d63c33"
      />
      <path
        d="M493.52,97.59H488.3V91.31a19.64,19.64,0,0,1-16.09,7.2A19.87,19.87,0,0,1,458,92.08a21.21,21.21,0,0,1,.42-29.7,19.19,19.19,0,0,1,15-6,20.13,20.13,0,0,1,14.4,6.49,19.17,19.17,0,0,1,5.64,14.53V97.59ZM457.6,77.05a15.56,15.56,0,0,0,4.17,11.15,14.73,14.73,0,0,0,10.51,5,14,14,0,0,0,11.29-4.58A15.85,15.85,0,0,0,488.16,77a15.62,15.62,0,0,0-4.52-10.87,14,14,0,0,0-10.51-4.51,14.81,14.81,0,0,0-10.94,4.51A15.19,15.19,0,0,0,457.6,77.05Z"
        fill="#d63c33"
      />
      <path
        d="M430.79,77.69a19.64,19.64,0,0,1-6.21,15,20,20,0,0,1-15.1,6,19.7,19.7,0,0,1-14.26-6.35,21.32,21.32,0,0,1,.5-29.71,19.23,19.23,0,0,1,15-6.07,19.92,19.92,0,0,1,14.82,7.13V43.46h5.29ZM409.55,93.43a13.87,13.87,0,0,0,11.29-4.59,15.36,15.36,0,0,0,4.52-11.58,15.67,15.67,0,0,0-4.52-10.86,13.87,13.87,0,0,0-10.52-4.52,14.62,14.62,0,0,0-10.93,4.52,15.11,15.11,0,0,0-4.59,10.93A15.69,15.69,0,0,0,399,88.49,14.37,14.37,0,0,0,409.55,93.43Z"
        fill="#d63c33"
      />
      <path d="M444,51.78a2.65,2.65,0,1,0-2.65,2.64A2.65,2.65,0,0,0,444,51.78Z" fill="#d63c33" />
      <path d="M444.24,97.59H439V61.7h5.29Z" fill="#d63c33" />
      <path
        d="M316.18,56.54h0a15.81,15.81,0,0,0-13.35,7.34,15.81,15.81,0,0,0-13.35-7.34h0a15.85,15.85,0,0,0-15.84,15.85V97.62h5V72.39a10.84,10.84,0,1,1,21.68,0V97.62h5V72.39a10.84,10.84,0,1,1,21.68,0V97.62h5V72.39A15.85,15.85,0,0,0,316.18,56.54Z"
        fill="#d63c33"
      />
      <polygon points="361.61 40.63 354.96 49.99 360.12 49.99 369.09 40.63 361.61 40.63" fill="#d63c33" />
    </svg>
  );
}

export default LogoAdditi;
