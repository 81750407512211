import { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import ManageModules from './Modules/Crud/ManageModules';
import PrebidVersionList from './PrebidVersion/PrebidVersionList';

function ManagePrebidVersion() {
  const [tab, setTab] = useState(1);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs value={tab} onChange={handleChange}>
        <Tab value={1} label="Gestion des version de prebid" />
        <Tab value={2} label="Gestion des modules mandatories" />
      </Tabs>
      {tab === 1 && <PrebidVersionList />}
      {tab === 2 && <ManageModules />}
    </>
  );
}

export default ManagePrebidVersion;
