import styled from '@emotion/styled/macro';

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export { LinksWrapper, ButtonWrapper };
