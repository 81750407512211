/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getPagetypes } from '../helpers/api/pagetypes';

const domain = 'pagetypes';

// Async actions
export const fetchPagetypes = createAsyncThunk(`${domain}/fetchPagetypes`, async (thunkAPI) => {
  try {
    return getPagetypes();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  pagetypes: [],
  status: 'idle',
  error: null,
};

export const pagetypeSlice = createSlice({
  name: domain,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPagetypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPagetypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pagetypes = action.payload['hydra:member'];
      })
      .addCase(fetchPagetypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions

// Selectors
export const selectAllPageTypes = (state) => state[domain].pagetypes;
export const selectAllPageTypesStatus = (state) => state[domain].status;
export const selectPagetypes = () =>
  createSelector(
    (state) => state[domain],
    (_, ids) => ids,
    (state, ids) => state.pagetypes.filter((pagetype) => ids.includes(pagetype.id))
  );
export const selectPagetype = () =>
  createSelector(
    (state) => state[domain],
    (_, id) => id,
    (state, id) => state.pagetypes.find((pagetype) => id === pagetype.id)
  );

export default pagetypeSlice.reducer;
