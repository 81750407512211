import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { TableHeadRowStyle } from './HeadTable.style';
import { CustomTR } from '../Table.style';

function HeadTable(props) {
  const { columns } = props;

  // La scrollbar Firefox n'a pas d'impact sur le tableau, si c'est firefox, on retire le paramètre 'header'.
  const firefoxScrollbar = useMemo(() => window.navigator.userAgent.indexOf('Firefox') !== -1, []);

  return (
    <thead>
      <CustomTR header={!firefoxScrollbar}>
        {columns &&
          columns.map((column, index) => (
            <TableHeadRowStyle
              key={column.id}
              left={index === 0}
              right={columns.length - 1 === index}
              title={column.label}
            >
              {column.label}
            </TableHeadRowStyle>
          ))}
      </CustomTR>
    </thead>
  );
}

HeadTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    })
  ),
};

HeadTable.defaultProps = {
  columns: [],
};

export default HeadTable;
