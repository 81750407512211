import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllGoogleTagVersion, reset, selectAllGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import Heading from '../../UI/Title/Heading';
import { selectCurrentPartner } from '../PartnerPageSlice';
import { GoogleTagsContainer } from './GoogleTagList.style';
import GoogleTagItem from './Item/GoogleTagItem';

function GoogleTagList() {
  const dispatch = useDispatch();
  const partner = useSelector(selectCurrentPartner);
  const googleTagVersions = useSelector(selectAllGoogleTagVersion);

  useEffect(() => {
    dispatch(fetchAllGoogleTagVersion({ partnerWebsites: partner.id, pagination: false }));
  }, [dispatch, partner]);

  // Le return est dans un second useEffect, car il s'éxecute au changement de partner (et donc quand il a fini de requeté)
  useEffect(() => {
    return () => {
      dispatch(reset);
    };
  }, [dispatch]);

  return (
    <>
      <Heading variant="h2">Liste des GoogleTags du partenaire</Heading>
      <GoogleTagsContainer>
        {googleTagVersions
          .map((googleTagVersion) => (
            <GoogleTagItem key={googleTagVersion.id} googleTagVersionId={googleTagVersion.id} />
          ))
          .reverse()}
      </GoogleTagsContainer>
    </>
  );
}

export default GoogleTagList;
