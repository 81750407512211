import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

const GoogleTagItemContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  border: 1px solid grey;
  padding: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  background-color: #264653;
  border-radius: 10px;

  &:hover {
    background-color: white;
    color: #264653;
    border: 1px solid #264653;
  }
`;

const InformationsContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GoogleTagReleaseId = styled.p`
  font-size: 17px;
  font-weight: bold;
`;

const GoogleTagDescription = styled.p`
  font-size: 14px;
  width: 50%;
`;

export { GoogleTagItemContainer, GoogleTagReleaseId, GoogleTagDescription, InformationsContainer };
