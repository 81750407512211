import { Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { snackActions } from '../../../../../helpers/SnackBarUtils';
import { applyDraft, deleteDraft, selectDraft, selectDraftApplicationStatus } from '../../../../../store/DraftSlice';
import Loader from '../../../../UI/Animation/Loader';
import PrimaryButton from '../../../../UI/Basic/Buttons/PrimaryButton';
import AlertDialog from '../../../../UI/Dialog/Dialog';
import MuiModal from '../../../../UI/Modal/MuiModal';
import { ButtonWrapper, LoaderWrapper, ModalButtonWrapper } from './Cart.style';

function DraftConfirmation(props) {
  const { direction, idDraft } = props;

  const [openApply, setOpenApply] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [nameDraft, setNameDraft] = useState('');

  const applicationStatus = useSelector(selectDraftApplicationStatus);
  const draftSlice = useSelector((state) => state.draft.draft);

  const draftSelector = useMemo(selectDraft, []);
  const draftProps = useSelector((state) => draftSelector(state, idDraft))[0];

  const draft = useMemo(() => draftSlice ?? draftProps, [draftProps, draftSlice]);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onCancel = useCallback(() => {
    setOpenApply(false);
    setOpenDelete(false);
  }, []);

  const validateCartHandler = useCallback(() => {
    if (!draft) {
      return;
    }

    const { id } = draft;
    const body = { label: nameDraft };

    dispatch(applyDraft({ id, body }))
      .unwrap()
      .then(() => snackActions.success('Le draft est en cours de traitement'));
    onCancel();
  }, [dispatch, draft, nameDraft, onCancel]);

  const confirmItemsRemoval = useCallback(() => {
    if (!draft) {
      return;
    }

    const { id } = draft;

    dispatch(deleteDraft(id))
      .unwrap()
      .then(() => enqueueSnackbar('Success', { variant: 'success' }));
    onCancel();
  }, [dispatch, draft, enqueueSnackbar, onCancel]);

  const handleChangeNameDraft = (event) => {
    setNameDraft(event.target.value);
  };

  return (
    <ButtonWrapper direction={direction}>
      <PrimaryButton
        onClick={() => setOpenApply(true)}
        disabled={!draft || applicationStatus === 'loading'}
        color="primary"
      >
        Appliquer le draft
      </PrimaryButton>

      <MuiModal open={openApply} handleClose={() => setOpenApply(false)}>
        <TextField
          label="Nom de votre modification (obligatoire)"
          variant="outlined"
          value={nameDraft}
          onChange={handleChangeNameDraft}
        />
        <p>Êtes-vous sur d&#39;appliquer ce draft, ces modifications sont définitives</p>
        <ModalButtonWrapper>
          <Button onClick={() => setOpenApply(false)} autoFocus disabled={!nameDraft}>
            Non
          </Button>
          <Button onClick={validateCartHandler} disabled={!nameDraft}>
            Oui
          </Button>
        </ModalButtonWrapper>
      </MuiModal>

      <PrimaryButton
        onClick={() => setOpenDelete(true)}
        disabled={!draft || applicationStatus === 'loading'}
        color="warning"
      >
        Supprimer le draft
      </PrimaryButton>
      <AlertDialog
        open={openDelete}
        title=""
        content="Etes-vous sûr ? Ce brouillon sera détruit"
        onCancel={onCancel}
        onValidate={confirmItemsRemoval}
      />
      {applicationStatus === 'loading' && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </ButtonWrapper>
  );
}

DraftConfirmation.propTypes = {
  direction: PropTypes.string,
  idDraft: PropTypes.number,
};

DraftConfirmation.defaultProps = {
  direction: null,
  idDraft: null,
};

export default DraftConfirmation;
