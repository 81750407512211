/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllVersionsBloc } from '../../../helpers/api/blocks';

export const fetchVersions = createAsyncThunk(`/versions`, async (idBlock, thunkAPI) => {
  try {
    return getAllVersionsBloc(idBlock);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  status: '',
  error: null,
};

export const VersionSlice = createSlice({
  name: 'versions',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchVersions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchVersions.fulfilled, (state, action) => {
      state.status = 'resolved';
      const version = action.payload;
      if (version) {
        state[version.id] = version.versionBlocks;
      }
    });
    builder.addCase(fetchVersions.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
  },
});

export const selectVersionsByBlockId = (state, blockId) => {
  return state.versions[blockId] || [];
};

export default VersionSlice.reducer;
