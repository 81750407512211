import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

function AlertDialog(props) {
  const { open, title, content, validateLabel, validateColor, validateVariant, cancelLabel, onValidate, onCancel } =
    props;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelLabel}</Button>
        <Button onClick={onValidate} color={validateColor} variant={validateVariant} autoFocus>
          {validateLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  validateLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validateColor: PropTypes.string,
  validateVariant: PropTypes.string,
};

AlertDialog.defaultProps = {
  title: '',
  validateLabel: 'Confirmer',
  cancelLabel: 'Annuler',
  validateColor: 'primary',
  validateVariant: 'text',
};

export default AlertDialog;
