import Heading from '../../UI/Title/Heading';
import CreatePosition from './Create/CreatePosition';
import ListPosition from './List/ListPosition';
import { AdminPositionContainer } from './ManagePosition.style';

function ManagePosition() {
  return (
    <>
      <Heading>Gestion des positions</Heading>
      <AdminPositionContainer>
        <ListPosition />
        <CreatePosition />
      </AdminPositionContainer>
    </>
  );
}

export default ManagePosition;
