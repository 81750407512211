/* eslint-disable */
import { Fragment, useState } from 'react';
import { FaMugHot } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import Button from '../../../UI/Basic/Buttons/Button';
import classes from './ConfirmCloning.module.css';
import Loader from '../../../UI/Animation/Loader';

function ConfirmCloning(props) {
  const [isLoading, setIsLoading] = useState(false);
  const executeTask = () => {
    setIsLoading(true);
    props.onConfirm();
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <IconContext.Provider value={{ color: 'blue', className: 'global-class-name', size: '3em' }}>
            <FaMugHot />
          </IconContext.Provider>
          <p className={classes.warning}>
            ATTENTION !!!!!! Vous êtes sur le point de lancer la création d'un nouveau googletag qui aura le statut "en
            cours de rédaction".
            <br />
            <br />
            Cette action est irréversible, voulez-vous néanmoins poursuivre ?
          </p>

          <p>
            <Button onClick={executeTask}>OK je suis chaud !</Button>
            <Button onClick={props.onCancel}>Oula non !</Button>
          </p>
        </div>
      )}
    </>
  );
}

export default ConfirmCloning;
