import { FaFileImport } from 'react-icons/fa';
import { GiPlatform } from 'react-icons/gi';
import { HiOutlineHome } from 'react-icons/hi';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { RiGlobalLine, RiDraftFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../../store/UsersSlice';
import NavItem from './NavItem';
import { NavigationContainer } from './NavMenu.style';

function NavMenu() {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <NavigationContainer>
      <NavItem to="/" title="Accueil" icon={<HiOutlineHome />} />
      <NavItem to="/floor" title="Floor" icon={<GiPlatform />} />
      {currentUser?.roles.includes('ROLE_ADMIN') && (
        <NavItem to="/admin" title="Admin" icon={<MdOutlineAdminPanelSettings />} />
      )}
      <NavItem to="/import-bidder-csv" title="Import CSV" icon={<FaFileImport />} />
      <NavItem to="/marsse" title="Modification de masse" icon={<RiGlobalLine />} />
      <NavItem to="/drafts" title="Drafts" icon={<RiDraftFill />} />
    </NavigationContainer>
  );
}

export default NavMenu;
