import styled from '@emotion/styled/macro';

const GtagGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 5px;
`;

const ItemColumn = styled.p`
  text-align: center;
`;

export { GtagGrid, ItemColumn };
