import styled from '@emotion/styled/macro';
import { css } from '@emotion/react/macro';

const CustomLabel = styled.span`
  margin: 1%;
  ${(props) =>
    props.color
      ? css`
          background-color: ${props.color};
        `
      : null};
  padding: ${(props) => (props.padding ? props.padding : '0.5em')};
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`;

export { CustomLabel };
