import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PartnerType from '../../../../types/PartnerType';
import Heading from '../../../UI/Title/Heading';
import PartnerCard from '../Card/PartnerCard';
import classes from './ListOfPartners.module.css';
import { FilterPartner, HeaderPartner } from './ListOfPartners.style';

function ListOfPartners(props) {
  const { selectedPartner, partners, selectedPartnerInfo } = props;

  const [filter, setFilter] = useState('');

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className={classes.section}>
      <HeaderPartner>
        <Heading variant="h2">Liste des sites partenaires :</Heading>
        <FilterPartner
          label="Filtre"
          fullWidth
          placeholder="Filtrez vos partenaires..."
          variant="filled"
          value={filter}
          onChange={handleChange}
          size="small"
        />
      </HeaderPartner>
      <div className={classes.choices}>
        {partners.map((partner) => {
          const lowerCaseLabel = partner.displayName
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '');
          const valueCaseLabel = filter
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '');

          if (!lowerCaseLabel.includes(valueCaseLabel)) return null;
          return (
            <PartnerCard
              key={partner.id}
              id={partner.id}
              onSelectPartner={selectedPartner}
              isSelected={partner.id === selectedPartnerInfo?.id}
            />
          );
        })}
      </div>
    </div>
  );
}

ListOfPartners.propTypes = {
  partners: PropTypes.arrayOf(PartnerType),
  selectedPartner: PropTypes.func,
  selectedPartnerInfo: PartnerType,
};

ListOfPartners.defaultProps = {
  partners: [],
  selectedPartner: () => {},
  selectedPartnerInfo: {},
};

export default ListOfPartners;
