import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDevices } from '../../../store/DevicesSlice';
import { selectMediatypes } from '../../../store/MediatypesSlice';
import { selectPagetypes } from '../../../store/PagetypesSlice';
import { selectPositions } from '../../../store/PositionsSlice';

function PositionDetail(props) {
  const { modification } = props;
  const { operation, metadata } = modification;

  const pagetypeSelector = useMemo(selectPagetypes, []);
  const pagetypeIds = useMemo(
    () => (Array.isArray(metadata.pagetypeId) ? metadata.pagetypeId : [metadata.pagetypeId]),
    [metadata.pagetypeId]
  );
  const pagetypes = useSelector((state) => pagetypeSelector(state, pagetypeIds));
  const deviceSelector = useMemo(selectDevices, []);
  const deviceIds = useMemo(
    () => (Array.isArray(metadata.deviceId) ? metadata.deviceId : [metadata.deviceId]),
    [metadata.deviceId]
  );
  const devices = useSelector((state) => deviceSelector(state, deviceIds));
  const positionsSelector = useMemo(selectPositions, []);
  const positionIds = useMemo(
    () => (Array.isArray(metadata.positionId) ? metadata.positionId : [metadata.positionId]),
    [metadata.positionId]
  );
  const positions = useSelector((state) => positionsSelector(state, positionIds));
  const mediatypesSelector = useMemo(selectMediatypes, []);
  const mediatypes = useSelector((state) => mediatypesSelector(state, metadata.mediatypeIds ?? []));

  const pagetypeLabel = useMemo(
    () =>
      pagetypeIds.map((id) => {
        const found = pagetypes.find((p) => p.id === id);
        return found ? found.genericName : id;
      }),
    [pagetypeIds, pagetypes]
  );

  const deviceLabel = useMemo(
    () =>
      deviceIds.map((id) => {
        const found = devices.find((p) => p.id === id);
        return found ? found.genericName : id;
      }),
    [deviceIds, devices]
  );

  const positionLabel = useMemo(
    () =>
      positionIds.map((id) => {
        const found = positions.find((p) => p.id === id);
        return found ? found.genericName : id;
      }),
    [positionIds, positions]
  );

  const mediatypeLabel = useMemo(
    () =>
      metadata?.mediatypeIds?.map((id) => {
        const found = mediatypes.find((p) => p.id === id);
        return found ? found.genericName : id;
      }),
    [mediatypes, metadata?.mediatypeIds]
  );

  const label = useMemo(() => {
    let operationLabel;

    if (operation === 'addPosition') {
      operationLabel = 'ajoutées';
    } else if (operation === 'removePosition') {
      operationLabel = 'supprimées';
    } else if (operation === 'modifyPosition') {
      operationLabel = 'modifiées';
    }

    return (
      <>
        Les positions <strong>({positionLabel.join(', ')})</strong> ont été {operationLabel} sur
        {metadata.pagetypeId && [' les pagetypes ', <strong key="pagetypes">({pagetypeLabel.join(', ')})</strong>]}
        {metadata.deviceId && [' les devices ', <strong key="devices">({deviceLabel.join(', ')})</strong>]}
        {metadata.mediatypeIds &&
          (mediatypeLabel?.length > 0 ? (
            [' les mediatypes ', <strong key="mediatypes">({mediatypeLabel.join(', ')})</strong>]
          ) : (
            <strong key="mediatypes"> tous les mediatypes</strong>
          ))}
      </>
    );
  }, [
    deviceLabel,
    mediatypeLabel,
    metadata.deviceId,
    metadata.mediatypeIds,
    metadata.pagetypeId,
    operation,
    pagetypeLabel,
    positionLabel,
  ]);

  return <div>{label}</div>;
}

PositionDetail.propTypes = {
  modification: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      pagetypeId: PropTypes.arrayOf(PropTypes.number),
      deviceId: PropTypes.arrayOf(PropTypes.number),
      positionId: PropTypes.arrayOf(PropTypes.number).isRequired,
      mediatypeIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default PositionDetail;
