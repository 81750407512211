import { FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

function BooleanVariable() {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => {
        const value = typeof field.value === 'string' ? field.value === 'true' : field.value;

        return (
          <FormGroup sx={{ alignContent: 'center', justifyContent: 'center', height: '56px' }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Inactif</Typography>
              <FormControlLabel control={<Switch {...field} value={value} checked={value} />} label="" />
              <Typography>Actif</Typography>
            </Stack>
          </FormGroup>
        );
      }}
      control={control}
      name="value"
    />
  );
}

export default BooleanVariable;
