import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectModifications } from '../../store/ModificationsSlice';
import { DetailsWrapper } from '../GoogleTag/Edit/MultiUnitModification/Sidebar/History/History.style';
import MuiModal from '../UI/Modal/MuiModal';
import Modification from './Modification';
import ModificationProvider from './ModificationProvider/ModificationProvider';

function ModalDetail(props) {
  const { open, handleClose, modifications } = props;

  const selectedModifications = useSelector(selectModifications());

  const finalModifications = useMemo(
    () => modifications || selectedModifications,
    [modifications, selectedModifications]
  );

  return (
    <MuiModal open={open} handleClose={handleClose} title="Détail des modifications" width="auto">
      <ModificationProvider>
        <DetailsWrapper>
          {finalModifications
            .map((element) => (
              <Modification
                key={element.createdAt}
                createdAt={element.createdAt}
                modificationProp={element}
                showDetails
              />
            ))
            .reverse()}
        </DetailsWrapper>
      </ModificationProvider>
    </MuiModal>
  );
}

ModalDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  modifications: PropTypes.arrayOf(PropTypes.shape({})),
};

ModalDetail.defaultProps = {
  modifications: null,
};

export default ModalDetail;
