import PropTypes from 'prop-types';
import { createElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectNotification } from '../../NotificationSlice';
import NotificationTemplate from '../../NotificationTemplate/NotificationTemplate';
import BlockImportNotification from './BlockImportNotification';
import GoogleTagImportNotification from './GoogleTagImportNotification';

function ImportNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));
  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  let importNotificationComponent = null;
  switch (metadata.type) {
    case 'BLOCK':
      importNotificationComponent = BlockImportNotification;
      break;

    case 'GOOGLETAG':
      importNotificationComponent = GoogleTagImportNotification;
      break;

    default:
      break;
  }

  return importNotificationComponent ? (
    createElement(importNotificationComponent, props)
  ) : (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        Un import de <b>{metadata.type}</b> a été fait.
      </p>
    </NotificationTemplate>
  );
}

ImportNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default ImportNotification;
