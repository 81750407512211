import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getTopics() {
  const url = `${API_BASE_URL}/topics`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getTopic(id) {
  const url = `${API_BASE_URL}/topics/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postTopic(data) {
  const url = `${API_BASE_URL}/topics`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function putTopic(id, data) {
  const url = `${API_BASE_URL}/topics/${id}`;
  const response = await defaultFetch(url, 'PUT', data);

  return response.json();
}

export async function deleteTopic(id) {
  const url = `${API_BASE_URL}/topics/${id}`;
  await defaultFetch(url, 'DELETE');
}
