import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import { patchPrebidVersions, selectPrebidVersion } from '../../../../../store/PrebidVersionSlice';
import { CardWrapper, ModuleWrapper, TitlePrebid, HeaderWrapper } from './PrebidCard.style';
import { selectMandatoryModules } from '../../../../../store/MandatoryModulesSlice';
import ItemModule from './ItemModule/ItemModule';

function PrebidCard(props) {
  const { prebidId } = props;
  const dispatch = useDispatch();
  const mandatoryModules = useSelector(selectMandatoryModules);

  const prebidSelector = useMemo(selectPrebidVersion, []);
  const prebid = useSelector((state) => prebidSelector(state, prebidId));

  const [isActive, setIsActive] = useState(prebid.isActive);

  const color = useMemo(() => {
    if (prebid.isActive && prebid.isRequestable) return '#518333';
    if (!prebid.isRequestable) return '#f40000';
    return '#ffffff';
  }, [prebid]);

  const prebidMandatoryModules = useMemo(() => prebid.prebidVersionMandatoryModules, [prebid]);

  const handleActivePrebidVersion = () => {
    setIsActive(!isActive);
    dispatch(patchPrebidVersions({ id: prebid.id, body: { isActive: !isActive } }));
  };

  return (
    <CardWrapper>
      <HeaderWrapper bgcolor={color} onClick={handleActivePrebidVersion}>
        <div />
        <TitlePrebid> {prebid.version} </TitlePrebid>
        <FormControlLabel disabled={!prebid.isRequestable} control={<Switch checked={isActive} />} label="" />
      </HeaderWrapper>
      <ModuleWrapper>
        {mandatoryModules.map((module) => (
          <ItemModule
            key={module.id}
            moduleId={module.id}
            prebidMandatoryModules={prebidMandatoryModules}
            prebidId={prebid.id}
          />
        ))}
      </ModuleWrapper>
    </CardWrapper>
  );
}

PrebidCard.propTypes = {
  prebidId: PropTypes.number.isRequired,
};

export default PrebidCard;
