import { Badge } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDraft, updateDraft } from '../../../../../store/DraftSlice';
import { selectBlockModifications } from '../../../../../store/ModificationsSlice';
import Button from '../../../../UI/Basic/Buttons/Button';
import DraftConfirmation from '../../../Edit/MultiUnitModification/Cart/DraftConfirmation';
import ViewModificationPopover from '../../Popover/ViewModificationPopover';

function FooterRightSidebar() {
  const [anchorElModif, setAnchorElModif] = useState(null);
  const draft = useSelector((state) => state.draft.draft);
  const dispatch = useDispatch();
  const modifications = useSelector(selectBlockModifications);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenPopoverModif = (event) => {
    setAnchorElModif(event.currentTarget);
  };

  const handleConfirmation = () => {
    let response;

    if (draft) {
      const body = { ...draft, draftModifications: modifications };
      body.draftModifications = body.draftModifications.map((m) => m['@id'] ?? m);

      response = dispatch(updateDraft({ draftId: draft.id, body }));
    } else {
      const body = { draftModifications: modifications, isActive: true, type: 0 };
      response = dispatch(createDraft(body));
    }

    response.unwrap().then(() => {
      enqueueSnackbar('Votre draft a bien été sauvegardé', { variant: 'success' });
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
      <Badge badgeContent={modifications.length} color="primary">
        <Button onClick={handleOpenPopoverModif} color="green">
          Modifications du draft
        </Button>
      </Badge>
      <Button onClick={handleConfirmation}>Sauvegarder le draft</Button>
      <DraftConfirmation />
      <ViewModificationPopover anchorEl={anchorElModif} setAnchorEl={setAnchorElModif} />
    </div>
  );
}

export default FooterRightSidebar;
