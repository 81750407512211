/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchGroupsWithManyPartners,
  fetchPartnerGroupWithlastGtag,
  pushPartnerOnQualOrProd,
} from '../helpers/api/partner';

export const fetchPartnerGroups = createAsyncThunk(`/partnerGroups`, async (thunkAPI) => {
  try {
    return fetchGroupsWithManyPartners();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchPartnerGroupsWithLastGTAG = createAsyncThunk(`/partnerGroupsWithGtag`, async (data, thunkAPI) => {
  try {
    return fetchPartnerGroupWithlastGtag(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const postPartnerInQualOrProd = createAsyncThunk(`/postQualProd`, async (data, thunkAPI) => {
  try {
    return pushPartnerOnQualOrProd(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  totalGroups: 0,
  partnerGroups: [],
  selectedGroups: [],
  status: '',
  error: null,
};

export const GroupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    resetSelectedGroups(state) {
      state.selectedGroups = initialState.selectedGroups;
    },
    addSelectedGroup(state, action) {
      if (Array.isArray(action.payload)) {
        const groups = state.selectedGroups;
        action.payload.forEach((group) => groups.push(group));
        state.selectedGroups = groups;
      } else {
        state.selectedGroups.push(action.payload);
      }
    },
    removeSelectedGroup: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.selectedGroups = state.selectedGroups.filter(
          (item) => !action.payload.some((item2) => item2.id === item.id)
        );
      } else {
        state.selectedGroups = state.selectedGroups.filter((item) => item.id !== action.payload.id);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartnerGroups.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPartnerGroups.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partnerGroups = action.payload['hydra:member'];
      state.totalGroups = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchPartnerGroups.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchPartnerGroupsWithLastGTAG.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPartnerGroupsWithLastGTAG.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partnerGroups = action.payload['hydra:member'];
      state.totalGroups = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchPartnerGroupsWithLastGTAG.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(postPartnerInQualOrProd.fulfilled, (state) => {
      state.status = 'resolved';
    });
    builder.addCase(postPartnerInQualOrProd.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
  },
});

export const { resetSelectedGroups, addSelectedGroup, removeSelectedGroup } = GroupsSlice.actions;

export const selectGroups = (state) => state.groups.partnerGroups;
export const selectGroupTotal = (state) => state.groups.totalGroups;
export const selectGroupSelected = (state) => state.groups.selectedGroups;

export default GroupsSlice.reducer;
