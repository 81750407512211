import styled from '@emotion/styled/macro';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  & > * {
    margin: 10px 0 !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

export { FormWrapper, FieldWrapper, ButtonContainer };
