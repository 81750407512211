import React from 'react';
import PropTypes from 'prop-types';
import BlockType from '../../../../../types/Block/BlockType';
// eslint-disable-next-line import/no-cycle
import Block from '../Block';
import VersionSubMenu from './Version/VersionSubMenu';
import VariableSubMenu from './Variable/VariableSubMenu';
import BlockCustomType from '../../../../../types/Block/BlockCustomType';

function SwitchSubMenu(props) {
  const { block, type, variable } = props;

  switch (type) {
    case 'sousBloc':
      return (
        <div>
          {block.sousBloc.length !== 0 &&
            block.sousBloc.map((ssbloc) => <Block block={ssbloc} key={ssbloc.id} variable={variable} />)}
        </div>
      );
    case 'version':
      return (
        <VersionSubMenu
          idBlock={block.id}
          idVersionSelected={block.idVersion}
          nameBlock={block.name}
          gtagBVid={block.idGtagBv}
        />
      );
    case 'variable':
      return <VariableSubMenu variables={block.variables} blockID={block.id} idVersionSelected={block.idVersion} />;
    default:
      return null;
  }
}

SwitchSubMenu.propTypes = {
  block: PropTypes.oneOfType([BlockType, BlockCustomType]).isRequired,
  type: PropTypes.string.isRequired,
  variable: PropTypes.bool,
};

SwitchSubMenu.defaultProps = {
  variable: false,
};

export default SwitchSubMenu;
