/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllBlocksFormatted, getOrganizedBlocks } from '../../../helpers/api/blocks';

export const fetchBlocks = createAsyncThunk(`/blocks`, async (thunkAPI) => {
  try {
    return getAllBlocksFormatted();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchSelectedBlock = createAsyncThunk(`/blocksSelected`, async (data, thunkAPI) => {
  try {
    const { id, modifications } = data;

    return getOrganizedBlocks(id, modifications);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  blocks: [],
  blocksSelected: [],
  status: '',
  error: null,
};

export const BlocksSlice = createSlice({
  name: 'blocks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchBlocks.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchBlocks.fulfilled, (state, action) => {
      state.status = 'resolved';
      const blocks = action.payload['hydra:member'];
      if (blocks) {
        state.blocks = blocks;
      }
    });
    builder.addCase(fetchBlocks.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchSelectedBlock.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchSelectedBlock.fulfilled, (state, action) => {
      state.status = 'resolved';
      const { blocks } = action.payload;
      if (blocks) {
        state.blocksSelected = blocks;
      }
    });
    builder.addCase(fetchSelectedBlock.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
  },
});

export const selectBlocks = (state) => state.blocks;
export const selectBlocksStatus = (state) => state.blocks.status;

export const selectBlock = () =>
  createSelector(
    (state) => state.blocks,
    (_, id) => id,
    (state, id) => state.blocks.find((block) => id === block.id)
  );

export default BlocksSlice.reducer;
