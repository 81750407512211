import styled from '@emotion/styled/macro';

const TableWrapper = styled.table`
  width: 100%;
  border-spacing: 0 0.3em;
`;
const NameList = styled.tr`
  display: table-row;
  width: 90vw;
`;

const ListItem = styled.td`
  padding: 20px;
  text-align: center;
  background-color: lightgrey;
  border-bottom-left-radius: ${(props) => props.left && '10px'};
  border-top-left-radius: ${(props) => props.left && '10px'};
  border-top-right-radius: ${(props) => props.right && '10px'};
  border-bottom-right-radius: ${(props) => props.right && '10px'};
`;

export { TableWrapper, NameList, ListItem };
