import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const TabBiddersModificationWrapper = styled.div`
  ${(props) =>
    !props.hidden &&
    css`
      display: flex;
    `}
  height: 100%;
`;

export { TabBiddersModificationWrapper };
