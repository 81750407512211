import { FormControlLabel, FormGroup, Switch, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { RiQuestionFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { IoReloadCircle } from 'react-icons/io5';
import { selectCurrentUser } from '../../../../store/UsersSlice';
import IconButtonTooltip from '../../../UI/Buttons/IconButtonTooltip';
import FormModal from '../../../UI/Modal/FormModal';
import MuiModal from '../../../UI/Modal/MuiModal';
import Heading from '../../../UI/Title/Heading';
import { VariableHeaderContainer, VariableTitleContainer, VariableTooltipWrapper } from '../Variable.style';
import { selectDefaultLocalVariable, selectDefaultVariable, updateGlobalVariable } from '../variableSettingsSlice';
import { variableModification } from '../../../../store/ModificationsSlice';
import { selectGoogleTagVersion } from '../../../../store/GoogleTagVersionSlice';

function VariableHeader(props) {
  const { variableId } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const defaultVariableSelector = useMemo(selectDefaultVariable, []);
  const defaultVariable = useSelector((state) => defaultVariableSelector(state, variableId));

  const defaultLocalVariableSelector = useMemo(selectDefaultLocalVariable, []);
  const defaultLocalVariable = useSelector((state) => defaultLocalVariableSelector(state, defaultVariable['@id']));

  const isPossibleReload = useMemo(() => {
    return !!(defaultLocalVariable && defaultLocalVariable?.value !== defaultVariable.defaultValue);
  }, [defaultLocalVariable, defaultVariable]);

  const { control } = useFormContext();
  const { register, handleSubmit } = useForm({ defaultValues: { description: defaultVariable.description } });

  const [open, setOpen] = useState(false);
  const user = useSelector(selectCurrentUser);

  const handleDescriptionModification = useCallback(() => {
    if (!user?.roles?.includes('ROLE_ADMIN')) return;

    setOpen(true);
  }, [user]);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(updateGlobalVariable({ variableId, data }))
      .unwrap()
      .finally(() => {
        setLoading(false);
        setOpen(false);
      });
  };

  const handleReloadDefaultValue = (active) => {
    dispatch(
      variableModification({
        variableName: defaultVariable.name,
        value: defaultVariable.defaultValue,
        active,
        googleTagVersions: [googleTagVersion['@id']],
      })
    );
  };

  return (
    <>
      <Controller
        render={({ field }) => (
          <VariableHeaderContainer selected={field.value}>
            <VariableTitleContainer>
              <VariableTooltipWrapper>
                <IconButtonTooltip
                  title={defaultVariable.description ?? ''}
                  Icon={RiQuestionFill}
                  onClick={handleDescriptionModification}
                />
                {isPossibleReload && (
                  <IconButtonTooltip
                    title="Réinitialiser la valeurs par défaut"
                    Icon={IoReloadCircle}
                    onClick={() => handleReloadDefaultValue(field.value)}
                  />
                )}
              </VariableTooltipWrapper>
              <Heading variant="h4">{defaultVariable.name}</Heading>
            </VariableTitleContainer>
            <FormGroup>
              <FormControlLabel control={<Switch {...field} checked={field.value} />} label="" />
            </FormGroup>
          </VariableHeaderContainer>
        )}
        control={control}
        name="active"
      />
      <MuiModal open={open} handleClose={() => setOpen(false)}>
        <FormModal onSubmit={handleSubmit(onSubmit)} loading={loading}>
          <TextField {...register('description')} placeholder="Description" />
        </FormModal>
      </MuiModal>
    </>
  );
}

VariableHeader.propTypes = {
  variableId: PropTypes.number.isRequired,
};

export default VariableHeader;
