import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import MassButton from '../../../../UI/Basic/Buttons/MassButton/MassButton';
import { postPartnerInQualOrProd, selectGroupSelected } from '../../../../../store/GroupSlice';
import NameVersionModal from '../../../../UI/NameVersionModal/NameVersionModal';
import { getLastDraftOfGoogleTag } from '../../../../../helpers/api/googleTagDrafts';

function MepSidebar() {
  const selectedGroups = useSelector(selectGroupSelected);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [nameVersion, setNameVersion] = useState('');

  const prodEnable = useMemo(() => {
    if (selectedGroups.length > 0) {
      return [2].includes(selectedGroups[0].googleTagStatus);
    }
    return false;
  }, [selectedGroups]);

  const qualEnable = useMemo(() => {
    if (selectedGroups.length > 0) {
      return [0, 1].includes(selectedGroups[0].googleTagStatus);
    }
    return false;
  }, [selectedGroups]);

  const pushToQualOrProd = (action = false) => {
    setOpen(false);
    const data = {
      prod: action,
      groups: selectedGroups,
      label: nameVersion,
    };
    dispatch(postPartnerInQualOrProd(data));
    const actionName = action ? 'PROD' : 'QUAL';
    enqueueSnackbar(`la mise en ${actionName} de ${selectedGroups.length} partenaires a été mis en file d'attente.`, {
      variant: 'success',
    });
  };

  useEffect(() => {
    if (open) {
      getLastDraftOfGoogleTag().then((res) => {
        setNameVersion(res);
      });
    }
  }, [open]);

  const onChangeNameVersion = (event) => {
    setNameVersion(event.target.value);
  };

  const onValidate = (action) => {
    if (!action) {
      setOpen(true);
    } else {
      pushToQualOrProd(true);
    }
  };

  return (
    <div>
      <NameVersionModal
        open={open}
        handleClose={() => setOpen(false)}
        nameVersion={nameVersion}
        onChangeNameVersion={onChangeNameVersion}
        onValidate={() => pushToQualOrProd()}
      />
      <MassButton onClick={() => onValidate(false)} colorButton="green" disabled={!qualEnable}>
        Mettre en QUAL
      </MassButton>
      <MassButton onClick={() => onValidate(true)} colorButton="red" disabled={!prodEnable}>
        Mettre en PROD
      </MassButton>
    </div>
  );
}

export default MepSidebar;
