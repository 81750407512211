import PropTypes from 'prop-types';

const PartnerType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  keyname: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  uuid: PropTypes.string,
  trigram: PropTypes.string,
  isPartner: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  lSSite: PropTypes.string,
  gitFileName: PropTypes.string,
  partnerKeyNameNotInGtag: PropTypes.bool,
  partnerGroup: PropTypes.shape({
    gcpRepository: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }),
});

export default PartnerType;
