import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TopicsContainer } from './Topics.style';
import TopicsList from './TopicsList';
import { reset, retrieveTopics } from './topics.slice';
import TopicContent from './TopicContent';

function Topics() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveTopics());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <>
      <h1>Administration des Topics</h1>
      <TopicsContainer>
        <TopicsList />
        <TopicContent />
      </TopicsContainer>
    </>
  );
}

export default Topics;
