import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../../UI/Title/Heading';
import { fetchMainBlocks, selectBlockAdministration } from '../BlockAdministrationSlice';
import Block from './Block/Block';
import { HeaderBlockList, Scrollable, Wrapper } from './BlockList.style';

function BlockList() {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState('');

  const { blocks } = useSelector(selectBlockAdministration);

  useEffect(() => {
    dispatch(fetchMainBlocks());
  }, [dispatch]);

  return (
    <Wrapper>
      <HeaderBlockList>
        <Heading variant="h2">Liste des blocs</Heading>
        <TextField
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          label="Filtre"
          placeholder="Filtre"
        />
      </HeaderBlockList>
      <hr />
      <Scrollable>
        {blocks &&
          blocks.map((bloc) => {
            const lowerCaseLabel = bloc.name
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '');

            const valueCaseLabel = filter
              .toLowerCase()
              .normalize('NFD')
              .replace(/\p{Diacritic}/gu, '');

            if (!lowerCaseLabel.includes(valueCaseLabel)) return null;
            return <Block key={bloc.id} block={bloc} />;
          })}
      </Scrollable>
    </Wrapper>
  );
}

export default BlockList;
