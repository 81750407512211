import { BiCodeBlock } from 'react-icons/bi';
import { BsGearWideConnected } from 'react-icons/bs';
import { FaTools } from 'react-icons/fa';
import { RiDraftLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../../store/UsersSlice';
import Heading from '../../../UI/Title/Heading';
import Card from '../../../UI/Wrapper/Card';
import SelectPrebidVersion from '../SelectPrebidVersion/SelectPrebidVersion';
import { ButtonWrapper, LinksWrapper } from './Links.style';
import NavigationCard from './NavigationCard';

function Links() {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <Card>
      <Heading variant="h3">
        <FaTools />
        &nbsp;Modifiez les tailles et les SSP de votre Gtag:
      </Heading>
      <LinksWrapper>
        <ButtonWrapper>
          <NavigationCard to="unit-modification" icon={<RiDraftLine />} label="Modification unitaire" />
          {currentUser?.roles.includes('ROLE_BLOC') && (
            <NavigationCard to="code-edit" icon={<BiCodeBlock />} label="Gestion des blocs" />
          )}
          <NavigationCard to="settings" icon={<BsGearWideConnected />} label="Paramétrage" />
        </ButtonWrapper>
        <SelectPrebidVersion />
      </LinksWrapper>
    </Card>
  );
}

export default Links;
