import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';

function CodeEditor(props) {
  const editorRef = useRef(null);
  const { onChange, language, code, theme, readOnly, height } = props;

  const handleEditorChange = (val) => {
    onChange(val);
  };

  function handleEditorDidMount(editor) {
    editorRef.current = editor;
  }

  return (
    <div>
      <Editor
        height={height}
        width="99%"
        language={language || 'javascript'}
        value={code}
        theme={theme}
        // eslint-disable-next-line react/jsx-no-bind
        onMount={handleEditorDidMount}
        onChange={handleEditorChange}
        options={{ readOnly }}
      />
    </div>
  );
}

CodeEditor.propTypes = {
  onChange: PropTypes.func,
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CodeEditor.defaultProps = {
  readOnly: false,
  onChange: () => {
    return false;
  },
  height: '70vh',
};

export default CodeEditor;
