/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Button from '../../../UI/Basic/Buttons/Button';
import { FormStyling, InputWidth } from './BlockCreation.style';
import CodeEditor from '../../../GoogleTag/CodeGeneration/CodeEditor/CodeEditor';
import { postBlock } from '../BlockAdministrationSlice';
import Heading from '../../../UI/Title/Heading';

function BlockCreation() {
  const [code, setCode] = useState('// Créer votre version ici');
  const { register, handleSubmit, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleChangeCode = (value) => {
    setCode(value);
  };

  const onSubmit = (dataForm) => {
    if (code === '// Créer votre version ici') {
      enqueueSnackbar('Vous devez changer le code pour pouvoir sauvegarder un bloc.', { variant: 'warning' });
      return;
    }
    const data = {
      name: dataForm.nameBlock,
      description: dataForm.modification,
      code: `${code}\n`,
    };
    dispatch(postBlock(data)).then(() => {
      enqueueSnackbar(`Le bloc ${data.name} a bien été créé`, { variant: 'success' });
      reset({ modification: '', nameBlock: '' });
      setCode('// Créer votre version ici');
    });
  };

  return (
    <div>
      <Heading variant="h2">Création d&#39;un bloc</Heading>
      <FormStyling onSubmit={handleSubmit(onSubmit)}>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name"> Nom du bloc.</label>
          <InputWidth {...register('nameBlock')} placeholder="Nom" required id="name" />
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="modif"> Texte de modifications de version.</label>
          <InputWidth {...register('modification')} placeholder="Modification" required id="modif" />
        </div>
        <div>
          <Button type="submit" color="green">
            Créer le bloc
          </Button>
        </div>
      </FormStyling>
      <div>
        <p> Votre première version de bloc.</p>
        <CodeEditor theme="vs-dark" language="javascript" code={code} onChange={handleChangeCode} height="50vh" />
      </div>
    </div>
  );
}

export default BlockCreation;
