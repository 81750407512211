import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

const SidebarBlockListWrapper = styled.div`
  min-height: 69.7vh;
  max-height: 69.7vh;
  overflow-y: ${({ isVisible }) => (isVisible ? 'auto' : 'hidden')};
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px ${({ isVisible }) => (isVisible ? '5px 15px' : '5px 15px')};
  width: ${({ isVisible }) => isVisible && '440px'};
`;

const TitleColumn = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const HeaderBlockList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      flex-direction: column;
    `}
  \`;
`;

const VerticalText = styled.div`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  font-size: 40px;
  height: 65vh;
`;

export { SidebarBlockListWrapper, TitleColumn, HeaderBlockList, VerticalText };
