import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FormControlFilter } from '../Filter/SidebarFilter.style';
import ControlledAutocomplete from '../../../UI/ControlledAutocomplete';
import { statusForMep } from '../../../../helpers/status';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import { fetchPartnerGroupsWithLastGTAG, resetSelectedGroups } from '../../../../store/GroupSlice';

function MepFilterSidebar() {
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const filterMep = (data) => {
    const filter = {};
    if (data.status) filter.status = data.status.value;
    dispatch(resetSelectedGroups());
    dispatch(fetchPartnerGroupsWithLastGTAG(filter));
  };

  return (
    <form onSubmit={handleSubmit(filterMep)}>
      <FormControlFilter fullWidth>
        <ControlledAutocomplete
          multiple={false}
          control={control}
          options={statusForMep}
          renderInput={(params) => <TextField {...params} label="Status" placeholder="Status" />}
          getOptionLabel={(option) => option.key}
          name="status"
          renderOption={(renderProps, option) => (
            <li {...renderProps} key={option.key + option.value}>
              {option.key}
            </li>
          )}
        />
      </FormControlFilter>
      <MassButton type="submit">Filtrer</MassButton>
    </form>
  );
}

export default MepFilterSidebar;
