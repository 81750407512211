import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import {
  fetchGoogleTagModifications,
  resetModifications,
  selectAllModifications,
} from '../../../store/ModificationsSlice';
import ItemModification from '../../Home/Modifications/ItemModification/ItemModification';
import ModificationProvider from '../../Modifications/ModificationProvider/ModificationProvider';
import Heading from '../../UI/Title/Heading';
import Card from '../../UI/Wrapper/Card';
import { ScrollableDiv } from './UpdateHistorySection.style';

function UpdateHistorySection() {
  const dispatch = useDispatch();

  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const modifications = useSelector(selectAllModifications);

  useEffect(() => {
    if (!googleTagVersion.id) return null;

    dispatch(fetchGoogleTagModifications(googleTagVersion.id));

    return () => {
      dispatch(resetModifications());
    };
  }, [dispatch, googleTagVersion.id]);

  return (
    <Card sx={{ gridColumn: '2', display: 'flex', flexDirection: 'column' }}>
      <Heading variant="h2">Historique des modifications</Heading>
      {modifications.length === 0 ? (
        <p>Aucune modification n&apos;a été constatée.</p>
      ) : (
        <ModificationProvider>
          <ScrollableDiv>
            {modifications
              .map((modification) => <ItemModification key={modification.id} modificationId={modification.id} />)
              .reverse()}
          </ScrollableDiv>
        </ModificationProvider>
      )}
    </Card>
  );
}

export default UpdateHistorySection;
