import { useMemo } from 'react';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import GoogleTagVersionType from '../../../../../types/GoogleTagVersionType';
import { addGoogleTag, removeGoogleTag, selectSelectedGoogleTags } from '../../../../../store/ModificationsSlice';
import { returnStatusValue } from '../../../../../helpers/returnStatusValue';
import { CustomTD } from '../../../../UI/CompleteTable/Table.style';

function ItemGoogleTag(props) {
  const { item } = props;
  const dispatch = useDispatch();

  const selectedGtag = useSelector(selectSelectedGoogleTags);

  const checked = useMemo(() => selectedGtag.some((gtag) => gtag === item.id), [item.id, selectedGtag]);

  const status = useMemo(() => returnStatusValue(item.status)?.key, [item.status]);

  const namePartner = useMemo(
    () => item.partnerGroup?.name ?? item.partnerGroup.partnerWebsites[0].displayName,
    [item]
  );

  const selectGoogleTag = () => {
    if (checked) {
      dispatch(removeGoogleTag(item.id));
    } else {
      dispatch(addGoogleTag(item.id));
    }
  };

  return (
    <>
      <CustomTD>
        <Checkbox
          onClick={selectGoogleTag}
          checked={checked}
          style={{
            color: '#264653',
          }}
        />
      </CustomTD>
      <CustomTD title={item.id}>{item.id} </CustomTD>
      <CustomTD title={item.author?.trigram ?? 'N/A'}>{item.author?.trigram ?? 'N/A'} </CustomTD>
      <CustomTD title={item.description}>{item.description} </CustomTD>
      <CustomTD title={status}>{status}</CustomTD>
      <CustomTD title={item.prebidVersion?.version ?? 'Non assigné'}>
        {item.prebidVersion?.version ?? 'Non assigné'}{' '}
      </CustomTD>
      <CustomTD title={namePartner}>{namePartner} </CustomTD>
      <CustomTD title={item.releaseId}>{item.releaseId} </CustomTD>
      <CustomTD title={new Date(item.validatedAt).toLocaleString()}>
        {new Date(item.validatedAt).toLocaleString()}
      </CustomTD>
    </>
  );
}

ItemGoogleTag.propTypes = {
  item: GoogleTagVersionType.isRequired,
};

export default ItemGoogleTag;
