import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import ControlledAutocomplete from '../../../UI/ControlledAutocomplete';

const SuggestionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: 20px;
`;

const TextFieldSuggestion = styled(TextField)`
  margin: 20px;
  width: 95%;
`;

const ControlledAutocompleteSuggestion = styled(ControlledAutocomplete)`
  margin: 20px;
`;

const ListSuggestionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const SuggestionItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export {
  SuggestionWrapper,
  ControlledAutocompleteSuggestion,
  TextFieldSuggestion,
  ListSuggestionWrapper,
  SuggestionItemContainer,
};
