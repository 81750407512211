import PropTypes from 'prop-types';
import { CustomTab, CustomTabs } from '../../UI/Tabs/Tabs.style';
import { HeaderTabsStyle, HeaderWrapper, TabWrapper } from './HeaderTabs.style';

function HeaderTabs(props) {
  const { value, handleChange } = props;

  return (
    <HeaderWrapper>
      <TabWrapper
        value={value}
        onChange={handleChange}
        sx={CustomTabs}
        TabIndicatorProps={{ style: { background: '#264653', height: 2 } }}
      >
        <HeaderTabsStyle value={1} label="Prebid Version" sx={CustomTab} />
        <HeaderTabsStyle value={2} label="Taille" sx={CustomTab} />
        <HeaderTabsStyle value={3} label="Position" sx={CustomTab} />
        <HeaderTabsStyle value={4} label="Bidders" sx={CustomTab} />
        <HeaderTabsStyle value={5} label="MEP" sx={CustomTab} />
        <HeaderTabsStyle value={6} label="Historique" sx={CustomTab} />
      </TabWrapper>
    </HeaderWrapper>
  );
}

HeaderTabs.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default HeaderTabs;
