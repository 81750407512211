import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

function InnerSnackbarUtilsConfigurator(props) {
  const { setUseSnackbarRef } = props;

  setUseSnackbarRef(useSnackbar());

  return null;
}

InnerSnackbarUtilsConfigurator.propTypes = {
  setUseSnackbarRef: PropTypes.func.isRequired,
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export default function SnackbarUtilsConfigurator() {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
}

export const snackActions = {
  success(msg) {
    this.toast(msg, 'success');
  },
  warning(msg) {
    this.toast(msg, 'warning');
  },
  info(msg) {
    this.toast(msg, 'info');
  },
  error(msg) {
    this.toast(msg, 'error');
  },
  toast(msg, variant = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
};
