import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';
import { snackActions } from '../../../../helpers/SnackBarUtils';
import { createPosition } from '../../../../store/PositionsSlice';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import Heading from '../../../UI/Title/Heading';
import { TextFieldPosition } from './CreatePosition.style';

function CreatePosition() {
  const { handleSubmit, register } = useForm();
  const dispatch = useDispatch();

  const onCreatePosition = (data) => {
    const positionData = data;
    positionData.uuid = v4();
    dispatch(createPosition(positionData))
      .unwrap()
      .then(() => snackActions.success(`Votre position ${data.genericName} a bien été créée`));
  };

  return (
    <div>
      <Heading variant="h2">Création d&apos;une position</Heading>
      <form onSubmit={handleSubmit(onCreatePosition)}>
        <TextFieldPosition {...register('genericName', { required: true })} label="Nom de la position *" />
        <MassButton type="submit">Créer une position</MassButton>
      </form>
    </div>
  );
}

export default CreatePosition;
