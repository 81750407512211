import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';

const NavigationContainer = styled.nav`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 20px;
  justify-content: left;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItemSelector = styled(NavLink)`
  position: relative;
  color: #4ddbff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const NavItemSelectorIcon = styled.span`
  display: flex;
  padding-right: 10px;
`;

const NavItemSelectorHelper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), opacity 0.4s cubic-bezier(0.25, 0.8, 0.5, 1);

  :hover {
    background-color: currentColor;
    opacity: 0.15;
  }
`;

const NavItemSelectorIndicator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ isActive }) => (isActive ? 'white' : null)};
  position: absolute;
  bottom: 0;
  left: 0;
`;

export { NavigationContainer, NavItemSelector, NavItemSelectorIcon, NavItemSelectorHelper, NavItemSelectorIndicator };
