/* eslint-disable */
import { IconContext } from 'react-icons';
import { FaHeartbeat } from 'react-icons/fa';
import classes from './WarningBox.module.css';

function WarningBox(props) {
  return (
    <div className={classes.warningDiv}>
      <div>
        <IconContext.Provider value={{ color: 'red', className: 'global-class-name', size: '3em' }}>
          <FaHeartbeat />
        </IconContext.Provider>
      </div>
      <div className={classes.message}>{props.children}</div>
    </div>
  );
}

export default WarningBox;
