import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getComments(data) {
  const url = new URL(`${API_BASE_URL}/gtag_comments`);
  url.search = param(data);

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function postComment(body) {
  const url = `${API_BASE_URL}/gtag_comments`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function deleteComment(id) {
  const url = `${API_BASE_URL}/gtag_comments/${id}`;
  return defaultFetch(url, 'DELETE');
}
