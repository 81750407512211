import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import PartnerType from '../../../../types/PartnerType';
import BooleanIcon from '../../../UI/BooleanIcon/BooleanIcon';
import { CustomTD } from '../../../UI/CompleteTable/Table.style';
import ModalModificationPartner from './ModalModificationPartner/ModalModificationPartner';

function TableRow(props) {
  const { item, page, itemsPerPage } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomTD title={item.id}>{item.id}</CustomTD>
      <CustomTD title={item.displayName}>{item.displayName}</CustomTD>
      <CustomTD title={item.keyname}>{item.keyname}</CustomTD>
      <CustomTD>
        <BooleanIcon bool={item.isPartner} />
      </CustomTD>
      <CustomTD>
        <BooleanIcon bool={item.isAuthorized} />
      </CustomTD>
      <CustomTD title={item.trigram}>{item.trigram}</CustomTD>
      <CustomTD title={item.uuid}>{item.uuid}</CustomTD>
      <CustomTD title={item.lSSite}>{item.lSSite}</CustomTD>
      <CustomTD>
        <BooleanIcon bool={item.partnerKeyNameNotInGtag} />
      </CustomTD>
      <CustomTD title={item.gitFileName}>{item.gitFileName}</CustomTD>
      <CustomTD title={item.partnerGroup ? item.partnerGroup.name : '--'}>
        {item.partnerGroup ? item.partnerGroup.name : '--'}
      </CustomTD>
      <CustomTD>
        <BsPencilSquare onClick={() => setOpen(true)} size={20} />
      </CustomTD>
      <ModalModificationPartner open={open} setOpen={setOpen} item={item} page={page} itemsPerPage={itemsPerPage} />
    </>
  );
}

TableRow.propTypes = {
  item: PartnerType.isRequired,
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
};

TableRow.defaultProps = {
  page: 1,
  itemsPerPage: 20,
};

export default TableRow;
