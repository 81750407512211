import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import CodeEditor from '../../../CodeEditor/CodeEditor';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { addBlockModification } from '../../../../../../store/ModificationsSlice';

function VariableSubMenu(props) {
  const { variables, blockID, idVersionSelected } = props;
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    const arrayInputs = [];

    if (inputs.length === 0) {
      variables.map((variable) => {
        const newArr = {
          ...variable,
          value: variable.valueVariableLocal ?? variable.defaultValue,
        };
        arrayInputs.push(newArr);
        return arrayInputs;
      });
    }

    if (arrayInputs.length > 0) {
      setInputs(arrayInputs);
    }
  }, [googleTagVersion.id, inputs, variables]);

  const onChangeText = (index) => (event) => {
    const newArr = [...inputs];
    newArr[index].value = event.target.value;
    setInputs(newArr);
  };

  const onClickChangeVariable = (id, index) => {
    const variable = variables.find((vari) => vari.id === id);

    const data = {
      metadata: {
        idBlock: blockID,
        value: inputs[index].value.toString(),
        idVariableGlobal: variable.id,
        nameVariable: variable.name,
      },
      googleTagVersions: [`/api/google_tag_versions/${googleTagVersion.id}`],
    };

    if (variable.varType === 'local' && variable.valueVariableLocal !== inputs[index].value) {
      data.operation = 'changeLocalVariable';
      data.metadata.method = 'PUT';
      data.metadata.type = 'variableLocal';
      data.metadata.gtagIdBV = variable.idGtagBv;
      data.metadata.idVariableLocal = variable.idVariableLocal;
    } else if (variable.varType === 'global' && variable.defaultValue !== inputs[index].value) {
      data.operation = 'changeGlobalVariable';
      data.metadata.method = 'POST';
      data.metadata.versionID = idVersionSelected;
      data.metadata.type = 'variableGlobal';
      data.metadata.idGTAGBV = variable.idGtagBv;
    } else {
      return;
    }

    dispatch(addBlockModification(data));
    enqueueSnackbar(`La variable ${variable.name} a bien été sauvegardé`, { variant: 'success' });
  };

  const onChangeboolean = (id, index) => {
    if (typeof inputs[index].value === 'string') {
      inputs[index].value = inputs[index].value === 'true';
    }
    const newArr = [...inputs];
    newArr[index].value = !newArr[index].value;
    setInputs(newArr);

    onClickChangeVariable(id, index);
  };

  return (
    <div>
      {inputs.length > 0 &&
        inputs.map((variable, index) => {
          const handleChange = (value) => {
            const newArr = [...inputs];
            newArr[index].value = value;
            setInputs(newArr);
          };

          switch (variable.type) {
            case 'number':
              return (
                <div key={variable.id}>
                  <p> {variable.name}</p>
                  <input
                    value={variable.value}
                    type="number"
                    onChange={onChangeText(index)}
                    onBlur={() => onClickChangeVariable(variable.id, index)}
                  />
                </div>
              );
            case 'array':
            case 'string':
              return (
                <div key={variable.id}>
                  <p> {variable.name}</p>
                  <input
                    value={variable.value}
                    onChange={onChangeText(index)}
                    onBlur={() => onClickChangeVariable(variable.id, index)}
                  />
                </div>
              );
            case 'bool':
              return (
                <div key={variable.id}>
                  <p> {variable.name}</p>
                  <input
                    type="checkbox"
                    onChange={() => onChangeboolean(variable.id, index)}
                    className={variable.name}
                    defaultChecked={inputs[index].value === 'true'}
                  />
                </div>
              );
            case 'function':
              return (
                <div key={variable.id} onBlur={() => onClickChangeVariable(variable.id, index)}>
                  <p> {variable.name}</p>
                  <CodeEditor
                    code={variable.value}
                    language="javascript"
                    theme="vs-dark"
                    onChange={handleChange}
                    height="190px"
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      {/* <Button onClick={onClickChangeVariable}> Enregistrer les variables.</Button> */}
    </div>
  );
}

VariableSubMenu.propTypes = {
  variables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      variableLocal: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ).isRequired,
  idVersionSelected: PropTypes.number.isRequired,
  blockID: PropTypes.number.isRequired,
};

export default VariableSubMenu;
