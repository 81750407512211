import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlockSelectionColumnsWrapper from '../../../components/GoogleTag/CodeGeneration/BlockSelectionColumns/BlockSelectionColumnsWrapper';
import RightSidebarCodeEditor from '../../../components/GoogleTag/CodeGeneration/RightSidebarCodeEditor/RightSidebarCodeEditor';
import { fetchDraft } from '../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import { EditCodeWrapper } from './EditCode.style';

function EditCode() {
  const dispatch = useDispatch();
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const [isVisibleList, setIsVisibleList] = useState(true);

  useEffect(() => {
    if (googleTagVersion) {
      dispatch(fetchDraft(googleTagVersion.id));
    }
  }, [dispatch, googleTagVersion]);

  const handleChangeVisibleList = () => {
    setIsVisibleList(!isVisibleList);
  };

  return (
    <EditCodeWrapper isVisibleList={isVisibleList}>
      <BlockSelectionColumnsWrapper isVisible={isVisibleList} handleChangeVisible={handleChangeVisibleList} />
      <RightSidebarCodeEditor />
    </EditCodeWrapper>
  );
}

export default EditCode;
