import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Label from '../../UI/Basic/Labels/Label';
import { returnStatusValue } from '../../../helpers/returnStatusValue';

function StatusLabel(props) {
  const { status, padding } = props;

  const statusObject = useMemo(() => returnStatusValue(status), [status]);

  return (
    <Label color={statusObject.color} padding={padding}>
      {statusObject.key}
    </Label>
  );
}

StatusLabel.propTypes = {
  status: PropTypes.number.isRequired,
  padding: PropTypes.string,
};

StatusLabel.defaultProps = {
  padding: null,
};

export default StatusLabel;
