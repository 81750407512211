import styled from '@emotion/styled/macro';

const XandrTemplatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
  max-width: 75%;
`;

export { XandrTemplatesWrapper, TemplatesContainer };
