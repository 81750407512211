import styled from '@emotion/styled/macro';
import { Button, FormControl, TextField } from '@mui/material';
import { logoColor } from '../../../../../helpers/color';

const FormWrapper = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
`;

const FieldError = styled.p`
  text-align: center;
  color: ${logoColor};
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ButtonCreationBidder = styled(Button)`
  margin-top: 50px;
  padding: 15px 30px;
  border: 1px solid transparent;
  background-color: #264653;
  border-radius: 20px;
  color: white;

  &:hover {
    background-color: white;
    border: 1px solid #264653;
    color: #264653;
  }
`;

const TextFieldBidder = styled(TextField)`
  width: 100%;
`;

const CustomFormControlBidder = styled(FormControl)`
  width: 100%;
`;

export { FormWrapper, ButtonCreationBidder, ButtonWrapper, CustomFormControlBidder, FieldError, TextFieldBidder };
