import { useDispatch, useSelector } from 'react-redux';
import TabPanel from '../../../../UI/TabPanel/TabPanel';
import CodeEditor from '../../CodeEditor/CodeEditor';
import { selectCode, selectCodeTabs, selectSelectedTab, updateCodeTab } from '../../CodeSlice';

function BodyRightSidebar() {
  const dispatch = useDispatch();

  const selectedTab = useSelector(selectSelectedTab);
  const code = useSelector(selectCode);
  const codeTab = useSelector(selectCodeTabs);

  const handleChangeCodeFromTab = (index, value) => {
    dispatch(updateCodeTab({ index, code: value }));
  };

  return (
    <>
      <TabPanel value={selectedTab} index={0}>
        <CodeEditor code={code} language="javascript" theme="vs-dark" readOnly height="72vh" />
      </TabPanel>
      {codeTab.map((tab, index) => (
        <TabPanel key={tab.id} value={selectedTab} index={index + 1}>
          <CodeEditor
            code={tab.code}
            language="javascript"
            theme="vs-dark"
            onChange={(value) => handleChangeCodeFromTab(index, value)}
            height="70vh"
          />
        </TabPanel>
      ))}
    </>
  );
}

export default BodyRightSidebar;
