import { Tooltip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiSpeakerphone } from 'react-icons/hi';
import ReactMarkdown from 'react-markdown';
import semver from 'semver';
import { useLocalStorage } from 'usehooks-ts';
import MuiModal from '../../../Modal/MuiModal';
import { Badge, IconWrapper, UpdateButton } from './HeaderActionButtons.style';

function ShowLastRelease() {
  const [open, setOpen] = useState(false);
  const [lastReleaseRed, setLastReleaseRed] = useLocalStorage('releaseRed');
  const [description, setDescription] = useState(null);

  const onClickHandler = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  useEffect(() => {
    if (!open) return;

    setLastReleaseRed(process.env.REACT_APP_VERSION);

    if (description === null) {
      fetch(
        `https://gitlab.com/api/v4/projects/${process.env.REACT_APP_GITLAB_PROJECT_ID}/releases/v${process.env.REACT_APP_VERSION}?simple=true`,
        {
          headers: {
            'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_TOKEN,
          },
        }
      ).then((response) => {
        if (response.status !== 200) {
          setDescription('Impossible de récupérer le changelog.');
        } else {
          response.json().then((data) => setDescription(data.description));
        }
      });
    }
  }, [description, open, setLastReleaseRed]);

  const hasUnreadRelease = useMemo(
    () => !lastReleaseRed || semver.gt(process.env.REACT_APP_VERSION, lastReleaseRed),
    [lastReleaseRed]
  );

  return (
    <>
      <Tooltip title={<span style={{ fontSize: '14px' }}>Voir le changelog de la dernière version</span>}>
        <UpdateButton type="button" onClick={onClickHandler}>
          <IconWrapper>
            <HiSpeakerphone />
          </IconWrapper>
          {hasUnreadRelease && <Badge pulse>!</Badge>}
        </UpdateButton>
      </Tooltip>
      <MuiModal open={open} handleClose={onClickHandler} title={`Version ${process.env.REACT_APP_VERSION}`}>
        <ReactMarkdown>{description}</ReactMarkdown>
      </MuiModal>
    </>
  );
}

export default ShowLastRelease;
