/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  applyGoogleTagDraft,
  createGoogleTagDraft,
  deleteGoogleTagDraft,
  getActiveDraft,
  getAllDraft,
  getOneDraft,
  updateGoogleTagDraft,
} from '../helpers/api/googleTagDrafts';

const domain = 'drafts';

// Async actions
export const createDraft = createAsyncThunk(`${domain}/createDraft`, async (body, thunkAPI) => {
  try {
    return createGoogleTagDraft(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchDraft = createAsyncThunk(`${domain}/fetchDraft`, async (id, thunkAPI) => {
  try {
    return getActiveDraft(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchAllDrafts = createAsyncThunk(`${domain}/fetchAllDraft`, async (data, thunkAPI) => {
  try {
    return getAllDraft(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateDraft = createAsyncThunk(`${domain}/updateDraft`, async (args, thunkAPI) => {
  try {
    const { draftId, body } = args;
    return updateGoogleTagDraft(draftId, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deleteDraft = createAsyncThunk(`${domain}/deleteDraft`, async (id, thunkAPI) => {
  try {
    await deleteGoogleTagDraft(id);
    return null;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const applyDraft = createAsyncThunk(`${domain}/applyDraft`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return applyGoogleTagDraft(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchSelectedDraft = createAsyncThunk(`${domain}/fetchSelectedDraft`, async (id, thunkAPI) => {
  try {
    return getOneDraft(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  drafts: [],
  draft: null,
  status: 'idle',
  error: null,
  applicationStatus: 'idle',
};

export const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    update: (state, action) => {
      state.draft = action.payload;
    },
    clearCurrentDraft: (state) => {
      state.draft = initialState.draft;
    },
    draftApplied: (state, action) => {
      const { payload } = action;
      state.drafts = state.drafts.map((draft) => (payload.id === draft.id ? payload : draft));
      state.applicationStatus = 'succeeded';
      state.draft = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDraft.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDraft.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const [draft] = action.payload['hydra:member'];
        state.draft = draft;
      })
      .addCase(fetchDraft.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createDraft.fulfilled, (state, action) => {
        state.draft = action.payload;
      })
      .addCase(updateDraft.fulfilled, (state, action) => {
        state.draft = action.payload;
      })
      .addCase(applyDraft.pending, (state) => {
        state.applicationStatus = 'loading';
      })

      .addCase(fetchAllDrafts.fulfilled, (state, action) => {
        state.drafts = action.payload['hydra:member'];
      })

      .addCase(applyDraft.rejected, (state) => {
        state.applicationStatus = 'failed';
      })

      .addCase(deleteDraft.fulfilled, (state, action) => {
        const { meta } = action;
        state.drafts = state.drafts.filter((draft) => draft.id !== meta.arg);
        state.draft = null;
      });
  },
});

// Actions
export const { update, clearCurrentDraft, draftApplied } = draftSlice.actions;

// Selectors
export const selectDraftApplicationStatus = (state) => state.draft.applicationStatus;
export const selectDraftStatus = (state) => state.draft.status;

export const selectAllDrafts = (state) => state.draft.drafts;

export const selectDraft = () =>
  createSelector(
    (state) => state.draft,
    (_, id) => id,
    (state, id) => state.drafts.filter((draft) => draft.id === id)
  );

export default draftSlice.reducer;
