import { FormControl, List, ListSubheader, MenuItem, Select } from '@mui/material';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../../../../../store/GoogleTagVersionSlice';
import Card from '../../../../../../UI/Wrapper/Card';
import { changePartnerWebsite, selectSelectedPartner, selectTabBiddersNodes } from '../TabBiddersModificationsSlice';
import SelectionItemDevice from './SelectionItemDevice';

function SelectionList() {
  const dispatch = useDispatch();
  const tabBiddersNodes = useSelector(selectTabBiddersNodes);
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const selectedPartner = useSelector(selectSelectedPartner);

  const partnerWebsites = useMemo(() => googleTagVersion?.partnerGroup?.partnerWebsites ?? [], [googleTagVersion]);

  const handleChangePartner = (event) => {
    dispatch(changePartnerWebsite(event.target.value));
  };

  return (
    <Card marginTop="0" height="auto" width="20%">
      <FormControl fullWidth>
        <Select
          onChange={handleChangePartner}
          value={selectedPartner}
          disabled={partnerWebsites.length < 2}
          defaultValue=""
        >
          {partnerWebsites.map((partner) => (
            <MenuItem key={partner.id} value={partner.id}>
              {partner.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List
        sx={{ width: '100%' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Liste des noeuds
          </ListSubheader>
        }
      >
        {Object.values(tabBiddersNodes).map((item) => (
          <SelectionItemDevice key={item.uuid} item={item} metadata={{}} />
        ))}
      </List>
    </Card>
  );
}

export default SelectionList;
