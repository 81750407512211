/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllPrebidVersion, patchPrebidVersion } from '../helpers/api/prebidVersion';
import {
  deleteMandatoryModulesWithPrebidVersion,
  patchMandatoryModulesWithPrebidVersion,
  postMandatoryModulesWithPrebidVersion,
} from './MandatoryModulesSlice';

const domain = 'prebidVersion';

export const fetchPrebidVersions = createAsyncThunk(`${domain}/fetch`, async (thunkAPI) => {
  try {
    return getAllPrebidVersion();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchPrebidVersions = createAsyncThunk(`${domain}/patch`, async (data, thunkAPI) => {
  const { id, body } = data;
  try {
    return patchPrebidVersion(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  selectedprebids: [],
  prebids: [],
  status: '',
  error: null,
};

export const PrebidVersionSlice = createSlice({
  name: domain,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPrebidVersions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPrebidVersions.fulfilled, (state, action) => {
      state.status = 'resolved';
      const allPrebids = action.payload['hydra:member'];
      state.prebids = allPrebids;
      state.selectedprebids = allPrebids.filter((prebid) => prebid.isActive === true);
    });
    builder.addCase(fetchPrebidVersions.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(patchPrebidVersions.fulfilled, (state, action) => {
      const { payload } = action;
      state.prebids = state.prebids.map((prebidVersion) => (prebidVersion.id === payload.id ? payload : prebidVersion));

      state.selectedprebids = state.selectedprebids.map((prebidVersion) =>
        prebidVersion.id === payload.id ? payload : prebidVersion
      );
    });

    builder.addCase(postMandatoryModulesWithPrebidVersion.fulfilled, (state, action) => {
      const { payload } = action;
      const partsIdPrebid = payload.prebidVersion.split('/');
      const idPrebidVersion = partsIdPrebid[partsIdPrebid.length - 1];
      const selectedPrebid = state.prebids.find((prebid) => prebid.id === parseInt(idPrebidVersion, 10));
      selectedPrebid.prebidVersionMandatoryModules.push(payload);
    });

    builder.addCase(patchMandatoryModulesWithPrebidVersion.fulfilled, (state, action) => {
      const { payload } = action;

      const partsIdPrebid = payload.prebidVersion.split('/');
      const idPrebidVersion = partsIdPrebid[partsIdPrebid.length - 1];

      const selectedPrebid = state.prebids.find((prebid) => prebid.id === parseInt(idPrebidVersion, 10));
      const prebidVersionMandatoryModules = selectedPrebid.prebidVersionMandatoryModules.find(
        (pvmm) => pvmm.id === payload.id
      );
      // Dans un premier temps, le patch est seulement pour le required.
      prebidVersionMandatoryModules.required = true;
    });

    builder.addCase(deleteMandatoryModulesWithPrebidVersion.fulfilled, (state, action) => {
      state.prebids = state.prebids.map((prebid) => {
        const prebidVersionMandatoryModule = prebid.prebidVersionMandatoryModules.find(
          (pvmm) => pvmm.id === action.meta.arg
        );
        if (prebidVersionMandatoryModule) {
          const pvmmCurrent = prebid.prebidVersionMandatoryModules;
          prebid.prebidVersionMandatoryModules = pvmmCurrent.filter((pvmm) => pvmm.id !== action.meta.arg);
        }
        return prebid;
      });
    });
  },
});

export const selectPrebidVersions = (state) => state[domain].prebids;
export const selectPrebidVersionsSelected = (state) => state[domain].selectedprebids;
export const selectPrebidVersionsStatus = (state) => state[domain].status;
export const selectPrebidVersion = () =>
  createSelector(
    (state) => state[domain],
    (_, id) => id,
    (state, id) => state.prebids.find((prebid) => prebid.id === id)
  );

export default PrebidVersionSlice.reducer;
