import {
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { MdArticle, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import {
  addedTabBidderSelector,
  removeAddedTabBidder,
  updateAddedTabBidder,
} from '../../../../../../../store/ModificationsSlice';
import AlertDialog from '../../../../../../UI/Dialog/Dialog';
import {
  selectSelectedPartner,
  selectSelectedTabBiddersNode,
  selectTabBidderById,
} from '../TabBiddersModificationsSlice';
import AddedKeywordText from './AddedKeywordText';

function AddedTabBiddersItem(props) {
  const { createdAt } = props;
  const [open, setOpen] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();

  const { metadata } = useSelector(selectSelectedTabBiddersNode);
  const selectedPartner = useSelector(selectSelectedPartner);

  const selectTabBidderModification = useMemo(addedTabBidderSelector, []);
  const selectorArgs = useMemo(
    () => ({ partnerWebsite: selectedPartner, metadata, createdAt }),
    [createdAt, metadata, selectedPartner]
  );
  const modification = useSelector((state) => selectTabBidderModification(state, selectorArgs));

  const bidderId = modification?.metadata?.bidder;
  const defaultValues = modification?.metadata?.defaultValues;

  const selectTabBidder = useMemo(selectTabBidderById, []);
  const bidder = useSelector((state) => selectTabBidder(state, bidderId));

  const handleClick = () => {
    setOpen(!open);
  };

  const handleRemove = useCallback(() => {
    dispatch(removeAddedTabBidder({ createdAt }));
  }, [dispatch, createdAt]);

  const handleReset = useCallback(() => {
    dispatch(
      updateAddedTabBidder({
        metadata,
        values: defaultValues,
        createdAt,
      })
    );
  }, [createdAt, defaultValues, dispatch, metadata]);

  return (
    <>
      <ListItem>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <MdArticle color="green" />
          </ListItemIcon>
          <ListItemText primary={bidder?.displayName} />
          {open ? <MdExpandLess /> : <MdExpandMore />}
        </ListItemButton>
        <ListItemSecondaryAction>
          <IconButton onClick={() => setOpenDialog(true)}>
            <TbTrash color="red" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <AlertDialog
        open={openDialog}
        content="Souhaitez-vous vraiment supprimer ce bidder ? Il a été ajouté dans ce draft."
        title="Confirmation"
        onCancel={() => setOpenDialog(false)}
        onValidate={handleRemove}
        cancelLabel="Annuler"
        validateLabel="Confirmer"
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {bidder?.bidderPlacementKeywords.map((placement) => (
          <AddedKeywordText key={placement.id} placement={placement} createdAt={createdAt} metadata={metadata} />
        ))}
        {defaultValues && Object.keys(defaultValues).length > 0 && (
          <Tooltip title="Reset aux valeurs par défaut">
            <IconButton sx={{ margin: '1em 0 1em 0.4em', imageOrientation: 'revert' }} onClick={handleReset}>
              <FaRedoAlt />
            </IconButton>
          </Tooltip>
        )}
      </Collapse>
      <Divider />
    </>
  );
}

AddedTabBiddersItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default AddedTabBiddersItem;
