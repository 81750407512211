import styled from '@emotion/styled/macro';

const ModificationsWrapper = styled.div`
  width: 45%;
`;

const ScrollableDiv = styled.div`
  max-height: 70vh;
  overflow-y: scroll;
`;

export { ModificationsWrapper, ScrollableDiv };
