/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BsPencilFill } from 'react-icons/bs';
import { AiOutlineCheck } from 'react-icons/ai';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DocList } from '../../CreatePartner/CreatePartner.style';
import { FormWrapper, GroupNameWrapper, InputGroup } from './GroupListingitem.style';
import { patchPartnerGroup } from '../../../../../helpers/api/partner';
import { fetchPartnerGroups } from '../../../../../store/GroupSlice';
import LinkWrapper from '../../../../UI/LinkWrapper/LinkWrapper';

function GroupListingItem(props) {
  const { group } = props;
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: group.name,
    },
  });

  const changeGroupName = (data) => {
    patchPartnerGroup(group.id, data).then((res) => {
      enqueueSnackbar(`Le groupe ${res.name} a été modifié.`, { variant: 'success' });
      dispatch(fetchPartnerGroups());
      setOpen(false);
    });
  };

  return (
    <div key={group.id}>
      <DocList>
        <GroupNameWrapper>
          {open ? (
            <FormWrapper onSubmit={handleSubmit((data) => changeGroupName(data))}>
              <InputGroup {...register('name', { required: true })} label="DisplayName" variant="outlined" />
              <LinkWrapper type="submit">
                <AiOutlineCheck color="green" size={20} cursor="pointer" />
              </LinkWrapper>
            </FormWrapper>
          ) : (
            <p>{group.name}</p>
          )}
          <BsPencilFill size={20} onClick={() => setOpen(!open)} cursor="pointer" />
        </GroupNameWrapper>
      </DocList>
      <ul>
        {group.partnerWebsites.map((partner) => (
          <DocList key={partner.id}> {partner.displayName}</DocList>
        ))}
      </ul>
    </div>
  );
}

GroupListingItem.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    gcpRepository: PropTypes.string,
    partnerWebsites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        displayName: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};

export default GroupListingItem;
