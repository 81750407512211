import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNotification } from '../NotificationSlice';
import NotificationTemplate from '../NotificationTemplate/NotificationTemplate';

function BlockNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        Le bloc <b>&#34;{metadata.name}&#34;</b> a été créé.
      </p>
    </NotificationTemplate>
  );
}

BlockNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default BlockNotification;
