/* eslint-disable */
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleTagsList from '../../../Reusable/NewGtag/GtagsListSelection/GoogleTagsList';
import MySelection from '../../../Reusable/NewGtag/MySelectionSection/MySelection';
import PartnersList from './PartnersListSection/PartnersList';
import classes from './SelectGtagToClone.module.css';
import { API_BASE_URL } from '../../../../references/base-urls';
import FormModal from '../../../UI/Overlay/FormModal';
import ConfirmCloning from '../../../Reusable/NewGtag/ConfirmCloning/ConfirmCloning';
import { clone } from '../../../../helpers/api/googleTagVersion';

function SelectGtagToClone(props) {
  const { partnerId } = props;
  const [listOfChoices, setListOfChoices] = useState(null);
  const [gtagsToDisplay, setGtagsToDisplay] = useState(null);
  const [selectedGtag, setSelectedGtag] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [beingCloning, setBeingCloning] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchChoices();
  }, []);

  const fetchChoices = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get-gtags-selection-for-cloning`);
      const data = await response.json();
      setListOfChoices(data.content);
    } catch (error) {
      // setError(error.message);
    }
  }, []);

  // enregistre le partner choisi
  const savePartnerHandler = (partnerKeyName) => {
    const selectedPartnerForCart = {
      name: partnerKeyName,
      isAmp: listOfChoices[partnerKeyName].hasAmpStatus,
      id: listOfChoices[partnerKeyName].partnerWebsiteId,
      uuid: listOfChoices[partnerKeyName].partnerWebsiteUuid,
      trigram: listOfChoices[partnerKeyName].trigram,
    };
    setSelectedPartner(selectedPartnerForCart);
    setSelectedGtag(null);
    setGtagsToDisplay(listOfChoices[partnerKeyName].googleTags);
  };

  // enregistre le partner choisi
  const saveGtagHandler = (gtagIndex, partnerKeyName) => {
    const selectedGtag = listOfChoices[partnerKeyName].googleTags[gtagIndex];
    if (selectedGtag) {
      setSelectedGtag(selectedGtag);
    }
  };

  const executeCloningHandler = async () => {
    clone(partnerId, selectedGtag.id).then((res) => {
      alert(`c'est bon ! ${res.releaseId} a été créé`);
      navigate(`/edit-google-tag/${res.id}`);
    })
  };

  const cancelCloningHandler = () => {
    setBeingCloning(false);
  };

  const startCloningHandler = () => {
    setBeingCloning(true);
  };

  let cloneActionModal = '';
  if (beingCloning) {
    cloneActionModal = (
      <FormModal title="Créez un nouveau GoogleTag" onCancel={cancelCloningHandler}>
        <ConfirmCloning onConfirm={executeCloningHandler} onCancel={cancelCloningHandler} />
      </FormModal>
    );
  }

  return (
    <div className={classes.section}>
      {cloneActionModal}
      <h1>Veuillez sélectionner un google tag existant qui servira de base pour la création du nouveau</h1>
      <div className={classes.choicesInterface}>
        <PartnersList partners={listOfChoices} onPartnerSelected={savePartnerHandler} />
        <GoogleTagsList
          gtags={gtagsToDisplay}
          onGtagSelected={saveGtagHandler}
          keyName={selectedPartner ? selectedPartner.name : null}
        />
        <MySelection firstChoice={selectedPartner} selectedGtag={selectedGtag} onValidateChoice={startCloningHandler} />
      </div>
    </div>
  );
}

export default SelectGtagToClone;
