import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getPartnerWebsites(page, itemsPerPage) {
  const url = `${API_BASE_URL}/partner_websites?hasAmpStatus=false&isAuthorized=true&page=${page}&itemsPerPage=${itemsPerPage}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getAllPartnerWebsites() {
  const url = `${API_BASE_URL}/partner_websites?isPartner=true&hasAmpStatus=false&isAuthorized=true&pagination=false`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getCurrentGoogleTagVersionByPartnerWebsite(id) {
  const url = `${API_BASE_URL}/partner_websites/${id}/google_tag_version/current`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getInfosForGtagAndPartner(idP) {
  const url = `${API_BASE_URL}/partner_websites/infos/${idP}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getOnePartner(idP) {
  const url = `${API_BASE_URL}/partner_websites/${idP}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getAllPartners(page = 1, itemsPerPage = 30, pagination = true) {
  let url = `${API_BASE_URL}/partner_websites`;
  if (pagination) {
    url += `?page=${page}&itemsPerPage=${itemsPerPage}`;
  } else {
    url += '?paginate=false';
  }

  const response = await defaultFetch(url);
  return response.json();
}

export async function fetchPartners() {
  const url = `${API_BASE_URL}/partner_websites/fetch`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function fetchOne(id) {
  const url = `${API_BASE_URL}/partner_websites/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function fetchGroupsWithManyPartners() {
  const url = `${API_BASE_URL}/partner_groups/many_partners`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postPartner(data) {
  const url = `${API_BASE_URL}/partner_websites`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function postPartnerGroup(data) {
  const url = `${API_BASE_URL}/partner_groups`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function patchPartnerGroup(id, data) {
  const url = `${API_BASE_URL}/partner_groups/${id}`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}

export async function patchPartnerWebsite(id, data) {
  const url = `${API_BASE_URL}/partner_websites/${id}`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}

export async function fetchPartnerGroupWithlastGtag(filters) {
  const url = new URL(`${API_BASE_URL}/partner_groups/google_tag_versions/latest`);
  if (filters) {
    url.search = param(filters);
  }

  const response = await defaultFetch(url.toString());
  return response.json();
}

export async function pushPartnerOnQualOrProd(data) {
  const url = `${API_BASE_URL}/partner_groups/push_qual_or_prod`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function fetchPartnerWithInfos() {
  const url = `${API_BASE_URL}/partner_websites/infos`;
  const response = await defaultFetch(url);

  return response.json();
}
