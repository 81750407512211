import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllSizes() {
  const url = `${API_BASE_URL}/sizes?pagination=false`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getSizesWithPagetypeAndDevice(gtagUuid, pagetype, device) {
  const url = `${API_BASE_URL}/google_tag_versions/${gtagUuid}/sizes?${new URLSearchParams({
    pagetype,
    device,
  })}`;
  const response = await defaultFetch(url);

  return response.json();
}
