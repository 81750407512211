/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllMepMessage } from '../helpers/api/messageQueue';

const DOMAIN = 'messages';

// Async actions
export const fetchMepMessage = createAsyncThunk(`${DOMAIN}/fetch`, async (thunkAPI) => {
  try {
    // On récupère seulement ceux ayant le status 1 (success)
    return getAllMepMessage();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  meps: [],
  status: 'idle',
  error: null,
};

export const MessageQueueSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMepMessage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMepMessage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const messages = action.payload['hydra:member'];
        const mep = [];
        // On veut que les mise en prod, on ignore les mises en qual
        messages.forEach((message) => {
          const serialized = JSON.parse(message.serializedMessage);
          if (serialized.prod) {
            mep.push(message);
          }
        });

        state.meps = mep;
      })
      .addCase(fetchMepMessage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions

// Selectors
export const selectMepMessage = (state) => state[DOMAIN].meps;
export const selectMessageStatus = (state) => state[DOMAIN].status;

export const selectMessage = () =>
  createSelector(
    (state) => state[DOMAIN],
    (_, id) => id,
    (state, id) => state.meps.find((mep) => mep.id === id)
  );

export default MessageQueueSlice.reducer;
