import { CiImport } from 'react-icons/ci';
import PropTypes from 'prop-types';
import { InputFile, LabelInputFile } from './FileInput.style';

function FileInput(props) {
  const { color, onChange, Icon, accept, width } = props;

  return (
    <>
      <LabelInputFile htmlFor="file-upload" width={width}>
        <Icon size={40} color={color} />
        <p>Uploadez votre fichier</p>
      </LabelInputFile>
      <InputFile type="file" accept={accept} id="file-upload" onChange={(event) => onChange(event)} />
    </>
  );
}

FileInput.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  Icon: PropTypes.elementType,
  accept: PropTypes.string.isRequired,
  width: PropTypes.string,
};

FileInput.defaultProps = {
  color: '#264653',
  Icon: CiImport,
  width: '100%',
};

export default FileInput;
