import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { StyledFormControl, StyledTextField } from './Floor.style';
import { fetchPrebidFloorFields, postPrebidFloorFields } from './PrebidFloorFieldsSlice';

const defaultName = ['gptSlot', 'adUnitCode', 'mediaType', 'size', 'domain'];
const tables = {
  position: 'Position',
  mediatype: 'Mediatype',
  device: 'Device',
  pagetype: 'Pagetype',
  domain: 'Domain',
  size: 'Size',
};

function AddField(props) {
  const { defaultValues } = props;

  const dispatch = useDispatch();
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues,
  });
  const name = useWatch({ control, name: 'name' });
  const type = useWatch({ control, name: 'type' });

  const onSubmit = (dataForm) => {
    const datas = { ...dataForm };
    if (defaultName.includes(name)) {
      datas.function = '';
    }

    if (datas.type !== '1') {
      datas.prefix = '';
      datas.target = '';
    }

    datas.type = parseInt(datas.type, 10);

    dispatch(postPrebidFloorFields(datas))
      .unwrap()
      .then(() => {
        dispatch(fetchPrebidFloorFields());
        reset();
      });
  };

  return (
    <form style={{ display: 'flex' }} onSubmit={handleSubmit(onSubmit)}>
      <StyledTextField id="name" {...register('name')} label="Nom" required type="text" variant="filled" />

      <Controller
        render={({ field }) => (
          <StyledFormControl sx={{ width: '150px' }} required>
            <InputLabel id="type-label">Type</InputLabel>
            <Select id="type" {...field} labelId="type-label" label="Type" defaultValue="">
              <MenuItem value="0">Prédefini</MenuItem>
              <MenuItem value="1">Table</MenuItem>
            </Select>
          </StyledFormControl>
        )}
        control={control}
        name="type"
      />

      {!defaultName.includes(name) && (
        <StyledTextField
          id="function"
          {...register('function')}
          label="Fonction"
          required
          type="text"
          variant="filled"
        />
      )}

      {type === '1' && (
        <>
          <StyledTextField id="prefix" {...register('prefix')} label="Prefix" type="text" variant="filled" />

          <Controller
            render={({ field }) => (
              <StyledFormControl sx={{ width: '150px' }}>
                <InputLabel id="target-label">Target</InputLabel>
                <Select id="target" {...field} labelId="target-label" label="target" defaultValue="">
                  {Object.entries(tables).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            )}
            control={control}
            name="target"
          />
        </>
      )}

      <Button sx={{ margin: '2px' }} type="submit" variant="contained">
        Créer un field
      </Button>
    </form>
  );
}

AddField.propTypes = {
  defaultValues: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
    function: PropTypes.string,
    prefix: PropTypes.string,
    target: PropTypes.string,
  }),
};

AddField.defaultProps = {
  defaultValues: {
    id: null,
    name: '',
    type: 0,
    function: '',
    prefix: '',
    target: '',
  },
};

export default AddField;
