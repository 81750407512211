import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMediaTypes, selectAllMediaTypes, selectAllMediaTypesStatus } from '../../../../store/MediatypesSlice';
import Heading from '../../../UI/Title/Heading';
import PlacementDetail from './PlacementDetail';

function PlacementsList() {
  const dispatch = useDispatch();
  const status = useSelector(selectAllMediaTypesStatus);
  const mediatypes = useSelector(selectAllMediaTypes);

  useEffect(() => {
    if (status !== 'idle') return;
    dispatch(fetchMediaTypes());
  }, [dispatch, status]);

  return (
    <>
      <Heading variant="h2">Gestion des placements templates</Heading>
      <div>
        {mediatypes.map((mediatype) => (
          <PlacementDetail key={mediatype.id} mediatypeId={mediatype.id} />
        ))}
      </div>
    </>
  );
}

export default PlacementsList;
