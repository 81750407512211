import styled from '@emotion/styled/macro';

const MiscellaneousWrapper = styled.div`
  display: flex;
  height: 650px;
  width: 100%;
`;

const MiscellaneousContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
`;

export { MiscellaneousWrapper, MiscellaneousContainer };
