import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDraftApplicationStatus } from '../../../../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { fetchAllPartners, selectPartnersStatus } from '../../../../../../store/PartnersSlice';
import TabBiddersList from './Content/TabBiddersList';
import SelectionList from './Selection/SelectionList';
import { TabBiddersModificationWrapper } from './TabBiddersModification.style';
import {
  fetchCurrentBidders,
  fetchTabBidders,
  fetchTabBiddersNodes,
  selectSelectedPartner,
  selectSelectedTabBiddersNode,
  selectTabBiddersStatus,
  setSelectedTabBidders,
} from './TabBiddersModificationsSlice';

function TabBiddersModificationPanel(props) {
  const { activeTab, index } = props;

  const dispatch = useDispatch();
  const { id: selectedTabBiddersNodeId } = useSelector(selectSelectedTabBiddersNode);
  const selectedPartner = useSelector(selectSelectedPartner);
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const partnersStatus = useSelector(selectPartnersStatus);
  const tabBiddersStatus = useSelector(selectTabBiddersStatus);
  const applicationStatus = useSelector(selectDraftApplicationStatus);

  const active = useMemo(() => activeTab === index, [activeTab, index]);

  useEffect(() => {
    if (!active || tabBiddersStatus !== 'idle') {
      return;
    }

    dispatch(
      fetchTabBidders({
        pagination: false,
        groups: ['BidderPlacementKeywordLib:read', 'PlacementKeywordLib:read:basic'],
      })
    );
  }, [dispatch, active, tabBiddersStatus]);

  useEffect(() => {
    if (!googleTagVersion?.id || !active || !['idle', 'succeeded'].includes(applicationStatus)) {
      return;
    }

    dispatch(fetchTabBiddersNodes({ id: googleTagVersion.id, selectedPartner }));
  }, [active, applicationStatus, dispatch, googleTagVersion.id, selectedPartner]);

  useEffect(() => {
    if (!active || partnersStatus !== 'idle') return;

    dispatch(fetchAllPartners({ pagination: false }));
  }, [active, dispatch, partnersStatus]);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (!selectedTabBiddersNodeId) {
      dispatch(setSelectedTabBidders());
    } else if (['idle', 'succeeded'].includes(applicationStatus)) {
      dispatch(fetchCurrentBidders({ id: selectedTabBiddersNodeId }));
    }
  }, [active, applicationStatus, dispatch, selectedTabBiddersNodeId]);

  return (
    <TabBiddersModificationWrapper hidden={!active}>
      <SelectionList />
      <TabBiddersList />
    </TabBiddersModificationWrapper>
  );
}

TabBiddersModificationPanel.propTypes = {
  activeTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabBiddersModificationPanel;
