import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { snackActions } from '../../../helpers/SnackBarUtils';
import FormModal from '../../UI/Modal/FormModal';
import MuiModal from '../../UI/Modal/MuiModal';
import ArrayVariable from './Types/ArrayVariable';
import BooleanVariable from './Types/BooleanVariable';
import NumberVariable from './Types/NumberVariable';
import StringVariable from './Types/StringVariable';
import WildcardVariable from './Types/WildcardVariable';
import {
  createGlobalVariable,
  fetchBlockVariableVersion,
  selectBlockVariableVersion,
  selectBlockVariableVersionStatus,
} from './variableSettingsSlice';

function VariableCreator(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const TYPES = useMemo(() => ['bool', 'string', 'array', 'number', 'wildcard'], []);

  const variableVersionBlock = useSelector(selectBlockVariableVersion);
  const status = useSelector(selectBlockVariableVersionStatus);
  useEffect(() => {
    if (status !== 'idle') return;

    dispatch(fetchBlockVariableVersion());
  }, [dispatch, status]);

  const methods = useForm({
    defaultValues: {
      name: '',
      type: '',
      versionBlock: '',
      value: '',
      isInit: true,
      description: '',
    },
  });

  const { register, handleSubmit, control, setValue, watch } = methods;

  useEffect(() => {
    setValue('versionBlock', variableVersionBlock['@id']);
  }, [setValue, variableVersionBlock]);

  const selectedType = watch('type');

  useEffect(() => {
    setValue('value', '');
  }, [setValue, selectedType]);

  let VariableComponent = null;
  let transformOutput = (value) => value;

  switch (selectedType) {
    case 'array':
      VariableComponent = ArrayVariable;
      transformOutput = (value) => (value ? JSON.stringify(value.map((v) => v.value)) : JSON.stringify([]));
      break;
    case 'bool':
      VariableComponent = BooleanVariable;
      transformOutput = (value) => (value ? 'true' : 'false');
      break;
    case 'number':
      VariableComponent = NumberVariable;
      break;
    case 'string':
      VariableComponent = StringVariable;
      transformOutput = (value) => `"${value}"`;
      break;
    case 'wildcard':
      VariableComponent = WildcardVariable;
      break;
    default:
      break;
  }

  const onSubmit = (data) => {
    setLoading(true);

    const newData = { ...data };
    newData.defaultValue = transformOutput(newData.value);
    delete newData.value;
    newData.uuid = v4();

    dispatch(createGlobalVariable(newData))
      .unwrap()
      .then(() => {
        snackActions.success('Variable créée');
        handleClose();
      })
      .catch((e) => snackActions.error(e.message))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MuiModal open={open} handleClose={handleClose} title="Création d'une variable">
      <FormProvider {...methods}>
        <FormModal onSubmit={handleSubmit(onSubmit)} loading={loading}>
          <TextField {...register('name')} label="Nom" required fullWidth />
          <FormControl required fullWidth>
            <InputLabel id="type-select-label">Type</InputLabel>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select labelId="type-select-label" {...field} label="type">
                  {TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          {VariableComponent && (
            <FormControl sx={{ maxHeight: '260px' }}>
              <VariableComponent header={false} />
            </FormControl>
          )}
          <TextField {...register('description')} label="Description" fullWidth multiline />
        </FormModal>
      </FormProvider>
    </MuiModal>
  );
}

VariableCreator.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

VariableCreator.defaultProps = {
  open: false,
};

export default VariableCreator;
