import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TbVersions } from 'react-icons/tb';
import { ImCross } from 'react-icons/im';
import { VscVariableGroup } from 'react-icons/vsc';
import { RxDragHandleDots1 } from 'react-icons/rx';
import { Draggable } from 'react-beautiful-dnd';
import { BiCodeBlock } from 'react-icons/bi';
// eslint-disable-next-line import/no-cycle
import SwitchSubMenu from './BlockSubMenu/SwitchSubMenu';
import BlockType from '../../../../types/Block/BlockType';
import {
  BlockWrapper,
  IconWrapper,
  NameAndArrowWrapper,
  CrossWrapper,
  ClickableIcon,
  IconNameContainer,
  CodePreviewContainer,
  CodePreview,
} from './Block.style';
import BlockCustomType from '../../../../types/Block/BlockCustomType';

function Block(props) {
  const { block, variable, display, preview } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('');
  const handleIsOpen = (typeSubMenu, event) => {
    if (!isOpen) {
      setIsOpen(true);
    }

    event.stopPropagation();
    setType(typeSubMenu);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  const previewOpen = useMemo(() => !!preview, [preview]);

  if (display) {
    return (
      <Draggable draggableId={block.id.toString()} index={block.position ?? block.id}>
        {(provided, snapshot) => (
          <BlockWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            // Prend pas les booleans
            currentlydragging={snapshot.isDragging.toString()}
          >
            <NameAndArrowWrapper>
              <IconNameContainer>
                <RxDragHandleDots1 size={30} color="black" />
                <p>
                  {block?.position && `${block.position}-`} {block.name}
                </p>
              </IconNameContainer>
              <IconWrapper>
                <ClickableIcon>
                  {variable && block.variables && block.idGtagBv && block.variables.length > 0 && (
                    <VscVariableGroup
                      cursor="pointer"
                      title="Contenu des variables du bloc"
                      size={25}
                      onClick={(event) => handleIsOpen('variable', event)}
                    />
                  )}

                  {block.idGtagBv && (
                    <TbVersions
                      cursor="pointer"
                      title="Gestion de version du bloc"
                      size={25}
                      onClick={(event) => handleIsOpen('version', event)}
                    />
                  )}
                  {block?.sousBloc.length > 0 && (
                    <BiCodeBlock
                      cursor="pointer"
                      title="Menu de gestion de sous-bloc"
                      size={25}
                      onClick={(event) => handleIsOpen('sousBloc', event)}
                    />
                  )}
                </ClickableIcon>
                <CrossWrapper>{isOpen && <ImCross onClick={handleClose} size={25} color="red" />}</CrossWrapper>
              </IconWrapper>
            </NameAndArrowWrapper>

            {previewOpen && (
              <CodePreviewContainer>
                <CodePreview>{preview}</CodePreview>
              </CodePreviewContainer>
            )}
            {isOpen && <SwitchSubMenu block={block} type={type} variable={variable} />}
          </BlockWrapper>
        )}
      </Draggable>
    );
  }
}

Block.propTypes = {
  block: PropTypes.oneOfType([BlockCustomType]).isRequired,
  variable: PropTypes.bool,
  display: PropTypes.bool,
  preview: PropTypes.string,
};

Block.sousBloc = PropTypes.arrayOf(BlockType);

Block.defaultProps = {
  variable: false,
  display: true,
  preview: null,
};

export default Block;
