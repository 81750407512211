import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNotification } from '../NotificationSlice';
import NotificationTemplate from '../NotificationTemplate/NotificationTemplate';
import { selectTabBidderById } from '../../../store/TabBiddersSlice';

function CSVNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  const bidderSelector = useMemo(selectTabBidderById, []);
  const bidder = useSelector((state) => bidderSelector(state, metadata.id));

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        Un import CSV a été effectué
        {bidder && (
          <span>
            &nbsp;sur <b> {bidder.displayName}</b>
          </span>
        )}
      </p>
    </NotificationTemplate>
  );
}

CSVNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default CSVNotification;
