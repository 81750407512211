import Heading from '../../../../UI/Title/Heading';
import ModulesCreate from './Create/ModulesCreate';
import ModulesList from './List/ModulesList';
import { ManageModulesContainer } from './ManageModules.style';

function ManageModules() {
  return (
    <>
      <Heading>Gestion des modules mandatories</Heading>
      <ManageModulesContainer>
        <ModulesList />
        <ModulesCreate />
      </ManageModulesContainer>
    </>
  );
}

export default ManageModules;
