/* eslint-disable react/jsx-props-no-spreading */
import { BsFillPersonFill } from 'react-icons/bs';
import { FaKey } from 'react-icons/fa';
import { AiOutlineEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  ButtonLogin,
  FormField,
  FormLoginWrapper,
  FormWrapper,
  InputLogin,
  InputPassword,
  Title,
  FieldError,
  Error,
} from './Form.style';
import { logoColor } from '../../helpers/color';
import { loginUser } from '../../store/UsersSlice';
import { verifyTokenExist } from '../../helpers/storage';
import { ForgotPassword } from '../../pages/Authentication/Login.style';
import { connectionActions } from '../../helpers/ConnectionUtils';

function FormLogin() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const login = (data) => {
    dispatch(loginUser(data))
      .unwrap()
      .then(() => {
        connectionActions.signin();
      })
      .catch(() => {
        setError('Vos identifiants sont incorrects');
      });
  };

  if (verifyTokenExist()) {
    return <Navigate to="/" replace />;
  }

  return (
    <FormLoginWrapper>
      <FormWrapper onSubmit={handleSubmit((data) => login(data))} method="POST">
        <Title> Connexion </Title>

        <FormField>
          <BsFillPersonFill size={30} color={logoColor} />
          <InputLogin
            {...register('username', { required: true })}
            id="input-with-sx"
            label="Adresse mail"
            variant="standard"
            sx={{
              '& .MuiInput-underline:after': { borderBottomColor: logoColor },
            }}
          />
        </FormField>
        {errors.username?.type === 'required' && <FieldError>L&#39;adresse mail est requise</FieldError>}

        <FormField>
          <FaKey size={25} color={logoColor} />
          <InputPassword
            {...register('password', { required: true })}
            id="input-with-sx"
            label="Mot de passe"
            variant="standard"
            type={showPassword ? 'text' : 'password'}
            sx={{
              '& .MuiInput-underline:after': { borderBottomColor: logoColor },
            }}
          />
          {showPassword ? (
            <AiOutlineEyeInvisible size={25} onClick={() => setShowPassword(!showPassword)} />
          ) : (
            <AiFillEye size={25} onClick={() => setShowPassword(!showPassword)} />
          )}
        </FormField>
        {errors.password?.type === 'required' && <FieldError role="alert">Le mot de passe est requis</FieldError>}

        <ButtonLogin type="submit" variant="contained" size="large">
          Se connecter
        </ButtonLogin>
        <Error> {error} </Error>
        <ForgotPassword to="/forgot-password">Mot de passe oublié</ForgotPassword>
      </FormWrapper>
    </FormLoginWrapper>
  );
}

export default FormLogin;
