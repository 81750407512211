import { css } from '@emotion/react';
import styled from '@emotion/styled';

const DescriptionWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
`;

const Description = styled.div`
  border-radius: 5px;
  line-height: 1;
  border: 1px solid #c4c4c4;
  font-size: 18px;
  padding: 10px;
  flex: 1;
`;

const PartnerDescription = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  ${({ multiple }) =>
    !multiple &&
    css`
      justify-content: center;
    `}
`;

export { DescriptionWrapper, Description, PartnerDescription };
