import styled from '@emotion/styled/macro';

const BidderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BidderNameContent = styled.div`
  width: 100%;
`;

const BidderNameWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

export { BidderWrapper, BidderNameContent, BidderNameWrapper };
