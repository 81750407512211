import styled from '@emotion/styled/macro';

const IconSwitchWrapper = styled.td`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => !props.active && '#c7c4c4'};
`;

export { IconSwitchWrapper };
