/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import {
  BackToLogin,
  ButtonLogin,
  Error,
  FormField,
  FormLoginWrapper,
  FormWrapper,
  InputPassword,
  Title,
} from './Form.style';
import { logoColor } from '../../helpers/color';
import { forgotPassword } from '../../helpers/api/authentication';

function FormMail() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState('');

  const sendToken = (data) => {
    forgotPassword(data.email)
      .then(() => {
        setError('Un mail vous a été envoyé si votre compte existe.');
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <FormLoginWrapper>
      <FormWrapper onSubmit={handleSubmit((data) => sendToken(data))} method="POST">
        <Title> Mot de passe oublié </Title>

        <FormField>
          <AiOutlineMail size={25} color={logoColor} />
          <InputPassword
            {...register('email', {
              required: 'Vous devez spécifier un mail',
            })}
            placeholder="Mail"
            required
            id="mail"
            type="email"
            error={!!errors?.mail}
            helperText={errors?.mail?.message}
            name="email"
            label="Email"
            variant="standard"
            sx={{
              '& .MuiInput-underline:after': { borderBottomColor: logoColor },
            }}
          />
        </FormField>
        {errors.email && <p>{errors.email.message}</p>}

        <ButtonLogin type="submit" variant="contained" size="large">
          Réinitialiser votre mot de passe
        </ButtonLogin>

        <Error>{error}</Error>
        <BackToLogin to="/login">
          <IoIosArrowBack size={30} />
          <p> Back to login </p>
        </BackToLogin>
      </FormWrapper>
    </FormLoginWrapper>
  );
}

export default FormMail;
