import styled from '@emotion/styled';
import { Card } from '@mui/material';

const TopicsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const TopicsListContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 10px;
  width: 20%;
  min-width: 200px;
  max-width: 500px;
`;

const TopicFormCreation = styled.div`
  display: flex;
`;

const TopicContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 5px;
`;

const TopicContentForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export { TopicsContainer, TopicsListContainer, TopicFormCreation, TopicContentContainer, TopicContentForm };
