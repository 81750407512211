import {
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { MdArticle, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../../../../../store/GoogleTagVersionSlice';
import {
  disabledTabBidderSelector,
  removeTabBidder,
  restoreTabBidder,
  tabBiddersModificationSelector,
} from '../../../../../../../store/ModificationsSlice';
import {
  selectSelectedPartner,
  selectSelectedTabBiddersNode,
  selectTabBidderById,
  selectedTabBiddersByIdSelector,
} from '../TabBiddersModificationsSlice';
import { KeywordTextContainer } from './Content.style';
import KeywordText from './KeywordText';

function TabBiddersItem(props) {
  const { id } = props;
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const selectedPartner = useSelector(selectSelectedPartner);

  const selectedTabBiddersNodes = useMemo(selectedTabBiddersByIdSelector, []);
  const selectedTabBidder = useSelector((state) => selectedTabBiddersNodes(state, id));

  const { occurrence, bidder: bidderId, isEnabled } = selectedTabBidder;

  const selectTabBidder = useMemo(selectTabBidderById, []);
  const bidder = useSelector((state) => selectTabBidder(state, bidderId));

  const { metadata } = useSelector(selectSelectedTabBiddersNode);

  const selectorArgs = useMemo(
    () => ({ partnerWebsite: selectedPartner, metadata, bidderId: bidder.id, occurrence }),
    [selectedPartner, metadata, bidder.id, occurrence]
  );

  const selectTabBidderModification = useMemo(tabBiddersModificationSelector, []);
  const modification = useSelector((state) => selectTabBidderModification(state, selectorArgs));
  const selectDisabled = useMemo(disabledTabBidderSelector, []);
  const modificationVisibility = useSelector((state) => selectDisabled(state, selectorArgs));

  const disabled = useMemo(() => {
    if (modificationVisibility) {
      return modificationVisibility.operation === 'disableTabBidders';
    }

    return !isEnabled;
  }, [modificationVisibility, isEnabled]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAddOrRemove = useCallback(() => {
    const data = {
      selectedPartner,
      metadata,
      bidderId: bidder.id,
      occurrence,
      googleTagVersions: [googleTagVersion['@id']],
    };

    if (disabled) {
      dispatch(restoreTabBidder(data));
    } else {
      dispatch(removeTabBidder(data));
    }
  }, [disabled, dispatch, metadata, bidder.id, occurrence, selectedPartner, googleTagVersion]);

  const color = useMemo(() => {
    if (disabled) {
      return 'red';
    }

    if (modification) {
      return 'orange';
    }

    return null;
  }, [modification, disabled]);

  return (
    <>
      <ListItem>
        <ListItemButton onClick={handleClick} sx={disabled ? { opacity: 0.5 } : {}}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <MdArticle color={color} />
          </ListItemIcon>
          <ListItemText primary={bidder?.displayName} />
          {open ? <MdExpandLess /> : <MdExpandMore />}
        </ListItemButton>
        <ListItemSecondaryAction>
          <IconButton onClick={handleAddOrRemove}>
            {disabled ? <AiFillEyeInvisible color="green" /> : <AiFillEye color="red" />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <KeywordTextContainer disabled={disabled}>
          {bidder?.bidderPlacementKeywords.map((placement) => (
            <KeywordText
              key={placement.id}
              bidderId={bidder.id}
              placement={placement}
              modification={modification}
              selectedTabBidder={selectedTabBidder}
              metadata={metadata}
              occurrence={occurrence}
            />
          ))}
        </KeywordTextContainer>
      </Collapse>
      <Divider />
    </>
  );
}

TabBiddersItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default TabBiddersItem;
