import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getPrebidFloor(options = {}) {
  let url = `${API_BASE_URL}/prebid_floors`;

  if ('paginate' in options) {
    url += `?paginate=${options.paginate}`;
  }

  const response = await defaultFetch(url);

  return response.json();
}

export async function getPrebidFloorFile(filePath) {
  const url = `${window.location.protocol}//${window.location.hostname}${filePath}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function createPrebidFloor(datas) {
  const url = `${API_BASE_URL}/prebid_floors`;
  const response = await defaultFetch(url, 'POST', datas);

  return response.json();
}

export async function upload(id, data) {
  const url = `${API_BASE_URL}/prebid_floors/${id}/upload`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}
