/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createBlock, getMainBlocks, putBlock } from '../../../helpers/api/blocks';

export const fetchMainBlocks = createAsyncThunk(`/mainBlock`, async (thunkAPI) => {
  try {
    return getMainBlocks();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateBlocks = createAsyncThunk(`/updateBlocks`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return putBlock(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const postBlock = createAsyncThunk(`/postBlock`, async (body, thunkAPI) => {
  try {
    return createBlock(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  blocks: [],
  deletedBlocks: [],
  status: '',
  error: null,
};

export const BlockAdministrationSlice = createSlice({
  name: 'blockAdministration',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMainBlocks.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchMainBlocks.fulfilled, (state, action) => {
      state.status = 'resolved';
      const mainBlocks = action.payload['hydra:member'];
      if (mainBlocks) {
        state.blocks = mainBlocks;
        state.deletedBlocks = mainBlocks.filter((bloc) => bloc.isActive === false);
      }
    });
    builder.addCase(fetchMainBlocks.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(updateBlocks.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateBlocks.fulfilled, (state, action) => {
      const { payload } = action;
      state.blocks = state.blocks.map((block) => (block.id === payload.id ? payload : block));
      state.status = 'resolved';
    });
    builder.addCase(updateBlocks.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(postBlock.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(postBlock.fulfilled, (state, action) => {
      state.blocks.push(action.payload);
      state.status = 'resolved';
    });
    builder.addCase(postBlock.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
  },
});

export const selectBlockAdministration = (state) => state.blocksAdministration;

export default BlockAdministrationSlice.reducer;
