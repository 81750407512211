import PropTypes from 'prop-types';
import { Button, Card, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdFileDownload } from 'react-icons/md';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import download from 'downloadjs';
import { fetchPrebidFloors, selectPrebidFloor, uploadPrebidFloor } from './PrebidFloorSlice';
import { PrebidVersionWrapper } from './Floor.style';
import { snackActions } from '../../helpers/SnackBarUtils';

function FloorSelection(props) {
  const { floorJson, selectedVersion, handleChange } = props;

  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const floorsFiles = useSelector(selectPrebidFloor);

  const selectedFloor = useMemo(
    () => floorsFiles.find((prebidFloor) => prebidFloor.version === selectedVersion),
    [floorsFiles, selectedVersion]
  );

  useEffect(() => {
    dispatch(fetchPrebidFloors({ paginate: false }));
  }, [dispatch]);

  const handleDownload = () => {
    download(JSON.stringify(floorJson), 'floor.json', 'application/json');
  };

  const handleUpload = (env) => {
    if (!selectedFloor) {
      snackActions.error('Veuillez sélectionner une version');
      return;
    }

    setUploading(true);

    dispatch(uploadPrebidFloor({ id: selectedFloor.id, body: { env } }))
      .unwrap()
      .then(() => dispatch(fetchPrebidFloors({ paginate: false })))
      .finally(() => setUploading(false));
  };

  return (
    <Card sx={{ margin: '10px 0' }}>
      <PrebidVersionWrapper>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id="floor-selection-label">Version du floor</InputLabel>
          <Select
            labelId="floor-selection-label"
            id="floor-selection"
            value={selectedVersion}
            onChange={handleChange}
            label="Version du floor"
          >
            <MenuItem value="">Ne rien sélectionner</MenuItem>
            {floorsFiles.map((file) => (
              <MenuItem key={file.id} value={file.version}>
                {file.version} {file.isStaging && <span>(QUAL)</span>} {file.isProd && <span>(PROD)</span>}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tooltip title="Télécharger le fichier">
          <span style={{ display: 'flex' }}>
            <Button
              sx={{ marginLeft: '10px' }}
              type="button"
              variant="contained"
              disabled={!selectedVersion}
              onClick={handleDownload}
            >
              <MdFileDownload size="26px" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title="Uploader le fichier en QUAL">
          <span style={{ display: 'flex' }}>
            <Button
              sx={{ marginLeft: '10px' }}
              type="button"
              variant="contained"
              disabled={!selectedFloor || selectedFloor.isStaging || uploading}
              onClick={() => handleUpload('staging')}
              color="warning"
            >
              <AiOutlineCloudUpload size="26px" />
            </Button>
          </span>
        </Tooltip>

        <Tooltip title="Uploader le fichier en PROD">
          <span style={{ display: 'flex' }}>
            <Button
              sx={{ marginLeft: '10px' }}
              type="button"
              variant="contained"
              disabled={!selectedFloor || selectedFloor.isProd || uploading}
              onClick={() => handleUpload('prod')}
              color="error"
            >
              <AiOutlineCloudUpload size="26px" />
            </Button>
          </span>
        </Tooltip>
      </PrebidVersionWrapper>
    </Card>
  );
}

FloorSelection.propTypes = {
  selectedVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired,
  floorJson: PropTypes.shape({
    floorsSchemaVersion: PropTypes.number,
  }).isRequired,
};

export default FloorSelection;
