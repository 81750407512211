import { Checkbox } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSelectedGroup,
  fetchPartnerGroupsWithLastGTAG,
  removeSelectedGroup,
  selectGroupSelected,
  selectGroupTotal,
  selectGroups,
} from '../../../../store/GroupSlice';
import Table from '../../../UI/CompleteTable/Table';
import itemPartner from './ItemPartner/ItemPartner';

function Mep() {
  const partnerGroups = useSelector(selectGroups);
  const totalGroups = useSelector(selectGroupTotal);
  const selectedGroups = useSelector(selectGroupSelected);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);

  const fetchPartners = useCallback(() => {
    if (count === 0) {
      dispatch(fetchPartnerGroupsWithLastGTAG());
      setCount(count + 1);
    }
  }, [dispatch, count]);

  const checkGtagSelected = useMemo(() => {
    for (let index = 0; index < partnerGroups.length; index += 1) {
      const group = partnerGroups[index];
      if (!selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)) {
        return false;
      }
    }

    return true;
  }, [partnerGroups, selectedGroups]);

  const selectAllGroups = useCallback(() => {
    const groupsSelected = [];

    if (checkGtagSelected) {
      partnerGroups.forEach((groups) => {
        if (selectedGroups.some((group) => group.id === groups.id)) {
          groupsSelected.push(groups);
        }
      });

      dispatch(removeSelectedGroup(groupsSelected));
    } else {
      partnerGroups.forEach((groups) => {
        if (!selectedGroups.some((group) => group.id === groups.id)) {
          groupsSelected.push(groups);
        }
      });

      dispatch(addSelectedGroup(groupsSelected));
    }
  }, [checkGtagSelected, dispatch, partnerGroups, selectedGroups]);

  const columns = useMemo(
    () => [
      {
        id: '0',
        label: (
          <Checkbox
            style={{
              color: 'white',
            }}
            onClick={selectAllGroups}
            checked={checkGtagSelected}
          />
        ),
      },
      { id: '1', label: 'ID' },
      { id: '2', label: 'Nom du partenaire' },
      { id: '3', label: 'GoogleTag ID' },
      { id: '4', label: 'Version de Prebid' },
      { id: '5', label: 'Status du GoogleTag' },
      { id: '6', label: 'GoogleTag Author' },
    ],
    [checkGtagSelected, selectAllGroups]
  );

  return (
    <div>
      <Table
        total={totalGroups}
        getData={fetchPartners}
        itemsPerPage={totalGroups}
        data={partnerGroups}
        columns={columns}
        Line={itemPartner}
        heightScroll="90vh"
      />
    </div>
  );
}

export default Mep;
