import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { selectRtbPositionModifications } from '../../../../../../store/ModificationsSlice';
import { fetchPositions, selectAllPositions, selectAllPositionsStatus } from '../../../../../../store/PositionsSlice';
import PositionCard from './PositionCard/PositionCard';
import { PositionWrapper } from './PositionModificationPanel.style';
import {
  fetchExistingAddPosition,
  fetchRtb,
  selectExistingAddPositionsStatus,
  selectPositionRTBStatus,
  selectRtbPositions,
} from '../../../../../../store/PositionRTBSlice';

function PositionModificationPanel(props) {
  const { activeTab, index } = props;
  const dispatch = useDispatch();
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const { rtbs, existingAddPosition } = useSelector(selectRtbPositions);
  const positions = useSelector(selectAllPositions);
  const rtbPositionModifications = useSelector(selectRtbPositionModifications);
  const rtbPositionStatus = useSelector(selectPositionRTBStatus);
  const existingAddPositionStatus = useSelector(selectExistingAddPositionsStatus);
  const positionStatus = useSelector(selectAllPositionsStatus);

  const [triagePosition, setTriagePosition] = useState([]);

  const findPosition = useCallback(() => {
    if (rtbPositionModifications && positions) {
      const arrayPosition = [];

      positions.forEach((position) => {
        const include2 = rtbPositionModifications.some(
          (element) =>
            element.metadata.position === position.id &&
            element.operation === 'delete adPosition' &&
            element.operation !== 'modify adPosition'
        );

        const include = rtbPositionModifications.some(
          (element) => element.metadata.position === position.id && element.operation !== 'delete adPosition'
        );

        let alreadyInBase = false;
        if (existingAddPosition && 'adPositions' in existingAddPosition) {
          alreadyInBase = existingAddPosition.adPositions.some((pos) => pos.position.id === position.id);
        }

        if ((!alreadyInBase && !include) || (alreadyInBase && include2)) {
          arrayPosition.push(position);
        }
      });
      setTriagePosition(arrayPosition);
    }
  }, [existingAddPosition, positions, rtbPositionModifications]);

  useEffect(() => {
    if (activeTab !== 3) {
      return;
    }

    if (['idle', 'failed', ''].includes(rtbPositionStatus)) {
      dispatch(fetchRtb());
    }

    if (['idle', 'failed', ''].includes(existingAddPositionStatus)) {
      dispatch(fetchExistingAddPosition(googleTagVersion.partnerGroup.id));
    }

    if (['idle', 'failed', ''].includes(positionStatus)) {
      dispatch(fetchPositions());
    }
  }, [activeTab, dispatch, existingAddPositionStatus, googleTagVersion, positionStatus, rtbPositionStatus]);

  useEffect(() => {
    if (activeTab !== 3) {
      return;
    }

    findPosition();
  }, [activeTab, findPosition, rtbPositionModifications, positions]);

  const active = useMemo(() => activeTab === index, [activeTab, index]);

  return (
    <Card hidden={!active} height="100%">
      <PositionWrapper>
        {rtbs.map((rtb) => (
          <PositionCard rtb={rtb} positionsSelect={triagePosition} key={rtb.id} />
        ))}
      </PositionWrapper>
    </Card>
  );
}

PositionModificationPanel.propTypes = {
  activeTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default PositionModificationPanel;
