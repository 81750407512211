import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedGroup, removeSelectedGroup, selectGroupSelected } from '../../../../../store/GroupSlice';
import GoogleTagLink from '../../../../UI/Link/GoogleTagLink';
import { returnStatusValue } from '../../../../../helpers/returnStatusValue';
import { CustomTD } from '../../../../UI/CompleteTable/Table.style';

function ItemPartner(props) {
  const { item } = props;
  const selectedGroups = useSelector(selectGroupSelected);
  const dispatch = useDispatch();

  const checked = useMemo(() => selectedGroups.some((group) => group.id === item.id), [item.id, selectedGroups]);

  const selectGroupCheckbox = () => {
    if (checked) {
      dispatch(removeSelectedGroup(item));
    } else {
      dispatch(addSelectedGroup(item));
    }
  };

  const status = useMemo(() => returnStatusValue(item.googleTagStatus)?.key, [item.googleTagStatus]);

  return (
    <>
      <CustomTD>
        <Checkbox
          onClick={selectGroupCheckbox}
          checked={checked}
          style={{
            color: '#264653',
          }}
        />
      </CustomTD>
      <CustomTD title={item.id}>{item.id} </CustomTD>
      <CustomTD title={item.name}>{item.name}</CustomTD>
      <CustomTD>
        <GoogleTagLink id={item.googleTagId} releaseId={item.googleTagReleaseId} />
      </CustomTD>
      <CustomTD title={item.prebidVersion ?? '--'}>{item.prebidVersion ?? '--'} </CustomTD>
      <CustomTD title={status}>{status} </CustomTD>
      <CustomTD title={item.googleTagAuthor !== '' ? item.googleTagAuthor : '--'}>
        {item.googleTagAuthor !== '' ? item.googleTagAuthor : '--'}
      </CustomTD>
    </>
  );
}

ItemPartner.propTypes = {
  item: PropTypes.objectOf({
    id: PropTypes.number,
    name: PropTypes.string,
    googleTagId: PropTypes.number,
    googleTagReleaseId: PropTypes.string,
    googleTagAuthor: PropTypes.string,
    googletagStatus: PropTypes.string,
    prebidVersion: PropTypes.string,
  }).isRequired,
};

export default ItemPartner;
