import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postVersionBlock } from '../../../../helpers/api/blocks';
import Button from '../../../UI/Basic/Buttons/Button';
import { selectCodeTabs, selectSelectedTab } from '../CodeSlice';
import { fetchVersions } from '../VersionSlice';
import { TextAreaDescription } from './Popover.style';
import PopoverTemplate from './PopoverTemplate';

function ExportVersionPopover(props) {
  const { anchorEl, setAnchorEl } = props;
  const dispatch = useDispatch();

  const [description, setDescription] = useState('');

  const selectedTab = useSelector(selectSelectedTab);
  const codeTab = useSelector(selectCodeTabs);
  const { enqueueSnackbar } = useSnackbar();

  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleCloseExport = () => {
    setAnchorEl(null);
  };

  const handleExport = () => {
    const goodTab = codeTab[selectedTab - 1];
    const data = {
      block: `/api/blocks/${goodTab.idBlock}`,
      code: goodTab.code,
      version: '1',
      modifications: description,
    };

    postVersionBlock(data)
      .then(() => {
        enqueueSnackbar('Votre version a bien été enregistrée', { variant: 'success' });
        setDescription('');
        dispatch(fetchVersions(goodTab.idBlock));
        handleCloseExport();
      })
      .catch((e) => {
        enqueueSnackbar(e, { variant: 'error' });
      });
  };

  return (
    <PopoverTemplate
      setAnchorEl={setAnchorEl}
      anchorEl={anchorEl}
      title="Exporter une version"
      vertical="bottom"
      horizontal="left"
    >
      <TextAreaDescription rows="3" value={description} onChange={onChangeDescription} />
      <Button onClick={handleExport}>Export la version</Button>
    </PopoverTemplate>
  );
}

ExportVersionPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setAnchorEl: PropTypes.func.isRequired,
};

ExportVersionPopover.defaultProps = {
  anchorEl: 'button',
};

export default ExportVersionPopover;
