import { NoNotificationsContainer } from './NoNotifications.style';

function NoNotifications() {
  return (
    <NoNotificationsContainer>
      <p>Aucunes Notifications</p>
    </NoNotificationsContainer>
  );
}

export default NoNotifications;
