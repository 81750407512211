import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBlock } from '../../GoogleTag/CodeGeneration/BlocksSlice';

function VersionBlockDetail(props) {
  const { modification } = props;
  const { metadata } = modification;

  const blockSelector = useMemo(selectBlock, []);
  const block = useSelector((state) => blockSelector(state, metadata.idBlock));

  const blockName = <strong>&quot;{block?.name ?? metadata.idBlock}&quot;</strong>;
  const oldIdVersion = <strong>{metadata.oldIdVersion}</strong>;
  const idVersion = <strong>{metadata.idVersion}</strong>;

  return (
    <div>
      La version du bloc {blockName} a changé (de l&apos;ID {oldIdVersion} à {idVersion}).
    </div>
  );
}

VersionBlockDetail.propTypes = {
  modification: PropTypes.shape({
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      idBlock: PropTypes.number,
      oldIdVersion: PropTypes.number,
      idVersion: PropTypes.number,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default VersionBlockDetail;
