import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { BlockSelectedColumnWrapper, TitleColumn } from './BlockSelectedColumn.style';
import { fetchSelectedBlock, selectBlocks } from '../../BlocksSlice';
import Block from '../../BlockUI/Block';
import { fetchCode } from '../../CodeSlice';
import { selectGoogleTagVersion } from '../../../../../store/GoogleTagVersionSlice';
import { selectBlockModifications } from '../../../../../store/ModificationsSlice';

function BlockSelectedColumn() {
  const dispatch = useDispatch();
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const modifications = useSelector(selectBlockModifications);

  useEffect(() => {
    dispatch(fetchSelectedBlock({ id: googleTagVersion.id, modifications }));
  }, [dispatch, googleTagVersion.id, modifications]);

  const { blocksSelected } = useSelector(selectBlocks);

  useEffect(() => {
    dispatch(fetchCode({ id: googleTagVersion.id, modifications }));
  }, [modifications, dispatch, googleTagVersion.id]);

  return (
    <BlockSelectedColumnWrapper>
      <TitleColumn>Blocs déjà séléctionnés :</TitleColumn>
      <hr />
      <Droppable droppableId="block-selected" type="droppableItem">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{ height: '100%' }}>
            {blocksSelected.length > 0 &&
              blocksSelected.map((block) => <Block block={block} key={block.id} variable display />)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </BlockSelectedColumnWrapper>
  );
}

export default BlockSelectedColumn;
