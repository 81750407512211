import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

const CommentsContainer = styled.div`
  overflow: auto;
  height: 400px;
  padding: 15px;
  display: flex;
  flex-direction: column-reverse;
`;

const ButtonSubmitComment = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormComment = styled.form`
  display: flex;
  justify-content: space-between;
`;

export { CommentsContainer, ButtonSubmitComment, FormComment };
