import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Heading from '../../UI/Title/Heading';
import { selectPartnerInfos } from '../PartnerPageSlice';
import ErrorTable from './ErrorTable/ErrorTable';
import { ErrorsWrapper } from './Errors.style';

function Errors() {
  const partnerInfo = useSelector(selectPartnerInfos);

  const errors = useMemo(() => {
    if (partnerInfo.gtagData) {
      return partnerInfo.gtagData.errors;
    }
    return [];
  }, [partnerInfo]);

  const typeErrors = useMemo(() => ['ejectedPbjs', 'ejectedAmazon', 'missingPbjs', 'mediatypeErrors'], []);

  return (
    <div>
      <Heading variant="h2">Erreurs :</Heading>
      <ErrorsWrapper>
        {errors.hasError ? (
          typeErrors.map((type) => <ErrorTable key={type} type={type} />)
        ) : (
          <p>Aucunes erreurs détectées.</p>
        )}
        {errors.missingSublime && <b>Erreur Sublime dans le GoogleTag</b>}
      </ErrorsWrapper>
    </div>
  );
}

export default Errors;
