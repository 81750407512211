import PropTypes from 'prop-types';

const DraftModificationType = PropTypes.shape({
  id: PropTypes.number,
  operation: PropTypes.string,
  metadata: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
      PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ])
  ),
  createdAt: PropTypes.string,
  googleTagDraft: PropTypes.string,
  googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  targetGoogleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
});

export default DraftModificationType;
