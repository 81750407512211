import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import {
  Button,
  Card,
  Collapse,
  Divider,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
} from '@mui/material';
import { MdEmojiObjects, MdExpandLess, MdExpandMore, MdOutlineAdd } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';
import { useState } from 'react';
import ModelVersion from './components/ModelVersion';
import FloorCore from './components/FloorCore';
import DefaultFloor from './components/DefaultFloor';
import SkipRate from './components/SkipRate';
import Currency from './components/Currency';
import ModelWeight from './components/ModelWeight';
import { ModelGroupWrapper } from './Floor.style';

function FloorVersion2(props) {
  const { hidden, control, errors, setValue } = props;

  const [open, setOpen] = useState({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: `modelGroups`,
    rules: {
      validate: {
        required: (value) => {
          if (!hidden && !value?.length) {
            return 'Au moins une valeur est requise';
          }

          return true;
        },
      },
    },
  });

  const handleClick = (target) => {
    const isOpen = !open?.[target.id];
    setOpen({ [target.id]: isOpen });
  };

  return (
    <List
      sx={{ width: '100%' }}
      component="ul"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <span>Listes des ModelGroups</span>
          &nbsp;
          <Tooltip title="Ajouter un ModelGroup">
            <Button type="button" onClick={() => append({})} variant="contained">
              <MdOutlineAdd />
            </Button>
          </Tooltip>
        </ListSubheader>
      }
    >
      {errors?.modelGroups && <FormHelperText error>{errors?.modelGroups?.root?.message}</FormHelperText>}
      {fields.map((item, index) => (
        <Card key={item.id}>
          <ListItem>
            <ListItemButton onClick={() => handleClick(item)}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <MdEmojiObjects />
              </ListItemIcon>
              <ListItemText primary="ModelGroup" />
              {errors?.modelGroups?.[index] && (
                <FormHelperText error>Au moins une erreur nécessite votre attention</FormHelperText>
              )}
              {open?.[item.id] ? <MdExpandLess /> : <MdExpandMore />}
            </ListItemButton>
            <ListItemSecondaryAction>
              <IconButton onClick={() => remove(index)}>
                <TbTrash color="red" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <Collapse in={open?.[item.id]} timeout="auto">
            <ModelGroupWrapper>
              <ModelVersion control={control} registerTarget={`modelGroups.${index}.modelVersion`} />
              <Currency control={control} registerTarget={`modelGroups.${index}.currency`} />
              <SkipRate control={control} registerTarget={`modelGroups.${index}.skipRate`} errors={errors} />
              <ModelWeight control={control} registerTarget={`modelGroups.${index}.modelWeight`} />
              <FloorCore
                hidden={hidden}
                registerTarget={`modelGroups.${index}.`}
                control={control}
                errors={errors}
                setValue={setValue}
              />
              <DefaultFloor
                control={control}
                hidden={hidden}
                registerTarget={`modelGroups.${index}.default`}
                errors={errors}
              />
            </ModelGroupWrapper>
          </Collapse>
        </Card>
      ))}
    </List>
  );
}

FloorVersion2.propTypes = {
  setValue: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any.isRequired,
};

export default FloorVersion2;
