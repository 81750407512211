import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

const DraftItemWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isActive ? '0.5fr 0.5fr 0.7fr 1fr 0.1fr' : '0.5fr 0.5fr 1fr 0.7fr 0.1fr')};
  align-items: center;
  padding: 20px;
  background-color: ${(props) => props.isActive && 'rgba(229,83,83,0.47)'};
  border-bottom: 1px solid grey;
`;

const Wrapper = styled.div`
  width: 95%;
`;

const IdIcon = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AccessDraft = styled(Link)`
  margin-right: 15px;
`;

export { DraftItemWrapper, Wrapper, IdIcon, GridItem, AccessDraft };
