import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedTabBiddersNode } from '../TabBiddersModificationsSlice';

const useSelectedTabBidderNode = (metadata) => {
  const selectedTabBiddersNode = useSelector(selectSelectedTabBiddersNode);
  const active = useMemo(() => {
    if (!selectedTabBiddersNode?.metadata) return false;
    const { metadata: tabBidderMetadata } = selectedTabBiddersNode;

    return !Object.entries(metadata).some(([key, value]) => tabBidderMetadata?.[key] !== value);
  }, [selectedTabBiddersNode, metadata]);

  return active;
};

export default useSelectedTabBidderNode;
