/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Card } from '@mui/material';

const ButtonWrapper = styled.div`
  display: flex;
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: column;
    `}

  ${({ direction }) =>
    direction !== 'column' &&
    css`
      & > button:not(:first-of-type) {
        margin-left: 10px;
      }
    `}
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

const CartWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const SelectionWrapper = styled.div`
  width: 20%;
`;

export { ButtonWrapper, LoaderWrapper, CartWrapper, SelectionWrapper, ModalButtonWrapper };
