import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDevices } from '../../../store/DevicesSlice';
import { selectPagetypes } from '../../../store/PagetypesSlice';
import { selectPositions } from '../../../store/PositionsSlice';
import { selectSize } from '../../../store/SizesSlice';

function SizeDetail(props) {
  const { modification } = props;
  const { operation, metadata } = modification;

  const pagetypeSelector = useMemo(selectPagetypes, []);
  const pagetypeIds = Array.isArray(metadata.pagetype) ? metadata.pagetype : [metadata.pagetype];
  const pagetypes = useSelector((state) => pagetypeSelector(state, pagetypeIds));
  const deviceSelector = useMemo(selectDevices, []);
  const deviceIds = Array.isArray(metadata.device) ? metadata.device : [metadata.device];
  const devices = useSelector((state) => deviceSelector(state, deviceIds));
  const positionsSelector = useMemo(selectPositions, []);
  const positionIds = Array.isArray(metadata.position) ? metadata.position : [metadata.position];
  const positions = useSelector((state) => positionsSelector(state, positionIds));

  const sizeSelector = useMemo(selectSize, []);
  let ids;
  if (Array.isArray(metadata.sizeId)) {
    ids = [...metadata.sizeId];
  } else {
    ids = [metadata.sizeId];
  }
  const sizes = useSelector((state) => sizeSelector(state, ids));

  const headers = useMemo(() => {
    if (metadata?.headersToggled.length === 0 || metadata?.headersToggled.length === 3) {
      return [' sur tous les objets ', <strong key="objects">(GAM, Amazon et Pbjs)</strong>];
    }

    return [' sur les objets ', <strong key="objects">({metadata.headersToggled.join(', ')})</strong>];
  }, [metadata.headersToggled]);

  const pagetypeLabel = pagetypeIds.map((id) => {
    const found = pagetypes.find((p) => p.id === id);
    return found ? found.genericName : id;
  });

  const deviceLabel = deviceIds.map((id) => {
    const found = devices.find((p) => p.id === id);
    return found ? found.genericName : id;
  });

  const positionLabel = positionIds.map((id) => {
    const found = positions.find((p) => p.id === id);
    return found ? found.genericName : id;
  });

  return (
    <div>
      Les tailles <strong>({sizes.map((e) => e.concatenatedSize).join(', ')})</strong> ont été{' '}
      {operation === 'addSize' ? 'ajoutées' : 'supprimées'} sur
      {metadata.pagetype && [' les pagetypes ', <strong key="pagetypes">({pagetypeLabel.join(', ')})</strong>]}
      {metadata.device && [' les devices ', <strong key="devices">({deviceLabel.join(', ')})</strong>]}
      {metadata.position && [' les positions ', <strong key="positions">({positionLabel.join(', ')})</strong>]}
      {headers}
    </div>
  );
}

SizeDetail.propTypes = {
  modification: PropTypes.shape({
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      sizeId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
      pagetype: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
      device: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      position: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
      headersToggled: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
  }).isRequired,
};

export default SizeDetail;
