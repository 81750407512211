import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNotification } from '../NotificationSlice';
import GoogleTagLink from '../../UI/Link/GoogleTagLink';
import { selectUser } from '../../../store/UsersSlice';
import NotificationTemplate from '../NotificationTemplate/NotificationTemplate';

function MepNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const metadata = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  const userSelector = useMemo(selectUser, []);
  const userMep = useSelector((state) => userSelector(state, metadata.user));

  const action = useMemo(() => (metadata.prod ? 'PROD' : 'QUAL'), [metadata]);

  const message = useMemo(() => {
    if (metadata.numberSuccessMep === metadata.numberMep) {
      return 'Tous les GoogleTags';
    }

    if (metadata.numberSuccessMep === 0) {
      return 'Aucun des GoogleTags';
    }

    return 'Certains des GoogleTags';
  }, [metadata]);

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>
        {message} (
        {metadata.googleTagVersions.map((googleTag, index) => {
          return (
            <Fragment key={googleTag}>
              {index ? ', ' : ''}
              <GoogleTagLink id={googleTag} />
            </Fragment>
          );
        })}
        ) ont été mis en <b>{action}</b> par&nbsp;
        {userMep ? (
          <b>
            {userMep.firstname} {userMep.lastname}
          </b>
        ) : (
          <b>{metadata.user}</b>
        )}
        <br />
        <b>
          {metadata.numberSuccessMep}/{metadata.numberMep} des MEP
        </b>{' '}
        ont été <b>réussies</b>
      </p>
    </NotificationTemplate>
  );
}

MepNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default MepNotification;
