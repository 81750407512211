import { Button, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { snackActions } from '../../../../helpers/SnackBarUtils';
import { selectMediatype, updateMediaType } from '../../../../store/MediatypesSlice';
import { ButtonWrapper, FormStyled } from './ManagePlacement.style';

function PlacementDetail(props) {
  const { mediatypeId } = props;

  const dispatch = useDispatch();

  const mediatypeSelector = useMemo(selectMediatype, []);
  const mediatype = useSelector((state) => mediatypeSelector(state, mediatypeId));

  const genericName = useMemo(() => {
    const name = mediatype?.genericName;

    if (!name) return '';

    return name.charAt(0).toUpperCase() + name.slice(1);
  }, [mediatype?.genericName]);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      templateXandrId: mediatype?.templateXandrId ?? '',
    },
  });

  const onSubmit = async (data, id) => {
    await dispatch(updateMediaType({ body: data, id }));
    snackActions.success(`Mediatype ${genericName} mis à jour`);
  };

  return (
    <FormStyled onSubmit={handleSubmit((data) => onSubmit(data, mediatype.id))}>
      <Tooltip title="Veuillez renseigner l'id du template Xandr">
        <TextField
          type="number"
          {...register('templateXandrId', { valueAsNumber: true })}
          placeholder="Id du template du Placement"
          label={genericName}
          defaultValue={mediatype?.templateXandrId}
        />
      </Tooltip>
      <ButtonWrapper>
        <Button sx={{ margin: '0 auto' }} type="submit" variant="contained">
          Modifier
        </Button>
      </ButtonWrapper>
    </FormStyled>
  );
}

PlacementDetail.propTypes = {
  mediatypeId: PropTypes.number.isRequired,
};

export default PlacementDetail;
