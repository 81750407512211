import { useMemo } from 'react';
import { v4 } from 'uuid';
import GoogleTagVersion from '../../../../types/GoogleTagVersionType';
import Heading from '../../../UI/Title/Heading';
import Card from '../../../UI/Wrapper/Card';
import { Description, DescriptionWrapper, PartnerDescription } from './GeneralDescription.style';
import NavigationCard from './NavigationCard';

function GeneralDescription(props) {
  const { gtag } = props;

  const infos = useMemo(() => {
    let string = `Dernière MAJ : ${gtag?.updatedAt ? gtag?.updatedAt.slice(0, 10) : gtag.createdAt.slice(0, 10)}`;
    if (gtag.inProdAt) {
      string += ` | Date de mise en PROD : ${new Date(gtag.inProdAt).toLocaleString()}`;
    } else if (gtag.inQualAt) {
      string += ` | Date de mise en QUAL: ${new Date(gtag.inQualAt).toLocaleString()}`;
    }

    return string;
  }, [gtag]);

  const formattedText = useMemo(() => {
    const lines = gtag.description.split('\n');
    if (lines.length > 1) {
      const listItems = lines.map((line) => <li key={v4}>{line}</li>);
      return <ul>{listItems}</ul>;
    }
    return gtag.description;
  }, [gtag.description]);

  const config = useMemo(() => {
    if (gtag.partnerGroup.partnerWebsites.length > 1) {
      return {
        width: ['50%', '50%'],
        title: `Groupe concerné (${gtag.partnerGroup.name})`,
        multiple: true,
      };
    }

    return {
      width: ['80%', '20%'],
      title: 'Partenaire concerné',
      multiple: false,
    };
  }, [gtag.partnerGroup]);

  return (
    <DescriptionWrapper>
      <Card sx={{ width: config.width[0], display: 'flex', flexDirection: 'column' }}>
        <Heading variant="h2">Description Principale (Auteur: {gtag.author?.trigram ?? 'N/A'})</Heading>
        <span>{infos}</span>
        <Description>{formattedText}</Description>
      </Card>
      <Card sx={{ width: config.width[1], display: 'flex', flexDirection: 'column' }}>
        <Heading variant="h2">{config.title}</Heading>
        <PartnerDescription multiple={config.multiple}>
          {gtag.partnerGroup.partnerWebsites.map((partnerWebsite) => (
            <NavigationCard
              key={partnerWebsite.id}
              label={partnerWebsite.displayName}
              to={`/show-partner/${partnerWebsite.id}`}
              paddingText="10px"
            />
          ))}
        </PartnerDescription>
      </Card>
    </DescriptionWrapper>
  );
}

GeneralDescription.propTypes = {
  gtag: GoogleTagVersion.isRequired,
};

export default GeneralDescription;
