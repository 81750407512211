import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import nodeType from '../../../../../../types/nodeType';
import { nodeModificationsSelector } from '../../../../../../store/ModificationsSlice';
import { selectSizes } from '../../../../../../store/SizesSlice';
import CellGrid from './CellGrid';
import SizeObject from './SizeObject';

function FinalCell(props) {
  const { element } = props;

  const memo = useMemo(() => element, [element]);
  const [localModifications, setLocalModifications] = useState('[]');

  const selectNodeModifications = useMemo(nodeModificationsSelector, []);
  const modifications = useSelector((state) => selectNodeModifications(state, memo));
  const sizes = useSelector(selectSizes);

  useEffect(() => {
    const strModification = JSON.stringify(modifications);

    if (localModifications !== strModification) {
      setLocalModifications(strModification);
    }
  }, [localModifications, modifications]);

  const sizesNodes = useMemo(() => {
    const { currentSizes } = element;
    let newSizes = [...new Set(currentSizes)];
    const localModificationsParsed = JSON.parse(localModifications);

    // On applique les modifications de l'utilisateur
    localModificationsParsed.forEach((modification) => {
      const index = newSizes.indexOf(modification.metadata.sizeId);
      if (modification.operation === 'addSize' && index === -1) {
        newSizes.push(modification.metadata.sizeId);
      } else if (modification.operation === 'removeSize' && index !== -1) {
        newSizes.splice(index, 1);
      }
    });

    newSizes = [...new Set(newSizes)];
    newSizes.sort();

    const { pagetypeId, deviceId, positionId, source } = element;
    const key = source + pagetypeId + deviceId + positionId;

    return newSizes.map((currentSizeId) => {
      const currentSize = sizes.find((size) => size.id === currentSizeId);

      if (!currentSize) {
        return null;
      }

      return (
        <SizeObject
          key={key + currentSize.uuid}
          size={currentSize}
          pagetype={pagetypeId}
          device={deviceId}
          position={positionId}
          source={source}
        />
      );
    });
  }, [localModifications, element, sizes]);

  const { pagetypeId, deviceId, positionId, source } = element;

  return (
    <CellGrid className={source} pagetype={pagetypeId} device={deviceId} position={positionId}>
      {sizesNodes}
    </CellGrid>
  );
}

FinalCell.propTypes = {
  element: nodeType.isRequired,
};

FinalCell.defaultProps = {};

export default FinalCell;
