import MepNotification from '../components/Notifications/DetailNotifications/MepNotification';
import DraftNotification from '../components/Notifications/DetailNotifications/DraftNotification';
import AppNexusNotification from '../components/Notifications/DetailNotifications/AppNexusNotification';
import PositionNotification from '../components/Notifications/DetailNotifications/PositionNotification';
import BlockNotification from '../components/Notifications/DetailNotifications/BlockNotification';
import CSVNotification from '../components/Notifications/DetailNotifications/CSVNotification';
import PrebidNotification from '../components/Notifications/DetailNotifications/PrebidNotification';
import DefaultNotification from '../components/Notifications/DetailNotifications/DefaultNotification';
import ImportNotification from '../components/Notifications/DetailNotifications/Import/ImportNotification';

export function getNotificationTypeName(type) {
  let splittedType;
  let nameType;

  switch (type) {
    case 'TYPE_MEP':
      return 'Notifications de mise en PROD/QUAL';
    case 'TYPE_DRAFT':
      return 'Notifications de draft';
    case 'TYPE_GAM':
      return 'Notifications provenant de Xandr';
    case 'TYPE_POSITION':
      return 'Notifications de création de position';
    case 'TYPE_BLOC':
      return 'Notifications de création de bloc';
    case 'TYPE_CSV':
      return "Notifications provenant d'un import CSV";
    case 'TYPE_PREBID':
      return 'Notifications des versions de Prebid';
    default:
      splittedType = type.split('_');
      nameType = splittedType[splittedType.length - 1];
      return `Notifications de type ${nameType}`;
  }
}

export function getNotificationComponent(type) {
  switch (type) {
    case 0:
      return MepNotification;
    case 1:
      return DraftNotification;
    case 2:
      return AppNexusNotification;
    case 3:
      return PositionNotification;
    case 4:
      return BlockNotification;
    case 5:
      return CSVNotification;
    case 6:
      return PrebidNotification;
    case 7:
      return ImportNotification;
    default:
      return DefaultNotification;
  }
}
