import { css } from '@emotion/react/macro';

const CustomTabs = css`
  margin-left: 2em;
`;

const CustomTab = css`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export { CustomTabs, CustomTab };
