import PropTypes from 'prop-types';
import { createElement, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import GoogleTagStatus from '../../../constants/GoogleTagStatus';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import { settingModificationSelector, variableModification } from '../../../store/ModificationsSlice';
import ArrayVariable from './Types/ArrayVariable';
import BooleanVariable from './Types/BooleanVariable';
import FunctionVariable from './Types/FunctionVariable';
import NumberVariable from './Types/NumberVariable';
import StringVariable from './Types/StringVariable';
import VariableHeader from './Types/VariableHeader';
import WildcardVariable from './Types/WildcardVariable';
import { VariableCardWrapper, VariableForm } from './Variable.style';
import { selectDefaultLocalVariable, selectDefaultVariable } from './variableSettingsSlice';

function Variable(props) {
  const { variableId, type } = props;

  const dispatch = useDispatch();

  const [timer, setTimer] = useState(null);

  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const defaultVariableSelector = useMemo(selectDefaultVariable, []);
  const defaultVariable = useSelector((state) => defaultVariableSelector(state, variableId));

  const defaultLocalVariableSelector = useMemo(selectDefaultLocalVariable, []);
  const defaultLocalVariable = useSelector((state) => defaultLocalVariableSelector(state, defaultVariable['@id']));

  const selectSettingModification = useMemo(settingModificationSelector, []);
  const modification = useSelector((state) => selectSettingModification(state, defaultVariable?.name));

  const [state] = useState(() => {
    let VariableComponent = null;
    let transformInput = null;
    let transformOutput = null;

    switch (type) {
      case 'array':
        VariableComponent = ArrayVariable;
        transformInput = (val) => {
          if (!val) return [];

          let parsedValues;
          try {
            parsedValues = JSON.parse(val);
          } catch (error) {
            parsedValues = val
              .replace(/^\[(.*)\]$/, '$1')
              .replace(/',\s/g, "',")
              .split(',')
              .map((e) => e.slice(1, -1));
          }

          return parsedValues.map((parsedValue) => ({ value: parsedValue }));
        };
        transformOutput = (values) => JSON.stringify(values.map((value) => value.value));
        break;
      case 'bool':
        transformOutput = (value) => {
          if (typeof value === 'string') {
            return value;
          }

          return value ? 'true' : 'false';
        };
        VariableComponent = BooleanVariable;
        break;
      case 'number':
        VariableComponent = NumberVariable;
        break;
      case 'string':
        VariableComponent = StringVariable;
        break;
      case 'wildcard':
        VariableComponent = WildcardVariable;
        break;
      case 'function':
        VariableComponent = FunctionVariable;
        break;
      default:
        VariableComponent = WildcardVariable;
        break;
    }

    const defaultValue = defaultLocalVariable?.value ?? defaultVariable?.defaultValue ?? '';

    const defaultValues = {
      value: (transformInput && transformInput(defaultValue)) ?? defaultValue,
      active: defaultLocalVariable?.active ?? true,
    };

    const values = { ...defaultValues };
    if (modification) {
      const { value, active } = modification.metadata;

      values.value = (transformInput && transformInput(value)) ?? value;
      values.active = active;
    }

    return { VariableComponent, transformInput, transformOutput, defaultValues, values };
  });

  const methods = useForm({ defaultValues: state.defaultValues, values: state.values });

  const hasChanged = useCallback(() => {
    const { getValues } = methods;

    const { active, value } = getValues();

    const calculatedValues = { ...state.defaultValues };

    if (modification) {
      calculatedValues.value =
        (state.transformInput && state.transformInput(modification.metadata.value)) ?? modification.metadata.value;
      calculatedValues.active = modification.metadata.active;
    }

    if (
      active === calculatedValues.active &&
      (typeof value === 'object'
        ? JSON.stringify(value) === JSON.stringify(calculatedValues.value)
        : value === calculatedValues.value)
    ) {
      return false;
    }

    return calculatedValues;
  }, [methods, modification, state]);

  useEffect(() => {
    const { reset } = methods;
    const calculatedValues = hasChanged();

    if (calculatedValues) {
      reset({ ...calculatedValues }, { keepDefaultValues: true, keepDirtyValues: false });
    }
  }, [methods, hasChanged]);

  const onSubmit = useCallback(
    (data) => {
      const calculatedValues = hasChanged();

      if (!calculatedValues) {
        return;
      }

      if (timer) {
        clearInterval(timer);
      }

      setTimer(
        window.setTimeout(() => {
          const body = { ...data };

          if (state.transformOutput) {
            body.value = state.transformOutput(body.value);
          }

          dispatch(
            variableModification({
              variableName: defaultVariable.name,
              ...body,
              googleTagVersions: [googleTagVersion['@id']],
            })
          );
        }, 1000)
      );
    },
    [hasChanged, timer, state, dispatch, defaultVariable.name, googleTagVersion]
  );

  // Permet de valider automatiquement le formulaire
  useEffect(() => {
    const { watch, handleSubmit } = methods;

    const subscription = watch(handleSubmit((data) => onSubmit(data)));
    return () => subscription.unsubscribe();
  }, [methods, onSubmit]);

  return (
    <VariableCardWrapper active={googleTagVersion.status === GoogleTagStatus.MODIFYING}>
      <FormProvider {...methods}>
        <VariableForm>
          <VariableHeader variableId={defaultVariable.id} />
          {createElement(state.VariableComponent)}
        </VariableForm>
      </FormProvider>
    </VariableCardWrapper>
  );
}

Variable.propTypes = {
  variableId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default Variable;
