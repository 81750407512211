/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBidderPlacementKeywordLibs } from '../helpers/api/bidderPlacementKeywordLib';

const DOMAIN = 'bidderPlacementKeywordLibs';

// Async actions
export const fetchBidderPlacementKeywordLibs = createAsyncThunk(
  `${DOMAIN}/fetchByBidder`,
  async (bidderId, thunkAPI) => {
    try {
      return getBidderPlacementKeywordLibs(bidderId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const bidderPlacementKeywordLibsSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBidderPlacementKeywordLibs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBidderPlacementKeywordLibs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload['hydra:member'];
      })
      .addCase(fetchBidderPlacementKeywordLibs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions

// Selectors
export const selectBidderPlacementKeywordLibs = (state) => state[DOMAIN].data;
export const selectBidderPlacementKeywordLibsStatus = (state) => state[DOMAIN].status;

export default bidderPlacementKeywordLibsSlice.reducer;
