import { API_BASE_URL } from '../references/base-urls';
import { connectionActions } from './ConnectionUtils';
import { snackActions } from './SnackBarUtils';
import { verifyTokenExist } from './storage';
import { jwtDecode } from './jwtHelper';

let refreshPromise = null;
let isInRefresh = false;

export const getRequestObjectToSend = (method, data) => {
  return {
    method,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/ld+json',
      Accept: 'application/ld+json',
    },
  };
};

export const getRequestObjectToFileSending = (method, data) => {
  return {
    method,
    body: data,
  };
};

const refreshToken = () => {
  const refresh = localStorage.getItem('refresh');
  if (!isInRefresh) {
    refreshPromise = new Promise((resolve) => {
      isInRefresh = true;
      if (verifyTokenExist()) {
        const dataRefresh = { refresh_token: refresh };

        fetch(`${API_BASE_URL}/token/refresh`, {
          method: 'POST',
          body: JSON.stringify(dataRefresh),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
          .then((refreshResponse) => refreshResponse.json())
          .then((refreshData) => {
            if (refreshData.code === 401) {
              throw new Error('Session expirée.');
            }

            localStorage.setItem('token', refreshData.token);
            localStorage.setItem('expiresAt', jwtDecode(refreshData.token).exp);
            localStorage.setItem('refresh', refreshData.refresh_token);
            resolve(refreshData.refresh_token);
          })
          .catch((e) => {
            connectionActions.signout();
            refreshPromise = null;
            snackActions.error(e.message);
          })
          .finally(() => {
            isInRefresh = false;
          });
      }
    });
  }
};

export default async function defaultFetch(url, method = 'GET', body = {}) {
  const expiration = localStorage.getItem('expiresAt');
  if (expiration && Date.now() / 1000 >= expiration) {
    refreshToken();
  }

  return Promise.all([refreshPromise]).then(async () => {
    let data = {
      method,
    };

    if (body && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
      data =
        body instanceof FormData ? getRequestObjectToFileSending(method, body) : getRequestObjectToSend(method, body);
    }

    if (method === 'PATCH') {
      data.headers = {
        'Content-Type': 'application/merge-patch+json',
      };
    }

    if (
      url !== `${API_BASE_URL}/login_check` &&
      url !== `${API_BASE_URL}/token/refresh` &&
      !url.includes('/forgot_password') &&
      !url.includes('check_token')
    ) {
      const token = localStorage.getItem('token');

      if (!data.headers) {
        data.headers = {};
      }

      if (method === 'PATCH') {
        data.headers = {
          'Content-Type': 'application/merge-patch+json',
        };
      }

      data.headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, data);

    if (response.status === 401) {
      if (verifyTokenExist()) {
        refreshToken();
        return defaultFetch(url, method, body);
      }
    }

    if (response.status === 504) {
      throw new Error('Serveur timeout');
    }

    if (!response.ok) {
      const jsonResponse = await response.json();
      const env = process.env.NODE_ENV;

      throw new Error(
        (env === 'development' && jsonResponse['hydra:description']) ||
          (env === 'development' && jsonResponse.detail) ||
          jsonResponse['hydra:type'] ||
          'Something went wrong!'
      );
    }

    if (response.status === 204) {
      return 'Votre requête a bien été effectué';
    }

    return response;
  });
}
