import { IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { BsArrowBarLeft } from 'react-icons/bs';
import { GrHomeRounded } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { reset as resetNodes } from '../../../components/GoogleTag/Edit/MultiUnitModification/Tabs/SizesTab/NodesSlice';
import { reset as resetTabBidders } from '../../../components/GoogleTag/Edit/MultiUnitModification/Tabs/TabBiddersTab/TabBiddersModificationsSlice';
import StatusLabel from '../../../components/Reusable/Status/StatusLabel';
import Loader from '../../../components/UI/Animation/Loader';
import { BASE_URL, MERCURE_BASE_URL } from '../../../references/base-urls';
import {
  fetchGoogleTagVersion,
  selectGoogleTagVersion,
  setGoogleTagVersion,
} from '../../../store/GoogleTagVersionSlice';
import { fetchMediaTypes } from '../../../store/MediatypesSlice';
import { HeaderTitle, LoaderWrapper } from './Edit.style';

function EditGtag() {
  const dispatch = useDispatch();

  const { googleTagVersionId } = useParams();

  const homeUrl = useMemo(() => `/edit-google-tag/${googleTagVersionId}`, [googleTagVersionId]);
  const location = useLocation();

  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const [partnerUuid] = useState(null);

  useEffect(() => {
    dispatch(fetchGoogleTagVersion(googleTagVersionId));

    const url = new URL(MERCURE_BASE_URL);
    url.searchParams.append('topic', `${BASE_URL}/api/google_tag_versions/${googleTagVersionId}`);
    const sse = new EventSource(url);

    sse.onmessage = (event) => {
      const data = JSON.parse(event.data);

      dispatch(setGoogleTagVersion(data));
    };

    return () => {
      sse.close();
      dispatch(setGoogleTagVersion(null));
    };
  }, [dispatch, googleTagVersionId]);

  useEffect(() => {
    dispatch(fetchMediaTypes());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(resetNodes());
      dispatch(resetTabBidders());
    },
    [dispatch]
  );

  let title = '';
  if (googleTagVersion) {
    title = `MODIFICATION DU GTAG ${googleTagVersion.releaseId}`;
  }

  return googleTagVersion ? (
    <>
      <HeaderTitle>
        <Link to={homeUrl}>
          <IconButton sx={{ margin: '0 5px', color: 'black' }}>
            {homeUrl === location.pathname ? <GrHomeRounded size="1.5em" /> : <BsArrowBarLeft size="1.5em" />}
          </IconButton>
        </Link>
        {title}
        <StatusLabel status={googleTagVersion.status} padding="0.3em" />
      </HeaderTitle>
      <Outlet context={[partnerUuid]} />
    </>
  ) : (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );
}

export default EditGtag;
