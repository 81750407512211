import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import {
  fetchMandatoryModules,
  selectMandatoryModules,
  selectMandatoryModulesTotal,
} from '../../../../../../store/MandatoryModulesSlice';
import Table from '../../../../../UI/CompleteTable/Table';
import ModuleItem from './ModuleItem/ModuleItem';

function ModulesList() {
  const dispatch = useDispatch();
  const modules = useSelector(selectMandatoryModules);
  const total = useSelector(selectMandatoryModulesTotal);

  const fetchModules = useCallback(() => {
    dispatch(fetchMandatoryModules());
  }, [dispatch]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  const columns = useMemo(
    () => [
      { id: 'id', label: 'Numéro' },
      { id: 'name', label: 'Nom du modules' },
      { id: 'keyname', label: 'Keyname du modules' },
      { id: 'description', label: 'Description du modules' },
      { id: 'actions', label: 'Actions' },
    ],
    []
  );

  return (
    <div>
      <Table columns={columns} data={modules} total={total} Line={ModuleItem} getData={fetchModules} />
    </div>
  );
}

export default ModulesList;
