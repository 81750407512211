import { GiKiwiBird } from 'react-icons/gi';
import React from 'react';
import PropTypes from 'prop-types';
import { FcApproval, FcCancel } from 'react-icons/fc';
import {
  AnalysisContainer,
  AnalysisSentence,
  CsvAnalysisTable,
  CsvAnalysisTableHead,
  CsvAnalysisTableTd,
  CsvAnalysisTableTh,
  CsvAnalysisTableTr,
  ResumeContainer,
  ResumeSentence,
} from './CsvAnalysis.style';
import MassButton from '../../../../UI/Basic/Buttons/MassButton/MassButton';

const GROUP_TITLES = [
  'Groupe',
  'Site Partenaire',
  'Nb de lignes dans le csv',
  'Nb de Noeuds retrouvés',
  'GTAG de départ',
  'Erreurs',
];

function CsvAnalysis(props) {
  const { report, consumeFile } = props;

  return (
    <div>
      <h2>Analyse de votre CSV :</h2>
      <div>
        <p>
          <GiKiwiBird /> {report.mainData.nbOfNodes} Noeuds retrouvés / {report.mainData.nbOfLines} Lignes dans le csv (
          {report.mainData.nbOfLines - 1} + 1 avec le header)
        </p>
        <ResumeContainer className="flex">
          <AnalysisContainer>
            {report.analysis.map((analyse) => (
              <AnalysisSentence key={analyse.description} isValid={analyse.isValid}>
                {analyse.isValid ? <FcApproval /> : <FcCancel />} {analyse.description}
              </AnalysisSentence>
            ))}
          </AnalysisContainer>

          {report.isValid ? (
            <div>
              <ResumeSentence color="green">
                Votre fichier CSV est conforme, vous pouvez finaliser l&apos;import.
              </ResumeSentence>
              <MassButton onClick={consumeFile} width="300px">
                FINALISER
              </MassButton>
            </div>
          ) : (
            <ResumeSentence color="red">
              Votre CSV n&apos;est pas valide, veuillez corriger les erreurs présentes dans le tableaux ci-dessous.
            </ResumeSentence>
          )}
        </ResumeContainer>
      </div>
      <div>
        <h3>
          <u>Statistiques par sites ({report ? Object.keys(report.groups).length : ''})</u>
        </h3>
        <CsvAnalysisTable>
          <CsvAnalysisTableHead>
            <tr>
              {GROUP_TITLES.map((el) => (
                <CsvAnalysisTableTh key={el}>{el}</CsvAnalysisTableTh>
              ))}
            </tr>
          </CsvAnalysisTableHead>
          <tbody>
            {Object.keys(report.groups).flatMap((groupName) => {
              return Object.keys(report.groups[groupName]).map((partnerName) => {
                const content = report.groups[groupName][partnerName];

                return (
                  <CsvAnalysisTableTr key={groupName + partnerName} error={content.nbCsvLines > content.nbNodeFound}>
                    <CsvAnalysisTableTd>{groupName}</CsvAnalysisTableTd>
                    <CsvAnalysisTableTd>
                      {partnerName} ({content.partnerTrigram})
                    </CsvAnalysisTableTd>
                    <CsvAnalysisTableTd>{content.nbCsvLines}</CsvAnalysisTableTd>
                    <CsvAnalysisTableTd>{content.nbNodeFound}</CsvAnalysisTableTd>
                    <CsvAnalysisTableTd>{content.gtagToCopy}</CsvAnalysisTableTd>
                    <CsvAnalysisTableTd>
                      {content.errors.map((error) => (
                        <p key={error}>{error}</p>
                      ))}
                    </CsvAnalysisTableTd>
                  </CsvAnalysisTableTr>
                );
              });
            })}
          </tbody>
        </CsvAnalysisTable>
        {report?.unOrderedErrors.length > 0 ? (
          <>
            <h3>Autres erreurs</h3>
            <ul>
              {report?.unOrderedErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </>
        ) : (
          <h3>Aucunes erreurs</h3>
        )}
      </div>
    </div>
  );
}

const PartnerShape = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  googleTagReleaseId: PropTypes.string.isRequired,
  gtagToCopy: PropTypes.string.isRequired,
  nbCsvLines: PropTypes.number.isRequired,
  nbNodeFound: PropTypes.number.isRequired,
  partnerTrigram: PropTypes.string.isRequired,
};

CsvAnalysis.propTypes = {
  consumeFile: PropTypes.func.isRequired,
  report: PropTypes.shape({
    analysis: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        isValid: PropTypes.bool.isRequired,
      }).isRequired
    ).isRequired,
    groups: PropTypes.shape({
      object: PropTypes.shape(PartnerShape),
    }).isRequired,
    isValid: PropTypes.bool.isRequired,
    mainData: PropTypes.shape({
      nbOfLines: PropTypes.number.isRequired,
      nbOfNodes: PropTypes.number.isRequired,
      nbOfPartners: PropTypes.number.isRequired,
      nbOfPartnersInGroup: PropTypes.number.isRequired,
    }).isRequired,
    unOrderedErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default CsvAnalysis;
