/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getNodeLibrariesByGtag } from '../../../../../../helpers/api/nodeLibraries';

const domain = 'nodeLibraries';

// Async actions
export const fetchNodeLibraries = createAsyncThunk(`${domain}/fetchNodes`, async (id, thunkAPI) => {
  try {
    return getNodeLibrariesByGtag(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  nodes: [],
  headersToggled: [],
  status: 'idle',
  error: null,
};

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    reset: () => initialState,
    setHeadersToggled: (state, action) => {
      state.headersToggled = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNodeLibraries.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNodeLibraries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nodes = action.payload['hydra:member'];
      })
      .addCase(fetchNodeLibraries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Actions
export const { reset, setHeadersToggled } = nodesSlice.actions;

// Selectors
const selectorCallback = (nodes, param, ifCallback, pushCallback, bypassSeen = false) => {
  const seen = [];
  const out = [];
  const { length } = nodes;

  for (let i = 0; i < length; i += 1) {
    const element = nodes[i];
    const hasBeenSeen = seen.includes(element[param]);

    if ((bypassSeen || !hasBeenSeen) && ifCallback(element)) {
      pushCallback(out, element, hasBeenSeen);
      seen.push(element[param]);
    }
  }

  return out;
};
const selectArgs = (state, args) => args;
export const selectNodes = (state) => state.nodes.nodes;
export const selectNodesStatus = (state) => state.nodes.status;
export const selectNodesPagetypes = createSelector([selectNodes], (nodes) => {
  const ifCallback = () => true;
  const pushCallback = (array, element) => {
    array.push({ pagetypeName: element.pagetype, pagetypeId: element.pagetypeId });
  };

  return selectorCallback(nodes, 'pagetypeId', ifCallback, pushCallback);
});
export const selectNodesDevices = () =>
  createSelector([selectNodes, selectArgs], (nodes, args) => {
    const ifCallback = (element) => element.pagetypeId === args.pagetypeId;
    const pushCallback = (array, element) => {
      array.push({ deviceName: element.device, deviceId: element.deviceId });
    };

    return selectorCallback(nodes, 'deviceId', ifCallback, pushCallback);
  });
export const selectNodesPositions = () =>
  createSelector([selectNodes, selectArgs], (nodes, args) => {
    const ifCallback = (element) => element.pagetypeId === args.pagetypeId && element.deviceId === args.deviceId;
    const pushCallback = (array, element, hasBeenSeen) => {
      if (!hasBeenSeen) {
        array.push({ positionName: element.position, positionId: element.positionId, active: element.active });
      } else {
        const elementIndex = array.findIndex((e) => e.positionId === element.positionId);
        array[elementIndex].active = element.active || array[elementIndex].active;
      }
    };

    return selectorCallback(nodes, 'positionId', ifCallback, pushCallback, true);
  });
export const selectFinalNodesPositions = () =>
  createSelector([selectNodes, selectArgs], (nodes, args) =>
    nodes.filter(
      (item) =>
        item.pagetypeId === args.pagetypeId && item.deviceId === args.deviceId && item.positionId === args.positionId
    )
  );
export const selectNodesHeaders = createSelector([selectNodes], (nodes) => {
  const headers = [...new Set(nodes.map((item) => item.source))];

  return headers.sort((a, b) => {
    if (a === 'GAM') return -1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
});
export const selectNodesHeadersToggled = (state) => state.nodes.headersToggled;

export default nodesSlice.reducer;
