/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllBidders, putBidder } from '../helpers/api/bidders';

const domain = 'tabBidders';

// Async actions
export const fetchTabBidders = createAsyncThunk(`${domain}/fetchTabBidders`, async (data, thunkAPI) => {
  try {
    return getAllBidders(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchTabBidders = createAsyncThunk(`${domain}/patchTabBidders`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return putBidder(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  tabBidders: [],
  status: 'idle',
  error: null,
  total: 0,
};

export const tabBiddersSlice = createSlice({
  name: domain,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTabBidders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTabBidders.fulfilled, (state, action) => {
        const { meta, payload } = action;

        state.status = 'succeeded';
        state.total = payload['hydra:totalItems'];

        if (!meta?.arg?.page || meta?.arg?.page === 1) {
          state.tabBidders = payload['hydra:member'];
        } else {
          state.tabBidders.push(...payload['hydra:member']);
        }
      })
      .addCase(fetchTabBidders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(patchTabBidders.fulfilled, (state, action) => {
        const { payload } = action;
        state.tabBidders = state.tabBidders.map((tb) => (tb.id === payload.id ? payload : tb));
      });
  },
});

// Actions

// Selectors
export const selectTabBidders = (state) => state[domain].tabBidders;
export const selectTotal = (state) => state[domain].total;
export const selectTabBiddersStatus = (state) => state[domain].status;
export const selectTabBidderById = () =>
  createSelector([selectTabBidders, (_, id) => id], (tabBidders, id) =>
    tabBidders.find((tabBidder) => tabBidder.id === id || tabBidder['@id'] === id)
  );
export const selectTabBidderByIds = () =>
  createSelector(
    (state) => state[domain],
    (_, ids) => ids,
    (state, ids) => state.tabBidders.filter((tabBidder) => ids.includes(tabBidder.id))
  );

export default tabBiddersSlice.reducer;
