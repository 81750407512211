import styled from '@emotion/styled/macro';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { logoColor } from '../../helpers/color';

const FormLoginWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 67vh;
`;

const FormWrapper = styled.form`
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  width: 25%;
  box-shadow: 20px 20px 45px rgba(171, 195, 226, 0.32);
`;

const Title = styled.div`
  font-weight: bolder;
  text-align: center;
  font-size: 22px;
  color: ${logoColor};
`;

const FormField = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;

const InputLogin = styled(TextField)`
  width: 80%;
  margin-left: 10px;
  & label.Mui-focused {
    color: ${logoColor};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${logoColor};
    }
  }
`;

const InputPassword = styled(TextField)`
  width: 71%;
  margin-left: 10px;
  margin-right: 10px;
  & label.Mui-focused {
    color: ${logoColor};
  }
`;

const ButtonLogin = styled(Button)`
  color: white;
  background-color: ${logoColor};
  border: 1px solid transparent;
  width: 80%;
  margin-top: 20px;
  margin-left: 10%;
  &:hover {
    border: 1px solid ${logoColor};
    background-color: white;
    color: ${logoColor};
  }
`;

const Error = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${logoColor};
`;

const FieldError = styled.p`
  text-align: center;
  color: ${logoColor};
  font-size: 12px;
`;

const BackToLogin = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  color: ${logoColor};
`;

export {
  FormLoginWrapper,
  FormWrapper,
  Title,
  FormField,
  InputLogin,
  InputPassword,
  ButtonLogin,
  Error,
  FieldError,
  BackToLogin,
};
