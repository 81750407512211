import PropTypes from 'prop-types';
import { MassButtonStyled } from './MassButton.style';

function MassButton(props) {
  const { type, children, width, colorButton, onClick, disabled } = props;

  return (
    <MassButtonStyled type={type} width={width} colorButton={colorButton} onClick={onClick} disabled={disabled}>
      {children}
    </MassButtonStyled>
  );
}

MassButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  colorButton: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

MassButton.defaultProps = {
  width: '100%',
  colorButton: '#264653',
  onClick: () => {},
  type: 'button',
  disabled: false,
};

export default MassButton;
