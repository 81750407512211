import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllMediaTypes() {
  const url = `${API_BASE_URL}/mediatypes`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function patchMediaType(id, body) {
  const url = `${API_BASE_URL}/mediatypes/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}
