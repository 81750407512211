/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCode } from '../../../helpers/api/blocks';

const DOMAIN = 'code';

export const fetchCode = createAsyncThunk(`${DOMAIN}/code`, async (data, thunkAPI) => {
  try {
    const { id, modifications } = data;
    return getCode(id, modifications);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  code: '',
  codeTab: [],
  selectedTab: 0,
  status: '',
  error: null,
};

export const CodeSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    addCodetabs: (state, action) => {
      const tabAlreadyExist = state.codeTab.findIndex((item) => item.id === action.payload.id);

      if (tabAlreadyExist === -1) {
        state.selectedTab = state.codeTab.push(action.payload);
      } else {
        state.selectedTab = tabAlreadyExist + 1;
      }
    },
    removeCodetabs: (state, action) => {
      const index = state.codeTab.findIndex((item) => item.id === action.payload.id);
      state.codeTab.splice(index, 1);

      if (index + 1 === state.selectedTab) {
        if (index === state.codeTab.length) {
          state.selectedTab = state.codeTab.length;
        }
      } else if (index < state.selectedTab) {
        state.selectedTab -= 1;
      }
    },
    updateCodeTab: (state, action) => {
      state.codeTab[action.payload.index].code = action.payload.code;
    },
    updateSelectTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCode.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCode.fulfilled, (state, action) => {
      state.status = 'resolved';
      const { code } = action.payload;
      if (code) {
        state.code = code;
      } else {
        state.code = '// Veuillez selectionner un bloc';
      }
    });
    builder.addCase(fetchCode.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
  },
});

export const { addCodetabs, removeCodetabs, updateCodeTab, updateSelectTab } = CodeSlice.actions;

export const selectCode = (state) => state[DOMAIN].code;
export const selectSelectedTab = (state) => state[DOMAIN].selectedTab;
export const selectCodeTabs = (state) => state[DOMAIN].codeTab;

export default CodeSlice.reducer;
