import { useMemo, useState } from 'react';
import { getAllSnapshots } from '../../../helpers/api/googleTagSnapshot';
import Table from '../../UI/CompleteTable/Table';
import Heading from '../../UI/Title/Heading';
import Card from '../../UI/Wrapper/Card';
import SnapshotRow from './SnapshotRow';

function SnapshotHistory() {
  const [snapshots, setSnapshots] = useState([]);
  const [total, setTotal] = useState(0);

  const columns = useMemo(
    () => [
      { id: 'action', label: '' },
      { id: 'id', label: 'Numéro' },
      { id: 'createdAt', label: "Début d'import", format: (value) => new Date(value).toLocaleString() },
      { id: 'updatedAt', label: "Fin d'import", format: (value) => new Date(value).toLocaleString() },
    ],
    []
  );

  const fetchHistory = (page, itemsPerPage) => {
    getAllSnapshots(itemsPerPage, page + 1).then((res) => {
      setTotal(res['hydra:totalItems']);
      setSnapshots(snapshots.concat(...res['hydra:member']));
    });
  };

  return (
    <>
      <Heading>Historique des imports des GoogleTags</Heading>
      <Card>
        <Table
          columns={columns}
          getData={fetchHistory}
          Line={SnapshotRow}
          itemsPerPage={10}
          data={snapshots}
          total={total}
        />
      </Card>
    </>
  );
}

export default SnapshotHistory;
