import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createModificationForSizes } from '../../../../../../helpers/modificationsTools';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { addSizeModification } from '../../../../../../store/ModificationsSlice';
import { Cell } from './Grid.style';
import { selectNodesHeaders, selectNodesHeadersToggled } from './NodesSlice';

function CellGrid(props) {
  const { children, className, expanded, pagetype, device, position } = props;

  const dispatch = useDispatch();
  const headers = useSelector(selectNodesHeaders);
  const headersToggled = useSelector(selectNodesHeadersToggled);
  const googleTagVersion = useSelector(selectGoogleTagVersion);

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDropEvent = useCallback(
    (event) => {
      event.preventDefault();

      const sizeId = Number.parseInt(event.dataTransfer.getData('text'), 10);

      if (sizeId === null || pagetype === null) {
        return;
      }

      const newModification = createModificationForSizes(
        'addSize',
        { sizeId, pagetype, device, position, headers, headersToggled },
        [googleTagVersion['@id']]
      );

      dispatch(addSizeModification(newModification));
    },
    [device, dispatch, googleTagVersion, headers, headersToggled, pagetype, position]
  );

  return (
    <Cell
      className={className}
      onDragOver={(event) => handleDragOver(event)}
      onDrop={(event) => handleDropEvent(event)}
      expanded={expanded}
    >
      {expanded ? null : children}
    </Cell>
  );
}

CellGrid.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  pagetype: PropTypes.number.isRequired,
  device: PropTypes.number,
  position: PropTypes.number,
};

CellGrid.defaultProps = {
  children: null,
  expanded: false,
  className: '',
  device: null,
  position: null,
};

export default CellGrid;
