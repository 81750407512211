import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fetchAllDrafts } from '../../../store/DraftSlice';
import { FormControlFilter } from '../../Mass/Sidebar/Filter/SidebarFilter.style';
import MassButton from '../../UI/Basic/Buttons/MassButton/MassButton';
import ControlledAutocomplete from '../../UI/ControlledAutocomplete';
import Heading from '../../UI/Title/Heading';
import { SidebarWrapper } from './DraftSidebar.style';

function DraftSidebar() {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const statusForm = useMemo(() => {
    return [
      {
        key: 'Terminé',
        value: false,
      },
      {
        key: 'En cours',
        value: true,
      },
    ];
  }, []);

  const typeForm = useMemo(() => {
    return [
      {
        key: 'Modification unitaire',
        value: 0,
      },
      {
        key: 'Modification de masse',
        value: 1,
      },
    ];
  }, []);

  const submitForm = (data) => {
    const { type, isActive } = data;
    const dataToDispatch = {
      type: type && type.value,
      isActive: isActive && isActive.value,
    };
    dispatch(fetchAllDrafts(dataToDispatch));
  };

  return (
    <SidebarWrapper>
      <Heading variant="h2">Filtez vos drafts</Heading>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            control={control}
            options={statusForm}
            renderInput={(params) => <TextField {...params} label="Status" placeholder="Status" />}
            getOptionLabel={(option) => option.key}
            name="isActive"
            renderOption={(renderProps, option) => <li {...renderProps}>{option.key}</li>}
          />
        </FormControlFilter>

        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            control={control}
            options={typeForm}
            renderInput={(params) => <TextField {...params} label="Type" placeholder="Type" />}
            getOptionLabel={(option) => option.key}
            name="type"
            renderOption={(renderProps, option) => <li {...renderProps}>{option.key}</li>}
          />
        </FormControlFilter>
        <MassButton type="submit">Filtrer</MassButton>
      </form>
    </SidebarWrapper>
  );
}

export default DraftSidebar;
