import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getActiveDraft(id) {
  let url = `${API_BASE_URL}/google_tag_drafts?isActive=true`;

  if (id) {
    url += `&draftModifications.googleTagVersions=${id}`;
  } else {
    url += '&type=1';
  }

  const response = await defaultFetch(url);

  return response.json();
}

export async function getOneDraft(id) {
  const url = `${API_BASE_URL}/google_tag_drafts/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getAllDraft(data) {
  const url = new URL(`${API_BASE_URL}/google_tag_drafts?order[id]=DESC`);
  if (data) {
    url.search = param(data);
  }

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function applyGoogleTagDraft(id, body) {
  const url = `${API_BASE_URL}/google_tag_drafts/${id}/apply`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function deleteGoogleTagDraft(id) {
  const url = `${API_BASE_URL}/google_tag_drafts/${id}`;
  return defaultFetch(url, 'DELETE');
}

export async function createGoogleTagDraft(body) {
  const url = `${API_BASE_URL}/google_tag_drafts`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function updateGoogleTagDraft(draftId, body) {
  const url = `${API_BASE_URL}/google_tag_drafts/${draftId}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}

export async function getLastDraftOfGoogleTag(draftId) {
  const url = `${API_BASE_URL}/google_tag_drafts/${draftId}/last`;
  const response = await defaultFetch(url);

  return response.json();
}
