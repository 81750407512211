import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { removeComment, selectComment } from '../../../store/CommentsSlice';
import { selectCurrentUser } from '../../../store/UsersSlice';
import AlertDialog from '../Dialog/Dialog';
import { CommentText, CommentTitle, CommentTitleWrapper, CommentWrapper } from './Comment.style';

function Comment(props) {
  const { id } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const commentSelector = useMemo(selectComment, []);
  const comment = useSelector((state) => commentSelector(state, id));

  const { user, text, createdAt } = comment;

  const currentUser = useSelector(selectCurrentUser);
  const currentUserComment = useMemo(() => currentUser?.id === user.id, [currentUser, user.id]);

  const date = new Date(createdAt);
  const formatDate = `${date.toLocaleDateString('fr')} à ${date.toLocaleTimeString('fr')}`;

  const removeCommentHandler = useCallback(() => {
    dispatch(removeComment(id));
  }, [dispatch, id]);

  return (
    <>
      <CommentWrapper>
        <CommentTitleWrapper currentUser={currentUserComment}>
          <CommentTitle>
            {user?.firstname} {user?.lastname} le {formatDate}
          </CommentTitle>
          {currentUserComment && (
            <Button onClick={() => setOpen(true)} variant="contained" color="error">
              <RiDeleteBin6Line />
            </Button>
          )}
        </CommentTitleWrapper>
        <CommentText currentUser={currentUserComment}>{text}</CommentText>
      </CommentWrapper>
      <AlertDialog
        open={open}
        title="Suppression d'un commentaire"
        content="Confirmer la suppression du commentaire"
        validateLabel="Confirmer"
        validateVariant="contained"
        validateColor="error"
        cancelLabel="Annuler"
        onValidate={removeCommentHandler}
        onCancel={() => setOpen(false)}
      />
    </>
  );
}

Comment.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Comment;
