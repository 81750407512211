export const adminPartnerTableHeaders = [
  { id: '0', label: 'ID' },
  { id: '1', label: 'Nom du partenaire' },
  { id: '2', label: 'keyname' },
  { id: '3', label: 'Partenaire' },
  { id: '4', label: 'Autorisé' },
  { id: '5', label: 'Trigram' },
  { id: '6', label: 'UUID (lié au Monetization Dashboard)' },
  { id: '7', label: 'LSSite' },
  { id: '8', label: 'NotIn' },
  { id: '9', label: 'Git fileName' },
  { id: '10', label: 'Nom du Groupe' },
  { id: '11', label: 'Action' },
];

export const adminBiddersTableHeaders = [
  { id: '1', label: '#' },
  { id: '2', label: 'Nom' },
  { id: '3', label: 'Keyname' },
  { id: '4', label: 'Type monetisation' },
  { id: '5', label: 'Adapter' },
  { id: '6', label: 'type' },
  { id: '7', label: 'Amp compatible' },
  { id: '8', label: 'Autorisé à bidder' },
  { id: '9', label: 'uuid' },
  { id: '10', label: 'Actions' },
];
