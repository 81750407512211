import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

function StringVariable() {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          label="Valeur"
          variant="filled"
          fullWidth
          sx={{ '& .MuiFilledInput-root': { backgroundColor: 'white' } }}
        />
      )}
      control={control}
      name="value"
    />
  );
}

export default StringVariable;
