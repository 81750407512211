import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllPlacementKeywordLibs() {
  const url = `${API_BASE_URL}/placement_keyword_libs?pagination=false`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getPlacementKeywordLibsTypes() {
  const url = `${API_BASE_URL}/placement_keyword_lib_types`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function createPlacementKeywordLib(body) {
  const url = `${API_BASE_URL}/placement_keyword_libs`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function modifyPlacementKeywordLib(id, body) {
  const url = `${API_BASE_URL}/placement_keyword_libs/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}
