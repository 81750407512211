import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleTagStatus from '../../../constants/GoogleTagStatus';
import { fetchDraft, selectDraftApplicationStatus, selectDraftStatus } from '../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import Heading from '../../UI/Title/Heading';
import DraftConfirmation from '../Edit/MultiUnitModification/Cart/DraftConfirmation';
import History from '../Edit/MultiUnitModification/Sidebar/History/History';
import Variable from './Variable';
import {
  HeaderContainer,
  SidebarWrapper,
  VariableCardContainer,
  VariablesContainer,
  VariablesWrapper,
} from './Variable.style';
import VariableCreator from './VariableCreator';
import {
  fetchBlockVariableLocalVariables,
  fetchBlockVariableVariables,
  fetchVariableBlock,
  reset,
  selectDefaultLocalVariablesStatus,
  selectDefaultVariables,
  selectDefaultVariablesStatus,
} from './variableSettingsSlice';

function Settings() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(localStorage.getItem('settings.order') ?? 'topic');

  const googleTag = useSelector(selectGoogleTagVersion);
  const applicationStatus = useSelector(selectDraftApplicationStatus);

  const defaultVariables = useSelector(selectDefaultVariables);

  const defaultVariablesStatus = useSelector(selectDefaultVariablesStatus);
  const defaultLocalVariablesStatus = useSelector(selectDefaultLocalVariablesStatus);
  const draftStatus = useSelector(selectDraftStatus);

  useEffect(() => {
    dispatch(fetchVariableBlock(googleTag.id));
  }, [dispatch, googleTag.id]);

  useEffect(() => {
    dispatch(fetchBlockVariableVariables());
  }, [dispatch]);

  useEffect(() => {
    if (googleTag.id && ['idle', 'succeeded'].includes(applicationStatus)) {
      dispatch(fetchBlockVariableLocalVariables(googleTag.id));
    }
  }, [dispatch, googleTag.id, applicationStatus]);

  useEffect(() => {
    dispatch(fetchDraft(googleTag.id));
  }, [dispatch, googleTag]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  const handleChangeOrder = useCallback((event) => {
    const { value } = event.target;

    setOrder(value);
    localStorage.setItem('settings.order', value);
  }, []);

  const sortedVariables = useMemo(() => {
    const orderedVariables = [...defaultVariables].sort((a, b) => {
      let primaryComparison = false;
      if (order === 'topic') {
        primaryComparison = a.topic?.name.localeCompare(b.topic?.name);
      } else if (order === 'type') {
        primaryComparison = a.type.localeCompare(b.type);
      }

      return primaryComparison || a.name.localeCompare(b.name);
    });

    const reducesVariable = orderedVariables.reduce((acc, variable) => {
      const { type, topic } = variable;

      if (order === 'topic') {
        if (!acc[topic?.name]) {
          acc[topic?.name] = [];
        }

        acc[topic?.name].push(variable);
      } else if (order === 'type') {
        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(variable);
      } else {
        if (!acc.default) {
          acc.default = [];
        }

        acc.default.push(variable);
      }

      return acc;
    }, {});

    if (order === 'topic' || order === 'type') {
      return Object.entries(reducesVariable).map(([key, variables]) => {
        let label = '';

        if (key === 'undefined') {
          if (order === 'topic') {
            label = 'Topic non précisé';
          } else if (order === 'type') {
            label = 'Type inconnu';
          } else {
            label = 'Inconnu';
          }
        } else {
          label = key;
        }

        return (
          <div key={key}>
            <Heading>{label}</Heading>
            <VariableCardContainer>
              {variables.map((variable) => (
                <Variable key={variable.id} variableId={variable.id} type={variable.type} />
              ))}
            </VariableCardContainer>
          </div>
        );
      });
    }

    return (
      <VariableCardContainer>
        {reducesVariable.default?.map((variable) => (
          <Variable key={variable.id} variableId={variable.id} type={variable.type} />
        ))}
      </VariableCardContainer>
    );
  }, [defaultVariables, order]);

  return (
    <>
      <HeaderContainer>
        <div>
          <Heading variant="h2">Configuration des variables</Heading>
          {googleTag.status !== GoogleTagStatus.MODIFYING && (
            <FormHelperText error>
              Impossible de modifier les variables d&apos;un GoogleTag qui n&apos;est pas en cours de modification
            </FormHelperText>
          )}
        </div>
        <FormControl sx={{ width: '350px' }}>
          <InputLabel id="settingsOrderLabel">Trié par</InputLabel>
          <Select id="settingsOrderSelect" label="Trié par" value={order} onChange={handleChangeOrder}>
            <MenuItem value="topic">Topic</MenuItem>
            <MenuItem value="type">Type</MenuItem>
            <MenuItem value="alphabet">Ordre alphabétique</MenuItem>
          </Select>
        </FormControl>
      </HeaderContainer>
      {defaultVariablesStatus === 'succeeded' &&
        defaultLocalVariablesStatus === 'succeeded' &&
        draftStatus === 'succeeded' && (
          <VariablesContainer>
            <VariablesWrapper>{sortedVariables}</VariablesWrapper>
            <SidebarWrapper>
              <Button fullWidth variant="contained" onClick={() => setOpen(true)}>
                Ajouter une variable
              </Button>
              &nbsp;
              <History />
              &nbsp;
              <DraftConfirmation />
            </SidebarWrapper>
          </VariablesContainer>
        )}
      <VariableCreator handleClose={() => setOpen(false)} open={open} />
    </>
  );
}

export default Settings;
