import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Heading from '../../../UI/Title/Heading';
import { selectPartnerInfos } from '../../PartnerPageSlice';
import { TableWrapper } from './ErrorTable.style';

function ErrorTable(props) {
  const { type } = props;
  const partnerInfos = useSelector(selectPartnerInfos);

  const error = useMemo(() => {
    if (partnerInfos.gtagData) {
      const { errors } = partnerInfos.gtagData;

      switch (type) {
        case 'ejectedPbjs': {
          return {
            title: 'Noeuds PBJS rejetés par GAM',
            data: errors.ejectedPbjs,
          };
        }
        case 'ejectedAmazon': {
          return {
            title: 'Noeuds Amazon rejetés par GAM',
            data: errors.ejectedAmazon,
          };
        }
        case 'missingPbjs': {
          return {
            title: 'Noeuds TabBidders absents de Pbjs',
            data: errors.missingPbjs,
          };
        }
        case 'mediatypeErrors': {
          return {
            title: 'Erreurs de médiatype',
            data: errors.mediatypeErrors,
          };
        }
        default:
          return null;
      }
    }

    return null;
  }, [partnerInfos, type]);

  if (error.data.length === 0) return null;

  return (
    <div>
      <Heading variant="h4">
        {error.title} ({error.data.length})
      </Heading>
      <TableWrapper>
        <thead>
          <tr>
            <th>Pagetype</th>
            <th>Device</th>
            <th>Position</th>
            <th>Mediatype</th>
          </tr>
        </thead>
        <tbody>
          {error.data.map((err) => {
            return (
              <tr key={`${err.pagetype}${err.device}${err.position}${err.mediatype}`}>
                {Object.keys(err).map((key) => {
                  return <td key={key}>{err[key]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </TableWrapper>
    </div>
  );
}

ErrorTable.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ErrorTable;
