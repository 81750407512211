import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getNodeLibrariesByGtag(gtagId) {
  const url = `${API_BASE_URL}/google_tag_versions/${gtagId}/node_libraries`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getTabBiddersNodesByGtag(id, selectedPartner) {
  const url = `${API_BASE_URL}/google_tag_versions/${id}/tab_bidders/nodes?partnerWebsite=${selectedPartner}`;
  const response = await defaultFetch(url);

  return response.json();
}
