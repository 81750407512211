import PropTypes from 'prop-types';
import { GoogleTagList, GoogleTagListLink, TitleGoogleTag } from '../GoogleTagInfos.style';

function Detail(props) {
  const { namePartner, infos, action } = props;

  return infos ? (
    <GoogleTagListLink to={`/edit-google-tag/${infos.id}`}>
      <p>
        <TitleGoogleTag color={action === 'PROD' ? 'red' : 'green'}>{action} : </TitleGoogleTag>
        {infos.releaseId} - {infos.description}
      </p>
    </GoogleTagListLink>
  ) : (
    <GoogleTagList>
      <p>
        <TitleGoogleTag color={action === 'PROD' ? 'red' : 'green'}>{action} : </TitleGoogleTag>
        Aucun googleTag en {action} pour {namePartner}
      </p>
    </GoogleTagList>
  );
}

Detail.propTypes = {
  namePartner: PropTypes.string.isRequired,
  infos: PropTypes.shape({
    id: PropTypes.number,
    releaseId: PropTypes.string,
    description: PropTypes.string,
  }),
  action: PropTypes.string.isRequired,
};

Detail.defaultProps = {
  infos: null,
};

export default Detail;
