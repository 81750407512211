import { Tab, Tabs } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { clearCurrentDraft, fetchDraft, fetchSelectedDraft } from '../../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../../store/GoogleTagVersionSlice';
import { CustomTab, CustomTabs } from '../../../UI/Tabs/Tabs.style';
import { CardWrapper, ContentWrapper } from '../../../UI/Wrapper/CardWrapper';
import Cart from './Cart/Cart';
import Sidebar from './Sidebar/Sidebar';
import PositionModificationPanel from './Tabs/PositionsTab/PositionModificationPanel';
import SizesModificationPanel from './Tabs/SizesTab/SizesModificationPanel';
import TabBiddersModificationPanel from './Tabs/TabBiddersTab/TabBiddersModificationPanel';

function MultiUnitModification() {
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const googleTagDraftID = useMemo(() => searchParams.get('draft'), [searchParams]);

  useEffect(() => {
    if (googleTagDraftID) {
      if (googleTagDraftID !== 'new') {
        dispatch(fetchSelectedDraft(googleTagDraftID));
      } else {
        dispatch(clearCurrentDraft());
      }
    } else if (googleTagVersion) {
      dispatch(fetchDraft(googleTagVersion.id));
    }
  }, [dispatch, googleTagDraftID, googleTagVersion]);

  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Cart gtag={googleTagVersion} />
      <CardWrapper>
        <ContentWrapper>
          <Tabs value={value} onChange={handleChange} sx={CustomTabs}>
            <Tab value={1} label="Tailles" sx={CustomTab} />
            <Tab value={2} label="TabBidders" sx={CustomTab} />
            <Tab value={3} label="RTBPositions" sx={CustomTab} />
          </Tabs>
          <SizesModificationPanel activeTab={value} index={1} />
          <TabBiddersModificationPanel activeTab={value} index={2} />
          <PositionModificationPanel activeTab={value} index={3} />
        </ContentWrapper>
        <Sidebar activeTab={value} />
      </CardWrapper>
    </>
  );
}

export default MultiUnitModification;
