import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import BidderCreation from '../../components/Admin/ManageBidders/Creation/BidderCreation';
import ManageKeyword from '../../components/Admin/ManageBidders/Keyword/ManageKeyword';
import ListBidder from '../../components/Admin/ManageBidders/List/ListBidder';
import Suggestion from '../../components/Admin/ManageBidders/Suggestion/Suggestion';

function AdminBidders() {
  const [tab, setTab] = useState(1);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs value={tab} onChange={handleChange}>
        <Tab value={1} label="Liste des bidders" />
        <Tab value={2} label="Création d'un Bidder" />
        <Tab value={3} label="Gestion des keywords" />
        <Tab value={4} label="Gestion des suggestions" />
      </Tabs>
      {tab === 1 && <ListBidder />}
      {tab === 2 && <BidderCreation />}
      {tab === 3 && <ManageKeyword />}
      {tab === 4 && <Suggestion />}
    </>
  );
}

export default AdminBidders;
