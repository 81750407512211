import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import InputWithSuggestions from '../../../../../UI/Basic/Input/InputWithSuggestions';
import { DeleteIcon, PlacementAction } from '../ModalModificationBidder.style';
import useSuggestionByScope from '../../../../../../hooks/useSuggestionByScope';

function KeywordRule(props) {
  const { item, index, removeKeywordRule, registerName, move } = props;

  const suggestionSSP = useSuggestionByScope('SSP');
  const suggestionConstraint = useSuggestionByScope('CONSTRAINT');

  const { register } = useFormContext();

  return (
    <PlacementAction>
      <InputWithSuggestions
        formProps={{
          ...register(`${registerName}.bidderPlacementKeywordRules.${index}.constraints`),
          defaultValue: item.constraints,
          variant: 'standard',
          label: 'Contraintes',
        }}
        sx={{ minWidth: '250px', margin: '10px' }}
        infos='Mettre en place une règle de contraintes pour que la valeur par défaut soit appliquée. (Ex: "{{mediatype}} === banner")'
        suggestions={suggestionConstraint}
      />
      <InputWithSuggestions
        formProps={{
          ...register(`${registerName}.bidderPlacementKeywordRules.${index}.defaultValue`),
          defaultValue: item.defaultValue,
          variant: 'standard',
          label: 'Valeur par défaut',
        }}
        sx={{ minWidth: '250px', margin: '10px' }}
        suggestions={suggestionSSP}
      />
      <span>
        <Button onClick={() => removeKeywordRule(index)} color="error" variant="outlined" sx={{ margin: '0 5px' }}>
          <DeleteIcon />
          Supprimer la règle
        </Button>
        <Button onClick={() => move(index, index - 1)} variant="outlined">
          Up
        </Button>
        <Button onClick={() => move(index, index + 1)} variant="outlined">
          Down
        </Button>
      </span>
    </PlacementAction>
  );
}

KeywordRule.propTypes = {
  item: PropTypes.shape({
    constraints: PropTypes.string,
    defaultValue: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  removeKeywordRule: PropTypes.func.isRequired,
  registerName: PropTypes.string.isRequired,
  move: PropTypes.func.isRequired,
};

export default KeywordRule;
