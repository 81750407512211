/* eslint-disable react/jsx-props-no-spreading */
import { ListItemText } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import MainBlock from '../../../../../types/Block/MainBlock';
import { BlockListItem, BlockListItemText, PrimaryTextForListItem } from './Block.style';
import { updateBlocks } from '../../BlockAdministrationSlice';

function Block(props) {
  const { block } = props;
  const dispatch = useDispatch();

  const [active, setActive] = useState(block.isActive);

  const handleDeleteBlock = () => {
    dispatch(updateBlocks({ id: block.id, body: { isActive: !block.isActive } })).then((response) => {
      const splitedResponse = response.type.split('/');
      if (splitedResponse[splitedResponse.length - 1] !== 'rejected') {
        setActive(!active);
      }
    });
  };

  return (
    <BlockListItem>
      <ListItemText
        primary={
          <BlockListItemText>
            <PrimaryTextForListItem>{block.name}</PrimaryTextForListItem>
            <Switch checked={active} onChange={handleDeleteBlock} />
          </BlockListItemText>
        }
      />
    </BlockListItem>
  );
}

Block.propTypes = {
  block: MainBlock.isRequired,
};

export default Block;
