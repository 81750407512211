import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

const POSSIBLE_COLOR = {
  inherit: {
    default: 'inherit',
    hover: 'inherit',
  },
  primary: {
    default: '#845EF7',
    hover: '#6b3aff',
  },
  secondary: {
    default: '',
    hover: '',
  },
  success: {
    default: '#37b24d',
    hover: '#305a2a',
  },
  error: {
    default: '#a50b0b',
    hover: '#860c0c',
  },
  info: {
    default: '',
    hover: '',
  },
  warning: {
    default: '#a50b0b',
    hover: '#860c0c',
  },
};

const PrimaryButtonCss = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 12px;
  max-width: 305px;
  height: 40px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.14);
  transition: 250ms;
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
  text-transform: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30%;
  border-style: none;
  cursor: pointer;
  margin: 10px 0;
  color: #fff;

  background-color: ${({ color }) => POSSIBLE_COLOR[color]?.default ?? ''};

  :hover {
    background-color: ${({ color }) => POSSIBLE_COLOR[color].hover ?? ''};
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0px rgba(0, 0, 0, 0.16), 0 1px 10px 0 rgba(0, 0, 0, 0.14);
  }
`;

const SecondaryButton = styled.button`
  padding: 1.3em 1em;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: #000;
  background-color: ${(props) => props.color};
  border: none;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin: 2px;
  &:hover {
    background-color: ${(props) => props.hoverColor};
    color: #fff;
  }

  ${({ sx }) => sx && { ...sx }}
`;

export { POSSIBLE_COLOR, PrimaryButtonCss, Button, SecondaryButton };
