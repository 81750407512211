import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

const CardWrapper = styled.div`
  box-shadow: 0 0 25px -6px rgba(0, 0, 0, 0.46);
`;

const TitlePrebid = styled.p`
  text-align: center;
  margin: 0;
  font-size: 19px;
  font-weight: bold;
`;

const HeaderWrapper = styled(Button)`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => props.bgcolor}55;
  text-decoration: none;
  color: black;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.bgcolor}33;
  }
`;

const ModuleWrapper = styled.div`
  padding: 5px 10px;
`;

const ModuleItem = styled.div`
  display: inline-block;
  background-color: ${(props) => !props.border && props.color};
  border: ${(props) => props.border && '1px solid black'};
  margin: 2px;
  padding: 0 5px;
  width: max-content;
  cursor: pointer;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ModuleName = styled.p`
  margin-left: 5px;
  color: ${(props) => (props.black ? 'black' : 'white')};
`;

export { CardWrapper, TitlePrebid, ModuleWrapper, ModuleItem, ItemWrapper, HeaderWrapper, ModuleName };
