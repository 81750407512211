import styled from '@emotion/styled/macro';

const HeaderStyled = styled.header`
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  top: 0;
  background: #264653;
  height: 70px;
  padding: 0 2%;
  z-index: 10;
  flex: 0 0 auto;
`;

const Avatar = styled.img`
  vertical-align: middle;
  height: 150%;
  border-radius: 50%;
  cursor: pointer;
`;

const Logo = styled.img`
  height: 200%;
  border-radius: 50%;
  padding-bottom: 10px;
`;

export { HeaderStyled, Avatar, Logo };
