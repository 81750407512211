/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllModifications, getGoogleTagModifications } from '../helpers/api/modifications';
import { clearCurrentDraft, createDraft, deleteDraft, fetchDraft, fetchSelectedDraft, updateDraft } from './DraftSlice';

export const fetchAllModifications = createAsyncThunk(`modifications/fetchAll`, async (thunkAPI) => {
  try {
    return getAllModifications();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchGoogleTagModifications = createAsyncThunk(`modifications/fetch`, async (id, thunkAPI) => {
  try {
    return getGoogleTagModifications(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  allModifications: [],
  selectedGoogleTags: [],
  tabBiddersModifications: [],
  sizesModifications: [],
  positionsModifications: [],
  rtbPositionModifications: [],
  prebidModifications: [],
  blockModifications: [],
  variableModifications: [],
  removedModifications: [],
};

const operationToArray = {
  removeSize: 'sizesModifications',
  addSize: 'sizesModifications',
  updateTabBidders: 'tabBiddersModifications',
  addTabBidders: 'tabBiddersModifications',
  disableTabBidders: 'tabBiddersModifications',
  enableTabBidders: 'tabBiddersModifications',
  removePosition: 'positionsModifications',
  addPosition: 'positionsModifications',
  modifyPosition: 'positionsModifications',
  'add adPosition': 'rtbPositionModifications',
  'delete adPosition': 'rtbPositionModifications',
  'modify adPosition': 'rtbPositionModifications',
  updatePrebidVersion: 'prebidModifications',
  addBlock: 'blockModifications',
  removeBlock: 'blockModifications',
  changeBlockPosition: 'blockModifications',
  changeVersion: 'blockModifications',
  changeGlobalVariable: 'blockModifications',
  changeLocalVariable: 'blockModifications',
  updateVariable: 'variableModifications',
};

const applyDraftModifications = (state, modifications) => {
  modifications.forEach((modification) => {
    const arrayToPush = operationToArray[modification.operation];
    if (arrayToPush) {
      state[arrayToPush].push(modification);
    }
  });

  return state;
};

const sortAllModification = (state) => {
  const array = [];
  Object.entries(state).forEach(([key, value]) => {
    if (!['allModifications', 'selectedGoogleTags', 'removedModifications'].includes(key)) {
      array.push(...value);
    }
  });

  array.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return -1;
    }

    if (a.createdAt > b.createdAt) {
      return 1;
    }

    return 0;
  });

  return array;
};

export const modificationsSlice = createSlice({
  name: 'modifications',
  initialState,
  reducers: {
    resetModifications: (state, action) => {
      let newState = JSON.parse(JSON.stringify(initialState));

      if (action.payload) {
        newState = applyDraftModifications(newState, action.payload);
      }

      return newState;
    },
    removeLastModification: (state) => {
      const array = sortAllModification(state);

      if (array.length === 0) {
        return state;
      }

      const modification = array.pop();
      const arrayToPop = operationToArray[modification.operation];
      state[arrayToPop].pop();

      state.removedModifications.push(modification);

      return state;
    },
    restoreLastModification: (state) => {
      if (state.removedModifications.length === 0) {
        return;
      }

      const modification = state.removedModifications.pop();
      const arrayToPush = operationToArray[modification.operation];
      state[arrayToPush].push(modification);
    },
    // Sizes reducer
    addSizeModification: (state, action) => {
      state.removedModifications = [];
      state.sizesModifications.push({ ...action.payload, createdAt: Date.now().toString() });
    },
    // Positions reducer
    addPositionsModification: (state, action) => {
      state.removedModifications = [];
      state.positionsModifications.push({ ...action.payload, createdAt: Date.now().toString() });
    },
    // TabBidders reducer
    updateTabBidderKeywordValue: (state, action) => {
      state.removedModifications = [];
      const { selectedPartner, operation, metadata, bidderId, occurrence, keywordId, value, googleTagVersions } =
        action.payload;

      const index = state.tabBiddersModifications.findIndex(
        (modification) =>
          modification.operation === operation &&
          modification.metadata.device === metadata?.device &&
          modification.metadata.position === metadata?.position &&
          modification.metadata.mediatype === metadata?.mediatype &&
          modification.metadata.bidder === bidderId &&
          modification.metadata.occurrence === occurrence
      );

      if (index === -1) {
        state.tabBiddersModifications.push({
          operation,
          metadata: {
            partnerWebsite: selectedPartner,
            device: metadata.device,
            position: metadata.position,
            mediatype: metadata.mediatype,
            bidder: bidderId,
            occurrence,
            values: {
              [keywordId]: value,
            },
          },
          createdAt: Date.now().toString(),
          googleTagVersions,
        });
      } else {
        const newModification = state.tabBiddersModifications[index];

        newModification.metadata.values = {
          ...newModification.metadata.values,
          [keywordId]: value,
        };

        state.tabBiddersModifications.splice(index, 1);
        state.tabBiddersModifications.push({ ...newModification, createdAt: Date.now().toString(), googleTagVersions });
      }
    },
    addTabBidder: (state, action) => {
      state.removedModifications = [];
      const { selectedPartner, metadata, bidderId, googleTagVersions, values, defaultValues } = action.payload;

      state.tabBiddersModifications.push({
        operation: 'addTabBidders',
        metadata: {
          partnerWebsite: selectedPartner,
          device: metadata.device,
          position: metadata.position,
          mediatype: metadata.mediatype,
          bidder: bidderId,
          values: values ?? {},
          defaultValues,
        },
        createdAt: Date.now().toString(),
        googleTagVersions,
      });
    },
    updateAddedTabBidder: (state, action) => {
      state.removedModifications = [];
      const { metadata, keyname, value, values, createdAt } = action.payload;

      const index = state.tabBiddersModifications.findIndex(
        (modification) =>
          modification.operation === 'addTabBidders' &&
          modification.metadata.device === metadata?.device &&
          modification.metadata.position === metadata?.position &&
          modification.metadata.mediatype === metadata?.mediatype &&
          modification.createdAt === createdAt
      );

      if (index > -1) {
        const newModification = state.tabBiddersModifications[index];

        if (values) {
          newModification.metadata.values = values;
        } else {
          newModification.metadata.values[keyname] = value ?? '';
        }
      }
    },
    removeAddedTabBidder: (state, action) => {
      state.removedModifications = [];
      const { createdAt } = action.payload;

      const index = state.tabBiddersModifications.findIndex(
        (modification) => modification.operation === 'addTabBidders' && modification.createdAt === createdAt
      );

      if (index > -1) {
        state.tabBiddersModifications.splice(index, 1);
      }
    },
    removeTabBidder: (state, action) => {
      state.removedModifications = [];
      const { selectedPartner, metadata, bidderId, occurrence, googleTagVersions } = action.payload;

      state.tabBiddersModifications.push({
        operation: 'disableTabBidders',
        metadata: {
          partnerWebsite: selectedPartner,
          device: metadata.device,
          position: metadata.position,
          mediatype: metadata.mediatype,
          bidder: bidderId,
          occurrence,
        },
        createdAt: Date.now().toString(),
        googleTagVersions,
      });
    },
    restoreTabBidder: (state, action) => {
      state.removedModifications = [];
      const { selectedPartner, metadata, bidderId, occurrence, googleTagVersions } = action.payload;

      state.tabBiddersModifications.push({
        operation: 'enableTabBidders',
        metadata: {
          partnerWebsite: selectedPartner,
          device: metadata.device,
          position: metadata.position,
          mediatype: metadata.mediatype,
          bidder: bidderId,
          occurrence,
        },
        createdAt: Date.now().toString(),
        googleTagVersions,
      });
    },
    // RtbPositions reducer
    addRtbPositionsModification: (state, action) => {
      state.removedModifications = [];
      const { operation, metadata } = action.payload;
      const { position, rtbPosition } = metadata;

      const exists = state.rtbPositionModifications.filter(
        (modification) => modification.metadata.position === position
      );

      if (exists.length > 0) {
        switch (operation) {
          case 'add adPosition': {
            if (exists[0].operation === 'delete adPosition') {
              state.rtbPositionModifications = state.rtbPositionModifications.filter(
                (modif) => modif.metadata.position !== position
              );
            }

            break;
          }
          case 'delete adPosition': {
            const existsCreation = exists.filter((modif) => modif.metadata.position === position);

            if (existsCreation.length > 0) {
              state.rtbPositionModifications = state.rtbPositionModifications.filter(
                (modif) => modif.metadata.position !== position
              );

              if (existsCreation[0].operation === 'modify adPosition') {
                state.rtbPositionModifications.push({ ...action.payload, createdAt: Date.now().toString() });
              }
            } else {
              state.rtbPositionModifications.push({ ...action.payload, createdAt: Date.now().toString() });
            }

            break;
          }
          case 'modify adPosition': {
            if (exists[0].operation === 'modify adPosition') {
              exists[0].metadata.rtbPosition = rtbPosition;
            } else {
              state.rtbPositionModifications = state.rtbPositionModifications.filter(
                (modif) => modif.metadata.position !== position
              );

              state.rtbPositionModifications.push({ ...action.payload, createdAt: Date.now().toString() });
            }

            break;
          }
          default:
            state.rtbPositionModifications.push({ ...action.payload, createdAt: Date.now().toString() });
            break;
        }
      } else {
        state.rtbPositionModifications.push({ ...action.payload, createdAt: Date.now().toString() });
      }
    },
    // PrebidVersion reducer
    updatePrebidVersion: (state, action) => {
      state.removedModifications = [];
      state.prebidModifications = [{ ...action.payload, createdAt: Date.now().toString() }];
    },

    // Block modification
    addBlockModification: (state, action) => {
      state.removedModifications = [];
      const { operation, metadata } = action.payload;
      let index = 0;

      switch (operation) {
        case 'removeBlock':
          index = state.blockModifications.findIndex(
            (modification) =>
              modification.operation === 'addBlock' &&
              modification.metadata.idBlock === metadata.idBlock &&
              modification.metadata.idVersion === metadata.idVersion
          );

          if (index === -1) {
            const modification = action.payload;
            modification.createdAt = Date.now().toString();
            state.blockModifications.push(action.payload);
          } else {
            state.blockModifications = state.blockModifications.filter(
              (modification, indexFilter) => indexFilter !== index
            );
          }
          break;

        case 'addBlock':
          index = state.blockModifications.findIndex(
            (modification) =>
              modification.operation === 'removeBlock' &&
              modification.metadata.idBlock === metadata.idBlock &&
              modification.metadata.idVersion === metadata.idVersion
          );

          if (index === -1) {
            const modification = action.payload;
            modification.createdAt = Date.now().toString();
            state.blockModifications.push(action.payload);
          } else {
            state.blockModifications = state.blockModifications.filter(
              (modification, indexFilter) => indexFilter !== index
            );
          }
          break;

        case 'changeBlockPosition':
          index = state.blockModifications.findIndex(
            (modification) =>
              modification.operation === 'changeBlockPosition' && modification.metadata.idBlock === metadata.idBlock
          );

          if (index === -1) {
            const modification = action.payload;
            modification.createdAt = Date.now().toString();
            state.blockModifications.push(action.payload);
          } else {
            const modif = state.blockModifications[index];
            modif.metadata.destinationPos = action.payload.metadata.destinationPos;
          }
          break;

        case 'changeLocalVariable':
        case 'changeGlobalVariable':
          index = state.blockModifications.findIndex(
            (modification) =>
              modification.operation === operation &&
              modification.metadata.idBlock === metadata.idBlock &&
              modification.metadata.idVariableGlobal === metadata.idVariableGlobal &&
              modification.metadata.idGTAGBV === metadata.idGTAGBV &&
              modification.metadata.type === metadata.type
          );

          if (index === -1) {
            const modification = action.payload;
            modification.createdAt = Date.now().toString();
            state.blockModifications.push(action.payload);
          } else {
            state.blockModifications = state.blockModifications.map((modif, indexModif) =>
              indexModif === index ? action.payload : modif
            );
          }
          break;

        case 'changeVersion':
          index = state.blockModifications.findIndex(
            (modification) =>
              modification.operation === operation &&
              modification.metadata.idBlock === metadata.idBlock &&
              modification.metadata.idGTAGBV === metadata.idGTAGBV &&
              modification.metadata.type === metadata.type
          );

          if (index === -1) {
            const modification = action.payload;
            modification.createdAt = Date.now().toString();
            state.blockModifications.push(action.payload);
          } else if (state.blockModifications[index].metadata.oldIdVersion !== metadata.idVersion) {
            state.blockModifications[index].metadata.idVersion = metadata.idVersion;
          } else {
            state.blockModifications = state.blockModifications.filter(
              (modification, indexFilter) => indexFilter !== index
            );
          }
          break;
        default:
      }
    },

    // Manage selected GoogleTag
    addGoogleTag: (state, action) => {
      if (Array.isArray(action.payload)) {
        const ids = state.selectedGoogleTags;
        action.payload.forEach((id) => ids.push(id));
        state.selectedGoogleTags = ids;
      } else {
        state.selectedGoogleTags.push(action.payload);
      }
    },

    removeGoogleTag: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.selectedGoogleTags = state.selectedGoogleTags.filter(
          (item) => !action.payload.some((item2) => item2 === item)
        );
      } else {
        state.selectedGoogleTags = state.selectedGoogleTags.filter((item) => item !== action.payload);
      }
    },

    // Variable modification
    variableModification: (state, action) => {
      state.removedModifications = [];
      const { variableName, value, active, googleTagVersions } = action.payload;

      const index = state.variableModifications.findIndex(
        (modification) =>
          modification.operation === 'updateVariable' && modification.metadata.variableName === variableName
      );

      if (index === -1) {
        state.variableModifications.push({
          operation: 'updateVariable',
          metadata: {
            variableName,
            value,
            active,
          },
          createdAt: Date.now().toString(),
          googleTagVersions,
        });
      } else {
        const newModification = state.variableModifications[index];

        newModification.metadata.value = value;
        newModification.metadata.active = active;

        state.variableModifications.splice(index, 1);
        state.variableModifications.push({ ...newModification, createdAt: Date.now().toString(), googleTagVersions });
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllModifications.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchAllModifications.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.allModifications = action.payload['hydra:member'];
      })
      .addCase(fetchAllModifications.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      });

    builder
      .addCase(fetchGoogleTagModifications.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(fetchGoogleTagModifications.fulfilled, (state, action) => {
        state.status = 'resolved';
        state.allModifications = action.payload['hydra:member'];
      })
      .addCase(fetchGoogleTagModifications.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      });

    builder
      .addCase(fetchDraft.fulfilled, (state, action) => {
        const [draft] = action.payload['hydra:member'];
        let newState = JSON.parse(JSON.stringify(initialState));

        if (draft?.draftModifications) {
          newState = applyDraftModifications(newState, draft.draftModifications);
        }

        return newState;
      })
      .addCase(fetchSelectedDraft.fulfilled, (state, action) => {
        const draft = action.payload;
        let newState = JSON.parse(JSON.stringify(initialState));

        if (draft?.draftModifications && draft.isActive) {
          newState = applyDraftModifications(newState, draft.draftModifications);
        }

        return newState;
      })
      .addCase(deleteDraft.fulfilled, () => initialState)
      .addCase(clearCurrentDraft, () => initialState)
      .addCase(createDraft.fulfilled, (state, action) => {
        const draft = action.payload;
        let newState = JSON.parse(JSON.stringify(initialState));

        if (draft?.draftModifications && draft.isActive) {
          newState = applyDraftModifications(newState, draft.draftModifications);
        }

        return newState;
      })
      .addCase(updateDraft.fulfilled, (state, action) => {
        const draft = action.payload;
        let newState = JSON.parse(JSON.stringify(initialState));

        if (draft?.draftModifications && draft.isActive) {
          newState = applyDraftModifications(newState, draft.draftModifications);
        }

        return newState;
      });
  },
});

// Actions
export const {
  resetModifications,
  removeLastModification,
  restoreLastModification,
  addSizeModification,
  addPositionsModification,
  updateTabBidderKeywordValue,
  addTabBidder,
  updateAddedTabBidder,
  removeAddedTabBidder,
  removeTabBidder,
  restoreTabBidder,
  addRtbPositionsModification,
  updatePrebidVersion,
  addBlockModification,
  addGoogleTag,
  removeGoogleTag,
  variableModification,
} = modificationsSlice.actions;

// Selectors
const selectDomain = (state) => state.modifications;
export const selectTabBiddersModifications = (state) => state.modifications.tabBiddersModifications;
export const selectSizesModifications = (state) => state.modifications.sizesModifications;
export const selectPositionsModifications = (state) => state.modifications.positionsModifications;
export const selectRtbPositionModifications = (state) => state.modifications.rtbPositionModifications;
export const selectPrebidModifications = (state) => state.modifications.prebidModifications;
export const selectBlockModifications = (state) => state.modifications.blockModifications;
export const selectSettingModifications = (state) => state.modifications.variableModifications;
export const selectSelectedGoogleTags = (state) => state.modifications.selectedGoogleTags;
export const selectAllModifications = (state) => state.modifications.allModifications;
export const selectRemovedModifications = (state) => state.modifications.removedModifications;

export const selectOneOfModification = () =>
  createSelector(
    selectDomain,
    (_, id) => id,
    (state, id) => state.allModifications.find((modification) => modification.id === id)
  );

export const selectModifications = () =>
  createSelector(selectDomain, (modificationState) => {
    return sortAllModification(modificationState);
  });

export const selectModification = () =>
  createSelector(
    selectModifications(),
    (_, createdAt) => createdAt,
    (modifications, createdAt) => modifications.find((modification) => modification.createdAt === createdAt)
  );

// TabBidders Modification Selector
export const tabBiddersModificationSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata, bidderId, occurrence } = args;

    return modifications.find(
      (modification) =>
        modification.operation === 'updateTabBidders' &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.metadata.bidder === bidderId &&
        modification.metadata.occurrence === occurrence &&
        modification.metadata.partnerWebsite === partnerWebsite
    );
  });

// TabBidders Modification Selector
export const tabBiddersModificationValueSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata, bidderId, occurrence, keywordId } = args;

    const modif = modifications.find(
      (modification) =>
        modification.operation === 'updateTabBidders' &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.metadata.bidder === bidderId &&
        modification.metadata.occurrence === occurrence &&
        modification.metadata.partnerWebsite === partnerWebsite
    );

    return modif?.metadata?.values?.[keywordId];
  });

export const addedTabBiddersSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata } = args;

    return modifications.filter(
      (modification) =>
        modification.operation === 'addTabBidders' &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.metadata.partnerWebsite === partnerWebsite
    );
  });

export const addedTabBidderSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata, createdAt } = args;

    return modifications.find(
      (modification) =>
        modification.operation === 'addTabBidders' &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.createdAt === createdAt &&
        modification.metadata.partnerWebsite === partnerWebsite
    );
  });

export const addedTabBidderValueSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata, createdAt, keyname } = args;

    const modif = modifications.find(
      (modification) =>
        modification.operation === 'addTabBidders' &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.createdAt === createdAt &&
        modification.metadata.partnerWebsite === partnerWebsite
    );

    return modif?.metadata?.values?.[keyname];
  });

export const addedTabBidderDefaultValueSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata, createdAt, keyname } = args;

    const modif = modifications.find(
      (modification) =>
        modification.operation === 'addTabBidders' &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.createdAt === createdAt &&
        modification.metadata.partnerWebsite === partnerWebsite
    );

    return modif?.metadata?.defaultValues?.[keyname];
  });

export const disabledTabBidderSelector = () =>
  createSelector([selectTabBiddersModifications, (state, args) => args], (modifications, args) => {
    const { partnerWebsite, metadata, bidderId, occurrence } = args;

    return modifications.findLast(
      (modification) =>
        (modification.operation === 'disableTabBidders' || modification.operation === 'enableTabBidders') &&
        modification.metadata.device === metadata?.device &&
        modification.metadata.position === metadata?.position &&
        modification.metadata.mediatype === metadata?.mediatype &&
        modification.metadata.bidder === bidderId &&
        modification.metadata.occurrence === occurrence &&
        modification.metadata.partnerWebsite === partnerWebsite
    );
  });

// Sizes modification Selector
export const nodeModificationsSelector = () =>
  createSelector([selectSizesModifications, (state, element) => element], (modifications, element) =>
    modifications.filter((modification) => {
      const { source, pagetypeId, deviceId, positionId } = element;
      const { headersToggled, pagetype, device, position } = modification.metadata;

      if (
        (modification.operation === 'addSize' || modification.operation === 'removeSize') &&
        (headersToggled.length === 0 || headersToggled.includes(source)) &&
        pagetypeId === pagetype &&
        (device === null || deviceId === device) &&
        (position === null || positionId === position)
      ) {
        return true;
      }

      return false;
    })
  );

// Position Selector
export const lastPositionModificationSelector = () =>
  createSelector([selectPositionsModifications, (state, element) => element], (modifications, element) =>
    [...modifications].findLast(
      (modification) =>
        (modification.operation === 'addPosition' || modification.operation === 'removePosition') &&
        (element.pagetypeId === -1 || modification.metadata.pagetypeId.includes(element.pagetypeId)) &&
        (!modification.metadata.deviceId || modification.metadata.deviceId.includes(element.deviceId)) &&
        (!element.positionId || modification.metadata.positionId.includes(element.positionId))
    )
  );

export const lastModifyPositionModificationSelector = () =>
  createSelector([selectPositionsModifications, (state, element) => element], (modifications, element) =>
    [...modifications].findLast(
      (modification) =>
        (modification.operation === 'modifyPosition' || modification.operation === 'addPosition') &&
        (element.pagetypeId === -1 || modification.metadata.pagetypeId.includes(element.pagetypeId)) &&
        (!modification.metadata.deviceId || modification.metadata.deviceId.includes(element.deviceId)) &&
        (!element.positionId || modification.metadata.positionId.includes(element.positionId))
    )
  );

export const addedPositionModificationSelector = () =>
  createSelector([selectPositionsModifications, (state, element) => element], (modifications, element) =>
    modifications.filter(
      (modification) =>
        modification.operation === 'addPosition' &&
        (element.pagetypeId === -1 || modification.metadata.pagetypeId.includes(element.pagetypeId)) &&
        (!modification.metadata.deviceId || modification.metadata.deviceId.includes(element.deviceId)) &&
        (!element.positionId || modification.metadata.positionId.includes(element.positionId))
    )
  );

// Setting selector
export const settingModificationSelector = () =>
  createSelector(
    selectSettingModifications,
    (_, variableName) => variableName,
    (modifications, variableName) =>
      modifications.find(
        (modification) =>
          modification.operation === 'updateVariable' && modification.metadata.variableName === variableName
      )
  );

export default modificationsSlice.reducer;
