import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectPlacementKeywordLibs } from '../../../../../../store/PlacementKeyworLibSlice';
import Heading from '../../../../../UI/Title/Heading';
import { AddIcon, DeleteIcon, PlacementAction, PlacementWrapper } from '../ModalModificationBidder.style';
import KeywordRule from './KeywordRule';

function BidderKeywordDetail(props) {
  const { index, removePlacement } = props;

  const placementKeywordLibs = useSelector(selectPlacementKeywordLibs);
  const { control } = useFormContext();

  const name = useMemo(() => `bidderPlacementKeywords.${index}`, [index]);
  const { fields, prepend, remove, move } = useFieldArray({
    control,
    name: `${name}.bidderPlacementKeywordRules`,
  });

  const handleRemoveKeywordRule = useCallback(
    (i) => {
      remove(i);
    },
    [remove]
  );

  return (
    <PlacementWrapper>
      <PlacementAction>
        <Controller
          render={({ field }) => (
            <FormControl sx={{ width: '150px' }} required>
              <InputLabel id=".label">Keyword</InputLabel>
              <Select {...field} label="Keyword" labelId={`${name}.placementKeywordLib.label`} defaultValue="">
                {placementKeywordLibs.map((p) => (
                  <MenuItem key={p['@id']} value={p['@id']}>
                    {p.keyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          control={control}
          name={`${name}.placementKeywordLib`}
        />
        <span>
          <Button onClick={() => removePlacement(index)} color="error" variant="outlined" sx={{ margin: '0 5px' }}>
            <DeleteIcon />
            Supprimer le placement
          </Button>
        </span>
      </PlacementAction>
      <PlacementAction>
        <Heading variant="h4">Règles</Heading>
        <span>
          <Button
            onClick={() => prepend({ defaultValue: '', constraints: '' })}
            variant="outlined"
            sx={{ margin: '0 5px' }}
          >
            <AddIcon />
            Ajouter une règle
          </Button>
        </span>
      </PlacementAction>
      {fields.map((r, i) => (
        <KeywordRule
          key={r.id}
          registerName={name}
          index={i}
          item={r}
          removeKeywordRule={handleRemoveKeywordRule}
          move={move}
        />
      ))}
    </PlacementWrapper>
  );
}

BidderKeywordDetail.propTypes = {
  index: PropTypes.number.isRequired,
  removePlacement: PropTypes.func.isRequired,
};

export default BidderKeywordDetail;
