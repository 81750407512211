import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { selectCurrentUser, selectCurrentUserStatus } from '../../store/UsersSlice';

function PrivateRoute(props) {
  const { roles } = props;

  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  const currentUser = useSelector(selectCurrentUser);
  const currentUserStatus = useSelector(selectCurrentUserStatus);

  useEffect(() => {
    if (currentUserStatus === 'idle' || currentUserStatus === 'loading') return;
    if (currentUserStatus === 'rejected') {
      navigate('/');
      return;
    }

    if (Array.isArray(roles) && currentUser?.roles.filter((role) => roles.includes(role)).length === 0) {
      navigate(-1);
      return;
    }

    if (!currentUser?.roles.includes(roles)) {
      navigate(-1);
      return;
    }

    setAuthenticated(true);
  }, [currentUserStatus, roles, currentUser?.roles, navigate]);

  return authenticated && <Outlet />;
}

PrivateRoute.propTypes = {
  roles: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
};

export default PrivateRoute;
