import styled from '@emotion/styled/macro';

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100vh;
  position: sticky;
  top: 0;
`;

export { SidebarWrapper };
