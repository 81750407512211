import styled from '@emotion/styled/macro';

const NavButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HeaderActionWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 0 10px 0 10px;
  width: 36px;
  height: 32px;
`;

const HeaderActionContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.bgc ?? '#2360dc'};
  color: white;
  font-size: 18px;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  top: 0;
  left: 0;

  :hover {
    top: -2px;
    left: -2px;
    box-shadow: 2px 2px 0 0 #fff;
  }
`;

const ConnectedAs = styled.p`
  color: white;
  margin-right: 10px;
`;

export { NavButtonWrapper, HeaderActionWrapper, HeaderActionContainer, ConnectedAs };
