import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getPrebidFloorFields(options = {}) {
  let url = `${API_BASE_URL}/prebid_floor_fields`;

  if ('paginate' in options) {
    url += `?paginate=${options.paginate}`;
  }

  const response = await defaultFetch(url);

  return response.json();
}

export async function getPrebidFloorFieldsTarget(id) {
  const url = `${API_BASE_URL}/prebid_floor_fields/${id}/target`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function createPrebidFloorFields(datas) {
  const url = `${API_BASE_URL}/prebid_floor_fields`;
  const response = await defaultFetch(url, 'POST', datas);

  return response.json();
}

export async function removePrebidFloorFields(id) {
  const url = `${API_BASE_URL}/prebid_floor_fields/${id}`;
  return defaultFetch(url, 'DELETE');
}
