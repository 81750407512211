/* eslint-disable */
import { React, Fragment } from 'react';
import { createPortal } from 'react-dom';
import classes from './FormModal.module.css';
import Backdrop from './BackDrop';
import Button from '../Basic/Buttons/Button';
import Card from '../Wrapper/Card';

function ModalOverlay(props) {
  return (
    <Card extraClasses={['modal']}>
      <header className={classes.header}>
        <h2>{props.title}</h2>
      </header>
      <div className={classes.content}>{props.children}</div>
      <footer className={classes.actions}>
        <Button onClick={props.onCancel}>Fermer</Button>
      </footer>
    </Card>
  );
}

function FormModal(props) {
  return (
    <>
      {createPortal(<Backdrop onConfirm={props.onCancel} />, document.getElementById('backdrop-root'))}
      {createPortal(
        <ModalOverlay title={props.title} onCancel={props.onCancel} children={props.children} />,
        document.getElementById('overlay-root')
      )}
    </>
  );
}

export default FormModal;
