import styled from '@emotion/styled/macro';

const CollapseContainer = styled.div`
  border: 0.5px solid lightgrey;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
`;

const TitleCollapse = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 0 10px;
`;

const CollapseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  color: black;
  padding: 10px;
`;

const ArrowTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CountNotification = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 20px;
  padding: 10px;
  align-self: center;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0;
`;

const TypedModificationContainer = styled.div`
  max-height: 50vh;
  overflow: auto;
`;

export { CollapseContainer, TitleCollapse, CollapseHeader, ArrowTitle, CountNotification, TypedModificationContainer };
