import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import Mep from '../components/Home/MEP/Mep';
import Modifications from '../components/Home/Modifications/Modifications';
import ListOfPartners from '../components/Home/PartnerSelection/List/ListOfPartners';
import ModificationProvider from '../components/Modifications/ModificationProvider/ModificationProvider';
import Card from '../components/UI/Wrapper/Card';
import { selectPartners } from '../store/PartnersSlice';
import { HomeWrapper } from './Home.style';

function Home() {
  const [selectedPartner, setSelectedPartner] = useState(null);

  const partners = useSelector(selectPartners);

  const selectPartnerHandler = (id) => {
    if (id) {
      setSelectedPartner(partners.find((partner) => partner.id === id));
    } else {
      setSelectedPartner(null);
    }
  };

  return (
    <HomeWrapper>
      <ModificationProvider>
        <ListOfPartners
          partners={partners}
          selectedPartner={selectPartnerHandler}
          selectedPartnerInfo={selectedPartner}
        />
        <Card extraClasses={['flex']} width="90%" sx={{ justifyContent: 'space-between' }}>
          <Mep />
          <Divider orientation="vertical" />
          <Modifications />
        </Card>
      </ModificationProvider>
    </HomeWrapper>
  );
}

export default Home;
