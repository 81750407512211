import styled from '@emotion/styled/macro';

const BlockSelectedColumnWrapper = styled.div`
  width: 25%;
  height: 70.7vh;
  overflow-y: scroll;
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px 15px;
  margin: 0 10px;
`;

const TitleColumn = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export { BlockSelectedColumnWrapper, TitleColumn };
