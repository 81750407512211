/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ControlledAutocomplete from '../../../../UI/ControlledAutocomplete';
import { ButtonWrapper, SizeFormControl } from './SizeSidebar.style';
import { addSizeModification, selectSelectedGoogleTags } from '../../../../../store/ModificationsSlice';
import { fetchPositions, selectAllPositions } from '../../../../../store/PositionsSlice';
import { fetchPagetypes, selectAllPageTypes } from '../../../../../store/PagetypesSlice';
import { fetchDevices, selectAllDevices } from '../../../../../store/DevicesSlice';
import { fetchSizes, selectSizes } from '../../../../../store/SizesSlice';
import MassButton from '../../../../UI/Basic/Buttons/MassButton/MassButton';

function SizeSidebar() {
  const { control, handleSubmit } = useForm();
  const devices = useSelector(selectAllDevices);
  const pageTypes = useSelector(selectAllPageTypes);
  const positions = useSelector(selectAllPositions);
  const sizes = useSelector(selectSizes);
  const types = ['GAM', 'Amazon', 'Pbjs'];
  const selectedGoogleTag = useSelector(selectSelectedGoogleTags);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (devices.length === 0) dispatch(fetchDevices());
  }, [dispatch, devices]);

  useEffect(() => {
    if (pageTypes.length === 0) dispatch(fetchPagetypes());
  }, [dispatch, pageTypes]);

  useEffect(() => {
    if (positions.length === 0) dispatch(fetchPositions());
  }, [dispatch, positions]);

  useEffect(() => {
    if (sizes.length === 0) dispatch(fetchSizes());
  }, [dispatch, sizes]);

  const updateSize = (data, method) => {
    if (selectedGoogleTag.length === 0) {
      enqueueSnackbar('Veuillez séléctionner au moins un GoogleTag.', { variant: 'error' });
      return;
    }

    const { size, position, device, pagetype, headersToggled } = data;

    if (!size) {
      enqueueSnackbar('Veuillez séléctionner une taille.', { variant: 'error' });
      return;
    }

    if (!pagetype) {
      enqueueSnackbar('Veuillez séléctionner au moins un pagetype.', { variant: 'error' });
      return;
    }

    if (!headersToggled) {
      enqueueSnackbar('Veuillez séléctionner au moins un type.', { variant: 'error' });
      return;
    }

    const dataToStore = {
      operation: method,
      metadata: {
        sizeId: size && size.map((s) => s.id),
        pagetype: pagetype && pagetype.map((pt) => pt.id),
        device: device && device.map((d) => d.id),
        position: position && position.map((pos) => pos.id),
        headersToggled,
      },
      googleTagVersions: selectedGoogleTag.map((gtag) => `api/google_tag_versions/${gtag}`),
    };
    dispatch(addSizeModification(dataToStore));
  };

  return (
    <div>
      <SizeFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={pageTypes}
          renderInput={(params) => <TextField {...params} label="PageType" placeholder="PageType" />}
          getOptionLabel={(option) => option.genericName}
          name="pagetype"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </SizeFormControl>

      <SizeFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={devices}
          renderInput={(params) => <TextField {...params} label="Device" placeholder="Device" />}
          getOptionLabel={(option) => option.genericName}
          name="device"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </SizeFormControl>

      <SizeFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={positions}
          renderInput={(params) => <TextField {...params} label="Position" placeholder="Position" />}
          getOptionLabel={(option) => option.genericName}
          name="position"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </SizeFormControl>

      <SizeFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={sizes}
          renderInput={(params) => <TextField {...params} label="Sizes" placeholder="Sizes" />}
          getOptionLabel={(option) => option.concatenatedSize}
          name="size"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.concatenatedSize}
            </li>
          )}
        />
      </SizeFormControl>

      <SizeFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={types}
          renderInput={(params) => <TextField {...params} label="Types" placeholder="Types" />}
          getOptionLabel={(option) => option}
          name="headersToggled"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option}
            </li>
          )}
        />
      </SizeFormControl>

      <ButtonWrapper>
        <MassButton colorButton="green" onClick={handleSubmit((data) => updateSize(data, 'addSize'))} width="48%">
          Ajouter
        </MassButton>
        <MassButton colorButton="red" onClick={handleSubmit((data) => updateSize(data, 'removeSize'))} width="48%">
          Supprimer
        </MassButton>
      </ButtonWrapper>
    </div>
  );
}

export default SizeSidebar;
