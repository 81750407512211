import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getSuggestions(data) {
  const url = new URL(`${API_BASE_URL}/suggestions`);
  url.search = param(data);

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function postSuggestion(body) {
  const url = `${API_BASE_URL}/suggestions`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function updateSuggestion(id, body) {
  const url = `${API_BASE_URL}/suggestions/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}
