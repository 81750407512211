/* eslint-disable import/no-cycle */
import defaultFetch from '../requestHelper';
import { API_BASE_URL } from '../../references/base-urls';

export async function loginCheck(data) {
  const url = `${API_BASE_URL}/login_check`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function refreshToken(data) {
  const url = `${API_BASE_URL}/token/refresh`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function forgotPassword(email) {
  const url = `${API_BASE_URL}/forgot_password/`;
  return defaultFetch(url, 'POST', { email });
}

export async function changePassword(token, password) {
  const url = `${API_BASE_URL}/forgot_password/${token}`;
  return defaultFetch(url, 'POST', { password });
}

export async function verifyToken(token) {
  const url = `${API_BASE_URL}/password_tokens/check_token`;
  const response = await defaultFetch(url, 'POST', { token });

  return response.json();
}
