import { BodyWrapper, CardPartner } from '../CreatePartner/CreatePartner.style';
import FormGroup from './Creation/FormGroup';
import ListGroups from './List/ListGroups';

function CreateGroup() {
  return (
    <CardPartner>
      <BodyWrapper>
        <FormGroup />
        <ListGroups />
      </BodyWrapper>
    </CardPartner>
  );
}

export default CreateGroup;
