import Header from '../../components/Authentication/Header';
import FormLogin from '../../components/Authentication/FormLogin';
import { LoginWrapper } from './Login.style';

function Login() {
  return (
    <LoginWrapper>
      <Header />
      <FormLogin />
    </LoginWrapper>
  );
}

export default Login;
