import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { verifyTokenExist } from '../../helpers/storage';
import { fetchLoggedUser, selectCurrentUser } from '../../store/UsersSlice';
import EventListener from '../EventListener/EventListener';
import Layout from '../UI/Layout/Layout';

function LoggedRoute() {
  const token = verifyTokenExist();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    if (!token) return;
    if (currentUser) return;

    dispatch(fetchLoggedUser());
  }, [dispatch, token, currentUser]);

  return token ? (
    <Layout>
      <Outlet />
      <EventListener />
    </Layout>
  ) : (
    <Navigate to="/login" replace />
  );
}

export default LoggedRoute;
