/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createPrebidFloor, getPrebidFloor, upload } from '../../helpers/api/prebidFloor';
import { snackActions } from '../../helpers/SnackBarUtils';

const domain = 'prebidFloor';

export const fetchPrebidFloors = createAsyncThunk(`${domain}/fetch`, async (options = {}, thunkAPI = null) => {
  try {
    return getPrebidFloor(options);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const postPrebidFloors = createAsyncThunk(`${domain}/post`, async (datas, thunkAPI = null) => {
  try {
    return createPrebidFloor(datas);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const uploadPrebidFloor = createAsyncThunk(`${domain}/upload`, async (data, thunkAPI = null) => {
  try {
    const { id, body } = data;
    return upload(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  data: [],
  total: 0,
  status: '',
  error: null,
};

export const PrebidFloorSlice = createSlice({
  name: 'prebidFloor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrebidFloors.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPrebidFloors.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.data = action.payload['hydra:member'];
      state.total = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchPrebidFloors.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
    builder.addCase(postPrebidFloors.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.total += 1;
      snackActions.success('Le fichier a été enregistré.');
    });
    builder.addCase(uploadPrebidFloor.fulfilled, () => {
      snackActions.success('Le fichier a été uploadé.');
    });
  },
});

export const selectPrebidFloor = (state) => state.prebidFloor.data;

export default PrebidFloorSlice.reducer;
