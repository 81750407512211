import styled from '@emotion/styled/macro';

const Hover = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
`;

const NoHover = styled.div`
  opacity: 1;
  cursor: default;
`;

const HoverWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: ${(props) => props.width ?? '243px'};
  height: ${(props) => props.height ?? '43px'};
  background: ${(props) => props.backgroundColor ?? '#fff'};
  border: 2px solid #dee2e6;
  border-radius: 6px;
  margin: 5px;
  /* text */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #343a40;

  &:hover ${NoHover} {
    opacity: 0;
  }

  &:hover ${Hover} {
    opacity: 1;
  }

  &.removed {
    color: #fcc419;
    border: 2px solid #fcc419;
  }

  &.added {
    color: #37b24d;
    border: 2px solid #37b24d;
  }
`;

export { HoverWrapper, Hover, NoHover };
