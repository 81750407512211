import React, { useMemo } from 'react';
import { Copyright, SubCopyright, Wrapper } from './Footer.style';

function Footer() {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Wrapper>
      <Copyright>©&nbsp;{year}&nbsp;Additi Media&nbsp;&#8212;&nbsp;BU Monétisation</Copyright>
      <SubCopyright>
        {process.env.REACT_APP_NAME}@{process.env.REACT_APP_VERSION}&nbsp;&#8212;&nbsp;All rights reserved
      </SubCopyright>
    </Wrapper>
  );
}

export default Footer;
