/* eslint-disable */
import { useState, useEffect, Fragment } from 'react';
import image from '../../../../images/csv-struct.png';
import { API_BASE_URL } from '../../../../references/base-urls';

function BidderXlsInstructions() {
  const [lists, setLists] = useState();

  useEffect(() => {
    fetchTables();
  }, []);

  const singleColTable = (arr, title) => {
    const cells = arr.map((el) => {
      return (
        <tr>
          <td>{el}</td>
        </tr>
      );
    });
    return (
      <>
        <h2>{title}</h2>
        <table>
          <tbody>{cells}</tbody>
        </table>
      </>
    );
  };

  const fetchTables = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/import_file_histories/ssp_instructions`);
      const data = await response.json();
      if (data.content) {
        setLists(data.content);
      }
    } catch (error) {}
  };

  let bidderPlacementKeyWords = null;
  let mediatypes = null;
  let positions = null;
  let devices = null;
  let partners = null;

  if (lists) {
    if (lists.mediatypes.length > 0) {
      mediatypes = singleColTable(lists.mediatypes, 'MEDIATYPES');
    }
    if (lists.positions.length > 0) {
      positions = singleColTable(lists.positions, 'POSITIONS');
    }
    if (lists.devices.length > 0) {
      devices = singleColTable(lists.devices, 'DEVICES');
    }
    if (lists.bidders) {
      const content = Object.keys(lists.bidders).map((bidderKey) => {
        return (
          <tr>
            <td>{bidderKey}</td>
            <td>{lists.bidders[bidderKey].join(' / ')}</td>
          </tr>
        );
      });
      bidderPlacementKeyWords = (
        <>
          <h2>SSP ET CLES ASSOCIEES</h2>
          <table>
            <thead>
              <tr>
                <th>SSP</th>
                <th>Clés</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
        </>
      );
    }
    if (lists.partners) {
      const content = Object.keys(lists.partners).map((partnerKey) => {
        return (
          <tr>
            <td>{partnerKey}</td>
            <td>{lists.partners[partnerKey]}</td>
          </tr>
        );
      });
      partners = (
        <>
          <h2>SITES PARTENAIRES</h2>
          <table>
            <thead>
              <tr>
                <th>Partner</th>
                <th>Trigram</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </table>
        </>
      );
    }
  }

  return (
    <div>
      <h1>Mode d'emploi pour un import csv réussi</h1>
      <ul>
        <li>Votre fichier doit finir en .csv</li>
        <li>Le séparateur doit être le point-virgule (;)</li>
        <li>
          L'ordre des colonnes et les titres sont représentés ici :<br />
          <br />
          <img src={image} alt="contacts" />
          <br />
          <br />
        </li>
        <li>
          Les 4 premières colonnes doivent respecter cet ordre, mais les suivantes correspondent à vos ID de placement
          (pour Teads ici) peuvent suivre l'ordre de votre choix
        </li>
        <li>Les sites partenaires sont représentés via un trigramme (voir liste)</li>
        <li>Les index des différents mots-clés reconnus et autorisées par l'appli est proposée ici:</li>
      </ul>
      {bidderPlacementKeyWords}
      {partners}
      {mediatypes}
      {positions}
      {devices}
    </div>
  );
}
export default BidderXlsInstructions;
