import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AiFillEdit, AiOutlineArrowRight } from 'react-icons/ai';
import { GrCheckboxSelected } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedVersion } from '../../../../../../helpers/api/blocks';
import { VersionWrapper, TextWrapper } from './VersionSubMenu.style';
import { addCodetabs } from '../../../CodeSlice';
import { addBlockModification } from '../../../../../../store/ModificationsSlice';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { fetchVersions, selectVersionsByBlockId } from '../../../VersionSlice';

function VersionSubMenu(props) {
  const { idBlock, idVersionSelected, nameBlock, gtagBVid } = props;

  const versions = useSelector((state) => selectVersionsByBlockId(state, idBlock));
  const dispatch = useDispatch();
  const googleTag = useSelector(selectGoogleTagVersion);

  useEffect(() => {
    dispatch(fetchVersions(idBlock));
  }, [dispatch, idBlock]);

  const handleModify = (version) => {
    const data = {
      id: version.id,
      idBlock,
      version: version.version,
      name: nameBlock,
      code: version.code,
    };
    dispatch(addCodetabs(data));
  };

  const onVersionSelected = (version) => {
    getFormattedVersion(version.id).then(() => {
      const modification = {
        operation: 'changeVersion',
        metadata: {
          method: 'PUT',
          type: 'changeversionSelected',
          idGTAGBV: gtagBVid,
          idVersion: version.id,
          idBlock,
          oldIdVersion: idVersionSelected,
        },
        googleTagVersions: [`/api/google_tag_versions/${googleTag.id}`],
      };

      dispatch(addBlockModification(modification));
    });
  };

  return (
    <div>
      {versions.map((version) => (
        <VersionWrapper key={version.id} selectedVersion={version.id === idVersionSelected}>
          <TextWrapper> {`v${version.version}:  ${version.modifications}`}</TextWrapper>
          {version.id === idVersionSelected ? (
            <AiOutlineArrowRight size={25} />
          ) : (
            <GrCheckboxSelected size={25} color="lightgrey" onClick={() => onVersionSelected(version)} />
          )}
          <AiFillEdit size={25} onClick={() => handleModify(version)} />
        </VersionWrapper>
      ))}
    </div>
  );
}

VersionSubMenu.propTypes = {
  idBlock: PropTypes.number.isRequired,
  idVersionSelected: PropTypes.number.isRequired,
  nameBlock: PropTypes.string.isRequired,
  gtagBVid: PropTypes.number.isRequired,
};

export default VersionSubMenu;
