import styled from '@emotion/styled/macro';
import { Input } from '@mui/material';

const FormStyling = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const InputWidth = styled(Input)`
  width: 100%;
  margin-top: 2px;
`;

export { FormStyling, InputWidth };
