import PropTypes from 'prop-types';

const VariableType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  varType: PropTypes.string.isRequired,
  idGtagBv: PropTypes.number.isRequired,
  idVariableLocal: PropTypes.number,
});

export default VariableType;
