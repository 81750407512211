import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GoogleTagList = styled.div`
  font-size: 25px;
`;

const GoogleTagListLink = styled(Link)`
  font-size: 25px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const TitleGoogleTag = styled.b`
  font-weight: bold;
  color: ${({ color }) => color ?? 'black'};
`;

const TitlePartner = styled.h1`
  font-size: 45px;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;

  > * {
    margin: 5px;
    height: 100%;
  }
`;

const CloneLink = styled(Link)`
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
`;

export { TitleContainer, GoogleTagList, TitleGoogleTag, GoogleTagListLink, TitlePartner, ButtonContainer, CloneLink };
