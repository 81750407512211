import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { StyledTextField } from '../Floor.style';

function ModelWeight(props) {
  const { control, registerTarget } = props;

  return (
    <Controller
      render={({ field }) => (
        <StyledTextField
          id={registerTarget}
          label="Model Weight"
          type="number"
          variant="filled"
          {...field}
          value={field.value ?? ''}
        />
      )}
      control={control}
      name={registerTarget}
    />
  );
}

ModelWeight.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  registerTarget: PropTypes.string.isRequired,
};

export default ModelWeight;
