import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useState } from 'react';
import DraggableObject from './DraggableObject';
import { StyledLabel } from './SidebarItems.style';

function SidebarItems(props) {
  const { items, inactiveText, labelFunction } = props;

  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleDragEvent = (event, item) => {
    event.dataTransfer.setData('text/plain', item.id);
  };

  return (
    <div>
      <TextField
        label="Filtre"
        fullWidth
        placeholder="Saisissez votre filtre..."
        variant="filled"
        value={value}
        onChange={handleChange}
        size="small"
      />
      {items.map((item) => {
        const label = labelFunction(item);

        const lowerCaseLabel = label.toLowerCase();
        const valueCaseLabel = value.toLowerCase();

        if (!lowerCaseLabel.includes(valueCaseLabel)) return null;

        return (
          <DraggableObject
            key={item.id}
            active={item.isActive}
            tooltipText={inactiveText}
            onDragStart={(event) => handleDragEvent(event, item)}
            colorCode={item.hexColorCode?.code ?? item.hexColorCode}
          >
            <StyledLabel>{label}</StyledLabel>
          </DraggableObject>
        );
      })}
    </div>
  );
}

SidebarItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      hexColorCode: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          code: PropTypes.string,
        }),
      ]),
    })
  ).isRequired,
  inactiveText: PropTypes.string,
  labelFunction: PropTypes.func.isRequired,
};

SidebarItems.defaultProps = {
  inactiveText: '',
};

export default SidebarItems;
