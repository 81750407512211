import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllModifications() {
  const url = `${API_BASE_URL}/draft_modifications?order[id]=desc&itemsPerPage=30`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getGoogleTagModifications(id) {
  const url = `${API_BASE_URL}/draft_modifications?pagination=false&targetGoogleTagVersions=${id}`;
  const response = await defaultFetch(url);

  return response.json();
}
