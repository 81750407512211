import { Checkbox, InputLabel, ListItemText, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { postPartnerGroup } from '../../../../../helpers/api/partner';
import { fetchPartnerGroups } from '../../../../../store/GroupSlice';
import { fetchAllPartners, selectPartners } from '../../../../../store/PartnersSlice';
import Heading from '../../../../UI/Title/Heading';
import {
  CreatePartnerButton,
  FieldError,
  GroupCreationFormGrid,
  SelectFixedWith,
  SubmitWrapper,
  TextFieldGroup,
} from '../../CreatePartner/CreatePartner.style';

function FormGroup() {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { partnerWebsite: [] } });

  const partnersSelected = watch('partnerWebsite');

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const partners = useSelector(selectPartners);

  useEffect(() => {
    dispatch(fetchAllPartners({ pagination: false }));
  }, [dispatch]);

  const groupCreation = (data) => {
    const { partnerWebsite, name, gcpRepository, trigram } = data;
    if (partnerWebsite.length > 1 && (!name || !gcpRepository || !trigram)) {
      return;
    }
    const partnersAPI = [];
    partnersSelected.forEach((partner) => {
      const goodPartner = partners.filter((part) => partner === part.displayName);
      partnersAPI.push(`api/partner_websites/${goodPartner[0].id}`);
    });
    const dataToApi = {
      name,
      gcpRepository,
      trigram,
      partnerWebsites: partnersAPI,
    };

    postPartnerGroup(dataToApi).then(() => {
      enqueueSnackbar('Le groupe de partenaire a bien été créé', { variant: 'success' });
      reset({ name: '', gcpRepository: '', trigram: '', partnerWebsite: [] });
      dispatch(fetchPartnerGroups());
    });
  };

  return (
    <div>
      <Heading>Création d&apos;un groupe</Heading>
      <form onSubmit={handleSubmit((data) => groupCreation(data))}>
        <GroupCreationFormGrid>
          <div>
            <div>
              <TextFieldGroup
                {...register('name', { required: partnersSelected.length > 1 })}
                id="input-with-sx"
                label="Nom du groupe (obligatoire si plus d'un partenaire)"
                variant="standard"
              />
              {errors.name?.type === 'required' && (
                <FieldError>Le nom est requis si plus d&#39;un partenaire</FieldError>
              )}
            </div>
            <div>
              <TextFieldGroup
                {...register('gcpRepository', { required: true })}
                id="input-with-sx"
                label="GCP Repository"
                variant="standard"
              />
              {errors.gcpRepository?.type === 'required' && <FieldError>Le repertoire GCP est obligatoire.</FieldError>}
            </div>
            <div>
              <TextFieldGroup
                {...register('trigram', { maxLength: 3, minLength: 3, required: partnersSelected.length > 1 })}
                id="input-with-sx"
                label="Trigram (obligatoire si plus d'un partenaire)"
                variant="standard"
              />
              {errors.trigram?.type === 'required' && (
                <FieldError>Le trigram est requis si plus d&#39;un partenaire</FieldError>
              )}
              {errors.trigram && <FieldError>Le trigram doit faire 3 caractères</FieldError>}
            </div>
          </div>
          <div>
            <InputLabel> Partenaire du groupe </InputLabel>
            <SelectFixedWith
              {...register('partnerWebsite')}
              name="partnerWebsite"
              multiple
              renderValue={(selected) => selected.join(', ')}
              defaultValue={[]}
            >
              {partners &&
                partners.map((partner) => (
                  <MenuItem key={partner.id} value={partner.displayName}>
                    <Checkbox checked={partnersSelected.indexOf(partner.displayName) > -1} />
                    <ListItemText primary={partner.displayName} />
                  </MenuItem>
                ))}
            </SelectFixedWith>

            {errors.partnerWebsite?.type === 'required' && <p>Au moins un partenaire par groupe.</p>}
          </div>
          <SubmitWrapper>
            <CreatePartnerButton type="submit">Créer le groupe</CreatePartnerButton>
          </SubmitWrapper>
        </GroupCreationFormGrid>
      </form>
    </div>
  );
}

export default FormGroup;
