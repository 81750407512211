import PropTypes from 'prop-types';
import { Collapse } from '@mui/material';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ArrowTitle,
  CollapseContainer,
  CollapseHeader,
  CountNotification,
  TitleCollapse,
  TypedModificationContainer,
} from './CollapseNotifications.style';
import { selectUserNotifications } from '../NotificationSlice';
import { getNotificationComponent, getNotificationTypeName } from '../../../helpers/NotificationHelper';

function CollapseNotification(props) {
  const { open, setOpen, typeValue, typeId } = props;

  const userNotifications = useSelector(selectUserNotifications);

  const notificationsTyped = useMemo(() => {
    return userNotifications.filter((userNotification) => typeValue === userNotification.notification.type);
  }, [userNotifications, typeValue]);

  const unreadNotification = useMemo(() => {
    return notificationsTyped.filter((notification) => notification.state === 0);
  }, [notificationsTyped]);

  const NotificationComponent = useMemo(() => {
    return getNotificationComponent(typeValue);
  }, [typeValue]);

  const title = useMemo(() => getNotificationTypeName(typeId), [typeId]);

  return (
    notificationsTyped.length > 0 && (
      <CollapseContainer>
        <CollapseHeader onClick={() => setOpen(typeId)}>
          <ArrowTitle>
            {open ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
            <TitleCollapse>{title}</TitleCollapse>
          </ArrowTitle>
          {unreadNotification.length > 0 && <CountNotification>{unreadNotification.length}</CountNotification>}
        </CollapseHeader>
        <Collapse in={open} timeout="auto">
          <TypedModificationContainer>
            {notificationsTyped.map((notification) => (
              <NotificationComponent key={notification.id} notifId={notification.id} />
            ))}
          </TypedModificationContainer>
        </Collapse>
      </CollapseContainer>
    )
  );
}

CollapseNotification.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  typeValue: PropTypes.number.isRequired,
  typeId: PropTypes.string.isRequired,
};

export default CollapseNotification;
