import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MepWrapper } from './Mep.style';
import { fetchMepMessage, selectMepMessage } from '../../../store/MessageQueueSlice';
import MepItem from './Item/MepItem';
import { fetchAllUsers, selectUsers } from '../../../store/UsersSlice';
import { ScrollableDiv } from '../Modifications/Modifications.style';

function Mep() {
  const dispatch = useDispatch();
  const messages = useSelector(selectMepMessage);
  const users = useSelector(selectUsers);

  useEffect(() => {
    dispatch(fetchMepMessage());
  }, [dispatch]);

  useEffect(() => {
    if (users.length === 0) dispatch(fetchAllUsers());
  }, [dispatch, users]);

  return (
    <MepWrapper>
      <b> Les 30 dernières mise en production </b>
      <ScrollableDiv>
        {messages.map((message) => (
          <MepItem key={message.id} messageId={message.id} />
        ))}
      </ScrollableDiv>
    </MepWrapper>
  );
}

export default Mep;
