import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import CreatePartner from './CreatePartner/CreatePartner';
import AdminPartnersTable from './List/AdminPartnersTable';
import CreateGroup from './CreateGroup/CreateGroup';
import GCPRepository from './GCPRepository/GCPRepository';

function ManagePartner() {
  const [tab, setTab] = useState(1);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs value={tab} onChange={handleChange}>
        <Tab value={1} label="Liste des partenaires" />
        <Tab value={2} label="Création d'un partenaire" />
        <Tab value={3} label="Création d'un groupe" />
        <Tab value={4} label="Assignation des repertoires GCP" />
      </Tabs>
      {tab === 1 && <AdminPartnersTable activeTab={tab} index={1} />}
      {tab === 2 && <CreatePartner activeTab={tab} index={2} />}
      {tab === 3 && <CreateGroup activeTab={tab} index={3} />}
      {tab === 4 && <GCPRepository activeTab={tab} index={4} />}
    </>
  );
}

export default ManagePartner;
