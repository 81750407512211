import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSizes } from '../../../../../store/SizesSlice';
import Card from '../../../../UI/Wrapper/Card';
import { selectTabBidders } from '../Tabs/TabBiddersTab/TabBiddersModificationsSlice';
import History from './History/History';
import SidebarItems from './SelectionPanel/SidebarItems';
import { SidebarWrapper } from './Sidebar.style';

function Sidebar(props) {
  const { activeTab } = props;

  const sizes = useSelector(selectSizes);
  const tabBidders = useSelector(selectTabBidders);

  const data = useMemo(() => (activeTab === 1 ? sizes : tabBidders), [activeTab, sizes, tabBidders]);
  const inactiveText = useMemo(() => (activeTab === 1 ? 'Taille inactive' : 'Bidder inactif'), [activeTab]);

  const labelFunction = useCallback(
    (item) => {
      if (activeTab === 1) {
        return `${item.concatenatedSize} ${item.stringValue ? item.stringValue : ''}`;
      }

      return item.displayName;
    },
    [activeTab]
  );

  return (
    <SidebarWrapper>
      <Card height="20%">
        <History />
      </Card>
      <Card height="80%">
        <SidebarItems items={data} labelFunction={labelFunction} inactiveText={inactiveText} />
      </Card>
    </SidebarWrapper>
  );
}

Sidebar.propTypes = {
  activeTab: PropTypes.number.isRequired,
};

export default Sidebar;
