export const statusGoogleTags = [
  {
    key: 'WiP',
    value: '0',
    color: '#30bbff',
  },
  {
    key: 'Modifications terminés',
    value: '1',
    color: '#2d54e8',
  },
  {
    key: 'En QUAL',
    value: '2',
    color: '#c8a51e',
  },
  {
    key: 'QUAL approuvé',
    value: '3',
    color: '#f64b02',
  },
  {
    key: 'En PROD',
    value: '4',
    color: '#008000',
  },
  {
    key: 'Jadis en Prod',
    value: '5',
    color: '#000000',
  },
  {
    key: 'Abandonné',
    value: '6',
    color: '#707070',
  },
];

export const statusForMep = [
  {
    key: 'En cours de modification',
    value: '0',
  },
  {
    key: 'Modifications terminés',
    value: '1',
  },
  {
    key: 'En QUAL',
    value: '2',
  },
  {
    key: 'En PROD',
    value: '4',
  },
];
