import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { BsPencilFill } from 'react-icons/bs';
import { patchBidder } from '../../../../../helpers/api/bidders';
import BidderType from '../../../../../types/BidderType';
import { CustomTD } from '../../../../UI/CompleteTable/Table.style';
import LinkWrapper from '../../../../UI/LinkWrapper/LinkWrapper';
import MuiModal from '../../../../UI/Modal/MuiModal';
import BidderSwitch from '../../../../UI/Switch/BidderSwitch';
import ModalModificationBidder from '../ModalModificationBidder/ModalModificationBidder';
import { IconSwitchWrapper } from './LineBidder.style';

function LineBidder(props) {
  const { item, page, itemsPerPage } = props;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(item.isActive);

  const handleChange = () => {
    setOpen(!open);
  };

  const handleChangeActive = () => {
    patchBidder(item.id, { isActive: !checked }).then(() => setChecked(!checked));
  };

  const type = useMemo(() => {
    switch (item.type) {
      case 0:
        return 'Standard';
      case 1:
        return 'Alias';
      case 2:
        return 'AliasNative';
      default:
        return 'none';
    }
  }, [item.type]);

  return (
    <>
      <MuiModal open={open} handleClose={handleChange} width="60vw">
        <ModalModificationBidder item={item} setOpen={setOpen} page={page} itemsPerPage={itemsPerPage} />
      </MuiModal>
      <CustomTD active={checked} title={item.id}>
        {item.id}
      </CustomTD>
      <CustomTD active={checked} title={item.displayName}>
        {item.displayName}
      </CustomTD>
      <CustomTD active={checked} title={item.keyname}>
        {item.keyname}
      </CustomTD>
      <CustomTD active={checked} title={item.monetizationType && item.monetizationType.genericName}>
        {item.monetizationType && item.monetizationType.genericName}
      </CustomTD>
      <CustomTD active={checked} title={item.adapter && item.adapter.adapter}>
        {item.adapter && item.adapter.adapter}
      </CustomTD>
      <CustomTD active={checked} title={type}>
        {type}
      </CustomTD>
      <CustomTD active={checked} title={item.isAmpCompliant ? 'Oui' : 'Non'}>
        {item.isAmpCompliant ? 'Oui' : 'Non'}
      </CustomTD>
      <CustomTD active={checked} title={item.authorizedToBid ? 'Oui' : 'Non'}>
        {item.authorizedToBid ? 'Oui' : 'Non'}
      </CustomTD>
      <CustomTD active={checked} title={item.uuid}>
        {item.uuid}
      </CustomTD>
      <IconSwitchWrapper active={checked}>
        <BidderSwitch
          defaultChecked={item.isActive}
          onChange={handleChangeActive}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <LinkWrapper onClick={handleChange}>
          <BsPencilFill size={20} />
        </LinkWrapper>
      </IconSwitchWrapper>
    </>
  );
}

LineBidder.propTypes = {
  item: BidderType.isRequired,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default LineBidder;
