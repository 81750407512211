import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { RiArticleLine } from 'react-icons/ri';
import { CellButton, LineGrid, ListContainer, ListItemButtonWrapper } from './Grid.style';
import DeviceGrid from './DeviceGrid';
import CellGrid from './CellGrid';
import { selectNodesDevices, selectNodesHeaders } from './NodesSlice';

function PagetypeGrid(props) {
  const { node } = props;
  const { pagetypeName, pagetypeId } = node;

  const selector = useMemo(() => ({ pagetypeId }), [pagetypeId]);

  const headers = useSelector(selectNodesHeaders);
  const nodesDevicesSelector = useMemo(selectNodesDevices, []);
  const devices = useSelector((state) => nodesDevicesSelector(state, selector));

  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(0);

  const line = useMemo(() => {
    const tmpLine = [];

    headers.forEach((header) => {
      const key = header + pagetypeName;

      tmpLine.push(<CellGrid key={key} className={header} pagetype={pagetypeId} />);
    });

    return tmpLine;
  }, [headers, pagetypeName, pagetypeId]);

  return (
    <LineGrid
      columns={headers.length + 1}
      hover={hover > 0}
      onDragEnter={() => setHover((h) => h + 1)}
      onDragLeave={() => setHover((h) => h - 1)}
      onDrop={() => setHover(0)}
    >
      {devices.length > 0 && (
        <>
          <ListItemButton sx={ListItemButtonWrapper} onClick={() => setExpanded(!expanded)} disableTouchRipple>
            <CellButton index={0}>
              <ListItemIcon>
                <RiArticleLine />
              </ListItemIcon>
              <ListItemText primary={pagetypeName} />
            </CellButton>
            {line}
          </ListItemButton>

          <Collapse in={expanded} timeout="auto" sx={{ gridColumn: '1/5' }}>
            <List component="div" sx={ListContainer}>
              {devices.map((children) => (
                <DeviceGrid key={children.deviceId} pagetypeId={pagetypeId} node={children} />
              ))}
            </List>
          </Collapse>
        </>
      )}
    </LineGrid>
  );
}

PagetypeGrid.propTypes = {
  node: PropTypes.shape({
    pagetypeName: PropTypes.string.isRequired,
    pagetypeId: PropTypes.number.isRequired,
  }).isRequired,
};

export default PagetypeGrid;
