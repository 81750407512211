import { Collapse, FormControl, InputLabel, ListItemButton, ListItemText, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { snackActions } from '../../../../helpers/SnackBarUtils';
import {
  patchPlacementKeywordLib,
  selectPlacementKeywordLib,
  selectPlacementKeywordLibsTypes,
} from '../../../../store/PlacementKeyworLibSlice';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import Heading from '../../../UI/Title/Heading';
import Card from '../../../UI/Wrapper/Card';

function PlacementItem(props) {
  const { placementId } = props;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const placementSelector = useMemo(selectPlacementKeywordLib, []);
  const placement = useSelector((state) => placementSelector(state, placementId));

  const placementKeywordLibsTypes = useSelector(selectPlacementKeywordLibsTypes);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      type: placementKeywordLibsTypes.find((type) => type.value === placement?.type)?.value,
    },
  });

  const updateKeyword = (values) => {
    const data = {
      type: values.type,
    };
    dispatch(patchPlacementKeywordLib({ id: placement.id, body: data }))
      .unwrap()
      .then((res) => {
        snackActions.success(`Le placement ${res.keyName} a été modifié`, { variant: 'success' });
      });
  };

  const handleOpen = () => {
    setOpen((o) => !o);
  };

  return (
    <Card key={placement.id} sx={{ margin: '0', padding: '0', height: 'fit-content' }}>
      <ListItemButton onClick={handleOpen}>
        <ListItemText
          sx={{ padding: '0', margin: '0' }}
          primary={<Heading variant="h3">{placement.keyName}</Heading>}
        />
        {open ? <MdExpandLess /> : <MdExpandMore />}
      </ListItemButton>
      <Collapse in={open} orientation="vertical" unmountOnExit>
        <form onSubmit={handleSubmit(updateKeyword)} style={{ padding: '10px' }}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth sx={{ margin: '5px 0' }}>
                <InputLabel id=".label">Keyword</InputLabel>
                <Select {...field} label="Keyword" labelId="type">
                  {placementKeywordLibsTypes.map((p) => (
                    <MenuItem key={p.value} value={p.value}>
                      {p.id.slice(5)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={control}
            name="type"
          />
          <MassButton type="submit">Modifier le type du placement</MassButton>
        </form>
      </Collapse>
    </Card>
  );
}

PlacementItem.propTypes = {
  placementId: PropTypes.number.isRequired,
};

export default PlacementItem;
