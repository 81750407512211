import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../store/UsersSlice';
import ItemModification from '../../Home/Modifications/ItemModification/ItemModification';
import ModificationProvider from '../../Modifications/ModificationProvider/ModificationProvider';
import Heading from '../../UI/Title/Heading';
import { selectPartnerInfos } from '../PartnerPageSlice';
import { ModificationsContainer } from './PartnerModifications.style';

function PartnerModifications() {
  const currentUser = useSelector(selectCurrentUser);
  const partnerInfos = useSelector(selectPartnerInfos);
  const modifications = useMemo(() => partnerInfos.modifications, [partnerInfos]);

  return (
    modifications && (
      <ModificationProvider>
        <Heading variant="h2">10 Dernières modifications</Heading>
        <ModificationsContainer fullHeight={currentUser?.roles.includes('ROLE_ADMIN')}>
          {modifications.map((modification) => (
            <ItemModification key={modification.id} modificationProps={modification} />
          ))}
        </ModificationsContainer>
      </ModificationProvider>
    )
  );
}

export default PartnerModifications;
