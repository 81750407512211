import PropTypes from 'prop-types';
import { BiLeftArrow, BiDownArrow } from 'react-icons/bi';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IconWrapper, PartnerButton, PartnerItemWrapper, PrebidSection } from './PartnerCard.style';
import CollapseSelectedPartner from '../CollapseSelectedPartner';
import { selectPartner } from '../../../../store/PartnersSlice';
import { selectPrebidVersion, selectPrebidVersionsSelected } from '../../../../store/PrebidVersionSlice';

function PartnerCard(props) {
  const { onSelectPartner, id, isSelected } = props;
  const selectedPrebidVersion = useSelector(selectPrebidVersionsSelected);

  const selectPartnerHandler = (event) => {
    event.preventDefault();
    if (isSelected) {
      onSelectPartner(null);
    } else {
      onSelectPartner(id);
    }
  };

  const partnerSelector = useMemo(selectPartner, []);
  const partner = useSelector((state) => partnerSelector(state, id));

  const Icon = useMemo(() => {
    if (isSelected) {
      return BiDownArrow;
    }

    return BiLeftArrow;
  }, [isSelected]);

  const PrebidVersionSelector = useMemo(selectPrebidVersion, []);
  const prebid = useSelector((state) => PrebidVersionSelector(state, partner.prebidVersionId));

  const colorPrebid = useMemo(() => {
    const presentSelected = selectedPrebidVersion.find(
      (prebidSelected) => prebidSelected.id === partner?.prebidVersionId
    );
    if (partner.prebidVersionId && presentSelected) {
      return {
        label: 'Cette version de Prebid est encore à jour',
        color: 'green',
      };
    }
    if (partner.prebidVersionId && !presentSelected) {
      return {
        label: "Cette version de Prebid n'est plus autorisée par l'administrateur",
        color: 'orange',
      };
    }
    return {
      label: 'Veuillez assigner une version de Prebid.',
      color: 'red',
    };
  }, [partner.prebidVersionId, selectedPrebidVersion]);

  return (
    <PartnerItemWrapper>
      <PartnerButton type="button" onClick={selectPartnerHandler} isSelected={isSelected}>
        <p>{partner.displayName}</p>
        <IconWrapper>
          <PrebidSection color={colorPrebid.color} title={colorPrebid.label}>
            {prebid?.version ?? 'No Prebid Version'}{' '}
          </PrebidSection>
          <Icon />
        </IconWrapper>
      </PartnerButton>
      {isSelected && <CollapseSelectedPartner selectedPartnerInfo={partner} />}
    </PartnerItemWrapper>
  );
}

PartnerCard.propTypes = {
  onSelectPartner: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default PartnerCard;
