import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getBlocks(params) {
  const url = new URL(`${API_BASE_URL}/blocks`);
  url.search = param(params);

  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function getAllBlocksFormatted() {
  const url = `${API_BASE_URL}/blocks/formatted`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getOrganizedBlocks(id, modifications) {
  const url = `${API_BASE_URL}/google_tag_versions/${id}/blocks`;
  const response = await defaultFetch(url, 'POST', modifications);

  return response.json();
}

export async function getCode(id, modifications) {
  const url = `${API_BASE_URL}/google_tag_versions/${id}/preview`;
  const response = await defaultFetch(url, 'POST', modifications);

  return response.json();
}

export async function getAllVersionsBloc(id) {
  const url = `${API_BASE_URL}/blocks/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postVersionBlock(data) {
  const url = `${API_BASE_URL}/version_blocks`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function getFormattedVersion(id) {
  const url = `${API_BASE_URL}/version_blocks/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getVersionBlocksByBlockName(name) {
  const url = `${API_BASE_URL}/version_blocks?block.name=${name}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getGtagVersionBlockByGoogleTagAndBlockName(googleTagId, name) {
  const url = `${API_BASE_URL}/gtag_block_versions?googleTagVersion=${googleTagId}&versionBlock.block.name=${name}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getMainBlocks() {
  const url = `${API_BASE_URL}/blocks/main_blocks?isMainBlock=true&pagination=false`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function saveSortingBlocks(data) {
  const url = `${API_BASE_URL}/blocks/saveOrderBlock`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function createBlock(data) {
  const url = `${API_BASE_URL}/blocks/createBlock`;
  const response = await defaultFetch(url, 'POST', data);

  return response.json();
}

export async function putBlock(id, data) {
  const url = `${API_BASE_URL}/blocks/${id}`;
  const response = await defaultFetch(url, 'PUT', data);

  return response.json();
}

export async function deleteBlock(id) {
  const url = `${API_BASE_URL}/blocks/${id}`;
  const response = await defaultFetch(url, 'DELETE');

  if (response.status === 204) {
    return 'Le bloc a bien été supprimé';
  }
  return response.json();
}
