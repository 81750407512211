import styled from '@emotion/styled/macro';
import { FormControl, TextField } from '@mui/material';

const PrebidVersionWrapper = styled.div`
  display: flex;
  margin: 10px;
`;

const StyledFormControl = styled(FormControl)`
  margin: 2px;
`;

const StyledTextField = styled(TextField)`
  margin: 2px;
`;

const ModelGroupWrapper = styled.div`
  margin: 10px;
`;

export { PrebidVersionWrapper, StyledFormControl, StyledTextField, ModelGroupWrapper };
