import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPositionRTBById } from '../../../store/PositionRTBSlice';

function RtbDetail(props) {
  const { modification } = props;
  const { operation, metadata } = modification;

  const rtbSelector = useMemo(selectPositionRTBById, []);
  const rtbPosition = useSelector((state) => rtbSelector(state, metadata.rtbPosition));

  const rtbLabel = <strong key="label">({rtbPosition ? rtbPosition.description : metadata.rtbPosition})</strong>;

  return (
    <div>
      La position <strong>({metadata.name})</strong> a été{' '}
      {operation === 'delete adPosition'
        ? 'supprimée sur les positions RTB'
        : ['ajoutée sur la position RTB ', rtbLabel]}
    </div>
  );
}

RtbDetail.propTypes = {
  modification: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      name: PropTypes.string.isRequired,
      rtbPosition: PropTypes.number,
    }).isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default RtbDetail;
