import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MuiModal from '../../../UI/Modal/MuiModal';
import { selectNotification } from '../../NotificationSlice';
import { LogsWrapper } from './ResumeModal.style';

function ResumeModal(props) {
  const { open, handleClose, notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  const resume = useMemo(() => JSON.parse(userNotification.notification.metadata), [userNotification]);

  return (
    <MuiModal open={open} handleClose={handleClose} width="60vw">
      <LogsWrapper>
        {Object.entries(resume.summary).map(([partner, logs]) => (
          <Fragment key={partner}>
            <h2>{partner}</h2>
            <ul>
              {logs.map((log, index) => {
                const key = `${partner}-${log}-${index}`;
                return <li key={key}>{log}</li>;
              })}
            </ul>
          </Fragment>
        ))}
      </LogsWrapper>
    </MuiModal>
  );
}

ResumeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  notifId: PropTypes.number.isRequired,
};

export default ResumeModal;
