/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  ButtonCreationBidder,
  ButtonWrapper,
  CustomFormControlBidder,
  FormWrapper,
  FieldError,
  TextFieldBidder,
} from './createBidder.style';
import { getAdapters, getMonetizationType, postBidder } from '../../../../../helpers/api/bidders';

function CreateBidder(props) {
  const { placementKeywords } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bidderPlacementKeywords: [],
    },
  });
  const type = watch('type');
  const placementsSelected = watch('bidderPlacementKeywords');
  const [monetizationType, setMonetizationType] = useState([]);
  const [adapters, setAdapters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getMonetizationType().then((res) => setMonetizationType(res['hydra:member']));
  }, []);

  useEffect(() => {
    if (type === 1 && adapters.length === 0) {
      getAdapters().then((res) => {
        setAdapters(res['hydra:member']);
      });
    }
  }, [adapters.length, type]);

  const onBidderCreated = (values) => {
    if (placementsSelected.length === 0) {
      enqueueSnackbar('Vous devez séléctionné au moins un champ de bidder');
      return;
    }

    const { bidderPlacementKeywords } = values;

    const id = [];
    bidderPlacementKeywords.forEach((placement) => {
      id.push(`api/placement_keyword_libs/${placementKeywords.find((e) => e.keyName === placement).id}`);
    });

    const data = {
      ...values,
      bidderPlacementKeywords: id,
      isActive: true,
    };

    postBidder(data).then((res) => {
      enqueueSnackbar(`Le bidder ${res.displayName} a été généré`, { variant: 'success' });
    });
  };

  const validateSelect = (value) => {
    return value.length > 0 || 'Veuillez sélectionner au moins un champ de bidder';
  };

  const validateColorCode = (value) => {
    if (value.length === 0) {
      return 'La couleur est requise pour le front';
    }
    if (value.length !== 3 && value.length !== 6) {
      return 'Le code hexadécimal de couleur doit avoir 3 ou 6 caractères';
    }

    if (!/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
      return 'Code hexadécimal de couleur invalide';
    }
    return true;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onBidderCreated)}>
        <FormWrapper>
          <div>
            <TextFieldBidder {...register('displayName', { required: true })} label="Display name *" />
            {errors.displayName?.type === 'required' && <FieldError role="alert">Le displayName est requis</FieldError>}
          </div>
          <div>
            <TextFieldBidder {...register('keyname', { required: true })} label="Keyname *" />
            {errors.keyname?.type === 'required' && <FieldError role="alert">Le keyname est requis</FieldError>}
          </div>

          <div>
            <TextFieldBidder
              {...register('hexColorCode', { validate: validateColorCode })}
              label="Couleur héxadecimal *"
            />
            {errors.hexColorCode && <FieldError role="alert">{errors.hexColorCode.message}</FieldError>}
          </div>

          <div>
            <TextFieldBidder {...register('fontAwesomeIcon', { required: true })} label="Icône fontAwesome *" />
            {errors.fontAwesomeIcon?.type === 'required' && (
              <FieldError role="alert">L&#39;icone est requise</FieldError>
            )}
          </div>

          <TextFieldBidder type="number" {...register('gvlId', { valueAsNumber: true })} label="ID GoogleVendorList" />

          <TextFieldBidder {...register('uuid')} label="UUID du bidder" />

          <CustomFormControlBidder>
            <InputLabel>Type d&#39;adapter</InputLabel>
            <Select {...register('type', { required: true })} label="Type d'adapter" name="type" defaultValue="">
              <MenuItem value={0}>Standard</MenuItem>
              <MenuItem value={1}>Alias</MenuItem>
              <MenuItem value={2}>Alias native</MenuItem>
            </Select>
            {errors.type?.type === 'required' && <FieldError role="alert">Le type est requis</FieldError>}
          </CustomFormControlBidder>

          <CustomFormControlBidder>
            <InputLabel id="monet"> Type de monetisation </InputLabel>
            <Select
              {...register('monetizationType', { required: true })}
              id="monet"
              label="Type de monetisation"
              defaultValue=""
            >
              {monetizationType &&
                monetizationType.map((monet) => (
                  <MenuItem key={monet.id} value={`api/monetization_types/${monet.id}`}>
                    {monet.genericName}
                  </MenuItem>
                ))}
            </Select>
            {errors.monetizationType?.type === 'required' && (
              <FieldError role="alert">Le type de monetisation est requis</FieldError>
            )}
          </CustomFormControlBidder>

          <FormControlLabel
            {...register('authorizedToBid')}
            control={<Checkbox />}
            label="Est-ce que le bidder est autorisé à bidder ?"
            labelPlacement="top"
          />

          {type === 1 && (
            <CustomFormControlBidder>
              <InputLabel>Adapter</InputLabel>
              <Select {...register('adapter', { required: true })} label="Adapter" displayEmpty defaultValue="">
                {adapters &&
                  adapters.map((adapter) => (
                    <MenuItem key={adapter.id} value={`api/adapters/${adapter.id}`}>
                      {adapter.adapter}
                    </MenuItem>
                  ))}
              </Select>
            </CustomFormControlBidder>
          )}

          {type === 0 && (
            <div>
              <TextFieldBidder {...register('adapterName', { required: true })} label="Adapter *" />
              {errors.keyname?.type === 'required' && (
                <FieldError role="alert">La valeur de l&#39;adapter est requise</FieldError>
              )}
            </div>
          )}

          <FormControlLabel
            {...register('isAmpCompliant')}
            control={<Checkbox />}
            label="Est-ce que le bidder est compatible AMP ?"
            labelPlacement="top"
          />

          <CustomFormControlBidder>
            <InputLabel id="monet"> Champs du bidder </InputLabel>
            <Select
              id="monet"
              {...register('bidderPlacementKeywords', { validate: validateSelect })}
              label="Champs du bidder"
              name="bidderPlacementKeywords"
              multiple
              renderValue={(selected) => selected.join(', ')}
              defaultValue={[]}
            >
              {placementKeywords.map((placement) => (
                <MenuItem key={placement.id} value={placement.keyName}>
                  <Checkbox checked={placementsSelected.indexOf(placement.keyName) > -1} />
                  <ListItemText primary={placement.keyName} />
                </MenuItem>
              ))}
            </Select>
            {errors.bidderPlacementKeywords && (
              <FieldError role="alert">{errors.bidderPlacementKeywords.message}</FieldError>
            )}
          </CustomFormControlBidder>
        </FormWrapper>
        <ButtonWrapper>
          <ButtonCreationBidder type="submit">Créer votre bidder</ButtonCreationBidder>
        </ButtonWrapper>
      </form>
    </div>
  );
}

CreateBidder.propTypes = {
  placementKeywords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      keyName: PropTypes.string,
    })
  ).isRequired,
};

export default CreateBidder;
