import { useNavigate } from 'react-router-dom';
import avatar from '../../../../pictures/logo_carto.png';
import { Bars } from './Bars/Bars';
import NavMenu from './NavMenu/NavMenu';
import NavButtons from './NavButtons/NavButtons';
import { Avatar, HeaderStyled } from './Header.style';

function Header() {
  const navigate = useNavigate();

  const toHome = () => {
    navigate('/');
  };

  return (
    <HeaderStyled>
      <Bars />
      <Avatar src={avatar} alt="avatar" onClick={toHome} />
      <NavMenu />
      <NavButtons />
    </HeaderStyled>
  );
}

export default Header;
