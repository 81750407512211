/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import MuiModal from '../../../../UI/Modal/MuiModal';
import PartnerType from '../../../../../types/PartnerType';
import { FieldWrapper, FormWrapper, InputPartner } from './ModalModificationPartner.style';
import { patchPartnerWebsite } from '../../../../../helpers/api/partner';
import { fetchAllPartners } from '../../../../../store/PartnersSlice';

function ModalModificationPartner(props) {
  const { open, setOpen, item, page, itemsPerPage } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayName: item.displayName,
      keyname: item.keyname,
      isPartner: item.isPartner,
      isAuthorized: item.isAuthorized,
      trigram: item.trigram,
      uuid: item.uuid,
      lSSite: item.lSSite,
      partnerKeyNameNotInGtag: item.partnerKeyNameNotInGtag,
      gitFileName: item.gitFileName,
      publisherId: item.publisherId,
    },
  });

  const onPartnerUpdate = (dataForm) => {
    patchPartnerWebsite(item.id, dataForm).then((res) => {
      enqueueSnackbar(`Le partenaire ${res.displayName} a été modifié.`, { variant: 'success' });
      dispatch(fetchAllPartners({ page: page + 1, itemsPerPage }));
      setOpen(false);
    });
  };

  return (
    <MuiModal open={open} handleClose={() => setOpen(false)} width="60vw" title={`Modification de ${item.displayName}`}>
      <FormWrapper onSubmit={handleSubmit(onPartnerUpdate)}>
        <FieldWrapper>
          <div>
            <InputLabel> Nom du partenaire </InputLabel>
            <InputPartner
              {...register('displayName', { required: true })}
              id="input-with-sx"
              label="DisplayName"
              variant="standard"
            />
            {errors.displayName?.type === 'required' && <p>Le Display name est requis</p>}
          </div>
          <div>
            <InputLabel> Keyname du partenaire </InputLabel>
            <InputPartner
              {...register('keyname', { required: true })}
              id="input-with-sx"
              label="DisplayName"
              variant="standard"
            />
            {errors.keyname?.type === 'required' && <p>Le keyname est requis</p>}
          </div>
          <div>
            <InputLabel> Trigram du partenaire </InputLabel>
            <InputPartner
              {...register('trigram', { required: true, maxLength: 3, minLength: 3 })}
              id="input-with-sx"
              label="DisplayName"
              variant="standard"
            />
            {errors.trigram && <p>Le trigram est requis, et doit faire 3 caractère.</p>}
          </div>
          <div>
            <InputLabel> UUID (lié au Monetization Dashboard) </InputLabel>
            <InputPartner
              {...register('uuid', { required: true })}
              id="input-with-sx"
              label="DisplayName"
              variant="standard"
            />
            {errors.uuid?.type === 'required' && <p>L&#39;UUID est requis</p>}
          </div>
          <div>
            <InputLabel> LSSIte </InputLabel>
            <InputPartner
              {...register('lSSite', { required: true })}
              id="input-with-sx"
              label="DisplayName"
              variant="standard"
            />
            {errors.lSSite?.type === 'required' && <p>Le LSSite est requis</p>}
          </div>
          <div>
            <InputLabel> Nom du fichier Git </InputLabel>
            <InputPartner {...register('gitFileName')} id="input-with-sx" label="DisplayName" variant="standard" />
          </div>
          <div>
            <InputLabel>Publisher Id</InputLabel>
            <InputPartner type="number" {...register('publisherId', { valueAsNumber: true })} variant="standard" />
          </div>
          <div>
            <FormControlLabel
              {...register('isAuthorized')}
              control={<Checkbox defaultChecked={item.isAuthorized} />}
              label="Partnenaire autorisé"
              labelPlacement="start"
            />
          </div>
          <div>
            <FormControlLabel
              {...register('isPartner')}
              control={<Checkbox defaultChecked={item.isPartner} />}
              label="Est-ce un partenaire"
              labelPlacement="start"
            />
          </div>
          <div>
            <FormControlLabel
              {...register('partnerKeyNameNotInGtag')}
              control={<Checkbox defaultChecked={item.partnerKeyNameNotInGtag} />}
              label="Keyname présent dans le GTAG"
              labelPlacement="start"
            />
          </div>
        </FieldWrapper>
        <Button type="submit" variant="contained">
          Modifier le partenaire.
        </Button>
      </FormWrapper>
    </MuiModal>
  );
}

ModalModificationPartner.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  item: PartnerType.isRequired,
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
};

ModalModificationPartner.defaultProps = {
  page: 1,
  itemsPerPage: 20,
};

export default ModalModificationPartner;
