import { BodyWrapper, CardPartner } from './CreatePartner.style';
import FormPartner from './Form/FormPartner';
import DocPartner from './Doc/DocPartner';

function CreatePartner() {
  return (
    <CardPartner>
      <BodyWrapper>
        <FormPartner />
        <DocPartner />
      </BodyWrapper>
    </CardPartner>
  );
}

export default CreatePartner;
