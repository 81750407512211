import { configureStore } from '@reduxjs/toolkit';
import blocksAdministrationReducer from '../components/Admin/ManageBlocs/BlockAdministrationSlice';
import prebidFloorFieldsReducer from '../components/Floor/PrebidFloorFieldsSlice';
import prebidFloorReducer from '../components/Floor/PrebidFloorSlice';
import blocksReducer from '../components/GoogleTag/CodeGeneration/BlocksSlice';
import codeReducer from '../components/GoogleTag/CodeGeneration/CodeSlice';
import versionReducer from '../components/GoogleTag/CodeGeneration/VersionSlice';
import nodesReducer from '../components/GoogleTag/Edit/MultiUnitModification/Tabs/SizesTab/NodesSlice';
import tabBiddersModificationsReducer from '../components/GoogleTag/Edit/MultiUnitModification/Tabs/TabBiddersTab/TabBiddersModificationsSlice';
import variableSettingsReducer from '../components/GoogleTag/Settings/variableSettingsSlice';
import notificationReducer from '../components/Notifications/NotificationSlice';
import partnerPageReducer from '../components/Partner/PartnerPageSlice';
import topicsSliceReducer, { domain as topicsDomain } from '../components/Admin/Topics/topics.slice';
import suggestionSliceReducer, { DOMAIN as suggestionDomain } from '../store/SuggestionSlice';
import bidderPlacementKeywordLibsReducer from '../store/BidderPlacementKeywordLibSlice';
import commentsReducer from '../store/CommentsSlice';
import configurationsReducer from '../store/ConfigurationsSlice';
import devicesReducer from '../store/DevicesSlice';
import draftReducer from '../store/DraftSlice';
import googleTagVersionReducer from '../store/GoogleTagVersionSlice';
import groupsReducer from '../store/GroupSlice';
import mandatoryModulesReducer from '../store/MandatoryModulesSlice';
import mediatypesReducer from '../store/MediatypesSlice';
import messageQueueReducer from '../store/MessageQueueSlice';
import modificationsReducer from '../store/ModificationsSlice';
import pageTypesReducer from '../store/PagetypesSlice';
import partnersReducer from '../store/PartnersSlice';
import placementKeyworLibReducer from '../store/PlacementKeyworLibSlice';
import positionRTBreducer from '../store/PositionRTBSlice';
import positionsReducer from '../store/PositionsSlice';
import prebidVersionReducer from '../store/PrebidVersionSlice';
import sizesReducer from '../store/SizesSlice';
import tabBiddersReducer from '../store/TabBiddersSlice';
import usersReducer from '../store/UsersSlice';
import listenerMiddleware from './listenerMiddleware';

export const store = configureStore({
  reducer: {
    bidderPlacementKeywordLibs: bidderPlacementKeywordLibsReducer,
    blocks: blocksReducer,
    blocksAdministration: blocksAdministrationReducer,
    code: codeReducer,
    comments: commentsReducer,
    configurations: configurationsReducer,
    devices: devicesReducer,
    draft: draftReducer,
    googleTagVersion: googleTagVersionReducer,
    groups: groupsReducer,
    mandatoryModules: mandatoryModulesReducer,
    mediatypes: mediatypesReducer,
    messages: messageQueueReducer,
    modifications: modificationsReducer,
    nodes: nodesReducer,
    notifications: notificationReducer,
    pagetypes: pageTypesReducer,
    partnerPage: partnerPageReducer,
    partners: partnersReducer,
    placementKeywordLib: placementKeyworLibReducer,
    positionRTB: positionRTBreducer,
    positions: positionsReducer,
    prebidFloor: prebidFloorReducer,
    prebidFloorFields: prebidFloorFieldsReducer,
    prebidVersion: prebidVersionReducer,
    sizes: sizesReducer,
    [suggestionDomain]: suggestionSliceReducer,
    tabBidders: tabBiddersReducer,
    tabBiddersModifications: tabBiddersModificationsReducer,
    [topicsDomain]: topicsSliceReducer,
    users: usersReducer,
    variableSettings: variableSettingsReducer,
    versions: versionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware),
});
