import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPlacementKeywordLib,
  fetchPlacementKeywordLibs,
  fetchPlacementKeywordLibsTypes,
  selectPlacementKeywordLibs,
  selectPlacementKeywordLibsTypes,
  selectPlacementKeywordLibsTypesStatus,
} from '../../../../store/PlacementKeyworLibSlice';
import Heading from '../../../UI/Title/Heading';
import { ButtonPlacement, ListWrapper, ManageKeywordWrapper } from './ManageKeyword.style';
import PlacementItem from './PlacementItem';

function ManageKeyword() {
  const dispatch = useDispatch();

  const placementKeywords = useSelector(selectPlacementKeywordLibs);
  const placementKeywordLibsTypesStatus = useSelector(selectPlacementKeywordLibsTypesStatus);
  const placementKeywordLibsTypes = useSelector(selectPlacementKeywordLibsTypes);

  const { handleSubmit, register } = useForm({
    defaultValues: {
      keyName: '',
      type: '',
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(fetchPlacementKeywordLibs());
  }, [dispatch]);

  useEffect(() => {
    if (placementKeywordLibsTypesStatus !== 'idle') {
      return;
    }

    dispatch(fetchPlacementKeywordLibsTypes());
  }, [dispatch, placementKeywordLibsTypesStatus]);

  const createKeyword = (values) => {
    dispatch(addPlacementKeywordLib(values))
      .unwrap()
      .then((res) => {
        enqueueSnackbar(`Le placement ${res.keyName} a été créé`, { variant: 'success' });
      });
  };

  return (
    <ManageKeywordWrapper>
      <form onSubmit={handleSubmit(createKeyword)}>
        <TextField {...register('keyName', { required: true })} label="Nom du champ du bidder" />
        <FormControl sx={{ width: '250px' }}>
          <InputLabel>Type</InputLabel>
          <Select {...register('type', { required: true })} label="Type" defaultValue="">
            {placementKeywordLibsTypes.map((type) => (
              <MenuItem key={type.id} value={type.value}>
                {type.id.slice(5)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ButtonPlacement type="submit">Créer un champ de bidder</ButtonPlacement>
      </form>

      <Heading variant="h2">Liste des champ de bidder</Heading>
      <ListWrapper>
        {placementKeywords &&
          placementKeywords.map((placement) => <PlacementItem key={placement.id} placementId={placement.id} />)}
      </ListWrapper>
    </ManageKeywordWrapper>
  );
}

export default ManageKeyword;
