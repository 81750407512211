import styled from '@emotion/styled/macro';
import { List, ListItem } from '@mui/material';

const DraggableFieldContainer = styled.div`
  display: inline-flex;
  background: #ebecf0;
  padding: grid;
  overflow: 'auto';
  margin-left: 20px;
  border-radius: 2px;
`;

const DraggableField = styled.div`
  user-select: none;
  padding: 16px;
  margin: 4px 8px 4px 0;
  border-radius: 2px;
  background: ${({ isDragging }) => (isDragging ? 'lightblue' : 'white')};

  &:first-of-type {
    margin-left: 8px;
  }
`;

const DroppableList = styled(List)`
  background: #ebecf0;
  padding: 10px;
  margin: 10px 0;
  border-radius: 2px;
`;

const DraggableListElement = styled(ListItem)`
  user-select: none;
  padding: 8px;
  margin: 4px 0;
  border-radius: 2px;
  background: ${({ isDragging }) => (isDragging ? 'lightblue' : 'white')};
`;

export { DraggableFieldContainer, DraggableField, DroppableList, DraggableListElement };
