import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { addBlockModification } from '../../../../store/ModificationsSlice';
import { selectBlocks } from '../BlocksSlice';
import { selectGoogleTagVersion } from '../../../../store/GoogleTagVersionSlice';
import SidebarBlockList from './LeftSidebarBlockList/SidebarBlockList';
import BlockSelectedColumn from './CenterBlockSelectedColumn/BlockSelectedColum';

function BlockSelectionColumnsWrapper(props) {
  const dispatch = useDispatch();
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const { isVisible, handleChangeVisible } = props;
  const { blocks, blocksSelected } = useSelector(selectBlocks);

  const addBlocModification = (blockId, position) => {
    if (blocksSelected.some(({ id }) => id === blockId)) {
      return;
    }
    const alredyExist = blocksSelected.filter((b) => b.id === blockId);
    if (alredyExist.length > 0) {
      return;
    }
    const block = blocks.find((bloc) => bloc.id === blockId);

    const modification = {
      operation: 'addBlock',
      metadata: {
        type: 'addBlockSelected',
        method: 'POST',
        idBlock: block.id,
        gtagId: googleTagVersion.id,
        idVersion: block.idVersion,
        position,
      },
      googleTagVersions: [`/api/google_tag_versions/${googleTagVersion.id}`],
    };

    dispatch(addBlockModification(modification));
  };

  const removeBlockModification = (blockId) => {
    if (!blocksSelected.some(({ id }) => id === blockId)) {
      return;
    }

    const block = blocks.find((bloc) => bloc.id === blockId);

    const modification = {
      operation: 'removeBlock',
      metadata: {
        type: 'deleteBlockSelected',
        method: 'DELETE',
        idBlock: block.id,
        gtagId: googleTagVersion.id,
        idVersion: block.idVersion,
      },
      googleTagVersions: [`/api/google_tag_versions/${googleTagVersion.id}`],
    };

    dispatch(addBlockModification(modification));
  };

  const changeBlockPosition = (sourcePos, destinationPos, idBlock) => {
    if (sourcePos === destinationPos) return;

    const block = blocks.find((bloc) => bloc.id === idBlock);

    const modification = {
      operation: 'changeBlockPosition',
      metadata: {
        type: 'changeBlockPosition',
        method: 'PUT',
        idBlock: block.id,
        gtagId: googleTagVersion.id,
        idVersion: block.idVersion,
        sourcePos,
        destinationPos,
      },
      googleTagVersions: [`/api/google_tag_versions/${googleTagVersion.id}`],
    };

    dispatch(addBlockModification(modification));
  };

  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!destination) return;
    const blockId = parseInt(draggableId, 10);
    if (source.droppableId === 'block-listing' && destination.droppableId === 'block-selected') {
      addBlocModification(blockId, destination.index);
    } else if (source.droppableId === 'block-selected' && destination.droppableId === 'block-listing') {
      removeBlockModification(blockId);
    } else if (source.droppableId === 'block-selected' && destination.droppableId === 'block-selected') {
      changeBlockPosition(source.index, destination.index, blockId);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <SidebarBlockList isVisible={isVisible} handleChangeVisible={handleChangeVisible} />
      <BlockSelectedColumn />
    </DragDropContext>
  );
}

BlockSelectionColumnsWrapper.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleChangeVisible: PropTypes.func.isRequired,
};

export default BlockSelectionColumnsWrapper;
