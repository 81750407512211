import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TopicContentContainer, TopicContentForm } from './Topics.style';
import {
  retrieveBlocks,
  retrieveTopic,
  retrieveVariableGlobals,
  selectBlocks,
  selectBlocksStatus,
  selectSelectedTopic,
  selectVariableGlobals,
  selectVariableGlobalsStatus,
  updateTopic,
} from './topics.slice';
import { snackActions } from '../../../helpers/SnackBarUtils';

const DEFAULT_VALUES = {
  name: '',
  variableGlobals: [],
  blocks: [],
};

function TopicContent() {
  const dispatch = useDispatch();
  const selectedTopic = useSelector(selectSelectedTopic);

  const blocks = useSelector(selectBlocks);
  const blocksStatus = useSelector(selectBlocksStatus);
  const variableGlobals = useSelector(selectVariableGlobals);
  const variableGlobalsStatus = useSelector(selectVariableGlobalsStatus);

  const [topicDetail, setTopicDetail] = useState(null);

  const { reset, control, handleSubmit } = useForm({ defaultValues: { ...DEFAULT_VALUES } });

  useEffect(() => {
    if (variableGlobalsStatus !== 'idle') {
      return;
    }

    dispatch(
      retrieveVariableGlobals({ 'versionBlock.block.name': 'variable', order: { name: 'asc' }, pagination: false })
    );
  }, [variableGlobalsStatus, dispatch]);

  useEffect(() => {
    if (blocksStatus !== 'idle') {
      return;
    }

    dispatch(retrieveBlocks({ order: { name: 'asc' }, pagination: false }));
  }, [blocksStatus, dispatch]);

  useEffect(() => {
    if (!selectedTopic) {
      reset({ ...DEFAULT_VALUES });
      return;
    }

    dispatch(retrieveTopic(selectedTopic.id))
      .unwrap()
      .then((data) => setTopicDetail(data));
  }, [dispatch, reset, selectedTopic]);

  useEffect(() => {
    reset({ ...topicDetail });
  }, [reset, topicDetail]);

  const onSubmit = (data) =>
    dispatch(updateTopic(data))
      .unwrap()
      .then(() => snackActions.success('Topic mis à jour !'));

  return (
    selectedTopic &&
    topicDetail && (
      <TopicContentContainer>
        <Typography variant="h4">Topic:&nbsp;{topicDetail.name}</Typography>
        <TopicContentForm onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Nom" placeholder="Saisir un nom" />}
          />
          <Grid container spacing="2" sx={{ flex: '1 1 auto' }}>
            <Grid item xs={6}>
              <Typography variant="h6">Variables&nbsp;:</Typography>
              <Controller
                name="variableGlobals"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Ajouter une variable</InputLabel>
                    <Select
                      {...field}
                      label="Ajouter une variable"
                      multiple
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {selected.map((value) => {
                            const variable = variableGlobals.find((element) => element['@id'] === value);
                            return <Chip key={value} label={variable.name} sx={{ margin: '2px', cursor: 'pointer' }} />;
                          })}
                        </Box>
                      )}
                    >
                      {variableGlobals.map((variable) => (
                        <MenuItem key={variable.id} value={variable['@id']}>
                          <Checkbox checked={field.value.indexOf(variable['@id']) > -1} />
                          <ListItemText primary={variable.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Divider orientation="vertical" sx={{ mr: '-1px' }} />
            <Grid item xs={6}>
              <Typography variant="h6">Blocks&nbsp;:</Typography>
              <Controller
                name="blocks"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>Ajouter un block</InputLabel>
                    <Select
                      {...field}
                      label="Ajouter un block"
                      multiple
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {selected.map((value) => {
                            const block = blocks.find((element) => element['@id'] === value);
                            return <Chip key={value} label={block.name} sx={{ margin: '2px', cursor: 'pointer' }} />;
                          })}
                        </Box>
                      )}
                    >
                      {blocks.map((block) => (
                        <MenuItem key={block.id} value={block['@id']}>
                          <Checkbox checked={field.value.indexOf(block['@id']) > -1} />
                          <ListItemText primary={block.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained">
            Valider
          </Button>
        </TopicContentForm>
      </TopicContentContainer>
    )
  );
}

export default TopicContent;
