import PropTypes from 'prop-types';
import { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { BsFillPencilFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { removeMandatoryModules } from '../../../../../../../store/MandatoryModulesSlice';
import { CustomTD } from '../../../../../../UI/CompleteTable/Table.style';
import AlertDialog from '../../../../../../UI/Dialog/Dialog';
import LinkWrapper from '../../../../../../UI/LinkWrapper/LinkWrapper';
import ModalModify from '../ModalModify/ModalModify';

function ModuleItem(props) {
  const { item } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openModify, setOpenModify] = useState(false);

  const deleteModule = () => {
    setOpen(false);
    dispatch(removeMandatoryModules(item.id));
  };

  const dialogConfirm = () => {
    setOpen(true);
  };

  return (
    <>
      <CustomTD title={item.id}>{item.id}</CustomTD>
      <CustomTD title={item.name}>{item.name}</CustomTD>
      <CustomTD title={item.keyname}>{item.keyname}</CustomTD>
      <CustomTD title={item.description}>{item.description}</CustomTD>
      <CustomTD>
        <LinkWrapper onClick={() => setOpenModify(true)}>
          <BsFillPencilFill size={20} />
        </LinkWrapper>
        <LinkWrapper onClick={dialogConfirm}>
          <AiFillDelete size={20} color="red" />
        </LinkWrapper>
      </CustomTD>
      <AlertDialog
        open={open}
        onCancel={() => setOpen(false)}
        onValidate={deleteModule}
        content={`Voulez-vous vraiment supprimer le module ${item.name}`}
      />

      <ModalModify open={openModify} handleClose={() => setOpenModify(false)} moduleId={item.id} />
    </>
  );
}

ModuleItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    keyname: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default ModuleItem;
