import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { fetchPositions, selectAllPositions, selectAllPositionsStatus } from '../../../../store/PositionsSlice';
import { PositionTD, PositionTH, TableContainer, TablePosition, TheadPosition } from './ListPosition.style';

function ListPosition() {
  const dispatch = useDispatch();
  const positions = useSelector(selectAllPositions);
  const allPositionsStatus = useSelector(selectAllPositionsStatus);

  const [filterValue, setFilterValue] = useState('');

  const fetchAllPositions = useCallback(() => {
    if (allPositionsStatus === 'idle' || allPositionsStatus === 'failed') {
      dispatch(fetchPositions());
    }
  }, [allPositionsStatus, dispatch]);

  useEffect(() => {
    fetchAllPositions();
  }, [allPositionsStatus, dispatch, fetchAllPositions]);

  const filterPositions = (event) => {
    setFilterValue(event.target.value);
  };

  return (
    <div>
      <TextField placeholder="Rechercher une position" onChange={filterPositions} sx={{ float: 'right' }} />
      <TableContainer>
        <TablePosition>
          <thead>
            <TheadPosition>
              <PositionTH>ID</PositionTH>
              <PositionTH>UUID</PositionTH>
              <PositionTH>Position</PositionTH>
            </TheadPosition>
          </thead>
          <tbody>
            {positions.map((position) => {
              const label = position.genericName.toLowerCase();
              const filter = filterValue.toLowerCase();

              if (!label.includes(filter)) return null;

              return (
                <tr key={position.id}>
                  <PositionTD>{position.id}</PositionTD>
                  <PositionTD>{position.uuid}</PositionTD>
                  <PositionTD bold>{position.genericName}</PositionTD>
                </tr>
              );
            })}
          </tbody>
        </TablePosition>
      </TableContainer>
    </div>
  );
}

export default ListPosition;
