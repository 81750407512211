import { InputLabel, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AiOutlineGlobal } from 'react-icons/ai';
import { MdMode } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchAllDrafts, selectAllDrafts, selectDraft } from '../../../../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { selectModifications } from '../../../../../../store/ModificationsSlice';
import AlertDialog from '../../../../../UI/Dialog/Dialog';
import { FormControlFullWidth, FormWrapper, MenuItemContent, SelectDraft } from './SelectionDraft.style';

function SelectionDraft(props) {
  const { type, draftId } = props;

  const dispatch = useDispatch();
  const googleTag = useSelector(selectGoogleTagVersion);
  const drafts = useSelector(selectAllDrafts);
  const { handleSubmit, control, resetField, setValue } = useForm({
    defaultValues: { draft: draftId },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const modifications = useSelector(selectModifications());

  const draftSelector = useMemo(selectDraft, []);
  const currentDraft = useSelector((state) => draftSelector(state, draftId))[0];

  const modificationSaved = useMemo(() => {
    if (!currentDraft && modifications.length === 0) {
      return true;
    }

    if (!currentDraft) {
      return false;
    }

    if (currentDraft.draftModifications && modifications.length === currentDraft.draftModifications.length) {
      const stringModifications = JSON.stringify(modifications);
      const stringDraftModifications = JSON.stringify(currentDraft.draftModifications);

      return stringModifications === stringDraftModifications;
    }

    return false;
  }, [currentDraft, modifications]);

  useEffect(() => {
    const data = {
      isActive: true,
    };

    if (type) {
      data.type = type;
    }

    dispatch(fetchAllDrafts(data));
  }, [dispatch, googleTag, type, draftId]);

  useEffect(() => {
    resetField('draft', {
      defaultValue: draftId,
    });
  }, [draftId, resetField]);

  const onSubmitDraft = (data) => {
    setOpenDialog(false);
    const { draft } = data;
    if (type === 0) {
      navigate(`/edit-google-tag/${googleTag.id}/unit-modification?draft=${draft}`);
    } else if (type === 1) {
      navigate(`/marsse?draft=${draft}`);
    }
  };

  const verifyModification = (data) => {
    if (!modificationSaved) {
      return setOpenDialog(true);
    }

    return onSubmitDraft(data);
  };

  const dialogRefused = () => {
    setValue('draft', draftId);
    setOpenDialog(false);
  };

  if (drafts.length > 0) {
    return (
      <>
        <AlertDialog
          onValidate={handleSubmit(onSubmitDraft)}
          onCancel={dialogRefused}
          content="Vous avez des modifications non sauvegarde, voulez-vous réellement changer de draft ?"
          open={openDialog}
        />
        <FormWrapper>
          <Controller
            name="draft"
            control={control}
            render={({ field }) => (
              <FormControlFullWidth>
                <InputLabel>Draft</InputLabel>
                <SelectDraft
                  {...field}
                  label="Draft"
                  onChange={(event) => {
                    field.onChange(event);
                    handleSubmit(verifyModification)();
                  }}
                >
                  <MenuItem value="new">Nouveau draft</MenuItem>
                  {drafts.map((draft) => (
                    <MenuItem key={draft.id} value={draft.id}>
                      <MenuItemContent>
                        {draft.type === 0 ? <MdMode /> : <AiOutlineGlobal />}
                        &nbsp;
                        {new Date(draft.createdAt).toLocaleString()}
                      </MenuItemContent>
                    </MenuItem>
                  ))}
                </SelectDraft>
              </FormControlFullWidth>
            )}
          />
        </FormWrapper>
      </>
    );
  }
}

SelectionDraft.propTypes = {
  type: PropTypes.number,
  draftId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SelectionDraft.defaultProps = {
  type: 0,
  draftId: null,
};

export default SelectionDraft;
