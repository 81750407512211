import { v4 as uuidv4 } from 'uuid';
import { Popover } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ClosePopover, ContentPopover, PopoverHeader } from './Popover.style';

function PopoverTemplate(props) {
  const {
    children,
    anchorEl,
    setAnchorEl,
    title,
    vertical,
    horizontal,
    transformVertical,
    transformHorizontal,
    header,
  } = props;

  const handleCloseExport = () => {
    setAnchorEl(null);
  };

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const uid = useMemo(() => uuidv4(), []);

  return (
    <Popover
      onClose={handleCloseExport}
      id={uid}
      open={open}
      anchorEl={anchorEl}
      sx={{
        '& div.MuiPopover-paper': {
          backgroundColor: 'transparent',
        },
      }}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      transformOrigin={{
        vertical: transformVertical,
        horizontal: transformHorizontal,
      }}
    >
      <ContentPopover>
        {header && (
          <PopoverHeader>
            <b>{title}</b>
            <ClosePopover onClick={handleCloseExport} size={15} color="red" />
          </PopoverHeader>
        )}
        {children}
      </ContentPopover>
    </Popover>
  );
}

PopoverTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setAnchorEl: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
  horizontal: PropTypes.string.isRequired,
  transformVertical: PropTypes.string,
  transformHorizontal: PropTypes.string,
  header: PropTypes.bool,
};

PopoverTemplate.defaultProps = {
  anchorEl: 'button',
  transformVertical: 'top',
  transformHorizontal: 'left',
  header: true,
};

export default PopoverTemplate;
