import { Tab, Tabs } from '@mui/material';
import { useCallback, useState } from 'react';
import { ImCross } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../UI/Basic/Buttons/Button';
import { removeCodetabs, selectCodeTabs, selectSelectedTab, updateSelectTab } from '../../CodeSlice';
import ExportVersionPopover from '../../Popover/ExportVersionPopover';
import { TabWrapper } from '../RightSidebarCodeEditor.style';

function HeaderRightSidebar() {
  const dispatch = useDispatch();
  const codeTab = useSelector(selectCodeTabs);
  const selectedTab = useSelector(selectSelectedTab);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = useCallback(
    (event, value) => {
      dispatch(updateSelectTab(value));
    },
    [dispatch]
  );

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, valueToDelete) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(removeCodetabs(valueToDelete));
  };

  return (
    <TabWrapper>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        TabIndicatorProps={{ style: { background: 'green' } }}
        sx={{
          '& button': { borderTopLeftRadius: 10, borderTopRightRadius: 10 },
          '& button:hover': { backgroundColor: 'grey' },
          '& button.Mui-selected': {
            backgroundColor: 'black',
            color: 'white',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          '& button:focus': { borderTopLeftRadius: 10, borderTopRightRadius: 10 },
          '& .MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
          },
        }}
      >
        <Tab label="Editeur GTAG" tabIndex={0} />
        {codeTab.map((tab, index) => (
          <Tab
            key={tab.id}
            tabIndex={index}
            label={
              <span>
                {`v${tab.version}: ${tab.name}`}
                <ImCross
                  size={12}
                  color="red"
                  onClick={(event) => handleClose(event, tab)}
                  style={{ marginLeft: 20 }}
                />
              </span>
            }
          />
        ))}
      </Tabs>
      {selectedTab > 0 && (
        <>
          <Button
            onClick={handleOpenPopover}
            title="Exporter votre bloc de code"
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', minWidth: '200px' }}
          >
            Exporter votre bloc de code
          </Button>
          <ExportVersionPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </>
      )}
    </TabWrapper>
  );
}

export default HeaderRightSidebar;
