import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const TableWrapper = styled.table`
  width: ${(props) => props.width};
`;

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 150px;
`;

const ScrollWrapper = styled.tbody`
  height: auto;
  max-height: ${({ height }) => height};
  display: block;
  overflow: auto;
  scrollbar-width: thin;
`;

const CustomTR = styled.tr`
  display: table;
  table-layout: fixed;
  width: ${({ header }) => (header ? 'calc(100% - 0.9em)' : '100%')};

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const CustomTD = styled.td`
  text-align: center;
  padding: 10px;

  ${({ active }) =>
    active === false &&
    css`
      background-color: #c7c4c4;
      color: #707070;
    `}
`;

export { TableWrapper, LoaderWrapper, ScrollWrapper, CustomTR, CustomTD };
