import { css, keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const NotificationWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  width: inherit;
  height: inherit;
`;

const UpdateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  cursor: inherit;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  background: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
`;

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const IconWrapper = styled.div`
  display: flex;

  ${(props) =>
    props.loading === 'true' &&
    css`
      animation: ${rotation} 1s ease infinite;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 0.8em;
  font-weight: bold;
  min-width: 20px;
  width: fit-content;
  height: 20px;
  text-align: center;
  box-shadow: 0 0 1px 1px #0000001a;

  ${({ pulse }) =>
    pulse &&
    css`
      animation: ${pulseAnimation} 2s infinite;
    `}
`;

export { NotificationWrapper, Badge, UpdateButton, IconWrapper, ButtonContainer };
