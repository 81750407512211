import { useCallback, useEffect, useState } from 'react';
import { getPlacementKeywords } from '../../../../helpers/api/bidders';
import { CreationWrapper } from './BidderCreation.style';
import CreateBidder from './Form/CreateBidder';

function BidderCreation() {
  const [placementKeywords, setPlacementKeywords] = useState([]);

  const fetchKeywords = useCallback(() => {
    getPlacementKeywords().then((res) => {
      setPlacementKeywords(res['hydra:member']);
    });
  }, []);

  useEffect(() => {
    fetchKeywords();
  }, [fetchKeywords]);

  return (
    <CreationWrapper>
      <CreateBidder placementKeywords={placementKeywords} />
    </CreationWrapper>
  );
}

export default BidderCreation;
