/* eslint-disable react/forbid-prop-types */
import { Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

function ControlledAutocomplete(props) {
  const { options, multiple, renderInput, getOptionLabel, control, defaultValue, name, renderOption, rules, sx } =
    props;

  return (
    <Controller
      render={({ field: { onChange } }) => (
        <Autocomplete
          multiple={multiple}
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          disableCloseOnSelect
          onChange={(e, data) => onChange(data)}
          sx={sx}
        />
      )}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
    />
  );
}

ControlledAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  renderInput: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.array,
  name: PropTypes.string.isRequired,
  renderOption: PropTypes.func.isRequired,
  rules: PropTypes.object,
  sx: PropTypes.object,
};

ControlledAutocomplete.defaultProps = {
  multiple: false,
  options: [],
  defaultValue: null,
  rules: {},
  sx: {},
};

export default ControlledAutocomplete;
