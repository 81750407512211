export function getDetailLabel(operation) {
  switch (operation) {
    case 'removeSize':
      return 'Taille supprimée';
    case 'addSize':
      return 'Taille ajoutée';
    case 'updateTabBidders':
      return 'TabBidder modifié';
    case 'addTabBidders':
      return 'TabBidder ajouté';
    case 'enableTabBidders':
      return 'TabBidder activé';
    case 'disableTabBidders':
      return 'TabBidder désactivé';
    case 'modifyPosition':
      return 'Position modifiée';
    case 'removePosition':
      return 'Position désactivée';
    case 'addPosition':
      return 'Position ajoutée';
    case 'add adPosition':
      return 'RTB position ajoutée';
    case 'delete adPosition':
      return 'RTB position supprimée';
    case 'modify adPosition':
      return 'RTB position modifiée';
    case 'updatePrebidVersion':
      return 'Prebid version modifiée';
    case 'addBlock':
      return 'Bloc ajouté';
    case 'removeBlock':
      return 'Bloc supprimé';
    case 'changeGlobalVariable':
    case 'changeLocalVariable':
      return 'Variable changée';
    case 'changeVersion':
      return 'Version de bloc changée';
    case 'changeBlockPosition':
      return 'Position de bloc modifiée';
    case 'updateVariable':
      return 'Variable mise à jour';
    case 'csvImport':
      return 'Import CSV de bidder';
    default:
      return operation;
  }
}
