import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPrebidVersion } from '../../../store/PrebidVersionSlice';
import { selectMandatoryModules } from '../../../store/MandatoryModulesSlice';

function PrebidVersionDetail(props) {
  const { modification } = props;
  const { metadata } = modification;

  const mandatoryModules = useSelector(selectMandatoryModules);
  const prebidVersionSelector = useMemo(selectPrebidVersion, []);
  const prebidVersion = useSelector((state) => prebidVersionSelector(state, metadata.prebidVersion));

  const modules = useMemo(() => {
    const arrayModules = [];
    if (mandatoryModules) {
      metadata.modules.forEach((module) => {
        const goodModule = mandatoryModules.find((mod) => mod.id === module);
        if (goodModule) {
          arrayModules.push(goodModule.name);
        }
      });
    }

    return arrayModules;
  }, [mandatoryModules, metadata.modules]);

  const prebidVersionLabel = (
    <strong>&quot;{prebidVersion ? prebidVersion.version : metadata.prebidVersion}&quot;</strong>
  );

  return (
    <div>
      La version {prebidVersionLabel} des prebids a été settée avec les modules : <b>{modules.join(', ')}</b>
      {prebidVersion && [
        ' (cliquez ',
        <a
          key="link"
          target="_blank"
          href={`https://github.com/prebid/Prebid.js/releases/tag/${prebidVersion.version}`}
          rel="noreferrer"
        >
          ici
        </a>,
        " pour plus d'information)",
      ]}
    </div>
  );
}

PrebidVersionDetail.propTypes = {
  modification: PropTypes.shape({
    metadata: PropTypes.shape({
      prebidVersion: PropTypes.number.isRequired,
      modules: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
  }).isRequired,
};

export default PrebidVersionDetail;
