import styled from '@emotion/styled/macro';

const FormStyled = styled.form`
  display: inline-flex;
  align-items: center;
  margin: 10px;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

export { FormStyled, ButtonWrapper };
