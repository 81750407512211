import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { TbDeviceNintendo } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { addedPositionModificationSelector } from '../../../../../../store/ModificationsSlice';
import CellGrid from './CellGrid';
import { CellButton, LineGrid, ListContainer, ListItemButtonWrapper } from './Grid.style';
import { selectNodesHeaders, selectNodesPositions } from './NodesSlice';
import PositionGrid from './PositionGrid';
import AddPositionManager from './PositionsManager/AddPositionManager';
import { selectAllPositions } from '../../../../../../store/PositionsSlice';

function DeviceGrid(props) {
  const { pagetypeId, node } = props;
  const { deviceId, deviceName } = node;

  // eslint-disable-next-line no-unused-vars
  const positions = useSelector(selectAllPositions);

  const selector = useMemo(() => ({ pagetypeId, deviceId }), [deviceId, pagetypeId]);
  const nodesPositionsSelector = useMemo(selectNodesPositions, []);
  const nodesPositions = useSelector((state) => nodesPositionsSelector(state, selector));

  const selectAddedPositions = useMemo(addedPositionModificationSelector, []);
  const addedPositions = useSelector((state) => selectAddedPositions(state, selector));

  const headers = useSelector(selectNodesHeaders);

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(0);

  const line = useMemo(() => {
    const tmpLine = [];

    headers.forEach((header) => {
      const key = header + pagetypeId + deviceId;

      tmpLine.push(<CellGrid key={key} className={header} pagetype={pagetypeId} device={deviceId} />);
    });

    return tmpLine;
  }, [deviceId, headers, pagetypeId]);

  const childrens = useMemo(() => {
    const formattedNodesPositions = nodesPositions.map((position) => ({
      key: position.id,
      positionName: position.positionName,
      positionId: position.positionId,
      active: position.active,
    }));

    const formattedAddedPosition = addedPositions.flatMap((addedPosition) =>
      addedPosition.metadata.positionId.map((positionId) => {
        const position = positions?.find((pos) => pos.id === positionId) ?? null;

        return {
          key: positionId,
          positionName: position?.genericName ?? '',
          positionId,
          added: true,
        };
      })
    );

    const renderedPositions = formattedNodesPositions
      .concat(formattedAddedPosition)
      .filter((value, index, self) => index === self.findIndex((t) => t.positionName === value.positionName));

    return renderedPositions.sort((a, b) => {
      const x = a.positionName;
      const y = b.positionName;

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    });
  }, [addedPositions, nodesPositions, positions]);

  return (
    <LineGrid
      columns={headers.length + 1}
      hover={hover > 0}
      onDragEnter={() => setHover((h) => h + 1)}
      onDragLeave={() => setHover((h) => h - 1)}
      onDrop={() => setHover(0)}
    >
      {nodesPositions.length > 0 && (
        <>
          <ListItemButton sx={ListItemButtonWrapper} onClick={() => setOpen(!open)} disableTouchRipple>
            <CellButton index={1}>
              <ListItemIcon>
                <TbDeviceNintendo />
              </ListItemIcon>
              <ListItemText primary={deviceName} />

              <AddPositionManager
                deviceId={deviceId}
                pagetypeId={pagetypeId}
                positions={nodesPositions.concat(addedPositions)}
              />
            </CellButton>

            {line}
          </ListItemButton>

          <Collapse in={open} timeout="auto" sx={{ gridColumn: '1/5' }}>
            <List component="div" sx={ListContainer}>
              {childrens.map((children) => (
                <PositionGrid key={children.positionId} pagetypeId={pagetypeId} deviceId={deviceId} node={children} />
              ))}
            </List>
          </Collapse>
        </>
      )}
    </LineGrid>
  );
}

DeviceGrid.propTypes = {
  pagetypeId: PropTypes.number.isRequired,
  node: PropTypes.shape({
    deviceName: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
  }).isRequired,
};

export default DeviceGrid;
