import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const CardDiv = styled.div`
  margin: 1rem;
  ${(props) =>
    props.marginTop
      ? css`
          margin-top: ${props.marginTop};
        `
      : null};
  padding: 1rem 1.5rem 1rem 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  width: ${(props) => props.width ?? 'auto'};
  height: ${(props) => props.height ?? 'auto'};
  overflow: auto;

  ${(props) => props.sx && { ...props.sx }}
`;

export { CardDiv };
