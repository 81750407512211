import { useSelector } from 'react-redux';
import { selectUserNotifications } from '../NotificationSlice';
import { AllNotificationsContainer } from './AllNotifications.style';
import NoNotifications from '../NoNotifications/NoNotifications';
import { getNotificationComponent } from '../../../helpers/NotificationHelper';

function AllNotifications() {
  const notifications = useSelector(selectUserNotifications);
  return (
    <AllNotificationsContainer>
      {notifications.length > 0 ? (
        notifications.map((notification) => {
          const { type } = notification.notification;
          const NotificationComponent = getNotificationComponent(type);

          return <NotificationComponent key={notification.id} notifId={notification.id} />;
        })
      ) : (
        <NoNotifications />
      )}
    </AllNotificationsContainer>
  );
}

export default AllNotifications;
