/* eslint-disable */
import WarningBox from '../../UI/Wrapper/WarningBox';

function PendingGtagReminderForPartner(props) {
  const { nbOfDrafts, pendingGtag } = props;

  if (nbOfDrafts && pendingGtag) {
    return (
      <WarningBox>
        Attention, nous avons détecté {nbOfDrafts} autre(s) google tag en mode brouillon déjà en base.
        <br />
        Nous vous recommandons de repartir du dernier Gtag Existant : {pendingGtag.releaseId} ({pendingGtag.uuid})
        <ul>
          <li>
            <u>Créé le : </u>
            {pendingGtag.createdAt}
          </li>
          <li>Par : {pendingGtag.author}</li>
          <li>Description : "{pendingGtag.description}"</li>
        </ul>
      </WarningBox>
    );
  }

  return null;
}

export default PendingGtagReminderForPartner;
