import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';

const FormWrapper = styled.div`
  display: block;
`;

const PartnerTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 15px;
`;

export { FormWrapper, PartnerTextField };
