import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { lightGreen, red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { TiTick } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import { lastPositionModificationSelector } from '../../../../../../../store/ModificationsSlice';
import { StyledListItemButton } from './SelectionItem.style';
import SelectionItemMediatype from './SelectionItemMediatype';
import useSelectedTabBidderNode from './useSelectedTabBidderNode';

function SelectionItemPosition(props) {
  const { item, metadata, added } = props;
  const [open, setOpen] = useState(false);

  const openIcon = useMemo(() => (open ? <MdExpandLess /> : <MdExpandMore />), [open]);

  const newMetadata = useMemo(() => ({ ...metadata, position: item.id }), [item.id, metadata]);
  const selected = useSelectedTabBidderNode(newMetadata);
  const selectorArgs = useMemo(
    () => ({ pagetypeId: -1, deviceId: newMetadata.device, positionId: newMetadata.position }),
    [newMetadata.device, newMetadata.position]
  );

  const selectorLastModification = useMemo(lastPositionModificationSelector, []);
  const lastModificationPosition = useSelector((state) => selectorLastModification(state, selectorArgs));

  const childrens = useMemo(() => {
    return Object.entries(item).filter(([key]) => {
      return !['name', 'uuid', 'node', 'id', 'added', 'removed', 'active'].includes(key);
    });
  }, [item]);

  const handleClick = () => {
    setOpen(!open);
  };

  const render = useMemo(() => {
    if (lastModificationPosition?.operation === 'removePosition' || (!lastModificationPosition && !item.active)) {
      return {
        backgroundColor: red[100],
        icon: <BsExclamationCircleFill color="red" />,
      };
    }

    if (added) {
      return {
        backgroundColor: lightGreen[100],
        icon: <TiTick style={{ backgroundColor: 'green', borderRadius: '50%', color: 'white' }} />,
      };
    }

    return {
      icon: <CgWebsite />,
    };
  }, [added, lastModificationPosition, item.active]);

  return (
    <>
      <StyledListItemButton onClick={handleClick} backgroundColor={render?.backgroundColor} selected={selected}>
        <ListItemIcon sx={{ minWidth: '30px' }}>{render?.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
        {childrens.length > 0 ? openIcon : null}
      </StyledListItemButton>
      {childrens.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" sx={{ pl: 3 }} disablePadding>
            {Object.values(childrens).map(([key, el]) => (
              <SelectionItemMediatype key={key} item={el} metadata={newMetadata} added={el.added} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

SelectionItemPosition.propTypes = {
  metadata: PropTypes.shape({
    device: PropTypes.number,
    position: PropTypes.number,
  }).isRequired,
  item: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
    active: PropTypes.bool,
  }).isRequired,
  added: PropTypes.bool,
};

SelectionItemPosition.defaultProps = {
  added: false,
};

export default SelectionItemPosition;
