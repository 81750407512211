/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, FormHelperText, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ControlledAutocomplete from '../../../../UI/ControlledAutocomplete';
import { PositionFormControl } from '../Position/PositionSidebar.style';
import { fetchTabBidders, selectTabBidders } from '../../../../../store/TabBiddersSlice';
import { removeTabBidder, selectSelectedGoogleTags } from '../../../../../store/ModificationsSlice';
import { fetchMediaTypes, selectAllMediaTypes } from '../../../../../store/MediatypesSlice';
import { fetchPositions, selectAllPositions } from '../../../../../store/PositionsSlice';
import { fetchDevices, selectAllDevices } from '../../../../../store/DevicesSlice';
import MassButton from '../../../../UI/Basic/Buttons/MassButton/MassButton';

function BidderSidebar() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const bidders = useSelector(selectTabBidders);
  const devices = useSelector(selectAllDevices);
  const mediaTypes = useSelector(selectAllMediaTypes);
  const positions = useSelector(selectAllPositions);
  const selectedGoogleTag = useSelector(selectSelectedGoogleTags);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    if (devices.length === 0) dispatch(fetchDevices());
  }, [dispatch, devices]);

  useEffect(() => {
    if (mediaTypes.length === 0) dispatch(fetchMediaTypes());
  }, [dispatch, mediaTypes]);

  useEffect(() => {
    if (positions.length === 0) dispatch(fetchPositions());
  }, [dispatch, positions]);

  useEffect(() => {
    if (bidders.length === 0) dispatch(fetchTabBidders({ pagination: false, isActive: true }));
  }, [bidders.length, dispatch]);

  const deleteBidder = (data) => {
    if (selectedGoogleTag.length === 0) {
      enqueueSnackbar('Veuillez séléctionner au moins un GoogleTag.', { variant: 'error' });
      return;
    }

    const { device, mediatype, position, bidder } = data;

    const dataToStore = {
      selectedPartner: -1,
      operation: 'disableTabBidders',
      metadata: {
        device: device && device.map((d) => d.id),
        position: position && position.map((pos) => pos.id),
        mediatype: mediatype && mediatype.map((pt) => pt.id),
      },
      bidderId: bidder && bidder.map((b) => b.id),
      occurrence: -1,
      googleTagVersions: selectedGoogleTag.map((gtag) => `api/google_tag_versions/${gtag}`),
    };

    dispatch(removeTabBidder(dataToStore));
  };

  return (
    <div>
      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={devices}
          renderInput={(params) => <TextField {...params} label="Device" placeholder="Device" />}
          getOptionLabel={(option) => option.genericName}
          name="device"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={positions}
          renderInput={(params) => <TextField {...params} label="Position" placeholder="Position" />}
          getOptionLabel={(option) => option.genericName}
          name="position"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={mediaTypes}
          renderInput={(params) => <TextField {...params} label="MediaType" placeholder="MediaType" />}
          getOptionLabel={(option) => option.genericName}
          name="mediatype"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={bidders}
          renderInput={(params) => <TextField {...params} label="Bidder" placeholder="Bidder" />}
          getOptionLabel={(option) => option.displayName}
          name="bidder"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.displayName}
            </li>
          )}
          rules={{
            validate: {
              validBidder: (value) => {
                if (!value || value.length === 0) {
                  return 'Choisissez au moins un bidder';
                }

                return true;
              },
            },
          }}
        />
        {errors.bidder && <FormHelperText> {errors?.bidder?.message}</FormHelperText>}
      </PositionFormControl>

      <MassButton onClick={handleSubmit((data) => deleteBidder(data))}>Supprimer le bidder</MassButton>
    </div>
  );
}

export default BidderSidebar;
