import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const BidderSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#264653',
    '&:hover': {
      backgroundColor: alpha('#264653', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#264653',
  },
}));

export default BidderSwitch;
