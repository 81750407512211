import { Button, ListItemIcon } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Controller, useWatch } from 'react-hook-form';
import { BsArrowsMove } from 'react-icons/bs';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledTextField } from '../Floor.style';
import { DraggableListElement } from './FloorComponents.style';
import RuleItemField from './RuleItemField';

function RuleItem(props) {
  const { registerTarget, control, selectedFields, index, item, errors, remove, insert, hidden } = props;

  const registerTargetIndex = useMemo(() => `${registerTarget}.${index}`, [registerTarget, index]);
  const registerTargetValue = useMemo(() => `${registerTargetIndex}.value`, [registerTargetIndex]);

  const line = useWatch({ control, name: registerTargetIndex });

  const error = useReactHookFormError(registerTargetValue, errors);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <DraggableListElement ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <BsArrowsMove />
          </ListItemIcon>
          {selectedFields &&
            selectedFields.map((field) => (
              <RuleItemField
                key={field}
                control={control}
                registerTarget={registerTargetIndex}
                fieldTarget={field}
                index={index}
                hidden={hidden}
                errors={errors}
              />
            ))}
          <Controller
            render={({ field }) => (
              <StyledTextField
                id={registerTargetValue}
                label="Valeur"
                type="number"
                inputProps={{
                  step: '0.01',
                }}
                variant="filled"
                error={!!error}
                helperText={error?.message}
                {...field}
                value={field.value ?? ''}
              />
            )}
            control={control}
            name={registerTargetValue}
            rules={{
              validate: {
                required: (value) => {
                  if (hidden) {
                    return true;
                  }

                  if (!value) {
                    return 'Le champ est requis.';
                  }

                  if (value < 0) {
                    return 'Le champ doit être supérieur à 0.';
                  }

                  if (Number.isNaN(value) || Number.isNaN(parseFloat(value))) {
                    return 'Le champ doit être un nombre.';
                  }

                  return true;
                },
              },
            }}
          />
          &nbsp;
          <Button type="button" onClick={() => insert(index, { ...line })} variant="contained">
            Copier
          </Button>
          &nbsp;
          <Button type="button" onClick={() => remove(index)} variant="contained" color="error">
            Delete
          </Button>
        </DraggableListElement>
      )}
    </Draggable>
  );
}

RuleItem.propTypes = {
  registerTarget: PropTypes.string.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  insert: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object.isRequired,
};

export default RuleItem;
