import styled from '@emotion/styled/macro';
import { BsCheckLg } from 'react-icons/bs';

const ItemWrapper = styled.tr`
  text-align: center;
  margin-top: 10px;
`;

const ColumnStyle = styled.td`
  padding: 10px;
  background-color: #f3f3f3;
  border-bottom-left-radius: ${(props) => props.left && '10px'};
  border-top-left-radius: ${(props) => props.left && '10px'};
  border-top-right-radius: ${(props) => props.right && '10px'};
  border-bottom-right-radius: ${(props) => props.right && '10px'};
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
`;

const CheckIcon = styled(BsCheckLg)`
  margin-left: 20px;
  vertical-align: middle;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const EmptyDiv = styled.div`
  width: 30px;
`;

export { ItemWrapper, ColumnStyle, CheckIcon, IconWrapper, EmptyDiv };
