import PropTypes from 'prop-types';

const nodeType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  pagetype: PropTypes.string,
  pagetypeId: PropTypes.number,
  device: PropTypes.string.isRequired,
  deviceId: PropTypes.number.isRequired,
  position: PropTypes.string.isRequired,
  positionId: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
});

export default nodeType;
