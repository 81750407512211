import styled from '@emotion/styled/macro';
import { Button, Card, FormControlLabel, Select, TextField } from '@mui/material';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconBackWrapper = styled.div`
  background-color: #2360dc;
  color: white;
  border-radius: 10px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 15vw;
  padding: 0 20px;
  cursor: pointer;
`;

const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 50px;
`;

const CheckBoxWrapper = styled(FormControlLabel)`
  display: flex;
  justify-content: center;
  padding: 0 10px;
`;

const SubmitWrapper = styled.div`
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CreatePartnerButton = styled(Button)`
  background-color: #2360dc;
  color: white;
  padding: 10px 20px;
  border: 1px solid transparent;

  &:hover {
    background-color: white;
    color: #2360dc;
    border: 1px solid #2360dc;
  }
`;

const DocList = styled.li`
  margin-top: 5px;
`;

const SelectGroup = styled(Select)`
  width: 100%;
`;

const CardPartner = styled(Card)`
  box-shadow: none;
`;

const FieldError = styled.p`
  color: red;
  font-size: 10px;
`;

const SelectFixedWith = styled(Select)`
  width: 350px;
`;

const GroupCreationFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  grid-column-gap: 20px;
`;

const TextFieldGroup = styled(TextField)`
  margin-bottom: 20px;
  width: 350px;
  height: auto;
`;

export {
  Header,
  IconBackWrapper,
  BodyWrapper,
  CheckBoxWrapper,
  FieldsWrapper,
  SubmitWrapper,
  CreatePartnerButton,
  DocList,
  SelectGroup,
  CardPartner,
  FieldError,
  SelectFixedWith,
  GroupCreationFormGrid,
  TextFieldGroup,
};
