import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import GoogleTagLink from '../../UI/Link/GoogleTagLink';
import { GtagGrid, ItemColumn } from './SnapshotRow.style';

function SnapshotRow(props) {
  const { item } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell sx={{ borderBottom: 'unset' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <ItemColumn>{item.id}</ItemColumn>
        </TableCell>
        <TableCell sx={{ borderBottom: 'unset' }}>
          <ItemColumn> {new Date(item.createdAt).toLocaleString()} </ItemColumn>
        </TableCell>
        <TableCell sx={{ borderBottom: 'unset' }}>
          <ItemColumn> {new Date(item.updatedAt).toLocaleString()} </ItemColumn>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                GoogleTags affectés
              </Typography>
              <GtagGrid>
                {item?.googleTagVersions.map((googleTagVersion) => (
                  <GoogleTagLink
                    key={googleTagVersion.id}
                    id={googleTagVersion.id}
                    releaseId={googleTagVersion.releaseId}
                  />
                ))}
              </GtagGrid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

SnapshotRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    googleTagVersions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        releaseId: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default SnapshotRow;
