import param from 'jquery-param';
import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getGoogleTagVersion(id) {
  const url = `${API_BASE_URL}/google_tag_versions/${id}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function importGoogleTagsFromGithub(body = []) {
  const url = `${API_BASE_URL}/google_tag_versions/import`;
  await defaultFetch(url, 'POST', body);
}

export async function generate(id, body) {
  const url = `${API_BASE_URL}/google_tag_versions/${id}/generation`;
  return defaultFetch(url, 'POST', body);
}

export async function getAllGoogleTag(data) {
  const url = new URL(`${API_BASE_URL}/google_tag_versions`);
  url.search = param(data);

  const response = await defaultFetch(url.toString());
  return response.json();
}

export async function getGoogleTagVersionsByPartner(id) {
  const url = `${API_BASE_URL}/partner_groups/${id}/google_tag_versions/last`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function clone(idPartner, idGtag) {
  const url = `${API_BASE_URL}/google_tag_versions/clone/${idPartner}/${idGtag}`;
  const response = await defaultFetch(url, 'POST');

  return response.json();
}

export async function patchGoogleTag(id, data) {
  const url = `${API_BASE_URL}/google_tag_versions/${id}`;
  const response = await defaultFetch(url, 'PATCH', data);

  return response.json();
}
