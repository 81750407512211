import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoSendSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { snackActions } from '../../../helpers/SnackBarUtils';
import { createComment, fetchComments, resetComments, selectComments } from '../../../store/CommentsSlice';
import { selectGoogleTagVersion } from '../../../store/GoogleTagVersionSlice';
import Comment from '../../UI/Comments/Comment';
import Heading from '../../UI/Title/Heading';
import Card from '../../UI/Wrapper/Card';
import { ButtonSubmitComment, CommentsContainer, FormComment } from './CommentsSection.style';

function CommentsSection() {
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      text: '',
    },
  });

  const comments = useSelector(selectComments);

  useEffect(() => {
    dispatch(fetchComments({ googleTagVersion: googleTagVersion.id, order: { id: 'DESC' } }));

    return () => {
      dispatch(resetComments());
    };
  }, [dispatch, googleTagVersion.id]);

  const saveCommentHandler = (data) => {
    const { text } = data;

    const transformedText = text.trim();

    if (transformedText.length === 0) {
      snackActions.error('Le commentaire est vide');
      return;
    }

    const body = {
      text: transformedText,
      googleTagVersion: `api/google_tag_versions/${googleTagVersion.id}`,
    };

    dispatch(createComment(body));
    reset();
  };

  const keydownHandler = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      handleSubmit(saveCommentHandler)();
    }
  };

  return (
    <Card sx={{ gridColumn: '1', display: 'flex', flexDirection: 'column' }}>
      <Heading variant="h2">Commentaires</Heading>

      <CommentsContainer>
        {comments.length === 0 ? (
          <Heading variant="h2">Pas de commentaire retrouvé.</Heading>
        ) : (
          [...comments].map((comment) => <Comment key={comment.id} id={comment.id} />)
        )}
      </CommentsContainer>
      <FormComment onSubmit={handleSubmit(saveCommentHandler)}>
        <TextField
          sx={{ width: '100%' }}
          multiline
          label="Commentaire"
          placeholder="Ajoutez un nouveau commentaire..."
          minRows={2}
          {...register('text')}
          onKeyDown={keydownHandler}
        />
        <ButtonSubmitComment type="submit">
          <IoSendSharp size={30} />
        </ButtonSubmitComment>
      </FormComment>
    </Card>
  );
}

export default CommentsSection;
