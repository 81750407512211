/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchOne, getInfosForGtagAndPartner, patchPartnerWebsite } from '../../helpers/api/partner';

const DOMAIN = 'partnerPage';

export const fetchPartnerInfos = createAsyncThunk(`${DOMAIN}/fetchPartnerInfos`, async (partnerId, thunkAPI) => {
  try {
    return getInfosForGtagAndPartner(partnerId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchPartner = createAsyncThunk(`${DOMAIN}/fetchOne`, async (id, thunkAPI) => {
  try {
    return fetchOne(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchPartner = createAsyncThunk(`${DOMAIN}/patchPartner`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return patchPartnerWebsite(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  partnerInfos: [],
  partner: null,
  status: 'idle',
  error: null,
};

export const PartnersPageSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    findPartner: (state, action) => {
      state.partner = state.partners.find((partnerItem) => partnerItem.id === parseInt(action.payload, 10));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPartnerInfos.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPartnerInfos.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partnerInfos = action.payload.content;
    });
    builder.addCase(fetchPartnerInfos.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchPartner.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchPartner.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partner = action.payload;
    });
    builder.addCase(fetchPartner.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(patchPartner.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partner = action.payload;
    });
  },
});

export const { findPartner } = PartnersPageSlice.actions;
export const selectPartnerPageStatus = (state) => state[DOMAIN].status;
export const selectCurrentPartner = (state) => state[DOMAIN].partner;
export const selectPartnerInfos = (state) => state[DOMAIN].partnerInfos;

export default PartnersPageSlice.reducer;
