import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { TbDeviceNintendo } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMediaTypes,
  selectAllMediaTypes,
  selectAllMediaTypesStatus,
} from '../../../../../../../store/MediatypesSlice';
import { addedPositionModificationSelector } from '../../../../../../../store/ModificationsSlice';
import { selectAllPositions } from '../../../../../../../store/PositionsSlice';
import { StyledListItemButton } from './SelectionItem.style';
import SelectionItemPosition from './SelectionItemPosition';
import useSelectedTabBidderNode from './useSelectedTabBidderNode';

function SelectionItemDevice(props) {
  const { item, metadata } = props;
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const openIcon = useMemo(() => (open ? <MdExpandLess /> : <MdExpandMore />), [open]);

  const newMetadata = useMemo(() => ({ ...metadata, device: item.id }), [item.id, metadata]);
  const selected = useSelectedTabBidderNode(newMetadata);
  const selectorArgs = useMemo(() => ({ pagetypeId: -1, deviceId: newMetadata.device }), [newMetadata.device]);

  const selectorAdded = useMemo(addedPositionModificationSelector, []);
  const addedPositions = useSelector((state) => selectorAdded(state, selectorArgs));

  const mediatypes = useSelector(selectAllMediaTypes);
  const mediatypesStatus = useSelector(selectAllMediaTypesStatus);
  const positions = useSelector(selectAllPositions);

  useEffect(() => {
    if (mediatypesStatus !== 'idle') return;

    dispatch(fetchMediaTypes());
  }, [dispatch, mediatypesStatus]);

  const childrens = useMemo(() => {
    const nodePositions = Object.entries(item).filter(([key]) => {
      return key !== 'name' && key !== 'uuid' && key !== 'node' && key !== 'id';
    });

    const formattedAddedPositions = addedPositions.flatMap((addedPosition) => {
      const { mediatypeIds } = addedPosition.metadata;

      let mediatypesAcc;

      if (mediatypeIds && mediatypeIds.length > 0) {
        mediatypesAcc = mediatypeIds.reduce((a, value) => {
          const mediatype = mediatypes?.find((m) => m.id === value) ?? null;

          return { ...a, [value]: { id: value, name: mediatype?.genericName ?? '', added: true } };
        }, {});
      } else {
        mediatypesAcc = mediatypes.reduce(
          (a, value) => ({ ...a, [value.id]: { id: value.id, name: value?.genericName, added: true } }),
          {}
        );
      }

      return addedPosition.metadata.positionId.map((positionId) => {
        const position = positions?.find((pos) => pos.id === positionId) ?? null;

        return [
          addedPosition.createdAt + positionId,
          {
            id: positionId,
            name: position?.genericName,
            added: true,
            ...mediatypesAcc,
          },
        ];
      });
    });

    formattedAddedPositions.forEach(([key, formattedAddedPosition]) => {
      const index = nodePositions.findIndex((value) => value[1].name === formattedAddedPosition.name);

      if (index !== -1) {
        const { added } = nodePositions[index][1];
        nodePositions[index] = [
          nodePositions[index][0],
          { ...formattedAddedPosition, ...nodePositions[index][1], added },
        ];
      } else {
        nodePositions.push([key, { ...formattedAddedPosition }]);
      }
    });

    return nodePositions.sort((a, b) => {
      const x = a[1].name;
      const y = b[1].name;

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    });
  }, [item, addedPositions, mediatypes, positions]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledListItemButton onClick={handleClick} selected={selected}>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          <TbDeviceNintendo />
        </ListItemIcon>
        <ListItemText primary={item.name} />
        {childrens.length > 0 ? openIcon : null}
      </StyledListItemButton>
      {childrens.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" sx={{ pl: 3 }} disablePadding>
            {Object.values(childrens).map(([key, el]) => (
              <SelectionItemPosition key={key} item={el} metadata={newMetadata} added={el.added} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

SelectionItemDevice.propTypes = {
  metadata: PropTypes.shape({
    device: PropTypes.number,
    position: PropTypes.number,
  }).isRequired,
  item: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
};

export default SelectionItemDevice;
