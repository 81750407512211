import PropTypes from 'prop-types';
import { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectOneOfModification } from '../../../../store/ModificationsSlice';
import { AffectedGoogleTags, DateModification, TextWrapper } from './ItemModification.style';
import SwitchModification from '../../../Modifications/SwitchModification';
import ListGoogleTagsAffected from '../../../Modifications/ListGoogleTagsAffected/ListGoogleTagsAffected';
import DraftModificationType from '../../../../types/DraftModificationType';

function ItemModification(props) {
  const { modificationId, modificationProps } = props;

  const modificationSelector = useMemo(selectOneOfModification, []);
  const modificationSlice = useSelector((state) => modificationSelector(state, modificationId));

  const modification = useMemo(() => modificationSlice ?? modificationProps, [modificationProps, modificationSlice]);

  const date = useMemo(() => {
    if (modification) {
      return new Date(parseInt(modification.createdAt, 10)).toLocaleString();
    }
    return '';
  }, [modification]);

  const author = useMemo(() => {
    const { user } = modification;
    if (user) {
      return ` | ${user.firstname} ${user.lastname}`;
    }
    return '';
  }, [modification]);

  return (
    <>
      <DateModification>{date + author}</DateModification>
      <TextWrapper>
        <SwitchModification modification={modification} />
        <AffectedGoogleTags>
          <ListGoogleTagsAffected modificationProp={modification} />
        </AffectedGoogleTags>
      </TextWrapper>
    </>
  );
}

ItemModification.propTypes = {
  modificationId: PropTypes.number,
  modificationProps: PropTypes.arrayOf(DraftModificationType),
};

ItemModification.defaultProps = {
  modificationProps: [],
  modificationId: null,
};
export default ItemModification;
