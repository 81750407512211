import PropTypes from 'prop-types';
import { NavItemSelector, NavItemSelectorHelper, NavItemSelectorIcon, NavItemSelectorIndicator } from './NavMenu.style';

function NavItem(props) {
  const { to, title, icon } = props;

  return (
    <NavItemSelector to={to}>
      {({ isActive }) => (
        <>
          <NavItemSelectorHelper />
          <NavItemSelectorIcon>{icon}</NavItemSelectorIcon>
          {title}
          <NavItemSelectorIndicator isActive={isActive} />
        </>
      )}
    </NavItemSelector>
  );
}

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

NavItem.defaultProps = {
  icon: null,
};

export default NavItem;
