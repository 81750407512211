/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getSuggestions, postSuggestion, updateSuggestion } from '../helpers/api/suggestions';

export const DOMAIN = 'suggestions';

export const fetchSuggestions = createAsyncThunk(`${DOMAIN}/fetch`, async (data, thunkAPI) => {
  try {
    return getSuggestions(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createSuggestion = createAsyncThunk(`${DOMAIN}/create`, async (body, thunkAPI) => {
  try {
    return postSuggestion(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchSuggestion = createAsyncThunk(`${DOMAIN}/patch`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return updateSuggestion(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  suggestions: [],
  status: 'idle',
  error: null,
};

export const SuggestionSlice = createSlice({
  name: DOMAIN,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSuggestions.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchSuggestions.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.suggestions = action.payload['hydra:member'];
    });
    builder.addCase(fetchSuggestions.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
    builder.addCase(createSuggestion.fulfilled, (state, action) => {
      state.suggestions.unshift(action.payload);
    });
    builder.addCase(patchSuggestion.fulfilled, (state, action) => {
      const { payload } = action;
      state.suggestions = state.suggestions.map((suggestion) => (suggestion.id === payload.id ? payload : suggestion));
    });
  },
});

export const selectSuggestions = (state) => state[DOMAIN].suggestions;
export const selectSuggestionsStatus = (state) => state[DOMAIN].status;
export const selectSuggestion = () =>
  createSelector([selectSuggestions, (state, id) => id], (suggestions, id) => {
    return suggestions.find((suggestion) => suggestion.id === id);
  });

export default SuggestionSlice.reducer;
