/* eslint-disable */
import classes from './SecondaryButton.module.css';
import compilePropClasses from '../../../../helpers/classBuilder';

function SecondaryButton(props) {
  const additionnalClasses = compilePropClasses(classes, props.extraClasses);

  return (
    <button className={`${classes.secondary} ${additionnalClasses}`} onClick={props.onClick}>
      {props.name}
    </button>
  );
}

export default SecondaryButton;
