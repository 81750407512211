import styled from '@emotion/styled/macro';
import { CardMedia } from '@mui/material';

const IconWrapper = styled(CardMedia)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  height: 140px;

  & > * {
    height: 80%;
    width: 80%;
  }
`;

export { IconWrapper };
