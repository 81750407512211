import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const DraggableDiv = styled.div`
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 8px solid ${(props) => (props.colorCode ? `#${props.colorCode}` : 'aqua')};
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  height: 20px;
  margin: 10px 0;

  ${({ active }) =>
    active
      ? css`
          cursor: grab;
        `
      : css`
          cursor: help;
          opacity: 0.25;
        `}
`;

export { DraggableDiv };
