import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { DraggableDiv } from './DraggableObject.style';

function DraggableObject(props) {
  const { colorCode, onDragStart, active, tooltipText, children } = props;

  return (
    <Tooltip title={!active && tooltipText}>
      <DraggableDiv draggable={active} colorCode={colorCode} onDragStart={onDragStart} active={active}>
        {children}
      </DraggableDiv>
    </Tooltip>
  );
}

DraggableObject.propTypes = {
  colorCode: PropTypes.string,
  onDragStart: PropTypes.func.isRequired,
  active: PropTypes.bool,
  tooltipText: PropTypes.string,
  children: PropTypes.node.isRequired,
};

DraggableObject.defaultProps = {
  colorCode: null,
  active: true,
  tooltipText: '',
};

export default DraggableObject;
