import { useEffect, useState } from 'react';
import { BASE_URL, MERCURE_BASE_URL } from '../references/base-urls';

function useEventSourceProgress(topic, callback = () => {}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const url = new URL(MERCURE_BASE_URL);
    url.searchParams.append('topic', `${BASE_URL}/api/${topic}`);
    const sse = new EventSource(url);

    sse.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.current && data.total) {
        setProgress((data.current / data.total) * 100);
      }

      callback(data);
    };

    return () => {
      sse.close();
    };
  }, [callback, topic]);

  return progress;
}

export default useEventSourceProgress;
