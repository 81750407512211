import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllSnapshots(itemsPerPage, page = 1) {
  const url = `${API_BASE_URL}/google_tag_snapshots?order[id]=desc&page=${page}&itemsPerPage=${itemsPerPage}`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getImportingSnapshot() {
  const url = `${API_BASE_URL}/google_tag_snapshots?isImporting=true`;
  const response = await defaultFetch(url);

  return response.json();
}
