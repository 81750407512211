import { Collapse, List } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FaBolt } from 'react-icons/fa';
import { useLocalStorage } from 'usehooks-ts';
import DraftEvent from './Draft/DraftEvent';
import {
  CollapseWrapper,
  EventWrapper,
  ExpandEventsButton,
  ExpandEventsIconWrapper,
  TabletWrapper,
} from './EventListener.style';
import Listener from './Listener';

const EVENTS = [
  {
    label: 'Draft',
    labelEmpty: "Aucun draft en cours d'application",
    topic: 'google_tag_drafts/apply',
    eventType: DraftEvent,
  },
];

function EventListener() {
  const [open, setOpen] = useState(false);

  const [startPositions, setStartPositions] = useState({ x: 0, y: 0 });
  const [positions, setPositions] = useLocalStorage('boltPositions', { x: 40, y: 40 });

  const [left, bottom, reading, up] = useMemo(() => {
    const { innerHeight, innerWidth } = window;
    const { x, y } = positions;

    let computedLeft = x;
    let computedBottom = y;
    let computedReading = true;
    let computedUp = true;

    if (!x) {
      computedLeft = 40;
    } else if (x < 0) {
      computedLeft = 0;
    } else if (innerWidth - (x + 60) < 0) {
      computedLeft = innerWidth - 60;
    }

    if (!y) {
      computedBottom = 40;
    } else if (y < 0) {
      computedBottom = 0;
    } else if (innerHeight - (y + 60) < 0) {
      computedBottom = innerHeight - 60;
    }

    if (innerWidth - computedLeft < 550) {
      computedReading = false;
    }

    if (innerHeight - computedBottom < innerHeight / 2) {
      computedUp = false;
    }

    return [computedLeft, computedBottom, computedReading, computedUp];
  }, [positions]);

  const handleOpen = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const handleDragStart = useCallback((e) => {
    const { bottom: clientBottom, left: clientLeft } = e.target.getBoundingClientRect();
    setStartPositions({ x: e.clientX - clientLeft, y: clientBottom - e.clientY });
  }, []);

  const handleDragEnd = useCallback(
    (e) => {
      const { innerHeight } = window;
      setPositions({ x: e.clientX - startPositions.x, y: innerHeight - e.clientY - startPositions.y });
    },
    [startPositions, setPositions]
  );

  return (
    <TabletWrapper style={{ left, bottom }}>
      <CollapseWrapper up={up} reading={reading}>
        <Collapse in={open} orientation="horizontal" timeout={250}>
          <Collapse in={open} orientation="vertical" timeout={250}>
            <EventWrapper up={up} reading={reading}>
              <List>
                {EVENTS.map((e) => (
                  <Listener
                    key={e.topic}
                    label={e.label}
                    labelEmpty={e.labelEmpty}
                    topic={e.topic}
                    eventType={e.eventType}
                  />
                ))}
              </List>
            </EventWrapper>
          </Collapse>
        </Collapse>
      </CollapseWrapper>
      <ExpandEventsButton onClick={handleOpen}>
        <ExpandEventsIconWrapper
          open={open}
          draggable
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          up={up}
          reading={reading}
        >
          <FaBolt size="30" color="#ffc400" />
        </ExpandEventsIconWrapper>
      </ExpandEventsButton>
    </TabletWrapper>
  );
}

export default EventListener;
