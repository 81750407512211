import { Box, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function LinearProgressWithLabel(props) {
  const { value, size } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: size }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 40 }}>
        <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.string,
};

LinearProgressWithLabel.defaultProps = {
  size: 'auto',
};

export default LinearProgressWithLabel;
