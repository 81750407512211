import { CSSProperties, ReactNode, createElement, useMemo } from 'react';

type HeadingType = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  title?: string;
  sx?: CSSProperties;
  children: ReactNode;
};

function Heading({ variant = 'h1', title = undefined, sx = {}, children }: HeadingType): ReactNode {
  const titleMemo = useMemo(() => {
    if (title) return title;

    if (Array.isArray(children)) {
      const message: (string | number)[] = [];
      children.forEach(
        (element) => typeof element !== 'object' && typeof element !== 'function' && message.push(element)
      );

      return message.join('');
    }

    return children;
  }, [title, children]);

  const style = useMemo(() => {
    if (Object.keys(sx).length === 0) {
      return null;
    }

    return sx;
  }, [sx]);

  return createElement(variant, { title: titleMemo, style }, children);
}

export default Heading;
