import styled from '@emotion/styled/macro';
import { Tab, Tabs } from '@mui/material';

const SidebarWrapper = styled.div`
  background-color: lightgrey;
  border-radius: 0 0 15px 15px;
  padding-bottom: 1rem;
`;

const SidebarContent = styled.div`
  background-color: white;
  margin: 0 12px 0 10px;
  border-radius: 15px;
  border-top-left-radius: ${(props) => props.tab === 1 && '0'};
  border-top-right-radius: ${(props) => props.tab === 2 && '0'};
  padding: 10px;
`;

const SidebarTabs = styled(Tabs)`
  margin: 0;
  display: flex;
  justify-content: space-between;
`;

const SidebarTab = styled(Tab)`
  border-radius: 15px 15px 0 0;
  padding: 0 40px;
  margin: 0 10px;
  color: #264653;
  font-weight: bolder;
  &.Mui-selected {
    color: #264653;
  }
`;

export { SidebarWrapper, SidebarContent, SidebarTabs, SidebarTab };
