import styled from '@emotion/styled/macro';

const DraftWrapper = styled.div`
  padding: 15px 30px;
  background-color: ${(props) => (props.isActive ? 'lightcoral' : '#f6f6f6')};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { DraftWrapper, TitleWrapper };
