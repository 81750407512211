import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TiArrowBack } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { createDraft, updateDraft } from '../../../../../../store/DraftSlice';
import {
  removeLastModification,
  resetModifications,
  restoreLastModification,
  selectModifications,
  selectRemovedModifications,
} from '../../../../../../store/ModificationsSlice';
import ModalDetail from '../../../../../Modifications/ModalDetail';
import Modification from '../../../../../Modifications/Modification';
import { partialReset } from '../../Tabs/TabBiddersTab/TabBiddersModificationsSlice';
import {
  HistoryButtonWrapper,
  HistoryExpandButton,
  HistoryExpandIcon,
  HistoryTitle,
  ModificationButton,
  ModificationButtonsContainer,
  ModificationList,
  Wrapper,
} from './History.style';

function History(props) {
  const { mass } = props;

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const draft = useSelector((state) => state.draft.draft);
  const modifications = useSelector(selectModifications());
  const removedModification = useSelector(selectRemovedModifications);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleConfirmation = useCallback(() => {
    let response;

    if (draft) {
      const body = { ...draft, draftModifications: modifications };
      body.draftModifications = body.draftModifications.map((m) => m['@id'] ?? m);

      response = dispatch(updateDraft({ draftId: draft.id, body }));
    } else {
      const body = { draftModifications: modifications, isActive: true, type: mass };
      response = dispatch(createDraft(body));
    }

    response.unwrap().then(() => {
      enqueueSnackbar('Success', { variant: 'success' });
    });
  }, [dispatch, draft, enqueueSnackbar, mass, modifications]);

  const handleAbandonment = useCallback(() => {
    dispatch(resetModifications(draft?.draftModifications));
    dispatch(partialReset());
  }, [dispatch, draft]);

  const disableButtons = useMemo(() => {
    if (!draft && modifications.length === 0) {
      return true;
    }

    if (!draft) {
      return false;
    }

    if (modifications.length === draft.draftModifications.length) {
      const stringModifications = JSON.stringify(modifications);
      const stringDraftModifications = JSON.stringify(draft.draftModifications);

      return stringModifications === stringDraftModifications;
    }

    return false;
  }, [draft, modifications]);

  const handleRemoveLastModification = useCallback(() => {
    dispatch(removeLastModification());
  }, [dispatch]);

  const handleRestoreLastModification = useCallback(() => {
    dispatch(restoreLastModification());
  }, [dispatch]);

  const handleKeyDowned = useCallback(
    (e) => {
      if (!e.ctrlKey) return;

      if (e.keyCode === 90) {
        handleRemoveLastModification();
      } else if (e.keyCode === 89) {
        handleRestoreLastModification();
      }
    },
    [handleRemoveLastModification, handleRestoreLastModification]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDowned);

    return () => {
      document.removeEventListener('keydown', handleKeyDowned);
    };
  }, [handleKeyDowned]);

  return (
    <>
      <Wrapper>
        <HistoryTitle>
          <span>Modifications</span>
        </HistoryTitle>
        <HistoryButtonWrapper>
          <HistoryExpandButton onClick={handleRemoveLastModification} disabled={modifications.length === 0}>
            <TiArrowBack size="1.7em" />
          </HistoryExpandButton>
          <HistoryExpandButton onClick={handleRestoreLastModification} disabled={removedModification.length === 0}>
            <TiArrowBack size="1.7em" style={{ transform: 'scaleX(-1)' }} />
          </HistoryExpandButton>
          <HistoryExpandButton onClick={() => setOpen(true)}>
            <HistoryExpandIcon />
          </HistoryExpandButton>
        </HistoryButtonWrapper>
        <ModificationList>
          {modifications
            .map((element) => <Modification key={element.createdAt} createdAt={element.createdAt} />)
            .reverse()}
        </ModificationList>
        <ModificationButtonsContainer>
          <ModificationButton
            type="button"
            variant="contained"
            onClick={() => handleConfirmation()}
            disabled={disableButtons}
          >
            Confirmer
          </ModificationButton>
          <ModificationButton
            type="button"
            variant="contained"
            color="warning"
            onClick={() => handleAbandonment()}
            disabled={disableButtons}
          >
            Reset
          </ModificationButton>
        </ModificationButtonsContainer>
      </Wrapper>
      {open && <ModalDetail open={open} handleClose={handleClose} />}
    </>
  );
}

History.propTypes = {
  mass: PropTypes.number,
};

History.defaultProps = {
  mass: 0,
};

export default History;
