import { SuggestionWrapper } from './Suggestion.style';
import ListSuggestion from './List/ListSuggestion';
import AddSuggestion from './AddSuggestion';

function Suggestion() {
  return (
    <SuggestionWrapper>
      <ListSuggestion />
      <AddSuggestion />
    </SuggestionWrapper>
  );
}

export default Suggestion;
