import React from 'react';
import PropTypes from 'prop-types';
import { CustomLabel } from './Label.style';

export const prebidVersionColor = '#ff8533';
export const verticalLoadingColor = '#4d88ff';
export const digitekaPlayerColor = '#00b359';
export const inProdColor = '#c68c53';
export const partnerGroupColor = '#cc7ba6';
export const validColor = '#2d862d  ';
export const errorColor = '#ff1a1a  ';

function Label(props) {
  const { color, padding, children } = props;
  return (
    <CustomLabel color={color} padding={padding}>
      {children}
    </CustomLabel>
  );
}

Label.propTypes = {
  color: PropTypes.string.isRequired,
  padding: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Label.defaultProps = {
  padding: '0.5em',
};

export default Label;
