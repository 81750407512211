import TextWithLink from '../../components/UI/Basic/Link/TextWithLink';
import Heading from '../../components/UI/Title/Heading';
import { ListFlex } from './Admin.style';

function Admin() {
  return (
    <>
      <Heading variant="h2">
        Bienvenue sur votre page d&#39;administration des trucs super confidentiels de CartoWriter
      </Heading>
      <ListFlex>
        <TextWithLink title="Partenaires" description="Gérez ici la liste des sites partenaires" url="partners" />
        <TextWithLink title="SSP" description="Gérez ici la liste de SSP" url="bidders" />
        <TextWithLink
          title="Blocks"
          description="Possibilité de créer de block de code pour la génération de GTAG"
          url="blocs"
        />
        <TextWithLink title="Import" description="Voir l'historique des imports de GoogleTags" url="history" />
        <TextWithLink
          title="Prebid"
          description="La gestion des versions de Prebid par l'administrateur"
          url="prebid"
        />
        <TextWithLink title="Utilisateurs" description="La gestion des accès à l'application" url="users" />
        <TextWithLink
          title="Templates Xandr"
          description="Gestion des templates Xandr (Placements, Publisher)"
          url="xandr"
        />
        <TextWithLink title="Positions" description="Gestion des positions" url="position" />
        <TextWithLink title="Topics" description="Gestion des topics" url="topics" />
      </ListFlex>
    </>
  );
}

export default Admin;
