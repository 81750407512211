/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  clone,
  getAllGoogleTag,
  getGoogleTagVersion,
  getGoogleTagVersionsByPartner,
  patchGoogleTag,
} from '../helpers/api/googleTagVersion';
import { getCurrentGoogleTagVersionByPartnerWebsite } from '../helpers/api/partner';

const domain = 'googleTagVersion';

export const fetchGoogleTagVersion = createAsyncThunk(`${domain}/fetch`, async (id, thunkAPI) => {
  try {
    return getGoogleTagVersion(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchAllGoogleTagVersion = createAsyncThunk(`/allGtag`, async (data, thunkAPI) => {
  try {
    return getAllGoogleTag(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchGoogleTagByPartner = createAsyncThunk(`/googleTagByPartner`, async (id, thunkAPI) => {
  try {
    return getGoogleTagVersionsByPartner(id);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchCurrentGoogleTagVersion = createAsyncThunk(`/update`, async (data, thunkAPI) => {
  const { id, body } = data;
  try {
    return patchGoogleTag(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchCurrentGoogleTagVersionByPartnerWebsite = createAsyncThunk(
  `/fetchCurrentGtagByPartner`,
  async (partnerId, thunkAPI) => {
    try {
      return getCurrentGoogleTagVersionByPartnerWebsite(partnerId);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const cloneGoogleTagVersion = createAsyncThunk(`/cloneGoogleTagVersion`, async (data, thunkAPI) => {
  try {
    const { idPartner, idGoogleTag } = data;
    return clone(idPartner, idGoogleTag);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  googleTagVersions: [],
  total: 0,
  googleTagVersion: null,
  status: '',
  error: null,
};

export const GoogleTagVersionSlice = createSlice({
  name: 'googleTagVersion',
  initialState,
  reducers: {
    reset: () => initialState,
    setGoogleTagVersion: (state, action) => {
      state.googleTagVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGoogleTagVersion.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchGoogleTagVersion.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.googleTagVersion = action.payload;
    });
    builder.addCase(fetchGoogleTagVersion.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchAllGoogleTagVersion.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllGoogleTagVersion.fulfilled, (state, action) => {
      const { meta, payload } = action;
      state.status = 'succeeded';
      if (!meta.arg.page || meta.arg.page === 1) {
        state.googleTagVersions = payload['hydra:member'];
      } else {
        state.googleTagVersions.push(...payload['hydra:member']);
      }

      state.total = payload['hydra:totalItems'];
    });
    builder.addCase(fetchAllGoogleTagVersion.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(patchCurrentGoogleTagVersion.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.googleTagVersion = action.payload;
    });

    builder.addCase(fetchGoogleTagByPartner.pending, (state) => {
      state.status = 'pending';
    });

    builder.addCase(fetchGoogleTagByPartner.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.googleTagVersions = action.payload['hydra:member'];
    });

    builder.addCase(fetchGoogleTagByPartner.rejected, (state) => {
      state.status = 'rejected';
    });

    builder.addCase(fetchCurrentGoogleTagVersionByPartnerWebsite.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.googleTagVersion = action.payload;
    });

    builder.addCase(cloneGoogleTagVersion.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(cloneGoogleTagVersion.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.googleTagVersions = [...state.googleTagVersions, action.payload];
    });
    builder.addCase(cloneGoogleTagVersion.rejected, (state) => {
      state.status = 'rejected';
    });
  },
});

export const { setGoogleTagVersion, reset } = GoogleTagVersionSlice.actions;
export const selectGoogleTagVersion = (state) => state.googleTagVersion.googleTagVersion;

export const selectAllGoogleTagVersion = (state) => state.googleTagVersion.googleTagVersions;
export const selectGoogleTagVersionTotal = (state) => state.googleTagVersion.total;

export const selectGoogleTagVersionStatus = (state) => state.googleTagVersion.status;

export const selectGoogleTag = () =>
  createSelector(
    (state) => state.googleTagVersion,
    (_, id) => id,
    (state, id) => state.googleTagVersions.find((googleTagVersion) => googleTagVersion.id === id)
  );

export default GoogleTagVersionSlice.reducer;
