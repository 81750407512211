/* eslint-disable */
import { Fragment } from 'react';
import classes from './MySelection.module.css';
import Button from '../../../UI/Basic/Buttons/Button';
import RoundedNumber from '../../../UI/Basic/RoundedNumber';

function MySelection(props) {
  const { firstChoice, selectedGtag, onValidateChoice } = props;
  let content = <p>Site en attente...</p>;
  let content2 = <p>Gtag en attente...</p>;
  let disabled = true;
  if (firstChoice && selectedGtag) {
    disabled = false;
  }

  const createGtagHandler = () => {
    onValidateChoice(firstChoice.uuid, selectedGtag.googletagUuid);
  };

  const buttons = (
    <Button disabled={disabled} onClick={createGtagHandler}>
      CLONER
    </Button>
  );

  if (props.firstChoice) {
    content = (
      <p>
        {firstChoice.name} ({firstChoice.trigram})
      </p>
    );
  }

  if (selectedGtag) {
    content2 = (
      <p>
        - {selectedGtag.gtagReleaseId} ({selectedGtag.gtagCreatedAt})
      </p>
    );
  }

  return (
    <div>
      <div className={classes.flex}>
        <RoundedNumber number="3" />
        <h2>Votre sélection</h2>
      </div>
      <div className={classes.mySelection}>
        {content}
        {content2}
      </div>
      {buttons}
    </div>
  );
}

export default MySelection;
