/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  fetchPartnerWithInfos,
  getAllPartners,
  getAllPartnerWebsites,
  getPartnerWebsites,
} from '../helpers/api/partner';

const DOMAIN = 'partners';

export const fetchAllPartners = createAsyncThunk(`${DOMAIN}/fetchAll`, async (data, thunkAPI) => {
  try {
    const { page, itemsPerPage, pagination } = data;
    return getAllPartners(page, itemsPerPage, pagination);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchAuthorizedPartners = createAsyncThunk(`${DOMAIN}/fetchAuthorized`, async (data, thunkAPI) => {
  try {
    const { page, itemsPerPage } = data;
    return getPartnerWebsites(page, itemsPerPage);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchAllAuthorizedPartners = createAsyncThunk(`${DOMAIN}/fetchAllAuthorized`, async (thunkAPI) => {
  try {
    return getAllPartnerWebsites();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchAllPartnersWithInfos = createAsyncThunk(`${DOMAIN}/fetcAllPartnersWithInfos`, async (thunkAPI) => {
  try {
    return fetchPartnerWithInfos();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  totalPartners: 0,
  allAuthorizedPartners: [],
  partners: [],
  status: 'idle',
  error: null,
};

export const PartnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPartners.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllPartners.fulfilled, (state, action) => {
      const { meta, payload } = action;
      if (!meta.arg.page || meta.arg.page === 1) {
        state.partners = payload['hydra:member'];
      } else {
        state.partners.push(...payload['hydra:member']);
      }

      state.status = 'resolved';
      state.totalPartners = payload['hydra:totalItems'];
    });
    builder.addCase(fetchAllPartners.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchAuthorizedPartners.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAuthorizedPartners.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partners = action.payload['hydra:member'];
      state.totalPartners = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchAuthorizedPartners.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchAllAuthorizedPartners.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllAuthorizedPartners.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.allAuthorizedPartners = action.payload['hydra:member'];
      state.totalPartners = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchAllAuthorizedPartners.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(fetchAllPartnersWithInfos.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllPartnersWithInfos.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.partners = action.payload['hydra:member'];
    });
    builder.addCase(fetchAllPartnersWithInfos.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });
  },
});

export const selectPartner = () =>
  createSelector(
    (state) => state[DOMAIN],
    (_, id) => id,
    (state, id) => state.partners.find((partner) => partner.id === id)
  );

export const selectPartnerByGroupId = () =>
  createSelector(
    (state) => state[DOMAIN],
    (_, id) => id,
    (state, id) => state.partners.find((partner) => partner.idGroup === id)
  );

export const selectPartnersStatus = (state) => state[DOMAIN].status;
export const selectPartners = (state) => state[DOMAIN].partners;
export const selectTotalPartners = (state) => state[DOMAIN].totalPartners;
export const selectAuthorizedPartners = (state) => state[DOMAIN].allAuthorizedPartners;

export default PartnersSlice.reducer;
