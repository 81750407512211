import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { v4 } from 'uuid';
import IconButtonTooltip from '../../Buttons/IconButtonTooltip';
import Heading from '../../Title/Heading';
import {
  InputSuggestionTitleWrapper,
  InputSuggestionWrapper,
  InputWrapper,
  SuggestionsListWrapper,
} from './Input.style';

function InputWithSuggestions(props) {
  const { formProps, suggestions, infos, sx } = props;
  const { onFocus, onBlur } = formProps;

  const wrapperRef = useRef(null);
  const [uuid] = useState(v4());
  const [hasFocus, setHasFocus] = useState(false);
  const [show, setShow] = useState(false);

  const handleFocus = useCallback(
    (event) => {
      setShow(hasFocus || wrapperRef.current.contains(event.target));
    },
    [hasFocus]
  );

  const handleOnFocus = useCallback(
    (event) => {
      setHasFocus(true);

      if (onFocus && typeof onFocus === 'function') {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const handleOnBlur = useCallback(
    (event) => {
      setHasFocus(false);

      if (onBlur && typeof onBlur === 'function') {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleClick = useCallback(
    (event, value) => {
      const input = document.getElementById(uuid);
      const { selectionStart, selectionEnd } = input;
      const finalValue = input.value.slice(0, selectionStart) + value + input.value.slice(selectionEnd);

      const setter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
      setter.call(input, finalValue);

      const inputEvent = new Event('input', { bubbles: true });
      input.dispatchEvent(inputEvent);

      input.focus();
      input.selectionStart = selectionStart + value.length;
      input.selectionEnd = selectionStart + value.length;
    },
    [uuid]
  );

  useEffect(() => {
    document.addEventListener('click', handleFocus);

    return () => {
      document.removeEventListener('click', handleFocus);
    };
  });

  return (
    <InputWrapper ref={wrapperRef} sx={sx}>
      <TextField id={uuid} {...formProps} onFocus={handleOnFocus} onBlur={handleOnBlur} fullWidth />
      {show && suggestions && suggestions.length > 0 && (
        <InputSuggestionWrapper>
          <InputSuggestionTitleWrapper>
            <Heading variant="h4" sx={{ margin: '5px' }}>
              Suggestions
            </Heading>
            {infos && <IconButtonTooltip Icon={FaInfoCircle} title={infos} />}
          </InputSuggestionTitleWrapper>
          <SuggestionsListWrapper>
            {suggestions.map((suggestion) => (
              <div key={suggestion.value}>
                <Button
                  variant="outlined"
                  size="small"
                  autoCapitalize="none"
                  onClick={(event) => handleClick(event, suggestion.value)}
                >
                  {suggestion.label}
                </Button>
              </div>
            ))}
          </SuggestionsListWrapper>
        </InputSuggestionWrapper>
      )}
    </InputWrapper>
  );
}

InputWithSuggestions.propTypes = {
  formProps: PropTypes.shape({
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    ref: PropTypes.func,
  }),
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  sx: PropTypes.shape({}),
  infos: PropTypes.string,
};

InputWithSuggestions.defaultProps = {
  sx: {},
  infos: null,
};

InputWithSuggestions.defaultProps = {
  formProps: {},
};

export default InputWithSuggestions;
