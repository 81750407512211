import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getConfigurations() {
  const url = `${API_BASE_URL}/configurations`;
  const response = await defaultFetch(url.toString());

  return response.json();
}

export async function postConfiguration(body) {
  const url = `${API_BASE_URL}/configurations`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function patchConfiguration(id, body) {
  const url = `${API_BASE_URL}/configurations/${id}`;
  const response = await defaultFetch(url, 'PATCH', body);

  return response.json();
}

export async function deleteConfiguration(id) {
  const url = `${API_BASE_URL}/configurations/${id}`;
  return defaultFetch(url, 'DELETE');
}
