import { useDispatch, useSelector } from 'react-redux';
import { fetchAllPartners, selectPartners, selectTotalPartners } from '../../../../store/PartnersSlice';
import Table from '../../../UI/CompleteTable/Table';
import Heading from '../../../UI/Title/Heading';
import { CardPartner } from '../CreatePartner/CreatePartner.style';
import ItemPartner from './Item/ItemPartner';

function GCPRepository() {
  const dispatch = useDispatch();

  const columns = [
    { id: '0', label: 'ID' },
    { id: '1', label: 'Nom du partenaire' },
    { id: '2', label: 'keyname' },
    { id: '3', label: 'Trigram' },
    { id: '4', label: 'Nom du Groupe' },
    { id: '5', label: 'Repertoire GCP' },
  ];

  const partners = useSelector(selectPartners);
  const totalPartners = useSelector(selectTotalPartners);

  const fetchPartner = (page, itemsPerPage) => {
    dispatch(fetchAllPartners({ page: page + 1, itemsPerPage }));
  };

  return (
    <CardPartner>
      <Heading>Gestion des répertoires GCP</Heading>
      <Table
        columns={columns}
        data={partners}
        getData={fetchPartner}
        Line={ItemPartner}
        itemsPerPage={15}
        total={totalPartners}
      />
    </CardPartner>
  );
}

export default GCPRepository;
