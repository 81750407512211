import styled from '@emotion/styled/macro';

const HeaderNotificationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 6vh;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SwitchLabel = styled.p`
  font-size: 12px;
  margin: 0;
`;

const NotifTitle = styled.h2``;

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const IconWrapper = styled.div``;

export { HeaderNotificationsContainer, SwitchContainer, SwitchLabel, NotifTitle, IconContainer, IconWrapper };
