import { ImCancelCircle } from 'react-icons/im';
import PropTypes from 'prop-types';
import { HoverWrapper, Hover, NoHover } from './RemovableLabel.style';

function RemovableLabel(props) {
  const { children, label, height, width, backgroundColor, onClick, className } = props;

  return (
    <HoverWrapper className={className} height={height} width={width} backgroundColor={backgroundColor}>
      <NoHover>{children ?? label}</NoHover>
      <Hover onClick={onClick}>
        <ImCancelCircle />
      </Hover>
    </HoverWrapper>
  );
}

RemovableLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
};

RemovableLabel.defaultProps = {
  children: null,
  className: '',
  label: '',
  height: null,
  width: null,
  backgroundColor: null,
};

export default RemovableLabel;
