import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchAllDrafts, selectAllDrafts } from '../../../../store/DraftSlice';
import DraftItem from './DraftItem/DraftItem';

function History() {
  const drafts = useSelector(selectAllDrafts);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      type: 1,
    };
    dispatch(fetchAllDrafts(data));
  }, [dispatch]);

  return <div>{drafts && drafts.map((draft) => <DraftItem draftId={draft.id} key={draft.id} />)}</div>;
}

export default History;
