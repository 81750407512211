import styled from '@emotion/styled/macro';
import { Button, FormControl } from '@mui/material';

const FormControlFilter = styled(FormControl)`
  margin: 0.5rem 0;
`;

const FilterButton = styled(Button)`
  width: 100%;
  text-align: center;
  background-color: #264653;
  border-radius: 10px;
  color: white;
  border: 1px solid transparent;

  &:hover {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #264653;
    color: #264653;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 3rem;
`;

export { FormControlFilter, FilterButton, LoaderWrapper };
