import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CsvAnalysis from '../../../../components/GoogleTag/Edit/BidderCsv/CsvAnalysis/CsvAnalysis';
import SelectBidder from '../../../../components/GoogleTag/Edit/BidderCsv/SelectBidder';
import UploadFile from '../../../../components/GoogleTag/Edit/BidderCsv/UploadFile';
import { LoaderWrapper } from '../../../../components/Mass/Sidebar/Filter/SidebarFilter.style';
import Loader from '../../../../components/UI/Animation/Loader';
import MuiModal from '../../../../components/UI/Modal/MuiModal';
import Heading from '../../../../components/UI/Title/Heading';
import { importCSV } from '../../../../helpers/api/bidders';
import classes from './BidderCsv.module.css';

function BidderXls() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedBidder, setSelectedBidder] = useState('');
  const [file, setFile] = useState(null);
  const [csvAnalysis, setCsvAnalysis] = useState(null);
  const [csvConsumedText, setCsvConsumedText] = useState('');
  const [openModal, setOpenModal] = useState(false);

  // Permet de vérifier que le fichier mis est conforme au SSP choisi
  useEffect(() => {
    if (!selectedBidder || !file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('action', 'verify');

    importCSV(selectedBidder, formData)
      .then((response) => response.json())
      .then((data) => setCsvAnalysis(data.content))
      .catch((exception) => enqueueSnackbar(exception.message, { variant: 'error' }));
  }, [selectedBidder, file, enqueueSnackbar]);

  const onSelectHandler = (bidderId) => {
    setSelectedBidder(bidderId);
  };

  const fileAddedHandler = (files) => {
    setFile(files);
  };

  const consumeFileHandler = async () => {
    if (!selectedBidder || !file) {
      enqueueSnackbar("Le fichier ou le bidder n'est plus sélectionné");
    }

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('action', 'consume');

    setCsvConsumedText('');
    setOpenModal(true);

    importCSV(selectedBidder, formData)
      .then((response) => {
        response.blob().then((blob) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          let filename;
          if (contentDisposition) {
            [filename] = contentDisposition.split('filename=')[1].split(';');
          } else {
            filename = 'bidder.csv';
          }

          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });

        setCsvConsumedText('Le CSV a été importé avec succès.');
      })
      .catch((exception) => {
        setCsvConsumedText("Une erreur est survenue lors de l'import.");
        enqueueSnackbar(exception.message, { variant: 'error' });
      });
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <MuiModal title="Traitement de l'import CSV" handleClose={closeModal} open={openModal}>
        {csvConsumedText !== '' ? (
          csvConsumedText
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </MuiModal>
      <div>
        <Heading>Importez les placements ids de vos SSP</Heading>
        <div>
          <Link to="instructions">En suivant la liste des instructions</Link>
        </div>
        <div>
          <Link to="results">Liste des précédents imports </Link>
        </div>
      </div>
      <div className={classes.flex}>
        <SelectBidder selectedBidderId={selectedBidder} onSelected={onSelectHandler} />
        {selectedBidder && <UploadFile fileChanged={fileAddedHandler} />}
      </div>
      {csvAnalysis && <CsvAnalysis consumeFile={consumeFileHandler} report={csvAnalysis} />}
    </div>
  );
}

export default BidderXls;
