import styled from '@emotion/styled/macro';
import { FormControl } from '@mui/material';

const SizeFormControl = styled(FormControl)`
  margin: 0.5rem 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { SizeFormControl, ButtonWrapper };
