import styled from '@emotion/styled';

const InputFile = styled.input`
  display: none;
`;

const LabelInputFile = styled.label`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #264653;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  width: ${({ width }) => width};

  &:hover {
    background-color: lightgrey;
  }
`;

export { InputFile, LabelInputFile };
