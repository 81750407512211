import { FormControl, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GoogleTagStatus from '../../../constants/GoogleTagStatus';
import { returnStatusValue } from '../../../helpers/returnStatusValue';
import {
  cloneGoogleTagVersion,
  fetchGoogleTagByPartner,
  reset,
  selectAllGoogleTagVersion,
  selectGoogleTagVersionStatus,
} from '../../../store/GoogleTagVersionSlice';
import PartnerType from '../../../types/PartnerType';
import { LoaderWrapper } from '../../Mass/Sidebar/Filter/SidebarFilter.style';
import StatusLabel from '../../Reusable/Status/StatusLabel';
import Loader from '../../UI/Animation/Loader';
import SecondaryButton from '../../UI/Basic/Buttons/SecondaryButton';
import Heading from '../../UI/Title/Heading';
import {
  GoogleTagLabel,
  GoogleTagSelect,
  GridSelected,
  InfoWrapper,
  SelectedPartnerWrapper,
} from './CollapseSelectedPartner.style';

function CollapseSelectedPartner(props) {
  const navigate = useNavigate();
  const { selectedPartnerInfo } = props;
  const [googleTag, setGoogleTag] = useState(null);
  const dispatch = useDispatch();
  const googleTagVersions = useSelector(selectAllGoogleTagVersion);
  const status = useSelector(selectGoogleTagVersionStatus);
  const [loadingText, setLoadingText] = useState('Chargement du partenaire');

  useEffect(() => {
    dispatch(fetchGoogleTagByPartner(selectedPartnerInfo.idGroup));

    return () => dispatch(reset());
  }, [dispatch, selectedPartnerInfo]);

  useEffect(() => {
    setGoogleTag(googleTagVersions.find((gtag) => gtag.status === GoogleTagStatus.PROD));
  }, [googleTagVersions]);

  const createNewGtagUrl = `/generate-new-gtag/${selectedPartnerInfo.id}`;

  const handleChange = (event) => {
    setGoogleTag(googleTagVersions.find((gtag) => gtag.id === event.target.value));
  };

  const clone = () => {
    setLoadingText("Le GoogleTag en train d'être cloner");
    dispatch(cloneGoogleTagVersion({ idPartner: selectedPartnerInfo.id, idGoogleTag: googleTag.id }))
      .unwrap()
      .then((response) => {
        setGoogleTag(response);
      });
  };

  return (
    <SelectedPartnerWrapper>
      {googleTag && status !== 'pending' ? (
        <GridSelected>
          <InfoWrapper>
            <FormControl sx={{ width: '100%' }}>
              <GoogleTagLabel id="demo-simple-select-label">Google Tag</GoogleTagLabel>
              <GoogleTagSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={googleTag.id}
                label="Google Tag"
                onChange={handleChange}
              >
                {googleTagVersions.map((googleTagVersion) => {
                  const statusGoogleTag = returnStatusValue(googleTagVersion.status)?.key.toUpperCase();

                  const date = new Date(googleTagVersion.createdAt);
                  const year = date.getUTCFullYear();
                  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                  const day = String(date.getUTCDate()).padStart(2, '0');

                  return (
                    <MenuItem value={googleTagVersion.id} key={googleTagVersion.id}>
                      <b>{statusGoogleTag}</b> :&nbsp;
                      {googleTagVersion?.author && `[${googleTagVersion.author.trigram}]`}
                      {googleTagVersion?.createdAt && `[${year}${month}${day}] - `}
                      {googleTagVersion.description}
                    </MenuItem>
                  );
                })}
              </GoogleTagSelect>
            </FormControl>

            <Heading variant="h2">
              {googleTag.releaseId ?? googleTag.trigram}
              <StatusLabel status={googleTag.status} />
            </Heading>
            <p>
              <b>Auteur :</b> {googleTag.author?.trigram ?? 'N/A'}
            </p>
            <p>
              <b>Description :</b> {googleTag.description}
            </p>
          </InfoWrapper>
          <div>
            <SecondaryButton
              name="Consulter le site Partenaire"
              onClick={() => {
                navigate(`/show-partner/${selectedPartnerInfo.id}`, {
                  state: { partner: selectedPartnerInfo },
                });
              }}
            />
            <SecondaryButton
              name="Générer un Gtag tout nouveau"
              onClick={() => {
                navigate(createNewGtagUrl);
              }}
            />
            {googleTag && (
              <>
                <SecondaryButton
                  name={`${googleTag.status === GoogleTagStatus.JADIS ? 'Consulter' : 'Editer'} ce GoogleTag`}
                  onClick={() => {
                    navigate(`/edit-google-tag/${googleTag.id}`);
                  }}
                />
                <SecondaryButton name="Cloner ce GoogleTag" onClick={clone} />
              </>
            )}
          </div>
        </GridSelected>
      ) : (
        <GridSelected center>
          {status === 'pending' ? (
            <LoaderWrapper>
              <Loader />
              <p>{loadingText}</p>
            </LoaderWrapper>
          ) : (
            <>
              <SecondaryButton
                name="Générer un Gtag tout nouveau"
                onClick={() => {
                  navigate(createNewGtagUrl);
                }}
              />
              <SecondaryButton
                name="Consulter le site Partenaire"
                onClick={() => {
                  navigate(`/show-partner/${selectedPartnerInfo.id}`, {
                    state: { partner: selectedPartnerInfo },
                  });
                }}
              />
            </>
          )}
        </GridSelected>
      )}
    </SelectedPartnerWrapper>
  );
}

CollapseSelectedPartner.propTypes = {
  selectedPartnerInfo: PartnerType.isRequired,
};

CollapseSelectedPartner.defaultProp = {
  selectedPartnerInfo: {},
};
export default CollapseSelectedPartner;
