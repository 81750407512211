import PropTypes from 'prop-types';

const PositionType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    genericName: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  })
);

export default PositionType;
