import styled from '@emotion/styled/macro';

const ErrorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10%;
  grid-row-gap: 5%;
  margin-bottom: 20px;
`;

export { ErrorsWrapper };
