export default function compilePropClasses(cssModuleRef, extraClasses) {
  let additionnalClasses = '';
  let compiledClasses = [];

  if (extraClasses && extraClasses.length > 0) {
    compiledClasses = extraClasses.map((className) => {
      return `${cssModuleRef[className]} `;
    });
  }

  compiledClasses.forEach((compiled) => {
    additionnalClasses += compiled;
  });

  return additionnalClasses;
}
