import styled from '@emotion/styled/macro';
import { Draggable } from 'react-beautiful-dnd';

const BlockWrapper = styled.div`
  margin-left: ${(props) => props.recursive * 2}px;
  padding: 5px 7px;
  border: 1px solid black;
  border-radius: 3px;
`;

const NameAndArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 30%;
`;

const ClickableIcon = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-around;
`;

const CrossWrapper = styled.div`
  width: 30px;
`;

const IconNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BlockContainer = styled(Draggable)`
  width: 450px;
`;

const CodePreviewContainer = styled.div`
  background-color: #1e1e1e;
  padding: 10px;
  color: white;
`;

const CodePreview = styled.p`
  white-space: pre-line;
  margin: 0;
`;

export {
  BlockWrapper,
  NameAndArrowWrapper,
  IconWrapper,
  CrossWrapper,
  ClickableIcon,
  IconNameContainer,
  BlockContainer,
  CodePreviewContainer,
  CodePreview,
};
