import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

function InnerConnectionUtils(props) {
  const { setUseNavigationRef, setUseLocationRef } = props;

  setUseNavigationRef(useNavigate());
  setUseLocationRef(useLocation());

  return null;
}

InnerConnectionUtils.propTypes = {
  setUseNavigationRef: PropTypes.func.isRequired,
  setUseLocationRef: PropTypes.func.isRequired,
};

let navigationRef;
const setUseNavigationRef = (navigationRefProp) => {
  navigationRef = navigationRefProp;
};

let useLocationRef;
const setUseLocationRef = (useLocationRefProp) => {
  useLocationRef = useLocationRefProp;
};

export default function NavigatorUtilsConfigurator() {
  return <InnerConnectionUtils setUseNavigationRef={setUseNavigationRef} setUseLocationRef={setUseLocationRef} />;
}

export const connectionActions = {
  signin() {
    const from = useLocationRef.state?.from?.pathname || '/';

    navigationRef(from, { replace: true });
  },
  signout(userTriggered = false) {
    localStorage.clear();

    if (userTriggered) {
      navigationRef('/login', { state: { from: useLocationRef } });
    }

    navigationRef('/login');
  },
};
