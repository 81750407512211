import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { createElement, useCallback, useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import useEventSourceProgress from '../../hooks/useEventSourceProgress';
import { EventsWrapper } from './EventListener.style';

function Listener(props) {
  const { topic, label, labelEmpty, eventType } = props;
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);

  const callback = useCallback((event) => {
    const { content, total, current, error } = event;
    const progress = (current / total) * 100;

    setEvents((oldEvents) => {
      const newEvents = [...oldEvents];
      const eventIndex = newEvents.findIndex((a) => a.id === content.id);

      if (eventIndex !== -1) {
        newEvents[eventIndex] = {
          ...newEvents[eventIndex],
          total,
          current,
          content,
          progress,
          error,
        };
      } else {
        newEvents.push({ total, current, content, progress, id: content.id, error });
      }

      return newEvents;
    });
  }, []);

  const eventsToDisplay = useMemo(() => events.filter((event) => !event.hidden), [events]);

  const handleEventRemove = useCallback((id) => {
    setEvents((oldEvents) => {
      const newEvents = [...oldEvents];
      const eventIndex = newEvents.findIndex((a) => a.id === id);

      if (eventIndex !== -1) {
        newEvents[eventIndex] = {
          ...newEvents[eventIndex],
          hidden: true,
        };
      }

      return newEvents;
    });
  }, []);

  useEventSourceProgress(topic, callback);

  const handleOpen = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemText primary={label} />
        {open ? <MdExpandLess /> : <MdExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        {eventsToDisplay.length ? (
          eventsToDisplay.map((event) => (
            <EventsWrapper key={event.id}>{createElement(eventType, { ...event, handleEventRemove })}</EventsWrapper>
          ))
        ) : (
          <EventsWrapper>{labelEmpty}</EventsWrapper>
        )}
      </Collapse>
    </>
  );
}

Listener.propTypes = {
  topic: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelEmpty: PropTypes.string.isRequired,
  eventType: PropTypes.func.isRequired,
};

export default Listener;
