import styled from '@emotion/styled/macro';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33vh;
`;

export { HeaderWrapper };
