import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Heading from '../../../../components/UI/Title/Heading';
import Card from '../../../../components/UI/Wrapper/Card';
import { getImportsByType } from '../../../../helpers/api/importFileHistory';

function BidderCsvResultList() {
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getImportsByType(1).then((data) => {
      setReports(data['hydra:member']);
      setTotal(data['hydra:totalItems']);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const columns = useMemo(
    () => [
      { id: 'id', label: 'Numéro' },
      { id: 'user', label: 'Utilisateur' },
      { id: 'filename', label: 'Fichier' },
      { id: 'mimeType', label: 'MimeType' },
      { id: 'nbOfLines', label: 'Nb de lignes' },
      { id: 'createdAt', label: "Début d'import", format: (value) => new Date(value).toLocaleString() },
    ],
    []
  );

  return (
    <>
      <Heading>Liste des imports CSV effectués pour les placements Ids des SSP</Heading>

      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.map((report) => (
                <TableRow key={report.id}>
                  {columns.map((column) => {
                    let value =
                      column.format && report?.[column.id] ? column.format(report?.[column.id]) : report?.[column.id];

                    if (column.id === 'user' && report?.user?.trigram) {
                      value = report.user.trigram;
                    }

                    return (
                      <TableCell sx={{ borderBottom: 'unset' }} key={column.id}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[30]}
          component="div"
          count={total}
          rowsPerPage={30}
          page={page}
          onPageChange={handleChangePage}
        />
      </Card>
    </>
  );
}

export default BidderCsvResultList;
