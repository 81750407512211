import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllDrafts } from '../../store/DraftSlice';
import { fetchAllAuthorizedPartners } from '../../store/PartnersSlice';
import Heading from '../UI/Title/Heading';
import DraftContent from './Content/DraftContent';
import { DraftWrapper } from './DraftList.style';
import DraftSidebar from './Sidebar/DraftSidebar';

function DraftList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllDrafts());
    dispatch(fetchAllAuthorizedPartners());
  }, [dispatch]);

  return (
    <>
      <Heading>Tous les drafts de l&#39;application</Heading>
      <DraftWrapper>
        <DraftContent />
        <DraftSidebar />
      </DraftWrapper>
    </>
  );
}

export default DraftList;
