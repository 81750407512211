import { useEffect } from 'react';
import { AiFillLock, AiOutlineCheck } from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMandatoryModules } from '../../../../store/MandatoryModulesSlice';
import { fetchPrebidVersions, selectPrebidVersions } from '../../../../store/PrebidVersionSlice';
import Heading from '../../../UI/Title/Heading';
import PrebidCard from './PrebidCard/PrebidCard';
import {
  HeaderWrapper,
  IconLabel,
  IconsWrapper,
  PrebidIcon,
  PrebidVersionListWrapper,
} from './PrebidVersionList.style';

function PrebidVersionList() {
  const prebids = useSelector(selectPrebidVersions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPrebidVersions());
    dispatch(fetchMandatoryModules());
  }, [dispatch]);

  return (
    <>
      <HeaderWrapper>
        <Heading>Gestion des versions de prebid</Heading>
        <IconsWrapper>
          <PrebidIcon color="#f40000">
            <AiFillLock size={25} color="red" title="Activé et obligatoire" />
            <IconLabel>Activé et obligatoire</IconLabel>
          </PrebidIcon>
          <PrebidIcon color="#518333">
            <AiOutlineCheck size={25} color="green" title="Activé et disponible a la séléction" />
            <IconLabel>Activé et disponible à la sélection</IconLabel>
          </PrebidIcon>
          <PrebidIcon color="#ffffff">
            <RxCross1 size={25} title="Non disponible" />
            <IconLabel>Non disponible</IconLabel>
          </PrebidIcon>
        </IconsWrapper>
      </HeaderWrapper>
      <hr />
      <PrebidVersionListWrapper>
        {prebids.map((prebid) => <PrebidCard key={prebid.id} prebidId={prebid.id} />).reverse()}
      </PrebidVersionListWrapper>
    </>
  );
}

export default PrebidVersionList;
