import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBlock } from '../../GoogleTag/CodeGeneration/BlocksSlice';

function BlockDetail(props) {
  const { modification } = props;
  const { metadata, operation } = modification;

  const blockSelector = useMemo(selectBlock, []);
  const block = useSelector((state) => blockSelector(state, metadata.idBlock));

  const blockName = <strong>&quot;{block?.name ?? metadata.idBlock}&quot;</strong>;
  const action = <strong>{operation === 'addBlock' ? 'ajouté' : 'supprimé'}</strong>;

  return (
    <div>
      Le bloc {blockName} a été {action}.
    </div>
  );
}

BlockDetail.propTypes = {
  modification: PropTypes.shape({
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      idBlock: PropTypes.number,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default BlockDetail;
