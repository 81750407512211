import { HeaderWrapper } from './Header.style';
import LogoAdditi from '../../images/svg/LogoAdditi';

function Header() {
  return (
    <HeaderWrapper>
      <LogoAdditi />
    </HeaderWrapper>
  );
}

export default Header;
