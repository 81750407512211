import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectMessage } from '../../../../store/MessageQueueSlice';
import { DateModification, TextWrapper } from '../../Modifications/ItemModification/ItemModification.style';
import { selectPartnerByGroupId } from '../../../../store/PartnersSlice';
import { selectUser } from '../../../../store/UsersSlice';

function MepItem(props) {
  const { messageId } = props;

  const messageSelector = useMemo(selectMessage, []);
  const message = useSelector((state) => messageSelector(state, messageId));

  const date = useMemo(() => {
    if (message) {
      return new Date(message.finishedAt).toLocaleString();
    }
    return '';
  }, [message]);

  const deserialized = useMemo(() => {
    const { serializedMessage } = message;
    return JSON.parse(serializedMessage);
  }, [message]);

  const partnerSelector = useMemo(selectPartnerByGroupId, []);
  const partner = useSelector((state) => partnerSelector(state, deserialized.idPartner));

  const partnerName = useMemo(() => {
    if (partner) {
      return partner.displayName;
    }
    return '';
  }, [partner]);

  const userSelector = useMemo(selectUser, []);
  const user = useSelector((state) => userSelector(state, deserialized.idUser));

  const userName = useMemo(() => {
    if (user) {
      return `${user.firstname} ${user.lastname}`;
    }
    return deserialized.idUser;
  }, [deserialized.idUser, user]);

  const googleTagId = useMemo(() => deserialized.idGoogleTag, [deserialized]);

  return (
    <div>
      <DateModification>{date}</DateModification>
      <TextWrapper>
        <p>
          <b>{userName}</b> a mis en <b>PROD</b> le google tag de <b>{partnerName}</b> (GoogleTag:{' '}
          <Link to={`/edit-google-tag/${googleTagId}`}>{googleTagId}</Link>)
        </p>
      </TextWrapper>
    </div>
  );
}

MepItem.propTypes = {
  messageId: PropTypes.number.isRequired,
};

export default MepItem;
