import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../../../../../store/GoogleTagVersionSlice';
import {
  fetchMediaTypes,
  selectAllMediaTypes,
  selectAllMediaTypesStatus,
} from '../../../../../../../store/MediatypesSlice';
import { addPositionsModification, addRtbPositionsModification } from '../../../../../../../store/ModificationsSlice';
import {
  fetchPositions,
  selectAllPositions,
  selectAllPositionsStatus,
} from '../../../../../../../store/PositionsSlice';
import MuiModal from '../../../../../../UI/Modal/MuiModal';
import { ButtonContainer, CustomButton } from '../Grid.style';
import {
  fetchExistingAddPosition,
  fetchRtb,
  selectExistingAddPositionsStatus,
  selectPositionRTBStatus,
  selectRtbPositions,
} from '../../../../../../../store/PositionRTBSlice';

function AddPositionManager(props) {
  const { deviceId, pagetypeId, positions } = props;

  const dispatch = useDispatch();

  const allPositions = useSelector(selectAllPositions);
  const allPositionsStatus = useSelector(selectAllPositionsStatus);
  const mediatypes = useSelector(selectAllMediaTypes);
  const mediatypesStatus = useSelector(selectAllMediaTypesStatus);
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const rtbStatus = useSelector(selectPositionRTBStatus);
  const existingAddPosStatus = useSelector(selectExistingAddPositionsStatus);
  const { existingAddPosition, rtbs } = useSelector(selectRtbPositions);

  const [existingRtb, setExistingRtb] = useState(false);

  useEffect(() => {
    if (mediatypesStatus !== 'idle') return;

    dispatch(fetchMediaTypes());
  }, [dispatch, mediatypesStatus]);

  useEffect(() => {
    if (allPositionsStatus !== 'idle') return;

    dispatch(fetchPositions());
  }, [dispatch, allPositionsStatus]);

  const visiblePosition = useMemo(() => {
    const positionIds = positions.map((p) => p?.positionId ?? p?.metadata?.position?.id);
    return allPositions.filter((p) => !positionIds.includes(p.id));
  }, [positions, allPositions]);

  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      position: '',
      mediatypes: [],
      rtb: 1,
    },
  });

  const handleAddPosition = (data) => {
    const position = allPositions.find((pos) => pos.id === data.position);

    const { mediatypeIds, rtb } = data;

    dispatch(
      addPositionsModification({
        operation: 'addPosition',
        metadata: {
          pagetypeId: [pagetypeId],
          deviceId: [deviceId],
          positionId: [position.id],
          position,
          rtb,
          mediatypeIds,
        },
        googleTagVersions: [googleTagVersion['@id']],
      })
    );

    if (existingRtb && existingRtb.rtbPosition.id === rtb) {
      setOpen((o) => !o);
      return;
    }

    const modification = {
      metadata: {
        position: position.id,
        name: position.genericName,
        partnerGroup: googleTagVersion.partnerGroup.id,
        rtbPosition: rtb,
      },
      googleTagVersions: [googleTagVersion['@id']],
    };

    if (!existingRtb) {
      modification.operation = 'add adPosition';
    } else if (existingRtb.rtbPosition.value !== rtb) {
      modification.operation = 'modify adPosition';
      modification.metadata.idAddPosition = existingRtb.id;
    }

    dispatch(addRtbPositionsModification(modification));

    setOpen((o) => !o);
  };

  const handleOpen = useCallback((event) => {
    event.stopPropagation();
    setOpen((o) => !o);
  }, []);

  const handleChangePosition = (event) => {
    const rtbPosition = existingAddPosition.adPositions.find(
      (existingAddPos) => existingAddPos.position.id === event.target.value
    );

    if (rtbPosition) {
      setValue('rtb', rtbPosition.rtbPosition.id);
      setExistingRtb(rtbPosition);
    } else {
      setValue('rtb', 1);
      setExistingRtb(null);
    }
  };

  useEffect(() => {
    if (!open) return;

    if (['idle', 'failed', ''].includes(existingAddPosStatus)) {
      dispatch(fetchExistingAddPosition(googleTagVersion.partnerGroup.id));
    }
    if (['idle', 'failed', ''].includes(rtbStatus)) {
      dispatch(fetchRtb());
    }
  }, [dispatch, existingAddPosStatus, googleTagVersion, open, rtbStatus]);

  return (
    <ButtonContainer onClick={(e) => e.stopPropagation()}>
      <Tooltip title="Ajouter une position" disableInteractive>
        <CustomButton color="primary" variant="contained" onClick={handleOpen}>
          <FaPlus />
        </CustomButton>
      </Tooltip>
      <MuiModal open={open} handleClose={handleOpen} title="Ajouter une position">
        <form onSubmit={handleSubmit(handleAddPosition)}>
          <FormControl fullWidth>
            <InputLabel>Position</InputLabel>
            <Select
              {...register('position', { required: true })}
              label="Position"
              defaultValue=""
              onChange={handleChangePosition}
            >
              {visiblePosition.map((position) => (
                <MenuItem key={position.id} value={position.id}>
                  {position.genericName}
                </MenuItem>
              ))}
            </Select>
            {errors?.position && <FormHelperText error>Veuillez sélectionner une position</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Mediatypes</InputLabel>
            <Select {...register('mediatypeIds', { required: true })} label="Mediatypes" multiple defaultValue={[]}>
              {mediatypes.map((mediatype) => (
                <MenuItem key={mediatype.id} value={mediatype.id}>
                  {mediatype.genericName}
                </MenuItem>
              ))}
            </Select>
            {errors?.mediatypes && <FormHelperText error>Veuillez sélectionner au moins un mediatype</FormHelperText>}
          </FormControl>

          <Controller
            name="rtb"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Position RTB</InputLabel>
                <Select {...field} label="Position RTB">
                  {rtbs.map((rtb) => (
                    <MenuItem key={rtb.id} value={rtb.id}>
                      {rtb.value} - {rtb.description}
                    </MenuItem>
                  ))}
                </Select>
                {existingRtb && <FormHelperText>La RTBPosition de cette position a déjà été setté.</FormHelperText>}
              </FormControl>
            )}
          />

          <Button type="submit" variant="contained" fullWidth>
            Valider
          </Button>
        </form>
      </MuiModal>
    </ButtonContainer>
  );
}

AddPositionManager.propTypes = {
  deviceId: PropTypes.number.isRequired,
  pagetypeId: PropTypes.number.isRequired,
  positions: PropTypes.arrayOf(PropTypes.shape({ positionId: PropTypes.number })).isRequired,
};

AddPositionManager.defaultProps = {};

export default AddPositionManager;
