import { FiExternalLink } from 'react-icons/fi';
import { IconWrapper, UpdateButton } from './HeaderActionButtons.style';
import { BASE_URL } from '../../../../../references/base-urls';

function Cartomonet() {
  const goToCartoMonet = () => {
    window.open(`${BASE_URL}/monet/`);
  };

  return (
    <UpdateButton type="button" title="CartoMonet" onClick={goToCartoMonet}>
      <IconWrapper>
        <FiExternalLink />
      </IconWrapper>
    </UpdateButton>
  );
}

export default Cartomonet;
