/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  getAllNotificationTypes,
  getNotificationsByUser,
  patchUserNotif,
  readAllNotifications,
} from '../../helpers/api/notifications';

const DOMAIN = 'notifications';

export const fetchNotificationTypes = createAsyncThunk(`${DOMAIN}/fetchTypes`, async (thunkAPI) => {
  try {
    return getAllNotificationTypes();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const fetchNotificationByUser = createAsyncThunk(`${DOMAIN}/fetch`, async (data, thunkAPI) => {
  try {
    return getNotificationsByUser(data);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const patchUserNotification = createAsyncThunk(`${DOMAIN}/patchUserNotification`, async (data, thunkAPI) => {
  try {
    const { id, body } = data;
    return patchUserNotif(id, body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const readAllNotificationsByUser = createAsyncThunk(`${DOMAIN}/readAllNotifications`, async (thunkAPI) => {
  try {
    return readAllNotifications();
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

const initialState = {
  notifications: [],
  total: 0,
  status: 'idle',
  types: [],
  typeStatus: 'idle',
  error: null,
};

export const NotificationSlice = createSlice({
  name: DOMAIN,
  initialState,
  reducers: {
    resetNotifications: () => initialState,
    addNotification: (state, action) => {
      const { payload } = action;
      if (!state.notifications.some((notification) => notification.id === payload.id)) {
        state.notifications.unshift(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationByUser.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchNotificationByUser.fulfilled, (state, action) => {
      state.status = 'resolved';
      state.notifications = action.payload['hydra:member'];
      state.total = action.payload['hydra:totalItems'];
    });
    builder.addCase(fetchNotificationByUser.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.payload;
    });

    builder.addCase(patchUserNotification.fulfilled, (state, action) => {
      const { payload } = action;
      state.notifications = state.notifications.map((notification) =>
        notification.id === payload.id ? payload : notification
      );
      state.total -= 1;
    });

    builder.addCase(readAllNotificationsByUser.fulfilled, (state, action) => {
      state.notifications = action.payload['hydra:member'];
      state.total = 0;
    });

    builder.addCase(fetchNotificationTypes.pending, (state) => {
      state.typeStatus = 'pending';
    });
    builder.addCase(fetchNotificationTypes.fulfilled, (state, action) => {
      state.types = action.payload['hydra:member'];
      state.typeStatus = 'resolved';
    });
    builder.addCase(fetchNotificationTypes.rejected, (state, action) => {
      state.typeStatus = 'rejected';
      state.error = action.payload;
    });
  },
});

export const { addNotification } = NotificationSlice.actions;

export const selectUserNotifications = (state) => state[DOMAIN].notifications;
export const selectNotificationTypes = (state) => state[DOMAIN].types;
export const selectTypeStatus = (state) => state[DOMAIN].typeStatus;
export const selectTotalNotification = (state) => state[DOMAIN].total;

export const selectNotification = () =>
  createSelector(
    (state) => state.notifications,
    (_, id) => id,
    (state, id) => state.notifications.find((notification) => notification.id === id)
  );

export default NotificationSlice.reducer;
