import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchSuggestions, selectSuggestions, selectSuggestionsStatus } from '../store/SuggestionSlice';

export default function useSuggestionByScope(scope) {
  const dispatch = useDispatch();
  const suggestions = useSelector(selectSuggestions);
  const status = useSelector(selectSuggestionsStatus);

  useEffect(() => {
    if (status !== 'idle') {
      return;
    }

    dispatch(fetchSuggestions({ pagination: false }));
  }, [dispatch, status]);

  const filteredSuggestions = suggestions.filter((suggestion) => suggestion.scope.includes(scope));

  return filteredSuggestions.map((sug) => ({ value: `{{${sug.label}}}`, label: sug.label }));
}
