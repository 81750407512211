import styled from '@emotion/styled/macro';
import { Button, css } from '@mui/material';
import { BiExpand } from 'react-icons/bi';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

const HistoryTitle = styled.span`
  border-bottom: 1px solid lightblue;
  padding-bottom: 0.5rem;
`;

const HistoryButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const HistoryExpandButton = styled(Button)`
  border: none;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  min-width: 0;
  transition: 0.1s;

  :hover {
    transform: scale(1.2);
  }

  :not(:last-child) {
    margin-right: 5px;
  }
`;

const HistoryExpandIcon = styled(BiExpand)`
  height: 1.7rem;
  width: 1.7rem;
`;

const HistoryDiv = styled.div``;

const ModificationTitle = styled.span`
  ${({ inDraft }) =>
    inDraft &&
    css`
      font-style: italic;
      font-weight: bold;
    `}
`;

const ModificationList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  min-height: 100px;
  max-height: 200px;
`;

const ModificationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const ModificationButton = styled(Button)`
  height: 30px;
  width: 100%;
  margin: 0 5px;
`;

const DetailsWrapper = styled.div`
  overflow: auto;
`;

export {
  Wrapper,
  HistoryTitle,
  HistoryButtonWrapper,
  HistoryExpandButton,
  HistoryExpandIcon,
  HistoryDiv,
  ModificationTitle,
  ModificationList,
  ModificationButtonsContainer,
  ModificationButton,
  DetailsWrapper,
};
