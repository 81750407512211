import PropTypes from 'prop-types';
import SizeSidebar from './Size/SizeSidebar';
import PrebidVersionSidebar from './PrebidVersion/PrebidVersionSidebar';
import PositionSidebar from './Position/PositionSidebar';
import BidderSidebar from './Bidder/BidderSidebar';
import MepSidebar from './Mep/MepSidebar';

function SidebarAction(props) {
  const { headerTab } = props;

  switch (headerTab) {
    case 1:
      return <PrebidVersionSidebar />;
    case 2:
      return <SizeSidebar />;
    case 3:
      return <PositionSidebar />;
    case 4:
      return <BidderSidebar />;
    case 5:
      return <MepSidebar />;
    default:
      return <p> / </p>;
  }
}

SidebarAction.propTypes = {
  headerTab: PropTypes.number.isRequired,
};

export default SidebarAction;
