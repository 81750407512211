import { API_BASE_URL } from '../../references/base-urls';
import defaultFetch from '../requestHelper';

export async function getAllPositions() {
  const url = `${API_BASE_URL}/positions`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function postPositions(body) {
  const url = `${API_BASE_URL}/positions`;
  const response = await defaultFetch(url, 'POST', body);

  return response.json();
}

export async function getAllRTBPosition() {
  const url = `${API_BASE_URL}/rtb_positions`;
  const response = await defaultFetch(url);

  return response.json();
}

export async function getExistingPositions(data) {
  const url = `${API_BASE_URL}/partner_groups/${data}/addPosition`;
  const response = await defaultFetch(url);

  return response.json();
}
