import PropTypes from 'prop-types';
import { ButtonStyle } from './LinkWrapper.style';

function LinkWrapper(props) {
  const { children, onClick, type } = props;

  return (
    <ButtonStyle onClick={onClick} type={type}>
      {children}
    </ButtonStyle>
  );
}

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

LinkWrapper.defaultProps = {
  onClick: () => {},
  type: 'button',
};

export default LinkWrapper;
