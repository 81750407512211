/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Checkbox, TextField } from '@mui/material';
import { addPositionsModification, selectSelectedGoogleTags } from '../../../../../store/ModificationsSlice';
import ControlledAutocomplete from '../../../../UI/ControlledAutocomplete';
import { ButtonWrapper, PositionFormControl } from './PositionSidebar.style';
import MassButton from '../../../../UI/Basic/Buttons/MassButton/MassButton';
import { fetchPositions, selectAllPositions } from '../../../../../store/PositionsSlice';
import { fetchDevices, selectAllDevices } from '../../../../../store/DevicesSlice';
import { fetchPagetypes, selectAllPageTypes } from '../../../../../store/PagetypesSlice';
import { fetchMediaTypes, selectAllMediaTypes } from '../../../../../store/MediatypesSlice';

function PositionSidebar() {
  const { control, handleSubmit } = useForm();

  const devices = useSelector(selectAllDevices);
  const pageTypes = useSelector(selectAllPageTypes);
  const positions = useSelector(selectAllPositions);
  const mediatypes = useSelector(selectAllMediaTypes);
  const selectedGoogleTag = useSelector(selectSelectedGoogleTags);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (devices.length === 0) dispatch(fetchDevices());
  }, [dispatch, devices]);

  useEffect(() => {
    if (pageTypes.length === 0) dispatch(fetchPagetypes());
  }, [dispatch, pageTypes]);

  useEffect(() => {
    if (positions.length === 0) dispatch(fetchPositions());
  }, [dispatch, positions]);

  useEffect(() => {
    if (mediatypes.length === 0) dispatch(fetchMediaTypes());
  }, [dispatch, mediatypes]);

  const updatePosition = (data, operation) => {
    if (selectedGoogleTag.length === 0) {
      enqueueSnackbar('Veuillez séléctionner au moins un GoogleTag.', { variant: 'error' });
      return;
    }

    const { device, pagetype, position, mediatype } = data;

    if (!pagetype || pagetype.length === 0) {
      enqueueSnackbar('Veuillez séléctionner au moins un pagetype.', { variant: 'error' });
      return;
    }

    if (['modifyPosition', 'addPosition'].includes(operation) && (!mediatype || mediatype.length === 0)) {
      enqueueSnackbar('Veuillez séléctionner au moins un mediatype.', { variant: 'error' });
      return;
    }

    const dataToStore = {
      operation,
      metadata: {
        pagetypeId: pagetype ? pagetype.map((pt) => pt.id) : [],
        deviceId: device ? device.map((d) => d.id) : [],
        positionId: position && position.map((pos) => pos.id),
        mediatypeIds: mediatype ? mediatype.map((m) => m.id) : [],
      },
      googleTagVersions: selectedGoogleTag.map((gtag) => `api/google_tag_versions/${gtag}`),
    };
    dispatch(addPositionsModification(dataToStore));
  };

  return (
    <>
      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={pageTypes}
          renderInput={(params) => <TextField {...params} label="PageType" placeholder="PageType" />}
          getOptionLabel={(option) => option.genericName}
          name="pagetype"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={devices}
          renderInput={(params) => <TextField {...params} label="Device" placeholder="Device" />}
          getOptionLabel={(option) => option.genericName}
          name="device"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={positions}
          renderInput={(params) => <TextField {...params} label="Position" placeholder="Position" />}
          getOptionLabel={(option) => option.genericName}
          name="position"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <PositionFormControl fullWidth>
        <ControlledAutocomplete
          multiple
          control={control}
          options={mediatypes}
          renderInput={(params) => <TextField {...params} label="Mediatype" placeholder="Mediatype" />}
          getOptionLabel={(option) => option.genericName}
          name="mediatype"
          renderOption={(renderProps, option, { selected }) => (
            <li {...renderProps}>
              <Checkbox checked={selected} style={{ marginRight: 8 }} />
              {option.genericName}
            </li>
          )}
        />
      </PositionFormControl>

      <ButtonWrapper>
        <MassButton
          colorButton="green"
          onClick={handleSubmit((data) => updatePosition(data, 'addPosition'))}
          width="32%"
        >
          Ajouter
        </MassButton>
        <MassButton
          colorButton="orange"
          onClick={handleSubmit((data) => updatePosition(data, 'modifyPosition'))}
          width="32%"
        >
          Modifier
        </MassButton>
        <MassButton
          colorButton="red"
          onClick={handleSubmit((data) => updatePosition(data, 'removePosition'))}
          width="32%"
        >
          Supprimer
        </MassButton>
      </ButtonWrapper>
    </>
  );
}

export default PositionSidebar;
