import PropTypes from 'prop-types';
import classes from './PrimaryButton.module.css';
import compilePropClasses from '../../../../helpers/classBuilder';
import { POSSIBLE_COLOR, PrimaryButtonCss } from './Button.style';

function PrimaryButton(props) {
  const { extraClasses, onClick, disabled, name, children, color } = props;
  const additionnalClasses = compilePropClasses(classes, extraClasses);

  return (
    <PrimaryButtonCss
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`${classes.primary} ${additionnalClasses}`}
      color={color}
    >
      {children ?? name}
    </PrimaryButtonCss>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node,
  extraClasses: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(POSSIBLE_COLOR)),
};

PrimaryButton.defaultProps = {
  children: null,
  extraClasses: [],
  disabled: false,
  name: '',
  color: 'primary',
};

export default PrimaryButton;
