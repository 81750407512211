import { FiLogOut } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { IconWrapper, UpdateButton } from './HeaderActionButtons.style';
import { connectionActions } from '../../../../../helpers/ConnectionUtils';
import { logout } from '../../../../../store/UsersSlice';

function Logout() {
  const dispatch = useDispatch();

  const logoutAction = () => {
    connectionActions.signout(true);
    dispatch(logout());
  };

  return (
    <UpdateButton type="button" title="Déconnexion" onClick={logoutAction}>
      <IconWrapper>
        <FiLogOut />
      </IconWrapper>
    </UpdateButton>
  );
}

export default Logout;
