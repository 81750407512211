import { List, ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDraftApplicationStatus } from '../../../../../../store/DraftSlice';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import { fetchPositions, selectAllPositionsStatus } from '../../../../../../store/PositionsSlice';
import { fetchSizes, selectSizesStatus } from '../../../../../../store/SizesSlice';
import Card from '../../../../../UI/Wrapper/Card';
import { LineGridHeader, ListContainer, Table } from './Grid.style';
import {
  fetchNodeLibraries,
  selectNodesHeaders,
  selectNodesHeadersToggled,
  selectNodesPagetypes,
  setHeadersToggled,
} from './NodesSlice';
import PagetypeGrid from './PagetypeGrid';

function SizesModificationPanel(props) {
  const { activeTab, index } = props;

  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const applicationStatus = useSelector(selectDraftApplicationStatus);

  const dispatch = useDispatch();
  const sizesStatus = useSelector(selectSizesStatus);

  const pagetypes = useSelector(selectNodesPagetypes);
  const headers = useSelector((state) => selectNodesHeaders(state));
  const headersToggled = useSelector(selectNodesHeadersToggled);

  const active = useMemo(() => activeTab === index, [activeTab, index]);

  const positionsStatus = useSelector(selectAllPositionsStatus);

  useEffect(() => {
    if (positionsStatus !== 'idle' || !active) return;

    dispatch(fetchPositions());
  }, [active, dispatch, positionsStatus]);

  useEffect(() => {
    if (googleTagVersion?.id && ['idle', 'succeeded'].includes(applicationStatus)) {
      dispatch(fetchNodeLibraries(googleTagVersion.id));
    }
  }, [dispatch, googleTagVersion.id, applicationStatus]);

  useEffect(() => {
    if (sizesStatus !== 'idle' || !active) {
      return;
    }

    dispatch(fetchSizes());
  }, [dispatch, sizesStatus, active]);

  const manageToggleButton = (event, newHeadersToggled) => {
    dispatch(setHeadersToggled(newHeadersToggled));
  };

  return (
    <Card hidden={!active} marginTop="0" height="100%">
      <Table columns={headers.length + 1} toggled={headersToggled}>
        {pagetypes.length > 0 && (
          <>
            <LineGridHeader columns={headers.length + 1}>
              <div />
              <ToggleButtonGroup
                sx={{ display: 'contents' }}
                value={headersToggled}
                onChange={manageToggleButton}
                aria-label="device"
              >
                {headers.map((header) => (
                  <ToggleButton sx={{ display: 'flex' }} key={header} value={header} aria-label="laptop">
                    {header}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </LineGridHeader>

            <List component="div" sx={ListContainer}>
              {pagetypes.map((children) => (
                <PagetypeGrid key={children.pagetypeId} node={children} />
              ))}
            </List>
          </>
        )}
      </Table>
    </Card>
  );
}

SizesModificationPanel.propTypes = {
  activeTab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default SizesModificationPanel;
