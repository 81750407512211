import { useMemo, useState } from 'react';

export default function useReactHookFormError(registerTarget, errors) {
  const keys = useMemo(() => registerTarget.split('.'), [registerTarget]);
  const [localError, setLocalError] = useState(false);

  let error = { ...errors };

  keys.forEach((key) => {
    if (error && key in error) {
      error = error[key];
    } else {
      error = false;
    }
  });

  if (error !== localError) {
    setLocalError(error);
  }

  return error;
}
