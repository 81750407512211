import { Button, TextField } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import IconButtonTooltip from '../../../UI/Buttons/IconButtonTooltip';
import { FieldsContainer } from '../Variable.style';

function ArrayVariable() {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'value',
  });

  return (
    <>
      <Button type="button" onClick={() => append({ value: '' })} fullWidth variant="outlined">
        Ajouter une valeur
      </Button>
      <FieldsContainer>
        {fields.map((item, index) => (
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                label="Valeur"
                variant="filled"
                fullWidth
                sx={{ '& .MuiFilledInput-root': { backgroundColor: 'white' } }}
                InputProps={{
                  endAdornment: (
                    <IconButtonTooltip title="Suppression" onClick={() => remove(index)} Icon={FaTrashAlt} />
                  ),
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`value.${index}.value`}
          />
        ))}
      </FieldsContainer>
    </>
  );
}

export default ArrayVariable;
