import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTabBidderById } from '../../../store/TabBiddersSlice';

function CsvImport(props) {
  const { modification } = props;
  const { metadata } = modification;

  const bidderSelector = useMemo(selectTabBidderById, []);
  const bidder = useSelector((state) => bidderSelector(state, metadata.bidder));

  return (
    <div>
      <p>
        Un <b>import CSV</b> sur le bidder <b>{bidder ? bidder.displayName : metadata.bidder}</b> a été effectué.
      </p>
      <a href={metadata.summaryUrl}>Résumé de l&apos;import</a>
    </div>
  );
}

CsvImport.propTypes = {
  modification: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      summaryUrl: PropTypes.string,
      bidder: PropTypes.number,
    }).isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default CsvImport;
