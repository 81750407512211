/* eslint-disable */
import React from 'react';

import classes from './BackDrop.module.css';

function Backdrop(props) {
  return <div className={classes.backdrop} onClick={props.onConfirm} />;
}

export default Backdrop;
