import { Tooltip, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

function IconButtonTooltip(props) {
  const { title, Icon, onClick } = props;

  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        <Icon size={15} />
      </IconButton>
    </Tooltip>
  );
}

IconButtonTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]).isRequired,
};

IconButtonTooltip.defaultProps = {
  onClick: () => {},
};

export default IconButtonTooltip;
