import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-family: 'Comic Neue';
  font-size: 6em;
  box-sizing: border-box;
  color: #363636;
  margin: 0;
`;

const Subtitle = styled.h2`
  font-family: 'Comic Neue';
  font-size: 3em;
  box-sizing: border-box;
  color: #363636;
  margin: 0;
`;

const TextContent = styled.p`
  font-family: 'Comic Neue';
  font-size: 2em;
  line-height: 50px;
  box-sizing: border-box;
  color: #565656;
`;

const Window404 = styled.div`
  margin: 1em;
  width: 13em;
  height: 24em;
  border-radius: 100px;
  box-shadow: ${({ reverse }) =>
    reverse
      ? '3px -3px 0px 5px rgba(212, 212, 212, 1), 5px 5px 0px 2px rgba(255, 255, 255, 1)'
      : '-3px -3px 0px 5px rgba(212, 212, 212, 1), 5px 5px 0px 2px rgba(255, 255, 255, 1)'};
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ image }) => image});
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;

export { Wrapper, Title, Subtitle, TextContent, Window404 };
