/* eslint-disable react/jsx-props-no-spreading */
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FaKey } from 'react-icons/fa';
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { logoColor } from '../../helpers/color';
import {
  ButtonLogin,
  FormField,
  FormLoginWrapper,
  FormWrapper,
  InputLogin,
  InputPassword,
  Title,
  Error,
} from './Form.style';
import { changePassword, verifyToken } from '../../helpers/api/authentication';

function FormResetPassword(props) {
  const { token } = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [sentence, setSentence] = useState('');

  useEffect(() => {
    verifyToken(token)
      .then((res) => {
        if (res.expired === true) {
          navigate('/login');
        }
        if (res.passwordGenerated) {
          setSentence('Réinitialisation de mot de passe');
        } else {
          setSentence('Création de votre mot de passe');
        }
      })
      .catch(() => {
        navigate('/login');
      });
  }, [navigate, token]);

  const reset = (data) => {
    const { password, rePassword } = data;
    if (password === rePassword) {
      changePassword(token, password)
        .then(() => {
          enqueueSnackbar('Votre mot de passe a bien été modifié.', { variant: 'success' });
          navigate('/login');
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error' });
          setError('Une erreur est survenue');
        });
    } else {
      setError('Vos mot de passe ne correspondent pas.');
    }
  };

  return (
    <FormLoginWrapper>
      <FormWrapper onSubmit={handleSubmit((data) => reset(data))} method="POST">
        <Title> {sentence} </Title>

        <FormField>
          <FaKey size={25} color={logoColor} />
          <InputPassword
            name="password"
            label="Mot de passe"
            variant="standard"
            type={showPassword ? 'text' : 'password'}
            sx={{
              '& .MuiInput-underline:after': { borderBottomColor: logoColor },
            }}
            {...register('password', {
              required: 'You must specify a password',
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters',
              },
            })}
          />
          {showPassword ? (
            <AiOutlineEyeInvisible size={25} onClick={() => setShowPassword(!showPassword)} />
          ) : (
            <AiFillEye size={25} onClick={() => setShowPassword(!showPassword)} />
          )}
        </FormField>
        {errors.password && <p>{errors.password.message}</p>}
        <FormField>
          <FaKey size={30} color={logoColor} />
          <InputLogin
            name="rePassword"
            label="Confirmation de votre mot de passe"
            variant="standard"
            type="password"
            sx={{
              '& .MuiInput-underline:after': { borderBottomColor: logoColor },
            }}
            {...register('rePassword', { required: true })}
          />
        </FormField>
        {errors.rePassword && <p>{errors.rePassword.message}</p>}

        <ButtonLogin type="submit" variant="contained" size="large">
          {sentence}
        </ButtonLogin>

        <Error>{error}</Error>
      </FormWrapper>
    </FormLoginWrapper>
  );
}

FormResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
};

export default FormResetPassword;
