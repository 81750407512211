import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectBlock } from '../../GoogleTag/CodeGeneration/BlocksSlice';

function VariableDetail(props) {
  const { modification } = props;
  const { metadata } = modification;

  const blockSelector = useMemo(selectBlock, []);
  const block = useSelector((state) => blockSelector(state, metadata.idBlock));

  const blockName = <strong>&quot;{block?.name ?? metadata.idBlock}&quot;</strong>;
  const variableName = <strong>&quot;{metadata.nameVariable}&quot;</strong>;

  return (
    <div>
      <p>
        La variable {variableName} du bloc {blockName} a été modifiée.
      </p>
      <p>
        Nouvelle valeur :
        <strong>
          {metadata.nameVariable} = {metadata.value}
        </strong>
      </p>
    </div>
  );
}

VariableDetail.propTypes = {
  modification: PropTypes.shape({
    operation: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      idBlock: PropTypes.number,
      nameVariable: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    googleTagVersions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
  }).isRequired,
};

export default VariableDetail;
