import Heading from '../../../../UI/Title/Heading';
import { DocList } from '../CreatePartner.style';

function DocPartner() {
  return (
    <div>
      <Heading variant="h2">Documentation sur la création d&#39;un partenaire :</Heading>
      <ul>
        <DocList>
          DisplayName : Le nom du partenaire qui sera affiché partout, avec les caractères que vous voulez.
        </DocList>
        <DocList>KeyName : Le nom du partenaire, mais comme vous l&#39;écrivez dans vos GoogleTags.</DocList>
        <DocList>
          Icone Font awesome : (Pour la cartoMonet) Icone FontAwesome qui identifiera le partenaire (
          <a href="https://fontawesome.com/search?m=free&o=r" target="_blank" rel="noreferrer">
            FontAwesome Link
          </a>
          )
        </DocList>
        <DocList>isPartner : Est-ce que ce partnaire est un partenaire d&#39;OF ? </DocList>
        <DocList>Autorisé : ?</DocList>
        <DocList>LSSite : ?</DocList>
        <DocList>Keyname présent : Assez parlant je pense ;)</DocList>
        <DocList>Status AMP : Est-ce que le partenaire a le status AMP</DocList>
        <DocList>AMP Keyname : SEULEMENT si le partnaire a le status AMP</DocList>
        <DocList>
          Couleur : (En héxadecimal) la couleur qui sera utilisé dans le front pour identifié le partenaire (
          <a href="https://www.google.com/search?q=S%C3%A9lecteur+de+couleur" target="_blank" rel="noreferrer">
            Voici un générateur
          </a>
          ), la couleur en héxa <b>ne doit pas</b> contenir le &apos;#&apos; (ex: 124a5d)
        </DocList>
        <DocList>
          Nom du fichier GIT : Lors de l&#39;import, le script va cherché le GTAG dans un dossier, il faut le nom de
          celui-ci
        </DocList>
        <DocList> Trigram : Trigram du partenaire (ex: Citations =&gt; CIT)</DocList>
        <DocList>
          {' '}
          Group : Votre partenaire fait-il parti d&#39;un groupe de partenaire ? Si non, répondez qu&#39;il est seul.
        </DocList>
      </ul>
    </div>
  );
}

export default DocPartner;
