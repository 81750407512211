import styled from '@emotion/styled/macro';

const DraftEventContainer = styled.div`
  display: flex;
  width: 100%;
`;

const DraftErrorContainer = styled.span`
  display: inline-flex;
  align-items: center;
  align-self: center;
  width: 100%;
  color: red;
`;

export { DraftEventContainer, DraftErrorContainer };
