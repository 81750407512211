import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createMandatoryModules } from '../../../../../../store/MandatoryModulesSlice';
import MassButton from '../../../../../UI/Basic/Buttons/MassButton/MassButton';
import Heading from '../../../../../UI/Title/Heading';
import { ModuleTextField } from './ModulesCreate.style';

function ModulesCreate() {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const createModule = (data) => {
    dispatch(createMandatoryModules(data));
    reset();
  };

  return (
    <div>
      <Heading variant="h2">Création d&#39;un module</Heading>
      <form onSubmit={handleSubmit(createModule)}>
        <ModuleTextField
          {...register('name', { required: true })}
          required
          id="input-with-sx"
          label="Nom du module"
          variant="outlined"
          error={errors.name}
          helperText={errors.name && 'Le nom du module est requis'}
        />
        <ModuleTextField
          {...register('keyname', { required: true })}
          required
          id="input-with-sx"
          label="Keyname du module"
          variant="outlined"
          error={errors.keyname}
          helperText={errors.keyname && 'Le nom du module est requis'}
        />
        <ModuleTextField
          {...register('description', { required: true })}
          required
          id="input-with-sx"
          multiline
          minRows={2}
          label="Description du module"
          variant="outlined"
          error={errors.description}
          helperText={errors.description && 'Le nom du module est requis'}
        />
        <MassButton type="submit" width="70%">
          Créer un module
        </MassButton>
      </form>
      <p>
        Attention : Si un module a un mauvais &quot;keyname&quot;, cela peut totalement faire crasher le build de
        prebid.
      </p>
      <p>Il est conseillé de copier coller l&#39;adapter depuis la doc de PrebidJS : </p>
      <a href="https://docs.prebid.org/download.html" target="_blank" rel="noreferrer">
        Documentation de Prebid
      </a>
    </div>
  );
}

export default ModulesCreate;
