import styled from '@emotion/styled/macro';

const HeaderTitle = styled.h1`
  display: flex;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export { HeaderTitle, LoaderWrapper };
