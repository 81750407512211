import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchPlacementKeywordLibs, selectPlacementKeywordLibsStatus } from '../../../store/PlacementKeyworLibSlice';
import { fetchTabBidders, selectTabBiddersStatus } from '../../../store/TabBiddersSlice';
import { fetchRtb, selectPositionRTBStatus } from '../../../store/PositionRTBSlice';
import { fetchPagetypes, selectAllPageTypesStatus } from '../../../store/PagetypesSlice';
import { fetchDevices, selectAllDevicesStatus } from '../../../store/DevicesSlice';
import { fetchPositions, selectAllPositionsStatus } from '../../../store/PositionsSlice';
import { fetchMediaTypes, selectAllMediaTypesStatus } from '../../../store/MediatypesSlice';
import { fetchPrebidVersions, selectPrebidVersionsStatus } from '../../../store/PrebidVersionSlice';
import { fetchBlocks, selectBlocksStatus } from '../../GoogleTag/CodeGeneration/BlocksSlice';
import { fetchSizes, selectSizesStatus } from '../../../store/SizesSlice';
import { fetchMandatoryModules, selectMandatoryModulesStatus } from '../../../store/MandatoryModulesSlice';
import { fetchAllPartnersWithInfos, selectPartnersStatus } from '../../../store/PartnersSlice';

function ModificationProvider(props) {
  const { children } = props;
  const dispatch = useDispatch();

  /*
   * Ce composant fetch :
   *  - PlacementKeywords
   *  - Bidders
   *  - RtbPositions
   *  - Pagetypes
   *  - Devices
   *  - Positions
   *  - Mediatypes
   *  - PrebidVersions
   *  - Blocks
   *  - Tailles
   *  - MandatoryModules
   *  - Partenaires
   * */

  // On fetch les keywords s'il ne sont pas déjà fetch
  const [tryFetchKeyword, setTryFetchKeyword] = useState(0);
  const [tryFetchBidder, setTryFetchBidder] = useState(0);
  const [tryFetchRtbPosition, setTryFetchRtbPosition] = useState(0);
  const [tryFetchPagetype, setTryFetchPagetype] = useState(0);
  const [tryFetchDevice, setTryFetchDevice] = useState(0);
  const [tryFetchPosition, setTryFetchPosition] = useState(0);
  const [tryFetchMediatypes, setTryFetchMediatypes] = useState(0);
  const [tryFetchPrebidVersion, setTryFetchPrebidVersion] = useState(0);
  const [tryFetchBlocks, setTryFetchBlocks] = useState(0);
  const [tryFetchSizes, setTryFetchSizes] = useState(0);
  const [tryMandatoryModules, setTryMandatoryModules] = useState(0);
  const [tryPartners, setTryPartners] = useState(0);

  const placementKeywordLibStatus = useSelector(selectPlacementKeywordLibsStatus);
  const bidderStatus = useSelector(selectTabBiddersStatus);
  const rtbPositionStatus = useSelector(selectPositionRTBStatus);
  const pagetypeStatus = useSelector(selectAllPageTypesStatus);
  const devicesStatus = useSelector(selectAllDevicesStatus);
  const positionStatus = useSelector(selectAllPositionsStatus);
  const mediatypesStatus = useSelector(selectAllMediaTypesStatus);
  const prebidVersionStatus = useSelector(selectPrebidVersionsStatus);
  const blockStatus = useSelector(selectBlocksStatus);
  const sizesStatus = useSelector(selectSizesStatus);
  const mandatoryModulesStatus = useSelector(selectMandatoryModulesStatus);
  const partnersStatus = useSelector(selectPartnersStatus);

  const callback = useCallback(
    (status, tryNumber, fetchFunction, setTryNumber) => {
      if (!['idle', 'failed', ''].includes(status) || tryNumber > 5) {
        return;
      }

      dispatch(fetchFunction());
      setTryNumber(tryNumber + 1);
    },
    [dispatch]
  );

  // On fetch les keywords s'il ne sont pas déjà fetch
  useEffect(() => {
    callback(placementKeywordLibStatus, tryFetchKeyword, fetchPlacementKeywordLibs, setTryFetchKeyword);
  }, [dispatch, placementKeywordLibStatus, callback, tryFetchKeyword]);

  // On fetch les bidders s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(bidderStatus, tryFetchBidder, fetchTabBidders, setTryFetchBidder);
  }, [dispatch, bidderStatus, tryFetchBidder, callback]);

  // On fetch les RTBPosition s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(rtbPositionStatus, tryFetchRtbPosition, fetchRtb, setTryFetchRtbPosition);
  }, [callback, dispatch, rtbPositionStatus, tryFetchRtbPosition]);

  // On fetch les pagetypes s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(pagetypeStatus, tryFetchPagetype, fetchPagetypes, setTryFetchPagetype);
  }, [callback, dispatch, pagetypeStatus, tryFetchPagetype]);

  // On fetch les devices s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(devicesStatus, tryFetchDevice, fetchDevices, setTryFetchDevice);
  }, [dispatch, devicesStatus, tryFetchDevice, callback]);

  // On fetch les positions s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(positionStatus, tryFetchPosition, fetchPositions, setTryFetchPosition);
  }, [callback, dispatch, positionStatus, tryFetchPosition]);

  // On fetch les mediatypes s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(mediatypesStatus, tryFetchMediatypes, fetchMediaTypes, setTryFetchMediatypes);
  }, [callback, dispatch, mediatypesStatus, tryFetchMediatypes]);

  // On fetch les prebid versions s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(prebidVersionStatus, tryFetchPrebidVersion, fetchPrebidVersions, setTryFetchPrebidVersion);
  }, [callback, dispatch, prebidVersionStatus, tryFetchPrebidVersion]);

  // On fetch les blocks s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(blockStatus, tryFetchBlocks, fetchBlocks, setTryFetchBlocks);
  }, [blockStatus, callback, dispatch, tryFetchBlocks]);

  // On fetch les tailles s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(sizesStatus, tryFetchSizes, fetchSizes, setTryFetchSizes);
  }, [callback, dispatch, sizesStatus, tryFetchSizes]);

  // On fetch les modules mandatory s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(mandatoryModulesStatus, tryMandatoryModules, fetchMandatoryModules, setTryMandatoryModules);
  }, [callback, dispatch, mandatoryModulesStatus, tryMandatoryModules]);

  // On fetch les partenaires s'ils ne sont pas déjà fetch
  useEffect(() => {
    callback(partnersStatus, tryPartners, fetchAllPartnersWithInfos, setTryPartners);
  }, [callback, dispatch, partnersStatus, tryPartners]);

  return children;
}

ModificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModificationProvider;
