import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

const TabletWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const ExpandEventsButton = styled.button`
  width: 60px;
  height: 60px;
  padding: 0;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  align-self: start;
`;

const ExpandEventsIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #00ffaa;
  border-radius: 50%;
  box-shadow: 0px 0px 10px -2px #000000;
  transition: all 250ms 250ms, background-color 250ms 0s;

  ${({ open }) =>
    open &&
    css`
      transition-delay: 0ms;
    `}

  ${({ open, up }) =>
    open &&
    (up
      ? css`
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          clip-path: inset(0px -10px -10px -10px);
        `
      : css`
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          clip-path: inset(-10px -10px 0px -10px);
        `)}

  :hover {
    background-color: #00ee9f;
  }
`;

const CollapseWrapper = styled.div`
  position: absolute;

  ${({ up }) =>
    up
      ? css`
          bottom: 60px;
        `
      : css`
          top: 60px;
        `}

  ${({ reading }) =>
    reading
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}
`;

const EventWrapper = styled.div`
  width: 500px;
  padding: 20px 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px -2px #000000;
  border-radius: 30px;

  ${({ up, reading }) => {
    if (up && reading) {
      return css`
        border-bottom-left-radius: 0;
      `;
    }
    if (!up && reading) {
      return css`
        border-top-left-radius: 0;
      `;
    }
    if (up && !reading) {
      return css`
        border-bottom-right-radius: 0;
      `;
    }
    return css`
      border-top-right-radius: 0;
    `;
  }}
`;

const EventsWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

export { CollapseWrapper, EventWrapper, EventsWrapper, TabletWrapper, ExpandEventsButton, ExpandEventsIconWrapper };
