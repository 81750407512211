import PropTypes from 'prop-types';
import HexColorCode from './HexColorCodeType';

const SizeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  concatenatedSize: PropTypes.string.isRequired,
  hexColorCode: HexColorCode.isRequired,
  stringValue: PropTypes.string,
  isOutOfPage: PropTypes.bool.isRequired,
});

export default SizeType;
