import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { TbTrash } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { snackActions } from '../../../helpers/SnackBarUtils';
import { TopicFormCreation, TopicsListContainer } from './Topics.style';
import { changeSelected, createTopic, removeTopic, selectSelectedTopic, selectTopics } from './topics.slice';

function TopicsList() {
  const dispatch = useDispatch();
  const topics = useSelector(selectTopics);
  const selectedTopic = useSelector(selectSelectedTopic);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = useCallback(
    (data) => {
      dispatch(createTopic(data))
        .unwrap()
        .then(() => {
          snackActions.success('Topic créé!');
          reset({ name: '' });
        })
        .catch(() => {});
    },
    [dispatch, reset]
  );

  const handleRemoveTopic = useCallback((id) => dispatch(removeTopic(id)), [dispatch]);
  const handleSelect = useCallback(
    (id) => selectedTopic?.id !== id && dispatch(changeSelected(id)),
    [dispatch, selectedTopic]
  );

  return (
    <TopicsListContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" component="div">
          Ajouter un topic
        </Typography>
        <TopicFormCreation>
          <TextField {...register('name', { required: true })} fullWidth size="small" placeholder="Saisir un topic" />
          <Button type="submit" variant="contained">
            Ajouter
          </Button>
        </TopicFormCreation>
      </form>
      <Divider sx={{ padding: '5px' }} />
      <Grid item>
        <Typography variant="h6" component="div">
          Topics
        </Typography>
        <List>
          {topics.map((topic) => (
            <ListItem
              key={topic.id}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleRemoveTopic(topic.id)}>
                  <TbTrash />
                </IconButton>
              }
              disablePadding
              divider
            >
              <ListItemButton onClick={() => handleSelect(topic.id)}>
                <ListItemText primary={topic.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </TopicsListContainer>
  );
}

export default TopicsList;
