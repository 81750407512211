/* eslint-disable */
import classes from './Instructions.module.css';

function Intructions() {
  return (
    <div className={classes.Instructions}>
      <u>MODE D'EMPLOI :</u>
      <br />
      Pour créer un nouveau Google Tag, il vous est indispensable de sélectionner un Google Tag antérieur qui vous
      servira de modèle de base avant d'y ajouter vos propres modifications.
      <br />
      <br />
      Cliquez sur POURSUIVRE, puis sélectionnez le site Partenaire de votre choix, et enfin le Google Tag modèle.
      <br />
      Il faudra ensuite CLONER le google tag. Ce qui générera un google tag tout neuf possédant à l'identifique les
      propriétés de l'ancien.
    </div>
  );
}

export default Intructions;
