import styled from '@emotion/styled/macro';
import { Select } from '@mui/material';
import { RiErrorWarningFill } from 'react-icons/ri';

const SelectPrebid = styled(Select, {
  shouldForwardProp: (prop) => !['warning'].includes(prop),
})`
  width: ${({ warning }) => (warning ? '440px' : '500px')};
`;

const SelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 500px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWarning = styled(RiErrorWarningFill)`
  margin: 10px;
`;

export { SelectPrebid, SelectedWrapper, Wrapper, IconWarning };
