import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconWrapper } from './NavigationCard.styled';

function NavigationCard(props) {
  const { to, icon, label, paddingText } = props;

  return (
    <Link to={to} style={{ margin: '2px 5px', width: '230px', textDecoration: 'none' }}>
      <Card>
        <CardActionArea>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <CardContent sx={{ backgroundColor: '#466672', color: '#ffffff', ...(paddingText && { padding: '10px' }) }}>
            <Typography variant="h6" align="center" noWrap>
              {label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}

NavigationCard.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  paddingText: PropTypes.string,
};

NavigationCard.defaultProps = {
  icon: null,
  paddingText: null,
};

export default NavigationCard;
