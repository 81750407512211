import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import Footer from './Footer/Footer';
import Header from './Header/Header';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  padding: 1em;
`;

function Layout(props) {
  const { children } = props;

  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
