/* eslint-disable react/jsx-props-no-spreading */
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAdapters, getMonetizationType } from '../../../../../helpers/api/bidders';
import {
  fetchBidderPlacementKeywordLibs,
  selectBidderPlacementKeywordLibs,
} from '../../../../../store/BidderPlacementKeywordLibSlice';
import { fetchPlacementKeywordLibs, selectPlacementKeywordLibs } from '../../../../../store/PlacementKeyworLibSlice';
import { patchTabBidders } from '../../../../../store/TabBiddersSlice';
import BidderType from '../../../../../types/BidderType';
import Heading from '../../../../UI/Title/Heading';
import BidderKeywordDetail from './BidderKeywords/BidderKeywordDetail';
import { AddIcon, FormWrapper, ModalWrapper, PlacementAction, TextFieldBidder } from './ModalModificationBidder.style';

function ModalModificationBidder(props) {
  const { item, setOpen } = props;

  const [adapters, setAdapters] = useState([]);
  const [monetizationTypes, setMonetizationTypes] = useState([]);

  const methods = useForm({
    defaultValues: {
      displayName: item.displayName,
      keyname: item.keyname,
      isAmpCompliant: item.isAmpCompliant,
      authorizedToBid: item.authorizedToBid,
      publisherId: item.publisherId,
      uuid: item.uuid,
      type: item.type,
      bidderPlacementKeywords: [],
      adapter: { adapter: item?.adapter.adapter },
      monetizationType: item.monetizationType['@id'],
    },
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const typeWatcher = watch('type');

  const { fields, prepend, remove, replace } = useFieldArray({
    control,
    name: 'bidderPlacementKeywords',
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const bidderPlacementKeywordLibs = useSelector(selectBidderPlacementKeywordLibs);
  const placementKeywordLibs = useSelector(selectPlacementKeywordLibs);

  useEffect(() => {
    dispatch(fetchBidderPlacementKeywordLibs(item.id));
  }, [dispatch, item.id]);

  useEffect(() => {
    if (placementKeywordLibs.length !== 0) return;
    dispatch(fetchPlacementKeywordLibs());
  }, [dispatch, placementKeywordLibs.length]);

  useEffect(() => {
    replace(bidderPlacementKeywordLibs.map((b) => ({ ...b })));
  }, [bidderPlacementKeywordLibs, replace]);

  const onBidderChange = (values) => {
    const valuesRequest = { ...item, ...values };

    if (typeof values.adapter === 'object') {
      valuesRequest.adapter = { ...item.adapter, ...values.adapter };
    } else if (values.adapter) {
      valuesRequest.adapter = values.adapter;
    }

    dispatch(patchTabBidders({ id: item.id, body: valuesRequest }))
      .unwrap()
      .then((res) => {
        enqueueSnackbar(`Le bidder ${res.displayName} a été modifié`, { variant: 'success' });
        setOpen(false);
      });
  };

  const handlePrependPlacementKeyword = useCallback(() => {
    prepend({ placementKeywordLib: '' });
  }, [prepend]);

  const handleRemovePlacement = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  useEffect(() => {
    if (adapters.length === 0) {
      getAdapters().then((res) => {
        setAdapters(res['hydra:member']);
      });
    }
  }, [adapters, typeWatcher]);

  useEffect(() => {
    if (monetizationTypes.length > 0) {
      return;
    }

    getMonetizationType().then((res) => setMonetizationTypes(res['hydra:member']));
  }, [monetizationTypes]);

  return (
    <ModalWrapper>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(onBidderChange)}>
          <Heading variant="h2">Modification de {item.displayName}</Heading>
          <TextFieldBidder
            {...register('displayName', { required: true })}
            required
            label="Display name"
            variant="standard"
            error={errors.displayName}
            helperText={errors.displayName && 'Le displayName est obligatoire'}
          />
          <TextFieldBidder
            {...register('keyname', { required: true })}
            required
            label="Keyname"
            variant="standard"
            error={errors.displayName}
            helperText={errors.keyname && 'Le Keyname est obligatoire'}
          />
          <TextFieldBidder
            {...register('uuid', { required: true })}
            required
            label="UUID"
            variant="standard"
            error={errors.displayName}
            helperText={errors.uuid && "L'UUID est obligatoire"}
          />
          <FormControl>
            <InputLabel>Type de bidder</InputLabel>
            <Select {...register('type')} label="Type de bidder" defaultValue={item.type}>
              <MenuItem value={0}>Standard</MenuItem>
              <MenuItem value={1}>Alias</MenuItem>
              <MenuItem value={2}>Alias native</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            {...register('authorizedToBid')}
            control={<Checkbox defaultChecked={item.authorizedToBid} />}
            label="Est-ce que le bidder est autorisé à bidder ?"
            labelPlacement="top"
          />

          <FormControl sx={{ width: '250px' }}>
            <InputLabel>Type de monetisation</InputLabel>
            <Select
              {...register('monetizationType')}
              label="Type de monetisation"
              defaultValue={item.monetizationType['@id']}
            >
              {monetizationTypes &&
                monetizationTypes.map((monetizationType) => (
                  <MenuItem key={monetizationTypes.id} value={monetizationType['@id']}>
                    {monetizationType.genericName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControlLabel
            {...register('isAmpCompliant')}
            control={<Checkbox defaultChecked={item.isAmpCompliant} />}
            label="Est-ce que le bidder est compatible AMP ?"
            labelPlacement="top"
          />
          <div />

          {typeWatcher === 0 && (
            <TextFieldBidder
              {...register('adapter.adapter', { required: true })}
              required
              label="Adapter"
              variant="standard"
              error={errors.adapter?.adapter}
              helperText={errors.adapter?.adapter && "L'adapter est obligatoire"}
            />
          )}

          {typeWatcher === 1 && adapters.length > 0 && (
            <FormControl>
              <InputLabel>Adapter</InputLabel>
              <Select {...register('adapter', { required: true })} label="Adapter" defaultValue={item?.adapter['@id']}>
                {adapters &&
                  adapters.map((adapter) => (
                    <MenuItem key={adapter.id} value={`/api/adapters/${adapter.id}`}>
                      {adapter.adapter}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}

          {placementKeywordLibs.length > 0 && (
            <>
              <PlacementAction>
                <Heading variant="h2">BidderPlacement</Heading>
                <Button onClick={handlePrependPlacementKeyword} variant="outlined" sx={{ margin: '0 5px' }}>
                  <AddIcon />
                  Ajouter un placement
                </Button>
              </PlacementAction>
              {fields.length > 0 &&
                fields.map((field, index) => (
                  <BidderKeywordDetail key={field.id} index={index} removePlacement={handleRemovePlacement} />
                ))}
            </>
          )}

          <Button type="submit" fullWidth variant="contained">
            Modifier le Bidder
          </Button>
        </FormWrapper>
      </FormProvider>
    </ModalWrapper>
  );
}

ModalModificationBidder.propTypes = {
  item: BidderType.isRequired,
  setOpen: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default ModalModificationBidder;
