import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Card } from '@mui/material';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VariablesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VariablesWrapper = styled.div`
  width: 78%;
`;

const SidebarWrapper = styled(Card)`
  width: 20%;
  height: fit-content;
  padding: 10px;
  position: sticky;
  top: 15px;
`;

const VariablesTitle = styled.h3`
  text-transform: capitalize;
`;

const VariableCardContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const VariableCardWrapper = styled(Card, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(
  ({ active }) => css`
    display: flex;
    margin: 5px;
    max-height: 255.5px;

    ${!active &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  `
);

const VariableForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const VariableHeaderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  ${({ selected }) =>
    selected
      ? css`
          background-color: rgba(255, 155, 0, 0.25);
        `
      : css`
          background-color: rgba(255, 0, 0, 0.5);
        `}
`;

const VariableTitleContainer = styled.div`
  display: flex;
  overflow: hidden;
`;

const VariableTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FieldsContainer = styled.div`
  overflow: auto;
`;

export {
  FieldsContainer,
  HeaderContainer,
  SidebarWrapper,
  VariableCardContainer,
  VariableCardWrapper,
  VariableForm,
  VariablesTitle,
  VariableHeaderContainer,
  VariableTitleContainer,
  VariableTooltipWrapper,
  VariablesContainer,
  VariablesWrapper,
};
