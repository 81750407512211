import { Collapse, Divider, ListItem, ListItemButton, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { getDetailLabel } from '../../helpers/detailModification';
import { selectModification } from '../../store/ModificationsSlice';
import DraftModificationType from '../../types/DraftModificationType';
import { HistoryDiv, ModificationTitle } from '../GoogleTag/Edit/MultiUnitModification/Sidebar/History/History.style';
import SwitchModification from './SwitchModification';
import ListGoogleTagsAffected from './ListGoogleTagsAffected/ListGoogleTagsAffected';

function Modification(props) {
  const { createdAt, showDetails, modificationProp } = props;

  const [open, setOpen] = useState(true);

  const modificationSelector = useMemo(selectModification, []);
  const modificationSlice = useSelector((state) => modificationSelector(state, createdAt));

  const modification = useMemo(() => modificationProp ?? modificationSlice, [modificationProp, modificationSlice]);

  const { id, operation } = modification;

  const detailLabel = useMemo(() => getDetailLabel(operation), [operation]);

  const localDate = useMemo(() => new Date(Number.parseInt(modification.createdAt, 10)), [modification.createdAt]);

  return (
    <HistoryDiv>
      {!showDetails ? (
        <ModificationTitle inDraft={id}>{detailLabel}</ModificationTitle>
      ) : (
        <>
          <ListItem sx={{ padding: 0, backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemText
                primary={<ModificationTitle inDraft={id}>{detailLabel}</ModificationTitle>}
                secondary={localDate.toLocaleString()}
              />
              {open ? <MdExpandLess /> : <MdExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={open}>
            <div style={{ margin: '5px 2px' }}>
              <SwitchModification modification={modification} />
              <ListGoogleTagsAffected modificationId={modification.id} modificationProp={modification} />
            </div>
          </Collapse>
          <Divider />
        </>
      )}
    </HistoryDiv>
  );
}

Modification.propTypes = {
  createdAt: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
  modificationProp: DraftModificationType,
};

Modification.defaultProps = {
  showDetails: false,
  modificationProp: null,
};

export default Modification;
