import { Button, Input, MenuItem, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { ColumnStyle, ItemWrapper } from '../Item/UserItem.style';

function AddUser(props) {
  const { register, errors } = props;

  return (
    <ItemWrapper>
      <ColumnStyle left />
      <ColumnStyle>
        <Input {...register('lastname', { required: true })} placeholder="Nom" required id="lastname" />
      </ColumnStyle>
      <ColumnStyle>
        <Input {...register('firstname', { required: true })} placeholder="Prénom" required id="firstname" />
      </ColumnStyle>
      <ColumnStyle>
        <TextField
          {...register('mail', {
            required: true,
            validate: {
              validEmail: (value) => {
                const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i;

                if (!regex.test(value.replace(/\s/g, ''))) {
                  return 'Veuillez saisir une adresse e-mail valide.';
                }

                return true;
              },
            },
          })}
          placeholder="Mail"
          required
          id="mail"
          type="email"
          error={!!errors?.mail}
          helperText={errors?.mail?.message}
        />
      </ColumnStyle>
      <ColumnStyle>
        <Select {...register('role', { required: true })} id="selectRole" defaultValue="ROLE_USER">
          <MenuItem value="ROLE_USER">Utilisateur</MenuItem>
          <MenuItem value="ROLE_ADMIN">Administrateur</MenuItem>
        </Select>
      </ColumnStyle>
      <ColumnStyle>
        <Button type="submit"> Créer l&#39;utilisateur </Button>
      </ColumnStyle>
    </ItemWrapper>
  );
}

AddUser.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    lastname: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    firstname: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    mail: PropTypes.object,
  }).isRequired,
};

export default AddUser;
