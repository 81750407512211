import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BsFillBellFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL, MERCURE_BASE_URL } from '../../references/base-urls';
import { fetchTabBidders, selectTabBiddersStatus } from '../../store/TabBiddersSlice';
import { selectCurrentUser } from '../../store/UsersSlice';
import { Badge, NotificationWrapper } from '../UI/Layout/Header/NavButtons/HeaderActionButtons.style';
import NotificationsModal from './NotificationModal/NotificationsModal';
import { addNotification, fetchNotificationByUser, selectTotalNotification } from './NotificationSlice';

function Notification() {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);
  const totalNotification = useSelector(selectTotalNotification);
  const bidderStatus = useSelector(selectTabBiddersStatus);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && bidderStatus === 'idle') {
      dispatch(fetchTabBidders());
    }
  }, [dispatch, open, bidderStatus]);

  useEffect(() => {
    if (user) {
      const data = {
        'user.id': user.id,
        order: { 'notification.createdAt': 'DESC' },
        state: 0,
      };

      dispatch(fetchNotificationByUser(data));
    }
  }, [dispatch, user]);

  useEffect(() => {
    let sse;
    if (user) {
      const url = new URL(MERCURE_BASE_URL);
      url.searchParams.append('topic', `${BASE_URL}/api/user_notifications?user.id=${user.id}`);
      sse = new EventSource(url);

      sse.onmessage = (event) => {
        const data = JSON.parse(event.data);
        dispatch(addNotification(data));
        const volume = localStorage.getItem('audio');
        const audio = new Audio(`/assets/notification-sound.wav`);
        audio.volume = volume === null || volume === undefined ? 1 : parseInt(volume, 10);
        audio.play();
      };
    }

    return () => {
      if (sse) {
        sse.close();
      }
    };
  }, [dispatch, user]);

  return (
    <NotificationWrapper>
      <IconButton onClick={() => setOpen(!open)}>
        <BsFillBellFill color="white" size={20} />
      </IconButton>
      {totalNotification !== 0 && <Badge pulse>{totalNotification > 99 ? '99+' : totalNotification}</Badge>}
      {open && <NotificationsModal open={open} handleClose={() => setOpen(false)} />}
    </NotificationWrapper>
  );
}

export default Notification;
