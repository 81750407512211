import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';
import { StyledTextField } from '../Floor.style';

function Delimiter(props) {
  const { control, registerTarget } = props;

  return (
    <Controller
      render={({ field }) => (
        <Tooltip title="Valeur par défaut: |" arrow>
          <StyledTextField
            id={registerTarget}
            placeholder="|"
            label="Delimiteur"
            type="text"
            variant="filled"
            {...field}
            value={field.value ?? ''}
          />
        </Tooltip>
      )}
      control={control}
      name={registerTarget}
    />
  );
}

Delimiter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  registerTarget: PropTypes.string.isRequired,
};

export default Delimiter;
