/* eslint-disable */
import Label, { inProdColor } from '../../../UI/Basic/Labels/Label';

import classes from './GtagItem.module.css';

function GtagItem(props) {
  const { keyName, gtagIndex, gtag } = props;
  const selectGtagHandler = () => {
    props.onAddGtag(gtagIndex, keyName);
  };

  const label = <Label color={inProdColor}>{gtag.status}</Label>;
  const prebid = <Label color={inProdColor}>Prebid: {gtag.gtagPrebidVersion}</Label>;

  return (
    <div className={classes.gtagItem} onClick={selectGtagHandler}>
      <h3>
        <u>{gtag.gtagReleaseId}</u> Créé le {gtag.gtagCreatedAt} par {gtag.gtagAuthor}
      </h3>
      <p>Dernière modif le {gtag.gtagUpdatedAt}</p>
      <p>Description : {gtag.gtagDescription}</p>
      {label}
      {prebid}
    </div>
  );
}

export default GtagItem;
