import styled from '@emotion/styled/macro';
import { Card } from '@mui/material';

const InputWrapper = styled.div`
  height: 100%;
  position: relative;

  ${({ sx }) => ({ ...sx })}
`;

const InputSuggestionWrapper = styled(Card)`
  position: absolute;
  top: -100px;
  padding: 10px;
  z-index: 100;
`;

const InputSuggestionTitleWrapper = styled.div`
  display: inline-flex;
`;

const SuggestionsListWrapper = styled.div`
  display: flex;
  max-width: 300px;
  overflow: auto;
  padding: 5px;

  & > * {
    margin: 2px;
  }

  & > *:first-of-type {
    margin-left: 0;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

export { InputWrapper, InputSuggestionWrapper, InputSuggestionTitleWrapper, SuggestionsListWrapper };
