import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminPartnerTableHeaders } from '../../../../references/table-headers-list';
import { fetchAllPartners, selectPartners, selectTotalPartners } from '../../../../store/PartnersSlice';
import Table from '../../../UI/CompleteTable/Table';
import Heading from '../../../UI/Title/Heading';
import { CardPartner } from '../CreatePartner/CreatePartner.style';
import TableRow from './TableRow';

function AdminPartnersTable() {
  const dispatch = useDispatch();

  const partners = useSelector(selectPartners);
  const totalPartners = useSelector(selectTotalPartners);

  const fetchPartner = useCallback(
    (page, itemsPerPage) => {
      dispatch(fetchAllPartners({ page: page + 1, itemsPerPage }));
    },
    [dispatch]
  );

  return (
    <CardPartner>
      <Heading>Administration des sites partenaires</Heading>
      <Table
        columns={adminPartnerTableHeaders}
        Line={TableRow}
        getData={fetchPartner}
        total={totalPartners}
        data={partners}
        itemsPerPage={20}
      />
    </CardPartner>
  );
}

export default AdminPartnersTable;
