/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { statusGoogleTags } from '../../../../helpers/status';
import { selectGoogleTagVersionStatus } from '../../../../store/GoogleTagVersionSlice';
import { fetchAllAuthorizedPartners, selectAuthorizedPartners } from '../../../../store/PartnersSlice';
import { fetchPositions, selectAllPositions } from '../../../../store/PositionsSlice';
import { fetchPrebidVersions, selectPrebidVersions } from '../../../../store/PrebidVersionSlice';
import { fetchSizes, selectSizes } from '../../../../store/SizesSlice';
import { fetchTabBidders, selectTabBidders } from '../../../../store/TabBiddersSlice';
import Loader from '../../../UI/Animation/Loader';
import MassButton from '../../../UI/Basic/Buttons/MassButton/MassButton';
import ControlledAutocomplete from '../../../UI/ControlledAutocomplete';
import { FormControlFilter, LoaderWrapper } from './SidebarFilter.style';

function SidebarFilter(props) {
  const { handleFilterChange } = props;

  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm();

  const positions = useSelector(selectAllPositions);
  const bidders = useSelector(selectTabBidders);
  const sizes = useSelector(selectSizes);
  const prebidVersions = useSelector(selectPrebidVersions);
  const status = useSelector(selectGoogleTagVersionStatus);
  const allAuthorizedPartners = useSelector(selectAuthorizedPartners);

  useEffect(() => {
    if (positions.length === 0) dispatch(fetchPositions());
  }, [positions, dispatch]);

  useEffect(() => {
    if (bidders.length === 0) dispatch(fetchTabBidders({ pagination: false, isActive: true }));
  }, [bidders, dispatch]);

  useEffect(() => {
    if (sizes.length === 0) dispatch(fetchSizes());
  }, [sizes, dispatch]);

  useEffect(() => {
    if (prebidVersions.length === 0) dispatch(fetchPrebidVersions());
  }, [prebidVersions, dispatch]);

  useEffect(() => {
    if (allAuthorizedPartners.length === 0) dispatch(fetchAllAuthorizedPartners());
  }, [allAuthorizedPartners, dispatch]);

  const submitForm = (data) => {
    handleFilterChange(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            multiple
            control={control}
            options={prebidVersions}
            renderInput={(params) => <TextField {...params} label="Version de prebid" placeholder="Favorites" />}
            getOptionLabel={(option) => option.version}
            name="prebidVersion"
            renderOption={(renderProps, option, { selected }) => (
              <li {...renderProps}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.version}
              </li>
            )}
          />
        </FormControlFilter>

        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            multiple
            control={control}
            options={bidders}
            renderInput={(params) => <TextField {...params} label="Bidder" placeholder="Bidder" />}
            getOptionLabel={(option) => option.displayName}
            name="bidder"
            renderOption={(renderProps, option, { selected }) => (
              <li {...renderProps}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.displayName}
              </li>
            )}
          />
        </FormControlFilter>

        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            multiple
            control={control}
            options={positions}
            renderInput={(params) => <TextField {...params} label="Positions" placeholder="Position" />}
            getOptionLabel={(option) => option.genericName}
            name="position"
            renderOption={(renderProps, option, { selected }) => (
              <li {...renderProps}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.genericName}
              </li>
            )}
          />
        </FormControlFilter>

        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            multiple
            control={control}
            options={sizes}
            renderInput={(params) => <TextField {...params} label="Tailles" placeholder="Taille" />}
            getOptionLabel={(option) => option.concatenatedSize}
            name="size"
            renderOption={(renderProps, option, { selected }) => (
              <li {...renderProps} key={option.concatenatedSize + option.id}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.concatenatedSize}
              </li>
            )}
          />
        </FormControlFilter>

        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            control={control}
            options={statusGoogleTags}
            renderInput={(params) => <TextField {...params} label="Status" placeholder="Status" />}
            getOptionLabel={(option) => option.key}
            name="status"
            renderOption={(renderProps, option) => (
              <li {...renderProps} key={option.key + option.value}>
                {option.key}
              </li>
            )}
          />
        </FormControlFilter>

        <FormControlFilter fullWidth>
          <ControlledAutocomplete
            multiple
            control={control}
            options={allAuthorizedPartners}
            renderInput={(params) => <TextField {...params} label="Partenaire" placeholder="Partenaire" />}
            getOptionLabel={(option) => option.displayName}
            name="partners"
            renderOption={(renderProps, option, { selected }) => (
              <li {...renderProps} key={option.displayName + option.id}>
                <Checkbox checked={selected} style={{ marginRight: 8 }} />
                {option.displayName}
              </li>
            )}
          />
        </FormControlFilter>

        <MassButton type="submit">Filtrer</MassButton>
        {status === 'pending' && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </form>
    </div>
  );
}

SidebarFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
};

export default SidebarFilter;
