import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { importGoogleTagsFromGithub } from '../../../helpers/api/googleTagVersion';
import MassButton from '../../UI/Basic/Buttons/MassButton/MassButton';
import { selectCurrentPartner, selectPartnerInfos } from '../PartnerPageSlice';
import Detail from './Detail/Detail';
import { ButtonContainer, CloneLink, TitleContainer, TitlePartner } from './GoogleTagInfos.style';
import { selectCurrentUser } from '../../../store/UsersSlice';
import MuiModal from '../../UI/Modal/MuiModal';

function GoogleTagInfos() {
  const user = useSelector(selectCurrentUser);
  const partner = useSelector(selectCurrentPartner);
  const partnerInfos = useSelector(selectPartnerInfos);

  const [forceImport, setForceImport] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setOpen((o) => !o);
  }, []);

  const handleReloadProd = useCallback(() => {
    importGoogleTagsFromGithub({ partners: [partner?.id], force: forceImport });
    setOpen((o) => !o);
  }, [forceImport, partner?.id]);

  if (!partner) return null;

  return (
    <>
      <TitleContainer>
        <TitlePartner>{partner.displayName}</TitlePartner>
        <ButtonContainer>
          {user?.roles?.includes('ROLE_ADMIN') && (
            <MassButton onClick={handleOpenModal}>Recharger le GoogleTag de prod</MassButton>
          )}
          <CloneLink to={`/generate-new-gtag/${partner.id}`}>
            <MassButton>Cloner un GoogleTag</MassButton>
          </CloneLink>
        </ButtonContainer>
      </TitleContainer>
      <Detail namePartner={partner.displayName} infos={partnerInfos.qual} action="QUAL" />
      <Detail namePartner={partner.displayName} infos={partnerInfos.prod} action="PROD" />
      <MuiModal open={open} handleClose={handleOpenModal} title="Recharger le GoogleTag de Prod ?">
        <p>Le rechargement du GoogleTag peut entraîner des pertes de données.</p>
        <p>Êtes-vous sûr de continuer ?</p>
        <FormControlLabel
          label="Forcer l'import"
          control={<Checkbox checked={forceImport} onChange={() => setForceImport((f) => !f)} />}
        />
        <ButtonContainer>
          <Button onClick={handleOpenModal} variant="contained" color="primary" fullWidth>
            Retour
          </Button>
          <Button onClick={handleReloadProd} variant="contained" color="warning" fullWidth>
            Valider
          </Button>
        </ButtonContainer>
      </MuiModal>
    </>
  );
}

export default GoogleTagInfos;
