import styled from '@emotion/styled/macro';
import { Select, FormControl } from '@mui/material';

const FormWrapper = styled.form`
  width: 100%;
`;

const FormControlFullWidth = styled(FormControl)`
  width: 100%;
`;

const SelectDraft = styled(Select)`
  padding: 5px 5px;

  & #mui-component-select-draft {
    padding: 5px 5px;
  }
`;

const MenuItemContent = styled.span`
  display: flex;
  align-items: center;
`;

export { FormWrapper, FormControlFullWidth, SelectDraft, MenuItemContent };
