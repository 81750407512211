import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import useReactHookFormError from '../../../hooks/useReactHookFormError';
import { StyledTextField } from '../Floor.style';

function DefaultFloor(props) {
  const { control, hidden, registerTarget, errors } = props;
  const error = useReactHookFormError(registerTarget, errors);

  return (
    <Controller
      render={({ field }) => (
        <StyledTextField
          id={registerTarget}
          label="Floor par défaut"
          type="number"
          inputProps={{
            step: '0.01',
          }}
          variant="filled"
          error={!!error}
          helperText={error?.message}
          {...field}
          value={field.value ?? ''}
        />
      )}
      control={control}
      name={registerTarget}
      rules={{
        validate: {
          required: (value) => {
            if (!hidden && !value) {
              return 'Le champ est requis.';
            }

            return true;
          },
        },
      }}
    />
  );
}

DefaultFloor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  hidden: PropTypes.bool.isRequired,
  registerTarget: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.any.isRequired,
};

export default DefaultFloor;
