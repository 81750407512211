import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminBiddersTableHeaders } from '../../../../references/table-headers-list';
import { fetchTabBidders, selectTabBidders, selectTotal } from '../../../../store/TabBiddersSlice';
import Table from '../../../UI/CompleteTable/Table';
import Heading from '../../../UI/Title/Heading';
import LineBidder from './Item/LineBidder';

function ListBidder() {
  const dispatch = useDispatch();
  const tabBidders = useSelector(selectTabBidders);
  const total = useSelector(selectTotal);

  const fetchBidders = useCallback(
    (page, itemsPerPage) => {
      dispatch(fetchTabBidders({ page: page + 1, itemsPerPage }));
    },
    [dispatch]
  );

  return (
    <>
      <Heading>Administration des bidders (SSP)</Heading>
      <Table
        columns={adminBiddersTableHeaders}
        data={tabBidders}
        getData={fetchBidders}
        total={total}
        Line={LineBidder}
      />
    </>
  );
}

export default ListBidder;
