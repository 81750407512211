/* eslint-disable react/jsx-props-no-spreading */
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsPencilFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { patchPartnerGroup, postPartnerGroup } from '../../../../../helpers/api/partner';
import { fetchAllPartners } from '../../../../../store/PartnersSlice';
import { CustomTD } from '../../../../UI/CompleteTable/Table.style';
import LinkWrapper from '../../../../UI/LinkWrapper/LinkWrapper';
import { GCPtextField, ModifySection } from '../../List/TableRow.style';

function ItemPartner(props) {
  const { item } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { handleSubmit, register } = useForm({
    defaultValues: {
      gcpRepository: item.partnerGroup && item.partnerGroup.gcpRepository,
    },
  });

  const validateGcpRepository = (data) => {
    let gcpRepo = data;

    if (item.partnerGroup) {
      if (item.partnerGroup.gcpRepository === data.gcpRepository) {
        enqueueSnackbar(`Le nom du repertoire n'est pas modifié.`, { variant: 'error' });
        return;
      }

      patchPartnerGroup(item.partnerGroup.id, gcpRepo).then(() => {
        enqueueSnackbar(`Le répertoire GCP de ${item.displayName} a bien été modifié`, { variant: 'success' });
        dispatch(fetchAllPartners({ page: 1, itemsPerPage: 15 }));
      });
    } else {
      gcpRepo = {
        ...gcpRepo,
        partnerWebsites: [`api/partner_websites/${item.id}`],
      };
      postPartnerGroup(gcpRepo).then(() => {
        enqueueSnackbar(`Le repertoire GCP de ${item.displayName} a bien été créé`, { variant: 'success' });
        dispatch(fetchAllPartners({ page: 1, itemsPerPage: 15 }));
      });
    }

    setOpen(false);
  };

  return (
    <>
      <CustomTD title={item.id}>{item.id}</CustomTD>
      <CustomTD title={item.displayName}>{item.displayName}</CustomTD>
      <CustomTD title={item.keyname}>{item.keyname}</CustomTD>
      <CustomTD title={item.trigram}>{item.trigram}</CustomTD>
      <CustomTD title={item.partnerGroup ? item.partnerGroup.name ?? '--' : '--'}>
        {item.partnerGroup ? item.partnerGroup.name ?? '--' : '--'}
      </CustomTD>
      <CustomTD>
        <ModifySection>
          {open ? (
            <form onSubmit={handleSubmit((data) => validateGcpRepository(data))}>
              <GCPtextField
                {...register('gcpRepository')}
                label="Repertoire GCP"
                variant="standard"
                name="gcpRepository"
              />
              <LinkWrapper type="submit">
                <AiOutlineCheck color="green" size={30} cursor="pointer" />
              </LinkWrapper>
            </form>
          ) : (
            <p>{item.partnerGroup ? item.partnerGroup.gcpRepository ?? '--' : '--'}</p>
          )}
          <BsPencilFill size={20} onClick={() => setOpen(!open)} cursor="pointer" />
        </ModifySection>
      </CustomTD>
    </>
  );
}

ItemPartner.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    keyname: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    trigram: PropTypes.string.isRequired,
    partnerGroup: PropTypes.shape({
      gcpRepository: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default ItemPartner;
