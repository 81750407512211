import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectNotification } from '../NotificationSlice';
import NotificationTemplate from '../NotificationTemplate/NotificationTemplate';

function DefaultNotification(props) {
  const { notifId } = props;

  const notificationSelector = useMemo(selectNotification, []);
  const userNotification = useSelector((state) => notificationSelector(state, notifId));

  return (
    <NotificationTemplate
      date={new Date(userNotification.notification.createdAt).toLocaleString()}
      userNotification={userNotification}
    >
      <p>Une notification avec un type inconnue est arrivée :</p>
      <p>{JSON.stringify(userNotification.notification.metadata)}</p>
    </NotificationTemplate>
  );
}

DefaultNotification.propTypes = {
  notifId: PropTypes.number.isRequired,
};

export default DefaultNotification;
