import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import MuiModal from '../Modal/MuiModal';
import { ModalButtonWrapper } from '../../GoogleTag/Edit/MultiUnitModification/Cart/Cart.style';

function NameVersionModal(props) {
  const { open, handleClose, nameVersion, onChangeNameVersion, onValidate } = props;

  return (
    <MuiModal open={open} handleClose={handleClose}>
      <TextField
        label="Nom de votre version (obligatoire)"
        variant="outlined"
        multiline
        minRows={2}
        maxRows={5}
        value={nameVersion}
        onChange={onChangeNameVersion}
      />
      <p>Vous allez mettre en ligne une version d&#39;un GoogleTag, en quoi consiste t-elle ?</p>
      <ModalButtonWrapper>
        <Button onClick={handleClose} autoFocus>
          Ne pas publier la version
        </Button>
        <Button onClick={onValidate} disabled={!nameVersion}>
          Publier la version
        </Button>
      </ModalButtonWrapper>
    </MuiModal>
  );
}

NameVersionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onChangeNameVersion: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  nameVersion: PropTypes.string.isRequired,
};

export default NameVersionModal;
