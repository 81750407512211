import styled from '@emotion/styled/macro';

const VersionWrapper = styled.div`
  padding: 2px 10px;
  border-radius: 5px;
  border: 0.1px solid black;
  text-align: center;
  margin-bottom: 1px;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.selectedVersion && 'lightgreen'};
`;

const TextWrapper = styled.div`
  width: 60%;
`;

export { VersionWrapper, TextWrapper };
