import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminBlock from '../components/Admin/ManageBlocs/AdminBlock';
import ManagePartner from '../components/Admin/ManagePartners/ManagePartner';
import ManagePosition from '../components/Admin/ManagePosition/ManagePosition';
import ManagePrebidVersion from '../components/Admin/ManagePrebidVersion/ManagePrebidVersion';
import TabUsers from '../components/Admin/ManageUsers/Wrapper/TabUsers';
import SnapshotHistory from '../components/Admin/SnapshotHistory/SnapshotHistory';
import ManageXandr from '../components/Admin/Xandr/ManageXandr';
import DraftList from '../components/Draft/DraftList';
import Floor from '../components/Floor/Floor';
import MultiUnitModification from '../components/GoogleTag/Edit/MultiUnitModification/MultiUnitModification';
import Settings from '../components/GoogleTag/Settings/Settings';
import MassModification from '../components/Mass/MassModification';
import Partner from '../components/Partner/Partner';
import LoggedRoute from '../components/Route/LoggedRoute';
import PrivateRoute from '../components/Route/PrivateRoute';
import Topics from '../components/Admin/Topics/Topics.index';
import Admin from '../pages/Admin/Admin';
import AdminBidders from '../pages/Admin/AdminBidders';
import ForgotPassword from '../pages/Authentication/ForgotPassword';
import Login from '../pages/Authentication/Login';
import PutMailForgotPassword from '../pages/Authentication/PutMailForgotPassword';
import EditCode from '../pages/GoogleTag/CodeGeneration/EditCode';
import BidderCsv from '../pages/GoogleTag/Edit/BidderCsv/BidderCsv';
import BidderCsvInstructions from '../pages/GoogleTag/Edit/BidderCsv/BidderCsvInstructions';
import BidderCsvResultList from '../pages/GoogleTag/Edit/BidderCsv/BidderCsvResultList';
import EditGtag from '../pages/GoogleTag/Edit/EditGtag';
import GoogleTagEdit from '../pages/GoogleTag/Edit/GoogleTagEdit';
import GenerateNewGtag from '../pages/GoogleTag/GenerateNewGtag';
import Home from '../pages/Home';
import PageNotFound from '../pages/PageNotFound';
import './App.css';

export default function App() {
  return (
    <Routes>
      <Route path="/" exact element={<LoggedRoute />}>
        <Route path="/" exact element={<Home />} />
        <Route path="/show-partner/:partnerId" element={<Partner />} />
        <Route path="/generate-new-gtag/:partnerId" exact element={<GenerateNewGtag />} />
        <Route path="/edit-google-tag/:googleTagVersionId" element={<EditGtag />}>
          <Route path="" exact element={<GoogleTagEdit />} />
          <Route path="unit-modification" element={<MultiUnitModification />} />
          <Route path="code-edit" element={<PrivateRoute roles="ROLE_BLOC" />}>
            <Route path="" element={<EditCode />} />
          </Route>
          <Route path="settings" element={<Settings />} />
        </Route>
        <Route path="/edit-shared-google-tag/:gtagUuid" exact element={<EditGtag />} />
        <Route path="/floor" element={<Floor />} />
        <Route path="/admin" element={<PrivateRoute roles="ROLE_ADMIN" />}>
          <Route path="" element={<Admin />} />
          <Route path="partners" element={<ManagePartner />} />
          <Route path="bidders" element={<AdminBidders />} />
          <Route path="blocs" element={<AdminBlock />} />
          <Route path="history" exact element={<SnapshotHistory />} />
          <Route path="prebid" element={<ManagePrebidVersion />} />
          <Route path="users" element={<TabUsers />} />
          <Route path="xandr" element={<ManageXandr />} />
          <Route path="position" element={<ManagePosition />} />
          <Route path="topics" element={<Topics />} />
        </Route>
        <Route path="marsse" element={<MassModification />} />
        <Route path="drafts" element={<DraftList />} />
        <Route path="/import-bidder-csv">
          <Route path="" exact element={<BidderCsv />} />
          <Route path="instructions" element={<BidderCsvInstructions />} />
          <Route path="results" element={<BidderCsvResultList />} />
        </Route>
      </Route>

      <Route path="/login" exact element={<Login />} />
      <Route path="/reset-password/:token" exact element={<ForgotPassword />} />
      <Route path="/forgot-password" exact element={<PutMailForgotPassword />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
