import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsBoxArrowLeft, BsBoxArrowRight } from 'react-icons/bs';
import { Button, Collapse, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { HeaderBlockList, SidebarBlockListWrapper, TitleColumn, VerticalText } from './SidebarBlockList.style';
import { fetchBlocks, selectBlocks } from '../../BlocksSlice';
import Block from '../../BlockUI/Block';
import { selectBlockModifications } from '../../../../../store/ModificationsSlice';

function SidebarBlockList(props) {
  const { isVisible, handleChangeVisible } = props;
  const dispatch = useDispatch();
  const { blocks, blocksSelected } = useSelector(selectBlocks);
  const [filter, setFilter] = useState('');

  const blocksFiltered = useMemo(() => {
    if (filter.length > 0) {
      const blockByName = blocks.filter(
        (block) => block.name.toLowerCase().includes(filter.toLowerCase()) && block.parentBloc === 'main'
      );
      const blockByCode = blocks.filter(
        (block) => block.versionBlock.toLowerCase().includes(filter.toLowerCase()) && block.parentBloc === 'main'
      );

      const blockWithPreview = blockByCode.map((block) => {
        const tempBlock = { ...block };
        const codeSplited = block.versionBlock.split('\n');
        const currentLineIndex = codeSplited.findIndex((code) => code.toLowerCase().includes(filter.toLowerCase()));
        const previousLine = codeSplited[currentLineIndex - 1] ?? '';
        const currentLine = codeSplited[currentLineIndex] ?? '';
        const nextLine = codeSplited[currentLineIndex + 1];
        tempBlock.preview = `${previousLine}\n${currentLine}\n${nextLine}`;
        return tempBlock;
      });

      const uniqueIds = new Set(blockWithPreview.map((item) => item.id));

      const filteredBlockByName = blockByName.filter((item) => !uniqueIds.has(item.id));

      return [...blockWithPreview, ...filteredBlockByName];
    }

    return blocks;
  }, [blocks, filter]);

  const modifications = useSelector(selectBlockModifications);

  const isInModifications = (id) => {
    for (let i = 0; i < modifications.lenght; i += 1) {
      const { metadata } = modifications[i];
      if (metadata?.idBlock === id && modifications.operation === 'addBlock') {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    dispatch(fetchBlocks());
  }, [dispatch]);

  return (
    <SidebarBlockListWrapper isVisible={isVisible}>
      <HeaderBlockList isVisible={isVisible}>
        {isVisible && <TitleColumn>Blocs disponibles :</TitleColumn>}
        {isVisible && (
          <TextField
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
            label="Filtre"
            placeholder="Filtre"
          />
        )}
        <Button onClick={handleChangeVisible}>
          {isVisible ? <BsBoxArrowLeft size="1.5em" /> : <BsBoxArrowRight size="1.5em" />}
        </Button>
        {!isVisible && <VerticalText>Liste des blocs disponibles</VerticalText>}
      </HeaderBlockList>
      {isVisible && <hr />}
      <Collapse
        in={isVisible}
        timeout="auto"
        orientation="horizontal"
        sx={{ minWidth: '100%', height: '100%', '& .MuiCollapse-wrapperInner': { minWidth: '100%', width: '100%' } }}
      >
        <Droppable droppableId="block-listing" type="droppableItem">
          {(provided) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ height: '63vh', width: '100%', overflowY: 'scroll' }}
            >
              {blocksFiltered.length > 0 &&
                blocksFiltered.map((block) => {
                  return (
                    <Block
                      block={block}
                      key={block.id}
                      display={
                        blocksSelected.length > 0
                          ? !blocksSelected.find(({ id }) => id === block.id) && !isInModifications(block.id)
                          : true
                      }
                      preview={block?.preview}
                    />
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Collapse>
    </SidebarBlockListWrapper>
  );
}

SidebarBlockList.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleChangeVisible: PropTypes.func.isRequired,
};

export default SidebarBlockList;
