import styled from '@emotion/styled';

const CsvAnalysisTable = styled.table`
  width: 100%;
`;

const CsvAnalysisTableHead = styled.thead`
  background-color: #264653;
  color: white;
`;

const CsvAnalysisTableTh = styled.th`
  padding: 10px;
`;

const CsvAnalysisTableTr = styled.tr`
  background-color: ${({ error }) => error && '#f39999'};
`;

const CsvAnalysisTableTd = styled.td`
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #264653;
  max-width: 250px;
`;

const ResumeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const AnalysisContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnalysisSentence = styled.p`
  color: ${({ isValid }) => (isValid ? 'green' : 'red')};
  font-weight: bolder;
`;

const ResumeSentence = styled.p`
  color: ${({ color }) => color ?? 'black'};
  font-weight: bold;
  max-width: 300px;
`;

export {
  CsvAnalysisTable,
  CsvAnalysisTableHead,
  CsvAnalysisTableTh,
  CsvAnalysisTableTd,
  ResumeContainer,
  ResumeSentence,
  AnalysisContainer,
  AnalysisSentence,
  CsvAnalysisTableTr,
};
