import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../UI/CompleteTable/Table';
import ItemGoogleTag from './Item/ItemGoogleTag';
import { selectGoogleTagVersionTotal, selectAllGoogleTagVersion } from '../../../../store/GoogleTagVersionSlice';
import { addGoogleTag, removeGoogleTag, selectSelectedGoogleTags } from '../../../../store/ModificationsSlice';

function GoogleTagTab(props) {
  const { fetchGoogleTag, page, setPage } = props;
  const googleTagVersions = useSelector(selectAllGoogleTagVersion);
  const total = useSelector(selectGoogleTagVersionTotal);
  const selectedGoogleTag = useSelector(selectSelectedGoogleTags);
  const dispatch = useDispatch();

  const checkGtagSelected = useMemo(() => {
    let valid = true;
    googleTagVersions.forEach((gtag) => {
      if (!selectedGoogleTag.some((id) => id === gtag.id)) {
        valid = false;
      }
    });
    return valid;
  }, [googleTagVersions, selectedGoogleTag]);

  const selectAllGoogleTag = useCallback(() => {
    const ids = [];

    if (checkGtagSelected) {
      googleTagVersions.forEach((googleTagVersion) => {
        if (selectedGoogleTag.some((id) => id === googleTagVersion.id)) {
          ids.push(googleTagVersion.id);
        }
      });

      dispatch(removeGoogleTag(ids));
    } else {
      googleTagVersions.forEach((googleTagVersion) => {
        if (!selectedGoogleTag.some((id) => id === googleTagVersion.id)) {
          ids.push(googleTagVersion.id);
        }
      });

      dispatch(addGoogleTag(ids));
    }
  }, [checkGtagSelected, dispatch, googleTagVersions, selectedGoogleTag]);

  const columns = useMemo(
    () => [
      {
        id: '0',
        label: (
          <Checkbox
            style={{
              color: 'white',
            }}
            onClick={selectAllGoogleTag}
            checked={checkGtagSelected}
          />
        ),
      },
      { id: '1', label: 'ID' },
      { id: '2', label: 'Auteur' },
      { id: '3', label: 'Description nouveauté' },
      { id: '4', label: 'Status' },
      { id: '5', label: 'Prebid Version' },
      { id: '6', label: 'Partenaire' },
      { id: '7', label: 'version' },
      { id: '8', label: 'Date de validation' },
    ],
    [checkGtagSelected, selectAllGoogleTag]
  );

  return (
    <Table
      total={total}
      getData={fetchGoogleTag}
      itemsPerPage={20}
      data={googleTagVersions}
      columns={columns}
      Line={ItemGoogleTag}
      pageExt={page}
      setPageExt={setPage}
      heightScroll="90vh"
    />
  );
}

GoogleTagTab.propTypes = {
  fetchGoogleTag: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default GoogleTagTab;
