import styled from '@emotion/styled/macro';
import { InputLabel, Select } from '@mui/material';

const SelectedPartnerWrapper = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #476b7c;
  color: white;
`;

const GridSelected = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  padding: 10px;
  color: white;
`;

const InfoWrapper = styled.div`
  width: 100%;
  max-width: 500px;
`;

const GoogleTagSelect = styled(Select)`
  color: white;
  .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: rgba(228, 219, 233, 0.5);
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: rgba(228, 219, 233, 0.5);
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: rgba(228, 219, 233, 1);
  }
  .MuiSvgIcon-root {
    fill: white !important;
  }
`;

const GoogleTagLabel = styled(InputLabel)`
  color: white;
`;

export { SelectedPartnerWrapper, GridSelected, InfoWrapper, GoogleTagSelect, GoogleTagLabel };
