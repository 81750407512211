import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { fetchAllUsers, postUser, selectUsers } from '../../../../store/UsersSlice';
import AddUser from '../AddUser/AddUser';
import UserItem from '../Item/UserItem';
import { ListItem, NameList, TableWrapper } from './TabUsers.style';

function TabUsers() {
  const dispatch = useDispatch();
  const [displayForm, setDisplayForm] = useState(false);
  const users = useSelector(selectUsers);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      role: 'ROLE_USER',
    },
  });

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const displayFormHandler = () => {
    setDisplayForm(!displayForm);
  };

  const onSubmit = (dataForm) => {
    const firstLetterFN = dataForm.firstname.substring(0, 1);
    const firstsLetterLN = dataForm.lastname.substring(0, 2);

    const trigram = (firstLetterFN + firstsLetterLN).toUpperCase();
    const dataUser = {
      firstname: dataForm.firstname,
      lastname: dataForm.lastname,
      email: dataForm.mail,
      trigram,
      roles: [dataForm.role],
      password: v4(),
      passwordGenerated: false,
    };

    dispatch(postUser(dataUser))
      .unwrap()
      .then(() => {
        setDisplayForm(false);
        reset();
      });
  };

  return (
    <>
      <Button onClick={displayFormHandler}>Ajouter un utilisateur</Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TableWrapper>
          <thead>
            <NameList>
              <ListItem left>ID</ListItem>
              <ListItem>Nom</ListItem>
              <ListItem>Prénom</ListItem>
              <ListItem>Mail</ListItem>
              <ListItem>Role</ListItem>
              <ListItem right>Action</ListItem>
            </NameList>
          </thead>
          <tbody>
            {displayForm && <AddUser register={register} errors={errors} />}
            {users.map((user) => (
              <UserItem key={user.id} userId={user.id} />
            ))}
          </tbody>
        </TableWrapper>
      </form>
    </>
  );
}

export default TabUsers;
