import styled from '@emotion/styled/macro';

const Menu = styled.div`
  min-height: 500px;
  min-width: 1200px;
  border: 2px black solid;
  border-radius: 5%;
`;

const ListFlex = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
`;

export { Menu, ListFlex };
