import styled from '@emotion/styled/macro';
import { TextField } from '@mui/material';
import { MdAdd, MdDangerous } from 'react-icons/md';

const ModalWrapper = styled.div`
  overflow: auto;
`;

const FormWrapper = styled.form``;

const TextFieldBidder = styled(TextField)`
  min-width: 250px;
  margin: 10px;
`;

const PlacementWrapper = styled.div`
  margin: 20px 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const PlacementAction = styled.div`
  display: flex;
  align-items: center;
`;

const AddIcon = styled(MdAdd)`
  margin-right: 5px;
  color: green;
  width: 20px;
  height: 20px;
`;

const DeleteIcon = styled(MdDangerous)`
  margin-right: 5px;
  color: #d32f2f;
  width: 20px;
  height: 20px;
`;

export { ModalWrapper, FormWrapper, TextFieldBidder, PlacementWrapper, PlacementAction, AddIcon, DeleteIcon };
