import styled from '@emotion/styled/macro';

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BlockCodeEditorContainer = styled.div`
  flex: 1;
  margin-top: -50px;
`;

export { TabWrapper, BlockCodeEditorContainer };
