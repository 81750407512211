import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllModifications, resetModifications, selectAllModifications } from '../../../store/ModificationsSlice';
import ItemModification from './ItemModification/ItemModification';
import { ModificationsWrapper, ScrollableDiv } from './Modifications.style';

function Modifications() {
  const modifications = useSelector(selectAllModifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllModifications());

    return () => {
      dispatch(resetModifications());
    };
  }, [dispatch]);

  return (
    <ModificationsWrapper>
      <b> Les 30 dernières modifications </b>

      <ScrollableDiv>
        {modifications.map((modification) => (
          <ItemModification key={modification.id} modificationId={modification.id} />
        ))}
      </ScrollableDiv>
    </ModificationsWrapper>
  );
}

export default Modifications;
