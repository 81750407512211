import { Button, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CgWebsite } from 'react-icons/cg';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TbDeviceGamepad } from 'react-icons/tb';
import { TiTick } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { selectGoogleTagVersion } from '../../../../../../store/GoogleTagVersionSlice';
import {
  fetchMediaTypes,
  selectAllMediaTypes,
  selectAllMediaTypesStatus,
} from '../../../../../../store/MediatypesSlice';
import {
  addPositionsModification,
  lastModifyPositionModificationSelector,
  lastPositionModificationSelector,
} from '../../../../../../store/ModificationsSlice';
import MuiModal from '../../../../../UI/Modal/MuiModal';
import FinalCell from './FinalCell';
import {
  ButtonContainer,
  ButtonWrapper,
  CellButton,
  ColorMediatype,
  CustomButton,
  LineGrid,
  MediaTypeColor,
} from './Grid.style';
import { selectFinalNodesPositions, selectNodesHeaders } from './NodesSlice';

function PositionGrid(props) {
  const { pagetypeId, deviceId, node } = props;
  const { positionName, positionId, added, active } = node;

  const dispatch = useDispatch();
  const ref = useRef(null);

  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const headers = useSelector(selectNodesHeaders);

  const mediatypes = useSelector(selectAllMediaTypes);
  const mediatypesStatus = useSelector(selectAllMediaTypesStatus);

  const selector = useMemo(() => ({ pagetypeId, deviceId, positionId }), [deviceId, pagetypeId, positionId]);

  const finalNodesPositionsSelector = useMemo(selectFinalNodesPositions, []);
  const positions = useSelector((state) => finalNodesPositionsSelector(state, selector));

  const selectLastPositionModification = useMemo(lastPositionModificationSelector, []);
  const lastPositionModification = useSelector((state) => selectLastPositionModification(state, selector));

  const selectLastModifyPositionModification = useMemo(lastModifyPositionModificationSelector, []);
  const lastModifyPositionModification = useSelector((state) => selectLastModifyPositionModification(state, selector));

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState(0);
  const [hover, setHover] = useState(0);

  useEffect(() => {
    if (mediatypesStatus !== 'idle') return;

    dispatch(fetchMediaTypes());
  }, [dispatch, mediatypesStatus]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      mediatypeIds: [],
    },
  });

  const defaultMediatypes = useMemo(() => {
    const mediatypesInPosition = [];

    if (lastModifyPositionModification?.metadata?.mediatypeIds) {
      lastModifyPositionModification.metadata.mediatypeIds.forEach((mediatypeId) => {
        if (mediatypeId) {
          mediatypesInPosition.push(mediatypeId);
        }
      });
    } else {
      positions.forEach((position) => {
        if (position.mediatypeId && position.active) {
          mediatypesInPosition.push(position.mediatypeId);
        }
      });
    }

    return mediatypesInPosition;
  }, [positions, lastModifyPositionModification]);

  const defaultMediatypesTitle = useMemo(() => {
    return mediatypes
      .filter((mediatype) => defaultMediatypes.includes(mediatype.id))
      .map((mediatype) => mediatype.genericName);
  }, [defaultMediatypes, mediatypes]);

  const handleOpen = useCallback(
    (type) => {
      setOpen((o) => !o);
      setModalType(type);
      reset({
        mediatypeIds: defaultMediatypes,
      });
    },
    [defaultMediatypes, reset]
  );

  const handleModifyMediatypes = (data) => {
    if (modalType !== 1 && data.mediatypeIds.length === defaultMediatypes.length) {
      const check = defaultMediatypes.every((e) => data.mediatypeIds.includes(e));
      if (check) {
        setOpen(false);
        return;
      }
    }

    const operation = modalType ? 'addPosition' : 'modifyPosition';

    dispatch(
      addPositionsModification({
        operation,
        metadata: { pagetypeId: [pagetypeId], deviceId: [deviceId], positionId: [positionId], ...data },
        googleTagVersions: [googleTagVersion['@id']],
      })
    );

    setOpen(false);
  };

  const cells = useMemo(
    () =>
      headers.map((header) => {
        const filteredPositions = positions.filter((position) => position.source === header);

        let newCell = {
          id: v4(),
          pagetype: '',
          pagetypeId,
          device: '',
          deviceId,
          position: '',
          positionId,
          source: header,
          currentSizes: [],
        };

        filteredPositions.forEach((filtered) => {
          newCell = {
            ...newCell,
            ...filtered,
            currentSizes: [...newCell.currentSizes, ...filtered.currentSizes],
          };
        });

        return newCell;
      }),
    [positions, headers, pagetypeId, deviceId, positionId]
  );

  const handlePositionAction = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      dispatch(
        addPositionsModification({
          operation: 'removePosition',
          metadata: { pagetypeId: [pagetypeId], deviceId: [deviceId], positionId: [positionId] },
          googleTagVersions: [googleTagVersion['@id']],
        })
      );
    },
    [deviceId, dispatch, googleTagVersion, pagetypeId, positionId]
  );

  const deleted = useMemo(
    () => (lastPositionModification && lastPositionModification.operation === 'removePosition') ?? !active,
    [lastPositionModification, active]
  );

  const colorMediatype = (mediatype) => {
    switch (mediatype) {
      case 'banner':
        return 'red';
      case 'native':
        return 'blue';
      case 'video':
        return 'green';
      default:
        return 'transparent';
    }
  };

  return (
    <>
      <LineGrid
        columns={headers.length + 1}
        hover={hover > 0}
        onDragEnter={() => setHover((h) => h + 1)}
        onDragLeave={() => setHover((h) => h - 1)}
        onDrop={() => setHover(0)}
        deleted={deleted}
        index={2}
        ref={ref}
      >
        <CellButton sx={{ opacity: '1 !important' }}>
          <MediaTypeColor>
            {mediatypes.map((mediatype) => {
              if (defaultMediatypesTitle.includes(mediatype.genericName)) {
                return (
                  <ColorMediatype
                    title={mediatype.genericName}
                    key={mediatype.id}
                    color={() => colorMediatype(mediatype.genericName)}
                  />
                );
              }
              return <ColorMediatype key={mediatype.id} color="transparent" />;
            })}
          </MediaTypeColor>
          <ListItemIcon sx={{ opacity: deleted && '0.5' }}>
            {added ? (
              <TiTick style={{ backgroundColor: 'green', borderRadius: '50%', color: 'white' }} />
            ) : (
              <CgWebsite />
            )}
          </ListItemIcon>
          <ListItemText primary={positionName} sx={{ opacity: deleted && '0.5' }} />

          <ButtonContainer>
            <Tooltip title={`Modification de mediatypes : ${defaultMediatypesTitle.join(', ')}`} disableInteractive>
              <ButtonWrapper>
                <CustomButton variant="contained" disabled={deleted} onClick={() => handleOpen(0)}>
                  <TbDeviceGamepad size="23" />
                </CustomButton>
              </ButtonWrapper>
            </Tooltip>
            <Tooltip title={deleted ? 'Activation de la position' : 'Désactivation de la position'} disableInteractive>
              <ButtonWrapper>
                {deleted ? (
                  <CustomButton
                    variant="contained"
                    color="success"
                    onClick={() => handleOpen(1)}
                    sx={{ pointerEvents: 'auto', opacity: 1 }}
                  >
                    <FaPlus />
                  </CustomButton>
                ) : (
                  <CustomButton
                    variant="contained"
                    color="error"
                    onClick={handlePositionAction}
                    sx={{ pointerEvents: 'auto', opacity: 1 }}
                  >
                    <FaMinus />
                  </CustomButton>
                )}
              </ButtonWrapper>
            </Tooltip>
          </ButtonContainer>
        </CellButton>

        {cells.length > 0 &&
          cells.map((item) => {
            const key = item.source + pagetypeId + deviceId + positionId;

            return <FinalCell key={key} element={item} />;
          })}
      </LineGrid>

      <MuiModal
        open={open}
        handleClose={() => setOpen(false)}
        title={modalType ? 'Activer la position' : 'Modification des mediatypes'}
      >
        <form onSubmit={handleSubmit(handleModifyMediatypes)}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Mediatypes</InputLabel>
            <Controller
              name="mediatypeIds"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select {...field} label="Mediatypes" multiple>
                  {mediatypes.map((mediatype) => (
                    <MenuItem key={mediatype.id} value={mediatype.id}>
                      {mediatype.genericName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          <Button type="submit" variant="contained" fullWidth>
            Valider
          </Button>
        </form>
      </MuiModal>
    </>
  );
}

PositionGrid.propTypes = {
  pagetypeId: PropTypes.number.isRequired,
  deviceId: PropTypes.number.isRequired,
  node: PropTypes.shape({
    positionName: PropTypes.string.isRequired,
    positionId: PropTypes.number.isRequired,
    added: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
};

export default PositionGrid;
