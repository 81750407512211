import { useSelector } from 'react-redux';
import { selectGoogleTagVersion } from '../../../../../store/GoogleTagVersionSlice';
import { selectCurrentUser } from '../../../../../store/UsersSlice';
import Notification from '../../../../Notifications/Notification';
import Cartomonet from './Cartomonet';
import ExportGoogleTag from './ExportGoogleTag';
import Logout from './Logout';
import { ConnectedAs, HeaderActionContainer, HeaderActionWrapper, NavButtonWrapper } from './NavButtons.style';
import ShowLastRelease from './ShowLastRelease';
import UpdateGoogleTags from './UpdateGoogleTags';

function NavButtons() {
  const googleTagVersion = useSelector(selectGoogleTagVersion);
  const user = useSelector(selectCurrentUser);

  return (
    <NavButtonWrapper>
      {user && (
        <ConnectedAs>
          Connecté en tant que {user.firstname} {user.lastname}
        </ConnectedAs>
      )}
      {googleTagVersion && (
        <HeaderActionWrapper>
          <HeaderActionContainer>
            <ExportGoogleTag />
          </HeaderActionContainer>
        </HeaderActionWrapper>
      )}
      {user?.roles.includes('ROLE_ADMIN') && (
        <HeaderActionWrapper>
          <HeaderActionContainer>
            <UpdateGoogleTags />
          </HeaderActionContainer>
        </HeaderActionWrapper>
      )}
      <HeaderActionWrapper>
        <HeaderActionContainer>
          <ShowLastRelease />
        </HeaderActionContainer>
      </HeaderActionWrapper>
      <HeaderActionWrapper>
        <HeaderActionContainer>
          <Notification />
        </HeaderActionContainer>
      </HeaderActionWrapper>
      <HeaderActionWrapper>
        <HeaderActionContainer>
          <Cartomonet />
        </HeaderActionContainer>
      </HeaderActionWrapper>
      <HeaderActionWrapper>
        <HeaderActionContainer bgc="red">
          <Logout />
        </HeaderActionContainer>
      </HeaderActionWrapper>
    </NavButtonWrapper>
  );
}

export default NavButtons;
