import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartnerGroups, selectGroups } from '../../../../../store/GroupSlice';
import Heading from '../../../../UI/Title/Heading';
import GroupListingItem from './GroupListingItem';

function ListGroups() {
  const partnerGroups = useSelector(selectGroups);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartnerGroups());
  }, [dispatch]);

  return (
    <div>
      <Heading variant="h2">Liste des groupes</Heading>
      <ul>{partnerGroups && partnerGroups.map((group) => <GroupListingItem key={group.id} group={group} />)}</ul>
    </div>
  );
}

export default ListGroups;
