import Heading from '../../UI/Title/Heading';
import { TemplatesContainer, XandrTemplatesWrapper } from './ManageXandr.style';
import PlacementsList from './Placements/PlacementsList';
import ManageTemplatePublisher from './Publisher/ManageTemplatePublisher';

function ManageXandr() {
  return (
    <XandrTemplatesWrapper>
      <Heading>Gestion des templates Xandr</Heading>
      <TemplatesContainer>
        <PlacementsList />
        <ManageTemplatePublisher />
      </TemplatesContainer>
    </XandrTemplatesWrapper>
  );
}

export default ManageXandr;
